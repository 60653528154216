import React, { useEffect, useState } from "react";
import styled from "styled-components";
import Button from "./UI/Buttons/Button";
import Arrow from "./UI/Icons/Arrow";
import Launch from "./UI/Icons/Launch";
import { UseErrorContext } from "contexts";
import { FaWindowClose } from "react-icons/fa";

const Btn = styled.span`      
  .button-container {
  }

  .menu-button {
    //padding: 0.8rem 0.8rem 0.8rem 1.8rem;
    padding: 0.8rem 1.8rem;
    > .button-icon {
      display: flex;
      align-items: center;
    }
  }

  .arrow {
    margin-left: 1.2rem;
    padding-left: 0.8rem;
    border-left: 1px solid white;
    & svg {
      path {
        fill: white;
      }
    }
  }

  .submenu {
    width: 20rem;
    margin-left: -60px;
    z-index: 9999;
    position: absolute;
    display: flex;
    flex-direction: column;
    background-color: var(--color-dark-grey);
    padding: 0.8rem;
    text-transform: uppercase;
    font-family: Montserrat;
    font-weight: 500;
    font-size: 1.2rem;
    border-radius: .8rem;
    > * {
      padding: .8rem;
    }
  }

  .publish-now{
    cursor: pointer;
    border-bottom: 1px solid rgba(255, 255, 255, 0.2);
    &:hover {
      opacity: 0.7;
      transition: all 0.25s ease-in;
    }
  }

  .publish-date input{
    font-size: 1.2rem;
  }

  .publish-date-input{
    display: flex;
  }
`;

const PublishButton = ({ publishDateTime, publish, publishNow }) => {
  const [showMenu, setShowMenu] = useState(false);
  const [date, setDate] = useState(publishDateTime);
  const { updateError } = UseErrorContext();

  const onChangeDate = async (e) => {
    const val = e.target.value.toString();
    setDate(val);
  };

  const _publish = async () => {
    await publish(date);
    setShowMenu(false); 
  };

  const _publishNow = async () => {
    await publishNow();
    setShowMenu(false);
  };

  const clearPublishDateTime = async () => {
    setDate(null);
    await publish(null);
    setShowMenu(false); 
  };

  return (
      <Btn>   
          <Button className="menu-button">
            <div onClick={() => _publish()}>Publish</div>
            <div className="arrow" onClick={() => setShowMenu(!showMenu)}><Arrow transform={!showMenu} /></div>
          </Button>
          {showMenu &&
            <div className="submenu">
              <div className="publish-now" onClick={() => _publishNow()}>
                <Launch/>   Publish now   <Launch/>
              </div>
              <div className="publish-date">
                Publish at: 
                <div className="publish-date-input">
                  <input type="datetime-local" onChange={onChangeDate} value={date}/>
                  {publishDateTime && <div onClick={() => clearPublishDateTime()} className="icon-button">
                    <FaWindowClose/>
                  </div>}
                </div>
              </div>
            </div>
          }
      </Btn>
  );
};

export default PublishButton;
