import React from "react";
import { FaTrash } from "react-icons/fa";

export default function Delete(props) {

  return (
    <>
      <div className="icon-button">
        <FaTrash/>
      </div>
    </>
  );
}
