import { getAxiosClient } from "../axios";

export const getEvents = async (id, params) => {
  const axiosClient = await getAxiosClient();

  return await axiosClient
    .get(`/organizations/${id}/events`, {params})
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      console.log("error", error);
      return Promise.reject(error);
    });
};

export const addEvent = async (organizationId, event) => {
  const axiosClient = await getAxiosClient();

  return await axiosClient
    .post(`/organizations/${organizationId}/events`, event)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      console.log("error", error);
      return Promise.reject(error);
    });
};

export const getEvent = async (eventId) => {
  const axiosClient = await getAxiosClient();

  return await axiosClient
    .get(`/events/${eventId}`)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      console.log("error", error);
      return Promise.reject(error);
    });
};

export const updateEvent = async (event) => {
  const eventData = await MapUpdateEventData(event);

  const axiosClient = await getAxiosClient();

  return await axiosClient
    .put(`/events/${event.id}`, eventData)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      console.log("error", error);
      return Promise.reject(error);
    });
};

export const deleteEvent = async (eventId) => {
  const axiosClient = await getAxiosClient();

  return await axiosClient
    .delete(`/events/${eventId}`)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      console.log("error", error);
      return Promise.reject(error);
    });
};

export const uploadCover = async (id, file) => {
  const axiosClient = await getAxiosClient();

  const formData = new FormData();
  formData.append("file", file);

  return await axiosClient
    .put(`/events/${id}/cover`, formData, { "content-type": "multipart/form-data" })
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      console.log("error", error);
      return Promise.reject(error);
    });

};

export const publishEvent = async (id) => {
  const axiosClient = await getAxiosClient();

  return await axiosClient
    .post(`/events/${id}/ispublished`)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      console.log("error", error);
      return Promise.reject(error);
    });
};

export const addTranslation = async (id, translation) => {
  const axiosClient = await getAxiosClient();

  return await axiosClient
    .post(`/events/${id}/translations`, translation)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      console.log("error", error);
      return Promise.reject(error);
    });
};

export const updateTranslation = async (id, translationId, translation) => {
  const axiosClient = await getAxiosClient();

  return await axiosClient
    .put(`/events/${id}/translations/${translationId}`, translation)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      console.log("error", error);
      return Promise.reject(error);
    });
};

export const removeTranslation = async (id, translationId) => {
  const axiosClient = await getAxiosClient();

  return await axiosClient
    .delete(`/events/${id}/translations/${translationId}`)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      console.log("error", error);
      return Promise.reject(error);
    });
};

export const addDocument = async (id, document, showAsContent = false) => {
  const axiosClient = await getAxiosClient();
  console.log('document', document)
  const formData = new FormData();
  formData.append("file", document.file);
  formData.append("name", document.name);
  if (document.languageCode)
    formData.append("languageCode", document.languageCode);
    
  formData.append("showAsContent", showAsContent);
  
  return await axiosClient
    .post(`/events/${id}/documents`, formData, { "content-type": "multipart/form-data" })
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      console.log("error", error);
      return Promise.reject(error);
    });
};

export const editDocument = async (id, documentId, document) => {
  const axiosClient = await getAxiosClient();

  return await axiosClient
    .put(`/events/${id}/documents/${documentId}`, document)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      console.log("error", error);
      return Promise.reject(error);
    });
};

export const removeDocument = async (id, documentId) => {
  const axiosClient = await getAxiosClient();

  return await axiosClient
    .delete(`/events/${id}/documents/${documentId}`)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      console.log("error", error);
      return Promise.reject(error);
    });
};

export const getDocumentContent = async (eventId, documentId, filename, isLink = true) => {
  const axiosClient = await getAxiosClient();

  return await axiosClient
    .get(`/events/${eventId}/documents/${documentId}/content`, { responseType: 'blob' })
    .then(response => {
      if(isLink){
        let url = window.URL.createObjectURL(response.data);
        let a = document.createElement('a');
        a.href = url;
        a.download = filename;
        a.click();
      }else{
        return response.data
      }
  })
  .catch((error) => {
      console.log("error", error);
      return Promise.reject(error);
  });
};

export const addLink = async (id, link) => {
  const axiosClient = await getAxiosClient();

  return await axiosClient
    .post(`/events/${id}/links`, link)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      console.log("error", error);
      return Promise.reject(error);
    });
};

export const editLink = async (id, linkId, link) => {
  const axiosClient = await getAxiosClient();
  
  return await axiosClient
    .put(`/events/${id}/links/${linkId}`, link)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      console.log("error", error);
      return Promise.reject(error);
    });
};

export const removeLink = async (id, linkId) => {
  const axiosClient = await getAxiosClient();

  return await axiosClient
    .delete(`/events/${id}/links/${linkId}`)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      console.log("error", error);
      return Promise.reject(error);
    });
};

export const getCategories = async () => {
  const axiosClient = await getAxiosClient();

  return await axiosClient
    .get(`/events/categories`)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      console.log("error", error);
      return Promise.reject(error);
    });
};

export const MapUpdateEventData = async (event) => {
  //making a shallow copy of the event. We remove the title/description field and don't want to edit the original object
  const eventData = {...event};
  eventData.Details = {
      title: eventData.title,
      description: eventData.description,
      languageCode: "EN"
  }  
  delete eventData.title;
  delete eventData.description; 

  eventData.communicationGroups = eventData.communicationGroups.map(x => x.id);

  return eventData;
}

export const getTimeZones = async () => {
  const axiosClient = await getAxiosClient();

  return await axiosClient
    .get(`/timezones`)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      console.log("error", error);
      return Promise.reject(error);
    });
};

export const getAdminEventSections = async (id) => {
  const axiosClient = await getAxiosClient();

  return await axiosClient
    .get(`/admin/events/${id}/eventsections`)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      console.log("error", error);
      return Promise.reject(error);
    });
};

export const getEventSections = async (id, language) => {
  const axiosClient = await getAxiosClient(language);

  return await axiosClient
    .get(`/events/${id}/eventsections`)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      console.log("error", error);
      return Promise.reject(error);
    });
};

export const getEventSection = async (id) => {
  const axiosClient = await getAxiosClient();

  return await axiosClient
    .get(`/eventsections/${id}`)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      console.log("error", error);
      return Promise.reject(error);
    });
};

export const addEventSection = async (id, data) => {
  const axiosClient = await getAxiosClient();

  return await axiosClient
    .post(`/events/${id}/eventsections`, data)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      console.log("error", error);
      return Promise.reject(error);
    });
};

export const editEventSection = async (id, title, text, isVisible) => {
  const axiosClient = await getAxiosClient();

  return await axiosClient
    .put(`/eventsections/${id}`, {
      title,
      text,
      isVisible,
    })
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      console.log("error", error);
      return Promise.reject(error);
    });
};

export const editImageEventSection = async (id, file) => {
  const axiosClient = await getAxiosClient();

  const formData = new FormData();
  formData.append("file", file);

  return await axiosClient
    .put(`/eventsections/${id}/image`, formData, { "content-type": "multipart/form-data" })
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      console.log("error", error);
      return Promise.reject(error);
    });
};

export const deleteEventSection = async (id) => {
  const axiosClient = await getAxiosClient();

  return await axiosClient
    .delete(`/eventsections/${id}`)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      console.log("error", error);
      return Promise.reject(error);
    });
};

export const moveEventSequenceUp = async (eventId, id) => {
  const axiosClient = await getAxiosClient();

  return await axiosClient
    .put(`/events/${eventId}/eventsections/${id}/move-up`)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      console.log("error", error);
      return Promise.reject(error);
    });
};

export const moveEventSequenceDown = async (eventId, id) => {
  const axiosClient = await getAxiosClient();

  return await axiosClient
    .put(`/events/${eventId}/eventsections/${id}/move-down`)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      console.log("error", error);
      return Promise.reject(error);
    });
};

export const sendMessage = async (id, message) => {
  const axiosClient = await getAxiosClient();

  return await axiosClient
    .post(`/events/${id}/questions`, message)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      console.log("error", error);
      return Promise.reject(error);
    });
};

export const updateEventSectionTranslation = async (id, translation) => {
  const axiosClient = await getAxiosClient();

  return await axiosClient
    .put(`/eventsections/${id}/translations`, translation)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      console.log("error", error);
      return Promise.reject(error);
    });
};

export const removeEventSectionTranslation = async (id, languageCode) => {
  const axiosClient = await getAxiosClient();

  return await axiosClient
    .delete(`/eventsections/${id}/translations/${languageCode}`)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      console.log("error", error);
      return Promise.reject(error);
    });
};


export const addRelatedEvent = async (parentId, childId) => {
  const axiosClient = await getAxiosClient();

  return await axiosClient
    .post(`/events/${parentId}/children/${childId}`)

    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      console.log("error", error);
      return Promise.reject(error);
    });
};

export const getFollowedEvents = async (params) => {
  const axiosClient = await getAxiosClient();

  return await axiosClient
    .get(`/me/events`, {params})
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      console.log("error", error);
      return Promise.reject(error);
    });
};

export const removeRelatedEvent = async (parentId, childId) => {
  const axiosClient = await getAxiosClient();

  return await axiosClient
    .delete(`/events/${parentId}/children/${childId}`)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      console.log("error", error);
      return Promise.reject(error);
    });
};

export const getFollowedEvent = async (id, language) => {
  const axiosClient = await getAxiosClient(language);

  return await axiosClient
    .get(`/me/events/${id}`)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      console.log("error", error);
      return Promise.reject(error);
    });
};

export const editIsFeatured = async (id, isFeatured) => {
  const axiosClient = await getAxiosClient();

  return await axiosClient
    .put(`/events/${id}/isfeatured`, {
      "isFeatured": isFeatured,
    })
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      console.log("error", error);
      return Promise.reject(error);
    });
};

export const editLinkIsFeatured = async (id, isFeatured) => {
  const axiosClient = await getAxiosClient();

  return await axiosClient
    .put(`/eventlinks/${id}/isfeatured`, {
      "isFeatured": isFeatured,
    })
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      console.log("error", error);
      return Promise.reject(error);
    });
};


export const setPublishDate = async (id, date) => {
  const axiosClient = await getAxiosClient();

  return await axiosClient
    .put(`/events/${id}/publishdatetime`, {
      "datetime": date,
    })
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      console.log("error", error);
      return Promise.reject(error);
    });
};