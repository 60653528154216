import styled from "styled-components";
import React, { useState, useEffect } from "react";
import { ValidateAccess } from "api/services/authorization";
import { UseUserContext } from "contexts";

const B = styled.button`
  display: inline-flex;
  align-items: center;
  padding: 0.8rem 1.8rem;
  border-radius: .4rem;
  background-color: var(--color-accent-prim);
  color: white;
  border: none;
  text-transform: uppercase;
  font-family: Montserrat;
  font-weight: 500;
  font-size: 1.2rem;
  line-height: 12px;
  text-align: center;
  border: solid 1px transparent;
  cursor: pointer;
  &.secondary {
    background-color: transparent;
    border: solid 1px white;
  }
  &.tertiary {
    background-color: var(--color-accent-sec);
  }
  &:hover {
    /*background-color: transparent;
    border: solid 1px var(--color-accent-prim);*/
    opacity: 0.7;
    transition: all 0.25s ease-in;
  }
  &.disabled {
    opacity: 0.6;
    cursor: not-allowed;
  }
  .button-icon{
    margin-right: .4rem;
    font-size: 1.2rem;
    color: white;
  }
`;
const A = styled.a``;

export default function Button({label, url, onClick, type, buttonType, disabled, roles, className, children, target}) {
  const [hide, setHide] = useState(true);
  const { permissions } = UseUserContext();

  useEffect(async () => {
    const access = ValidateAccess(roles, permissions);
    setHide(!access);
  }, [permissions]);

  if (url) {
    return (
      <a href={url} target={target}>
        <B onClick={onClick} roles={roles} className={`${type} ${disabled ? "disabled " + className : className}`} type={buttonType || "button"}>
          <div className="button-icon">{children}</div>
          {label}
        </B>
      </a>
    );
  } else {
    return (
      <>
      { !hide &&
        <B className={`${type} ${disabled ? "disabled " + className : className}`} onClick={(e) => onClick(e)} roles={roles} type={buttonType || "button"} disabled={disabled}>
          <div className="button-icon">{children}</div>
          {label}
        </B>}
      </>
    );
  }
}

Button.defaultProps = {
  label: "",
  onClick: () => console.log("click"),
  type: "primary",
};
