import { getAxiosClient } from "../axios";
import { getProducts } from "./product";

export const getOrganzationUser = async () => {
  const axiosClient = await getAxiosClient();

  return await axiosClient
    .get(`/me/organizationusers`)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      console.log("error", error);
      return Promise.reject(error);
    });
};

export const getUser = async () => {
  const axiosClient = await getAxiosClient();

  return await axiosClient
    .get(`/me/users`)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      console.log("error", error);
      console.log("error", error.code);
      return Promise.reject(error);
    });
};

export const addUser = async () => {
  const axiosClient = await getAxiosClient();

  return await axiosClient
    .post(`/me/users`)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      console.log("error", error);
      console.log("error", error.code);
      return Promise.reject(error);
    });
};

export const editUser = async (user) => {
  const axiosClient = await getAxiosClient();

  return await axiosClient
    .put(`/me/users`, user)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      console.log("error", error);
      alert(error?.response?.data?.title);
      return Promise.reject(error);
    });
};

export const getUsers = async (id, params) => {
  const axiosClient = await getAxiosClient();

  return await axiosClient
    .get(`/organizations/${id}/organizationusers`, { params: {...params}})
    .then((response) => {
      return response;
    })
    .catch((error) => {
      console.log("error", error);
      return Promise.reject(error);
    });
};

export const addOrganizationUser= async (organizationId, user) => {
  console.log(user)
  const axiosClient = await getAxiosClient();

  return await axiosClient
    .post(`/organizations/${organizationId}/organizationusers`, user)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      console.log("error", error);
      return Promise.reject(error);
    });
};

export const updateUser = async (id, user) => {
  const axiosClient = await getAxiosClient();

  return await axiosClient
    .put(`/organizationusers/${id}`, user)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      console.log("error", error);
      alert(error?.response?.data?.title);
      return Promise.reject(error);
    });
};

export const deleteUser = async (id) => {
  const axiosClient = await getAxiosClient();

  return await axiosClient
    .delete(`/organizationusers/${id}`)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      console.log("error", error);
      return Promise.reject(error);
    });
};

export const getDepartments = async () => {
  const axiosClient = await getAxiosClient();

  return await axiosClient
    .get(`/departments`)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      console.log("error", error);
      return Promise.reject(error);
    });
};

export const getPermissions = async (id) => {
  const axiosClient = await getAxiosClient();

  return await axiosClient
    .get(`/permissions`)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      console.log("error", error);
      return Promise.reject(error);
    });
};

export const updateDepartments = async (userID, departments) => {
  const axiosClient = await getAxiosClient();

  return await axiosClient
    .put(`/organizationusers/${userID}/departments`, { departments })
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      console.log("error", error);
      return Promise.reject(error);
    });
};

export const updatePermissions = async (userID, permissions) => {
  const axiosClient = await getAxiosClient();

  return await axiosClient
    .put(`/organizationusers/${userID}/permissions`, { permissions })
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      console.log("error", error);
      return Promise.reject(error);
    });
};

export const getFollowers = async (organizationId, params) => {
  const axiosClient = await getAxiosClient();

  return await axiosClient
    .get(`/organizations/${organizationId}/followers`, { params: {...params}} )
    .then((response) => {
      return response;
    })
    .catch((error) => {
      console.log("error", error);
      return Promise.reject(error);
    });
};   

export const completeRegistration = async (code) => {
  const axiosClient = await getAxiosClient();

  return await axiosClient
    .post(`/organizationuserinvites/${code}/completed`)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      console.log("error", error);
      return Promise.reject(error);
    });
};

export const getFilters = async (organizationId) => {
  const products = await getProducts(organizationId);
  return {"products": products};
};

export const resendInvitation = async (userId) => {
  const axiosClient = await getAxiosClient();

  return await axiosClient
    .post(`/organizationusers/${userId}/invitemail`)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      console.log("error", error);
      return Promise.reject(error);
    });
};

export const AddUserCompany = async (company) => {
  const axiosClient = await getAxiosClient();

  return await axiosClient
    .post(`/me/companies`, company)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      console.log("error", error);
      return Promise.reject(error);
    });
};

export const DeleteUserCompany = async (id) => {
  const axiosClient = await getAxiosClient();

  return await axiosClient
  .delete(`/me/companies/${id}`)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      console.log("error", error);
      return Promise.reject(error);
    });
};