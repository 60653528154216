import React, { useState, useEffect } from "react";
import HeaderDropDown from "components/UI/HeaderDropDown";
import PageHeaderSub from "components/PageHeaderSub";
import Page from "components/UI/Elements/Page";
import FollowedEventGrid from "pages/events/Components/FollowedEventGrid";
import SearchBar from "../../components/UI/SearchBar";
import { getFollowedEvents } from "../../api/services/event";
import { UseErrorContext } from "contexts";
import { delay } from "api/helpers/datahelper";
import { useParams } from "react-router-dom";
import { BackButton } from "components/UI/Buttons/BackButton";
import { getOrganization } from "api/services/organization";
import { Link } from "react-router-dom";
import Button from "components/UI/Buttons/Button";
import moment from "moment";
import { useTranslation } from 'react-i18next';

const FollowedOrganizationEvents = () => {
  const { id } = useParams();
  const [search, setSearch] = useState("");
  const [pastEvents, setPastEvents] = useState(null);
  const [currentEvents, setCurrentEvents] = useState(null);
  const [organization, setOrganization] = useState(null);
  const { updateError } = UseErrorContext();
  const { t, i18n } = useTranslation();

  useEffect(async () => {
    refresh();
  }, [search]);

  const refresh = async () => {  
    try{ 
      const pastEventsData = await getFollowedEvents({
        search: search,
        to: moment().startOf("day").format("YYYY-MM-DD"),
        organizationId: id,
        order: "DESC",
      });

      setPastEvents(pastEventsData);
    
      const currentEventsData = await getFollowedEvents({
        search: search,
        from: moment().startOf("day").format("YYYY-MM-DD"),
        organizationId: id,
        order: "ASC",
      });

      setCurrentEvents(currentEventsData);
      
      const p = await getOrganization(id);
      setOrganization(p);
    } catch (err) {
      updateError(err);
    }
  };

  // Search
  const onSearch = async (e) => {
    await delay(1000);
    const val = e.target.value.toString();
    setSearch(val);
  };

  return (
    <>
      <Page>        
        <div className="navigation-banner">              
              <BackButton link={"/organizations/view/" + id}/>
              <ul className="breadcrumbs">
                <li>
                  <a href="/organizations">{t('organizations')}</a>
                </li>
                <li>
                  <a href={"/organizations/view/" + id}>{organization?.name}</a>
                </li>
                <li>
                  <span className="selected">{t('actions')}</span>
                </li>
              </ul>
        </div>  
        <div className="page__header">
          <h1></h1>
          <PageHeaderSub show>
            <Link to="events-calendar">
              <Button label={t('calendarview')}/>
            </Link>
          </PageHeaderSub>
        </div>
        <div className="page__inner">
          <div className="searchBarContainer">
            <SearchBar onChange={onSearch}/>
          </div>
          <HeaderDropDown titleHeader={t('upcomingactions')}>
            <FollowedEventGrid canEdit events={currentEvents} refresh={refresh} link={`/organizations/view/${id}/events/`}/>
          </HeaderDropDown>
          <HeaderDropDown titleHeader={t('pastactions')}>
            <FollowedEventGrid canEdit events={pastEvents} refresh={refresh} link={`/organizations/view/${id}/events/`}/>
          </HeaderDropDown>
        </div>
      </Page>
    </>
  );
};

export default FollowedOrganizationEvents;
