import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { useParams } from "react-router-dom";
import HeaderDropDown from "../../components/UI/HeaderDropDown";
import Page from "../../components/UI/Elements/Page";
import Loader from "components/UI/Loader";
import { BackButton } from "components/UI/Buttons/BackButton";
import { UseErrorContext } from "contexts";
import PageHeaderSub from "components/PageHeaderSub";
import { getMyQuestion, getMyQuestions } from "api/services/communication";
import QuestionInfo from "./Components/QuestionInfo";

const Container = styled.div``;

const ViewQuestion = () => {
  const { id } = useParams();
  const [question, setQuestion] = useState(null);
  const { updateError } = UseErrorContext();

  useEffect(async () => {
    await refresh();
  }, []);

  const refresh = async () => {
    try{
      //const q = await getMyQuestion(id);
      let questions = await getMyQuestions();
      let q = questions.data.filter(x => x.id == id)[0];
      console.log(q)
      setQuestion(q);
    } catch (err) {
      updateError(err);
    }
  };  

  return (
    <>
      <Page>
        {!question ? (
          <Loader />
        ) : (
          <Container>
            <div className="navigation-banner">              
              <BackButton link="/my-questions"/>
              <ul className="breadcrumbs">
                <li>
                  <a href="/my-questions">Q/A</a>
                </li>
                <li>
                  <span className="selected">{question?.event?.name}</span>
                </li>
              </ul>
            </div>     
            <div className="page__header">             
              <h1></h1>
              <PageHeaderSub >
              </PageHeaderSub>
            </div>
            <div className="page__inner">
              <HeaderDropDown titleHeader="">              
                <QuestionInfo question={question}/>
              </HeaderDropDown>
            </div>
          </Container>
        )}
      </Page>
    </>
  );
};

export default ViewQuestion;
