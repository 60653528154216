import moment from "moment";

// moment(item.date).format("YYYY-MM-DD HH:mm")
export const FormatDate = (date) => {
  //return new Date(date).toLocaleDateString();
  return date?.includes("T") ? date.split('T')[0] : date;
};

export const GetEventDate = (event) => {
  var startDate = event.startDateUtc ?? event.startDate;
  var endDate = event.endDateUtc ?? event.endDate;
  return FormatDateTime(startDate) == FormatDateTime(endDate) ? FormatDateTimeDisplay(startDate) : `${FormatDateTimeDisplay(startDate)} - ${FormatDateTimeDisplay(endDate)}`;
};

export const FormatDateTime = (date) => {
  return moment(date).format("YYYY-MM-DDTHH:mm");
};

export const FormatDateTimeDisplay = (date) => {
  return moment(date).format("YYYY-MM-DD HH:mm");
};

export const FormatDateTimeOffset = (date, offset) => {
  return moment(date).utcOffset(offset).format("YYYY-MM-DD[T]HH:mm:ss.SSSZ");
};

export const FormatLocalToUtcDateTime = (date) => {
  return moment(date).utc().format("YYYY-MM-DDTHH:mm:ss.SSS");
}

export const ConvertToDate = (date) => {
  return moment(date, "YYYY-MM-DDTHH:mm");
};

export const ConvertToDateToUTC = (dateString) => {
  // Parse the date string
  const parsedDate = moment(dateString);

  // Convert the date to UTC
  const utcDate = parsedDate.utc();

  // Format the UTC date in your desired format
  const formattedUtcDate = utcDate.format("YYYY-MM-DDTHH:mm:ss[Z]");

  console.log("CONVERT DATE TO UTC", dateString, formattedUtcDate)

  return formattedUtcDate;
};

export const ConvertToLocaleDate = (dateString) => {
  const date = new Date(dateString);

  // Get the user's time zone offset in minutes
  const timeZoneOffset = new Date().getTimezoneOffset();

  // Adjust the date by the time zone offset
  const localDate = new Date(date.getTime() - timeZoneOffset * 60000);

  // Format the date 
  const formattedLocalDate = moment(localDate).format("YYYY-MM-DDTHH:mm");

  console.log("CONVERT DATE TO LOCALE", dateString, formattedLocalDate)
  return formattedLocalDate;
};

export const GetPagination = (headers) => {
  let pagination = JSON.parse(headers["x-pagination"]);
  return pagination;
};

export const GetNrOfPages = (headers) => {
  let pagination = GetPagination(headers);
  let totalCount = pagination.totalCount;
  let pageSize = pagination.pageSize;

  return Math.ceil(totalCount / pageSize);
}

// First group by (result is dictionary) then convert into array
export const groupBy = function (xs, key) {
  return xs.reduce(function (rv, x) {
    (rv[x[key]] = rv[x[key]] || []).push(x);
    return rv;
  }, {});
};

// First group by (result is dictionary) then convert into array
export const groupByToList = function (xs, key) {
  let groupByDict = groupBy(xs, key);

  let result = [];
  Object.keys(groupByDict).map(dictKey => {
    let obj = { key: dictKey, values: groupByDict[dictKey] };
    result.push(obj);
  });

  return result;
};

export const formatDescription = function (value, length = 100) {
  return value?.length > length ? value.substring(0, length - 3) + "..." : value;
};

export const delay = ms => new Promise(
  resolve => setTimeout(resolve, ms)
);

export const htmlToRawText = (htmlString) => {
  if (!htmlString) return;
  const parsedAnswer = new DOMParser().parseFromString(htmlString, "text/html");
  return parsedAnswer?.body?.innerText?.trim();
};