import React from "react";
import styled from "styled-components";
import Back from "../Icons/Back";
import { useHistory } from "react-router-dom";
import { useTranslation } from 'react-i18next';

const Container = styled.div`
`;

export const BackButton = ({link}) => {  
  const history = useHistory();
  const { t, i18n } = useTranslation();

  return (
    <div onClick={() => history.push(link)} className="icon-button back">
      <Back />
      <label>{t('back')}</label>
    </div>
  );
};