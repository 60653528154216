import React, { useState, useEffect } from "react";
import styled from "styled-components";
import Loader from "../../../components/UI/Loader";
import Kalend, { CalendarView } from 'kalend' // import component
import 'kalend/dist/styles/index.css'; // import styles
import { useHistory } from "react-router-dom";
import { date } from "yup";

const Container = styled.div`
.Kalend__button{
  min-height: 30px;
}
  .Kalend__header_calendar_button-text-selected-dark {
      color: var(--color-accent-prim) !important;
  }
  .Kalend__Calendar__table, .Kalend__Calendar__table-dark {
    height: 500px !important;
  }
  .Kalend__NewEvent{
    display: none;
  }
`;

// https://docs.kalend.org/docs/intro

const FollowedEventCalendar = ({ events }) => {
  const history = useHistory();
  const [selectedView, setSelectedView] = useState(null);
  const [calendarEvents, setCalendarEvents] = useState([]);
 
  useEffect(async () => {
    if (!events) return;
    let array = [];
    events.forEach((x) => array.push({      
      "id": x.id,
      "startAt": x.startDateUtc ?? x.startDate,
      "endAt": x.endDateUtc ?? x.endDate,
      "timezoneStartAt": x.timeZone,
      "summary": x.title, 
    }));
    setCalendarEvents(array);
  }, [events]);

  const onEventClick = (data) => {
    history.push(`/followed-events/view/${data.id}`)
  };

  const onNewEventClick = (data) => {
    // do something
  };

  const onSelectView = (data) => {
    // do something
  };

  const onPageChange = (data) => {
    // do something
  };

  return (
    <Container >
      {!events ? (
        <Loader center />
      ) : (
        <Kalend
          onEventClick={onEventClick}
          onNewEventClick={onNewEventClick}
          events={calendarEvents}
          initialDate={new Date().toISOString()}
          hourHeight={40}
          initialView={CalendarView.AGENDA}
          disabledViews={[CalendarView.THREE_DAYS]}
          onSelectView={onSelectView}
          //selectedView={selectedView}
          onPageChange={onPageChange}
          timeFormat={'24'}
          weekDayStart={'Monday'}
          calendarIDsHidden={['work']}
          language={'en'}
          showTimeLine={false}
          isDark={true}
          autoScroll={true}
          isNewEventOpen={false}
          disabledDragging={true}
          colors={{
            dark: {
              primaryColor: 'var(--color-accent-prim)',
              secondaryColor: 'var(--color-accent-sec)',
            },
          }}
        />
      )}      
    </Container>
  );
};

FollowedEventCalendar.defaultProps = {
  items: Array.from(Array(3)),
};

export default FollowedEventCalendar;
