import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { UseOrganizationContext, UseErrorContext } from "contexts";
import AddButton from "./UI/Buttons/AddButton";
import Modal from "components/UI/Modal";
import Trash from "components/UI/Icons/Trash";
import Edit from "components/UI/Icons/Edit";
import { TranslationForm } from "./TranslationForm";
import { capitalizeFirstLetter } from "utils";
import HTMLRenderView from "./UI/Blocks/HTMLRenderView";
import HeaderDropDown from "components/UI/HeaderDropDown";
import { getAdminOrganization } from "api/services/organization";

const Container = styled.div`
  overflow: auto;
  table {
    width: 100%;
    min-width: 100rem;
  }

  .icon-button__container {
    display: flex;
    justify-content: flex-end;
  }

  .icon-button {
    width: 20px;
  }
`;

const LANGS = ["NL", "FR", "DE", "ES"];

const TranslationGrid = ({ item, type = "default", fields, translations, validationSchema, remove, add, edit }) => {
  const [showModalEdit, setShowModalEdit] = useState(false);
  const [selectedTranslation, setSelectedTranslation] = useState(false);
  const [showModalDelete, setShowModalDelete] = useState(false);
  const [languages, setLanguages] = useState([]);
  const [loadingLanguages, setLoadingLanguages] = useState(false);

  const organizationId = UseOrganizationContext()?.id;
  const { updateError } = UseErrorContext();

  useEffect(async () => {
    if (!organizationId) return;
    setLoadingLanguages(true);
    try {
      const getCompanylanguages = await getAdminOrganization(organizationId);
      setLanguages(getCompanylanguages?.languages || []);
      setLoadingLanguages(false);
    } catch (err) {
      setLoadingLanguages(false);
      updateError(err);
    }
  }, [organizationId]);

  const _addTranslation = () => {
    setSelectedTranslation(null);
    setShowModalEdit(true);
  };

  const _editTranslation = (translation) => {
    setSelectedTranslation(translation);
    setShowModalEdit(true);
  };

  const showDeleteModal = async (translation) => {
    setSelectedTranslation(translation);
    setShowModalDelete(true);
  };

  const _save = async (values) => {
    console.log("valuesxxx", values);
    const editedTranslation = translations.find((x) => x?.languageCode == values?.languageCode);
    return editedTranslation ? await edit(editedTranslation.id, values) : await add(values);
  };

  const _delete = async () => {
    console.log("selectedTranslation", selectedTranslation);
    if (type == "action") {
      await remove(translations.find((x) => x?.languageCode == selectedTranslation)?.id);
    } else {
      await remove(item.id, selectedTranslation);
    }

    setShowModalDelete(false);
  };

  return (
    <Container>
      <HeaderDropDown showCollapse titleHeader={item?.title || "EN (default)"}>
        <TranslationForm type={type} fields={fields} item={item} translation={selectedTranslation} validationSchema={validationSchema} onClose={() => setShowModalEdit(false)} save={_save} />
      </HeaderDropDown>

      {languages.map((LG) => (
        LG != "EN" && <HeaderDropDown showCollapse collapseBody titleHeader={`${LG} (${translations.findIndex((translation) => translation.languageCode == LG) >= 0 ? "Completed" : "Not completed"}) `}>
          {showModalDelete && (
            <Modal title={`Delete "${selectedTranslation}"`} onClose={() => setShowModalDelete(false)} onSubmit={() => _delete()} cancelLabel={"Cancel"} submitLabel={"Delete"}>
              <div>Are you sure you want to delete this translation?</div>
            </Modal>
          )}

          {translations.findIndex((translation) => translation.languageCode == LG) >= 0 && (
            <div className="icon-button__container">
              <div className="icon-button" onClick={() => showDeleteModal(LG)}>
                <Trash className="icon_button" />
              </div>
            </div>
          )}
          <TranslationForm
            key={LG}
            fields={fields}
            item={item}
            translation={LG}
            validationSchema={validationSchema}
            onClose={() => setShowModalEdit(false)}
            save={_save}
            targetLanguage={LG}
            initialValues={{ ...translations.find((translation) => translation.languageCode == LG) }}
          />
        </HeaderDropDown>
      ))}
    </Container>
  );
};

TranslationGrid.defaultProps = {
  translations: Array.from(Array(0)),
};

export default TranslationGrid;
