import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { getPermissions, updatePermissions } from "../../../api/services/user";
import Button from "components/UI/Buttons/Button";
import SwitchButtonOn from "components/UI/Buttons/SwitchButtonOn";
import { PermissionGroupMapping } from "../../../constants";
import { UseErrorContext } from "contexts";

const Container = styled.div`
  display: grid;
  grid-template-columns: 570px 570px;
  table {
    width: 100%;
    min-width: 0;
    margin-bottom: 4rem;
    tr {
      display: flex;
    }
    td,
    th {
      width: 35rem;
    }
  }
`;

const EditPermissionsView = ({ user, onClose }) => {
  const [permissions, setPermissions] = useState([]);
  const [selectedPermissions, setSelectedPermissions] = useState([]);
  const { updateError } = UseErrorContext();

  useEffect(async () => {
    try {
      var arr = await getPermissions();

      console.log(arr);

      const withSpecificName = [];
      const withoutSpecificName = [];

      arr.forEach((item) => {
        if (item.name === "Admin") {
          withSpecificName.push(item);
        } else {
          withoutSpecificName.push(item);
        }
      });

      // Sort the array withoutSpecificName in ascending order
      withoutSpecificName.sort((a, b) => a.name.localeCompare(b.name));

      // Combine both arrays, putting elements with specificName first
      const sortedArray = withSpecificName.concat(withoutSpecificName);

      setPermissions(sortedArray);

      setSelectedPermissions(user.permissions);
    } catch (err) {
      updateError(err);
    }
  }, []);

  const _addPermission = async (permission) => {
    setSelectedPermissions([...selectedPermissions, permission]);
  };

  const _removePermission = async (permission) => {
    setSelectedPermissions(selectedPermissions.filter((item) => item.id !== permission.id));
  };

  const handleSubmit = async () => {
    try {
      var permissionIds = selectedPermissions.map(function (x) {
        return x.id;
      });
      await updatePermissions(user.id, permissionIds);
      onClose();
    } catch (err) {
      updateError(err);
    }
  };

  const convertPermissionToHeaderText = (permission) => {
    let permissionGroup = permission.name.split(".")[0];
    return PermissionGroupMapping[permissionGroup] ?? permissionGroup;
  };

  var groupBy = function (xs, key) {
    const objByTpe = xs.reduce(function (rv, x) {
      (rv[x[key].split(".")[0]] = rv[x[key].split(".")[0]] || []).push(x);
      return rv;
    }, {});

    let array = [];
    Object.keys(objByTpe).forEach((permissiongroup) => array.push(objByTpe[permissiongroup]));
    return array;
  };

  const permissionsByType = permissions && groupBy(permissions, "name");
  console.log(permissionsByType);

  return (
    <>
      <div className="modal-buttons">
        <Button label="cancel" onClick={() => onClose()} type="secondary" />
        <Button onClick={handleSubmit} label="Save" />
      </div>
      <Container>
        <table>
          <tbody>
            <tr>
              <td>Turn all on / off</td>
              <td>
                <SwitchButtonOn onSwitchOn={() => setSelectedPermissions(permissions)} onSwitchOff={() => setSelectedPermissions([])} />
              </td>
            </tr>
          </tbody>
        </table>
        {permissionsByType?.map((permissions) => (
          <div>
            <h2> {convertPermissionToHeaderText(permissions[0])}</h2>
            <table>
              <tbody>
                {permissions.map((permission, idx) => (
                  <tr key={idx}>
                    <td>{permission.description}</td>
                    <td>
                      <SwitchButtonOn on={selectedPermissions?.find((el) => el.id === permission.id)} onSwitchOn={() => _addPermission(permission)} onSwitchOff={() => _removePermission(permission)} />
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        ))}
      </Container>
      <div className="modal-buttons">
        <Button label="cancel" onClick={() => onClose()} type="secondary" />
        <Button onClick={handleSubmit} label="Save" />
      </div>
    </>
  );
};

export default EditPermissionsView;
