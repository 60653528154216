import React, { useState, useEffect } from "react";
import styled from "styled-components";
import thumbnail from "../../../assets/thumbnail.jpg";
import { FormatDate, FormatDateTime, FormatDateTimeDisplay, htmlToRawText } from "api/helpers/datahelper";
import { Interweave } from "interweave";
import * as DOMPurify from "dompurify";
import draftToHtml from "draftjs-to-html";
import PrivateCard from "components/PrivateCard";
import { formatDescription } from "api/helpers/datahelper";
import { getOrganization } from "api/services/organization";
import { UseErrorContext } from "contexts";
import { checkIfStringIsJson } from "utils";

const Div = styled.div``;

const FollowedEventCard = ({ id, coverUrl, organization, organizationId, title, location, description, startDateUtc, endDateUtc, link }) => {
  const { updateError } = UseErrorContext();
  const [org, setOrg] = useState(null);

  useEffect(async () => {
    try {
      if (!organization) {
        const p = await getOrganization(organization?.id ?? organizationId);
        setOrg(p);
      } else {
        setOrg(organization);
      }
    } catch (err) {
      updateError(err);
    }
  }, []);

  return (
    <>
      <Div className="card" small>
        <PrivateCard to={link + id}>
          <div className="card_image__container">
            <div className="cover">
              <img src={coverUrl ?? thumbnail} alt="" />
            </div>
            <div className="logo">
              <img src={org?.logoUrl} alt="" />
            </div>
          </div>
          <div className="card__content">
            <div className="card__content__header">
              <div className="card__title">{title}</div>
            </div>
            <div className="card__content__body">
              {checkIfStringIsJson(description) ? (
                <Interweave
                  content={DOMPurify.sanitize(
                    htmlToRawText(draftToHtml(JSON.parse(description)))
                      ?.slice(0, 120)
                      ?.concat(`…`)
                  )}
                />
              ) : (
                <p>{formatDescription(description)}</p>
              )}
            </div>
            <div className="card__content__footer">
              {FormatDateTime(startDateUtc) == FormatDateTime(endDateUtc) ? FormatDateTimeDisplay(startDateUtc) : `${FormatDateTimeDisplay(startDateUtc)} - ${FormatDateTimeDisplay(endDateUtc)}`}
            </div>
          </div>
        </PrivateCard>
      </Div>
    </>
  );
};

FollowedEventCard.defaultProps = {
  cover: thumbnail,
  title: "title",
  subtitle: "subtitle",
  updatedTimeUtc: "01-01-1900",
};

export default FollowedEventCard;
