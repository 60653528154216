import React, { useState, useEffect } from "react";
import PageHeaderSub from "../../components/PageHeaderSub";
import Page from "../../components/UI/Elements/Page";
import UserGrid from "pages/users/Components/UserGrid";
import HeaderDropDown from "../../components/UI/HeaderDropDown";
import { UseOrganizationContext } from "contexts";
import { getUsers, getDepartments } from "api/services/user";
import Button from "components/UI/Buttons/Button";
import Modal from "components/UI/Modal";
import { UserForm } from "./Components/UserForm";
import SearchBar from "components/UI/SearchBar";
import Loader from "components/UI/Loader";
import { delay } from "api/helpers/datahelper";
import { UseErrorContext } from "contexts";

const Users = () => {
  const organizationId = UseOrganizationContext()?.id;
  const [users, setUsers] = useState([]);  
  const [usersGroupedByDepartment, setUsersGroupedByDepartment] = useState([]);  
  const [showModalAddUser, setShowModalAddUser] = useState(false);
  const [showGroupedByDepartmentsView, setShowGroupedByDepartmentsView] = useState(false);
  const [search, setSearch] = useState("");
  const [loading, setLoading] = useState(false);
  const { updateError } = UseErrorContext();

  useEffect(async () => {
    if (!organizationId) return;
    refresh();    
  }, [organizationId, search]);

  const refresh = async () => {         
    setLoading(true);
    try{
      const usersResponse = await getUsers(organizationId,
        {
          'search': search,
        });  

      let usersData = usersResponse.data;

      setUsers(usersData);

      var usersGrpdByDepartment = await getUsersGroupedByDepartments(usersData);
      setUsersGroupedByDepartment(usersGrpdByDepartment);

      setShowModalAddUser(false);      
      setLoading(false);
    } catch (err) {
      updateError(err);
    }
  };

  // Search
  const onSearch = async (e) => {
    await delay(1000);
    const val = e.target.value.toString();
    setSearch(val);
  };

  const showGroupByDepartmentsView = async () => {
    setShowGroupedByDepartmentsView(!showGroupedByDepartmentsView);
  };

  const getUsersGroupedByDepartments = async (users) => {
    try{
      var usersGroupedByDepartments = [];

      const departments = await getDepartments();
      departments.map(department => {
          let usersForDepartment = users.filter(user => user.departments.map(x => x.id).includes(department.id));
          let usersGroupedByDepartment = {'department': department, 'users': usersForDepartment};
          usersGroupedByDepartments.push(usersGroupedByDepartment)
        }
      )      
      return usersGroupedByDepartments;      
    } catch (err) {
      updateError(err);
    }
  };

  const _addUser = () => {
    setShowModalAddUser(true);
  };

  return (
    <Page>
      {showModalAddUser && (
        <Modal title="Add user" hideActions>
          <UserForm onClose={() => refresh()} />
        </Modal>
      )}
      <div className="page__header">
        <h1>Role management</h1>
        <PageHeaderSub show>          
          <Button label={`${!showGroupedByDepartmentsView ? "Group by departments" : "Show all users"}`} onClick={showGroupByDepartmentsView}/>  
          <Button label="Add user" onClick={_addUser} roles={["OrganizationUsers.Create"]}/>   
        </PageHeaderSub>
      </div>
      <div className="page__inner">
        <div className="searchBarContainer">
          <SearchBar onChange={onSearch}/>
        </div>

        {!showGroupedByDepartmentsView 
        ?
          <HeaderDropDown titleHeader="">
            {loading && <Loader />}
            {!loading && (            
              <UserGrid users={users} refresh={refresh}/>   
            )} 
          </HeaderDropDown>
         : 
          usersGroupedByDepartment?.map((usersForDepartment, idx) => {
            return (
              <HeaderDropDown key={idx} titleHeader={usersForDepartment.department.name} collapseBody={usersForDepartment.users.length === 0} showCollapse>
                {loading && <Loader />}
                {!loading && (  
                  <UserGrid users={usersForDepartment.users} refresh={refresh}/>
                )}
              </HeaderDropDown>
            );
          })
      }
      </div>
    </Page>
  );
};

export default Users;
