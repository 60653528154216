import React, { useState, useEffect } from "react";
import PageHeaderSub from "../../components/PageHeaderSub";
import Page from "../../components/UI/Elements/Page";
import { useParams } from "react-router-dom";
import HeaderDropDown from "../../components/UI/HeaderDropDown";
import TranslationGrid from "components/TranslationGrid";
import { BackButton } from "components/UI/Buttons/BackButton";
import { UseErrorContext, UseOrganizationContext } from "contexts";
import { getAdminOrganization } from "api/services/organization";
import { addTranslation, updateTranslation, removeTranslation } from "api/services/organization";
import * as Yup from 'yup'

const validationSchema = Yup.object().shape({
  description: Yup.string().required('Required'),
  languageCode: Yup.string().required('Required'),
}) 


const Translations = () => {
  const id = UseOrganizationContext()?.id;
  const [object, setObject] = useState(null);
  const [translations, setTranslations] = useState([]);
  const { updateError } = UseErrorContext();

  useEffect(async () => {    
    refresh();
  }, []);

  const refresh = async () => { 
    try{
      const res = await getAdminOrganization(id);
      setObject(res)
      var trans = res.translations.filter(item => !item.isDefault);
      setTranslations(trans);
    } catch (err) {
      updateError(err);
    }
  };

  const add = async (values) => {
    console.log('values', values)
    try{
      const resp = await updateTranslation(id, values);
      refresh();
      return resp;
    } catch (err) {
      updateError(err);
    }
  };

  const edit = async (translationId, values) => {
    try{
      delete values.id;
      const resp = await updateTranslation(id, values);  
      refresh();
      return resp;
    } catch (err) {
      updateError(err);
    }
  };

  const remove = async (translationId, languageCode) => {
    try{
      await removeTranslation(id, languageCode);
      refresh();
    } catch (err) {
      updateError(err);
    }
  };
  
  return (
    <Page>   
      <div className="navigation-banner">
        <BackButton link={"/profile/"}/>   
        <ul className="breadcrumbs">
          <li><a href="/profile">Profile</a></li>
          <li><span className="selected">Translations</span></li>
        </ul> 
      </div>

      <div className="page__header">
        <h1>Translations</h1>
        <PageHeaderSub />
      </div>
      <div className="page__inner">
         {object && <TranslationGrid item={object} fields={['description']} validationSchema={validationSchema} translations={translations} remove={remove} add={add} edit={edit}/>} 
      </div>
    </Page>
  );
};

export default Translations;
