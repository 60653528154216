import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { disclosureStatusFullText, DISCLOSURESTATUS, DISCLOSURESTATUSARRAY } from "constants";
import { getAxiosClient } from "api/axios";
import Button from "components/UI/Buttons/Button";
import Loader from "components/UI/Loader";
import Modal from "components/UI/Modal";
import moment from "moment";
import ShareholdersFilteredGrid from "../../../pages/loader/Components/ShareholdersFilteredGrid";
moment.locale("nl-be");

const BarContainer = styled.div`
  width: 100%;
  padding: 1.2rem;
  border-radius: 0.8rem;
  background-color: var(--color-black);
  .bar_main {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .status__container {
    display: flex;
    align-items: center;
    column-gap: 2rem;
    font-size: 1.6rem;
    .status {
      min-width: 22rem;
      .status_list_item {
        opacity: 0.6;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        max-height: 1.3rem;
        &.active {
          opacity: 1;
        }
        span {
          margin-left: 0.4rem;
        }
      }
    }
    .stats {
      display: flex;
      align-items: center;
      column-gap: 2rem;
    }
  }
  .title__container {
    .meta__container {
      display: flex;
      align-items: center;
      column-gap: 2rem;
      margin-top: 0.4rem;
    }
    .meta {
      font-size: 1.6rem;
    }
  }
  .stats_container {
    margin-top: 1.2rem;
    padding-top: 1.2rem;
    border-top: 1px solid grey;
    &__inner {
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      gap: 0.8rem;
      max-width: 60rem;
    }
  }
  .spacing {
    min-width: 12rem;
  }
  .stats_item {
    width: calc(50% - 0.8rem);
  }
`;

const DisclosureBar = ({ _onClick, data, stats, loadStats }) => {
  const [showStats, setShowStats] = useState(false);
  const [statistics, setstatistics] = useState(null);
  const [loading, setLoading] = useState(false);
  const [showMailingListPreview, setShowMailingListPreview] = useState(false);
  const [selectedProduct, setSelectedProduct] = useState(null);

  const getstatistics = async () => {
    setShowStats(true);

    if (data.status !== DISCLOSURESTATUS.SENTMAILING)
    {
      return;
    }

    setLoading(true);
    const axiosClient = await getAxiosClient();
    try {
      return await axiosClient
        .get(`/loader/processingjobs/${data.id}/mailingstats`)
        .then((response) => {
          setLoading(false);
          setstatistics(response.data);
          console.log(response);
          return response.data;
        })
        .catch((error) => {
          console.log("error1", error);
          setLoading(false);
          return Promise.reject(error);
          //alert("error", error);
        });
    } catch (error) {
      console.log("error2", error);
      setLoading(false);
    }
  };

  useEffect(() => {
    if (loadStats && !statistics) {
      getstatistics();
    }
  }, []);

  return (
    <BarContainer>
      <div className="bar_main">
        <div className="title__container">
          <div className="title"> {data.disclosureRecordId??''}</div>
          <div className="meta__container">
            <div className="meta">{`${moment(data.disclosureRequestDate).format("DD.MM.YYYY")}`}</div>
            <div>-</div>
            <div className="meta">{data.productName}</div>
          </div>
        </div>
        <div className="status__container">
          <div className="status">
            {data.status !== DISCLOSURESTATUS.SENTMAILING ? (
              DISCLOSURESTATUSARRAY.map((item, idx) => {
                return (
                  <div className={data.status === item ? "status_list_item active" : "status_list_item"} key={idx}>
                    {`${idx + 1}. ${disclosureStatusFullText[item]}`}
                    {data.status === item && data.status != DISCLOSURESTATUS.MAILINGREADY && <Loader center={false} size={"small"} />}
                    {idx < DISCLOSURESTATUSARRAY.indexOf(data.status) && <span>&#x2713;</span>}
                  </div>
                );
              })) : (
                <div>{`completed on ${moment(data.updatedTime).format("DD.MM.YYYY, HH:mm ")}`}</div>
              )}
          </div>
          <div className="stats">
              {data.status !== DISCLOSURESTATUS.UPLOADED && !loadStats && 
                <div>
                  <Button onClick={() => (showStats ? setShowStats(false) : getstatistics())} label={showStats ? "Hide statistics" : "Show statistics"} />
                </div>
              }
              {(data.status === DISCLOSURESTATUS.CREATEDMAILING || data.status === DISCLOSURESTATUS.MAILINGREADY) &&
                <Button onClick={() => { setSelectedProduct(data.productName); setShowMailingListPreview(true); }} label="Mailinglist preview" />
              }
          </div>
          {data.status == DISCLOSURESTATUS.MAILINGREADY && 
            <div className="spacing">
              <Button label={"Send invites"} onClick={() => data.status == DISCLOSURESTATUS.MAILINGREADY && _onClick()} />
            </div>
          }
        </div>
      </div>

      {(showStats || loadStats) && (
        <div className="stats_container">
          {loading ? (
            <Loader />
          ) : (
            <div className="stats_container__inner">
              <div className="stats_item">Total shareholders: {data.totalRecordCount}</div>
              <div className="stats_item">Removed shareholders: {data.deletedCount}</div>
              <div className="stats_item">Already registered: {data.alreadyRegisteredCount}</div>
              <div className="stats_item">Reinvite count: {data.reinviteCount}</div>
              <div className="stats_item">New invite count: {data.newInviteCount}</div>
              {statistics &&
                <>
                <div className="stats_item">Contacts: {statistics.contactsTotal}</div>
                <div className="stats_item">
                  Mails opened: {statistics?.contactsUniqueOpenes} ({statistics.contactsUniqueOpensPercentage}%)
                </div>
                <div className="stats_item">Mails sent: {statistics.contactsSent}</div>
                <div className="stats_item">
                  Mails clicked: {statistics?.contactsClicked} ({statistics.contactsClickedPercentage}%)
                </div>
                <div className="stats_item">
                  Mails delivered: {statistics?.contactsDelivered} ({statistics.contactsDeliveredPercentage}%)
                </div>
                <div className="stats_item">
                  Mails bounced: {statistics?.contactsBounceHard} ({statistics.contactsBounceHardPercentage}%)
                </div>
                </>
              }
            </div>
          )}
        </div>
      )}

      {showMailingListPreview && (
        <Modal title="Mailing list preview" showClose wide hideActions onClose={() => setShowMailingListPreview(false)}>
          <ShareholdersFilteredGrid showFilters={false} type={"NaturalPerson"} hasRegistered={false} product={selectedProduct} hasEmail/>
        </Modal>
      )}
    </BarContainer>
  );
};

// DisclosureBar.defaultProps = {
//   title: "XXXXXXXXXX",
//   status: "Uploading",
//   _onClick: () => console.log("click"),
//   buttonDisabled: false,
//   stats: false,
//   processingJobId: 1,
// };

export default DisclosureBar;
