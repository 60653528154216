import React, { useState, useEffect } from "react";
import styled from "styled-components";
import HeaderDropDown from "../../../components/UI/HeaderDropDown";
import PageHeaderSub from "../../../components/PageHeaderSub";
import Page from "../../../components/UI/Elements/Page";
import SearchBar from "../../../components/UI/SearchBar";
import { getCommunicationGroupUsers } from "api/services/communication-groups";
import { useParams } from "react-router-dom";
import Pagination from "components/Pagination";
import { GetPagination } from "api/helpers/datahelper";
import { delay } from "api/helpers/datahelper";
import { BackButton } from "components/UI/Buttons/BackButton";
import { UseErrorContext } from "contexts";

const Container = styled.div`
  table {
    width: 100%;
    min-width: 100rem;
  }
`;

const CommunicationGroupUserGrid = () => {
  const { id } = useParams();
  const [users, setUsers] = useState([]);
  const [search, setSearch] = useState("");
  const [currentPage, setCurrentPage] = useState(1); 
  const [numberOfPages, setNumberOfPages] = useState(1);
  const { updateError } = UseErrorContext();

  useEffect(async () => {
    try{
      const userReponse = await getCommunicationGroupUsers(id,
        {
          'page': currentPage,
          'search': search,
        });

      let userData = userReponse.data;
      let userHeader = userReponse.headers;

      let pagination = GetPagination(userHeader);
      let totalCount = pagination.totalCount;
      let pageSize = pagination.pageSize;
      let nbrOfPages = Math.ceil(totalCount / pageSize);
        
      setNumberOfPages(nbrOfPages);

      setUsers(userData);
    } catch (err) {
      updateError(err);
    }
  }, [currentPage, search]);

  // Search
  const onSearch = async (e) => {
    await delay(1000);
    setCurrentPage(1);
    const val = e.target.value.toString();
    setSearch(val);
  };

  return (
    <Page>      
      <div className="navigation-banner">
        <BackButton link="/communicationgroups"/>
        <ul className="breadcrumbs">
          <li>
            <a href="/communicationgroups">Commmunication Groups</a>
          </li>
          <li>            
            <span className="selected">Followers</span>
          </li>
        </ul> 
      
      </div>
      <Container>
        <div className="page__header">
          <h1>COMMUNICATION GROUPS</h1>
          <PageHeaderSub />
        </div>
        <div className="page__inner">
          <div className="searchBarContainer">
            <SearchBar onChange={onSearch} />
          </div>
          <HeaderDropDown titleHeader="Followers">
            <table cellSpacing="500">
              <thead>
                <tr>
                  <th></th>
                  <th>Name</th>
                  <th>Country</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                {users?.map((user, idx) => (
                  <tr key={idx}>
                    <td></td>
                    <td>{`${user.firstName} ${user.lastName}`}</td>
                    <td>{user.country}</td>
                    <td></td>
                  </tr>
                ))}
              </tbody>
            </table>
          <Pagination numberOfPages={numberOfPages} currentPage={currentPage} setCurrentPage={setCurrentPage}/>
          </HeaderDropDown>
        </div>
      </Container>
    </Page>
  );
};

export default CommunicationGroupUserGrid;
