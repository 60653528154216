import React, { useState, useEffect } from "react";
import styled from "styled-components";
import Button from "components/UI/Buttons/Button";
import { getCommunicationGroups } from "api/services/communication-groups";
import { UseOrganizationContext } from "contexts";
import { Tooltip } from "recharts";
import SwitchButtonOn from "components/UI/Buttons/SwitchButtonOn";

const Container = styled.div`
  overflow: auto;
`;

const CommunicationGroupsForm = ({ data, eventIsPublic, nextStep, backStep }) => {
  const organizationId = UseOrganizationContext()?.id;
  const [selectedCommunicationGroups, setSelectedCommunicationGroups] = useState(data ?? []);
  const [communicationGroups, setCommunicationGroups] = useState([]);
  const [isPublic, setIsPublic] = useState(false); 

  useEffect(async () => {
    // Init is public
    setIsPublic(data.length == 0 && eventIsPublic); 

    if (!organizationId) return;
      refresh();
  }, [organizationId]);

  const refresh = async () => {
    try{
      var commGroups = await getCommunicationGroups(organizationId);
      setCommunicationGroups(commGroups);
    } catch (err) {
      updateError(err);
    }
  }

  const _add = (commGroup) => {
    setSelectedCommunicationGroups([...selectedCommunicationGroups, commGroup]);
  };

  const _delete = async (commGroup) => {
    setSelectedCommunicationGroups(selectedCommunicationGroups.filter(x => x.id !== commGroup.id))
  };

  const onChangeIsPublic = (e) => {
    const val = JSON.parse(e.target.value);
    if (val){
      setSelectedCommunicationGroups([]);
    }
    setIsPublic(val);
  };

  return (
    <Container className="form">
      <div className="form_row mb-3">
        <div className="field">
          <label htmlFor="isPublic" className="label">
            Visible to
            <Tooltip text="Should the published action be visible for everyone, or only for the linked communication groups?" />
          </label>
          <select onChange={onChangeIsPublic} value={isPublic}>
            <option value="true">Public</option>
            <option value="false">Communication groups only</option>
          </select>
        </div>
       </div>
       { !isPublic &&
          <div className="switch-container">
          {communicationGroups?.map((communicationGroup, idx) => (
            <div key={idx} className="switch-item">
              <label>{communicationGroup.name}</label>
              <SwitchButtonOn
                  on={selectedCommunicationGroups?.find((el) => el.id === communicationGroup.id)}
                  onSwitchOn={() => _add(communicationGroup)}
                  onSwitchOff={() => _delete(communicationGroup)}
              />
            </div>                
            ))}
          </div>
      }
      <div className="buttons">
        <Button label="Back" onClick={() => backStep()} type="secondary" />
        <Button label="Next" onClick={() => nextStep(selectedCommunicationGroups)} type="primary" />
      </div>
    </Container>
  );
};

CommunicationGroupsForm.defaultProps = {
  selectedCommunicationGroups: Array.from(Array(0)),
};

export default CommunicationGroupsForm;
