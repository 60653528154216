export const GetErrorMessage = (errorData) => {
    switch (errorData?.extensions?.errorCode) {
        case errorMessages.inviteNotFound:
            return "Unknown invite";
        case errorMessages.inviteCancelled:
            return "Invite was revoked by your admin";
        case errorMessages.inviteAlreadyCompleted:
            return "Invite was already completed!";
        default:
            return errorData?.title;
    }    
}

export const errorMessages = {
    inviteNotFound: "invite-not-found",
    inviteCancelled: "invite-cancelled",
    inviteAlreadyCompleted: "invite-already-completed"
}