import React, { useState } from "react";
import styled from "styled-components";
import { FormatDate, FormatDateTimeDisplay } from "api/helpers/datahelper";
import { useTranslation } from 'react-i18next';

const Container = styled.div`
  overflow: auto;
  .question {
    margin-bottom: 1.2rem;
    padding: 1.2rem;
    border: 1px solid rgba(255, 255, 255, 0.6);
    border-radius: 0.8rem;
    cursor: pointer;
    &.open {
      .question__body {
        display: block;
      }
    }
    &__header {
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      justify-content: space-between;
      :hover{
        color: var(--color-accent-prim);
        opacity: .7;
      }
      &__event {
        font-weight: bold;
        font-size: 1.8rem;
        margin-bottom: 0.4rem;
      }
      &__question {
        opacity: 0.8;
        width: 100%;
      }
      &__time {
        font-size: 1.2rem;
        opacity: 0.8;
        margin-bottom: 0.4rem;
        text-align: right;
      }
      .status{
        margin-top: .2rem;
        margin-bottom: .2rem;
      }
      .keywords {
        margin-top: .8rem;
        span {
          margin-left: 0.4rem;
          padding: 0.1rem 0.4rem;
          border: 1px solid rgba(255, 255, 255, 0.6);
          border-radius: 0.8rem;
        }
      }
    }
    &__body {
      margin-top: 1.2rem;
      display: none;
      & > div {
        width: 80%;
        padding: 1.2rem;
        border-radius: 0.8rem;
        margin-bottom: 1.2rem;
        box-shadow: 5px 6px 12px -5px black;
      }
      &__question {
        background-color: black;
      }
      &__answer {
        background-color: rgba(255, 255, 255, 0.6);
        color: #1a1a1a;
        margin-left: auto;
      }
    }
    .sender {
      font-size: 1.2rem;
      margin-bottom: 0.4rem;
      opacity: 0.8;
    }
  }
  table {
    display: none;
    td {
    }
  }
`;

const QuestionGrid = ({ mails }) => {
  const { t, i18n } = useTranslation();

  return (
    <Container>
      {mails.map(({ event, question, createdTime, id, answer, category, user, keywords }, idx) => {
        const [open, setOpen] = useState(false);
        return (
          <div onClick={() => setOpen(!open)} key={id + "_" + idx} className={open ? "question open" : "question"}>
            <div className="question__header">
              <div className="question__header__event">{event.name}</div>
              <div className="question__header__time">
                {`${category} - ${FormatDateTimeDisplay(createdTime + 'Z')}`}
                <div className={answer ? "status good" : "status bad"}>{!answer ? t('unanswered') : t('answered')}</div>
                <div className="keywords">
                  {keywords.map((keyword) => (
                    <span>{keyword}</span>
                  ))}
                </div>
              </div>
              {!open && <div className="question__header__question">{question.length < 60 ? question : question.substring(0, 60) + "..."}</div> }
            </div>
            <div className="question__body">
              <div className="question__body__question">
                <div className="sender">{user?.name}</div>
                {question}
              </div>
              {answer && (
                <div className="question__body__answer">
                  <div className="sender">{answer?.answeredBy?.name}</div>
                  {answer?.message}
                </div>
              )}
            </div>
          </div>
        );
      })}
    </Container>
  );
};

QuestionGrid.defaultProps = {
  mails: Array.from(Array(0)),
};

export default QuestionGrid;
