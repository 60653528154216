import React from "react";
import { FaCheck, FaPaperPlane } from "react-icons/fa";

export default function Send() {

  return (
    <>
      <div className="icon-button">
        <FaPaperPlane/>
      </div>
    </>
  );
}
