import styled from "styled-components";
import React from "react";

export default function PageHeaderSub({ show, children }) {
  return <div className="page__header__sub">{show && children}</div>;
}

PageHeaderSub.defaultProps = {
  show: false,
  children: (
    <ul>
      <li>New messages</li>
      <li>Views</li>
      <li>Followers</li>
    </ul>
  )
};
