import React, { useState, useEffect } from "react";
import styled from "styled-components";
import MyPostComponent from "./MyPostComponent";

const Container = styled.div`
  overflow: auto;
`;

const MyPostGrid = ({ posts, refresh }) => {

  return (
    <Container>        
      {posts.map((post, idx) => (
          <MyPostComponent post={post} key={post.id + "_" + idx} refresh={refresh}/>
      ))}
    </Container>
  );
};

MyPostGrid.defaultProps = {
  posts: Array.from(Array(0)),
};

export default MyPostGrid;
