import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { useParams, useHistory } from "react-router-dom";
import HeaderDropDown from "../../components/UI/HeaderDropDown";
import Page from "../../components/UI/Elements/Page";
import { getAdminProduct, updateProduct, uploadCover } from "api/services/product";
import Loader from "components/UI/Loader";
import { ProductForm } from "pages/products/Components/ProductForm";
import { BackButton } from "components/UI/Buttons/BackButton";
import { UseErrorContext } from "contexts"
import Button from "components/UI/Buttons/Button";
import PageHeaderSub from "components/PageHeaderSub";
import Modal from "components/UI/Modal";
import { deleteProduct } from "api/services/product";
import { Link } from "react-router-dom";
import LinksHeader from "components/LinksHeader";

const Container = styled.div``;

const EditProduct = () => {
  const { id } = useParams();
  const history = useHistory();
  const [product, setProduct] = useState(null);
  const [isSaving, setIsSaving] = useState(false);
  const { updateError } = UseErrorContext();  
  const [showModalDelete, setShowModalDelete] = useState(false);

  useEffect(async () => {
    try{
      const productData = await getAdminProduct(id);
      setProduct(productData);
    } catch (err) {
      updateError(err);
    }
  }, []);

  const _updateProduct = async (productData) => {
    try{
      setIsSaving(true);
      const newObject = { ...product, ...productData };
      const resp = await updateProduct(newObject);

      if (productData.cover) {
        if (productData.cover != product.cover) {
          await uploadCover(product.id, productData.cover);
        }
      }

      setIsSaving(false);
      history.push("/productmanager");
    } catch (err) {
      updateError(err);
    }
  };

  const _deleteProduct = async () => {
    try {
      await deleteProduct(id);
      setShowModalDelete(false);
      history.push("/productmanager");
    } catch (err) {
      updateError(err);
    }
  };
  
  return (
    <>
      {showModalDelete && (
        <Modal title={`Delete "${product.title}"`} onClose={() => setShowModalDelete(false)} onSubmit={() => _deleteProduct()} cancelLabel={"Cancel"} submitLabel={"Delete"}>
          <div>Are you sure you want to delete this share?</div>
        </Modal>
      )}
      <Page>
        {!product ? (
          <Loader />
        ) : (
          <Container>
            <div className="navigation-banner">              
              <BackButton link="/productmanager"/>
              <ul className="breadcrumbs">
                <li>
                  <a href="/productmanager">Shares</a>
                </li>
                <li>
                  <span className="selected">{product?.title}</span>
                </li>
              </ul>
            </div>
            <div className="page__header">
              <h1></h1>
              <PageHeaderSub show>
                <Button onClick={() => setShowModalDelete(true)} label="Delete" type="tertiary"/>
              </PageHeaderSub>  
            </div>
              <LinksHeader>
                <Link to={`/productmanager/edit/${product?.id}/translations`}>
                  <Button label="Translations" type="secondary"/>
                </Link>
              </LinksHeader>
            <div className="page__inner">
              <HeaderDropDown titleHeader="Edit share" extraOption={isSaving && "Saving..."}>
                <ProductForm save={_updateProduct} product={product} />
              </HeaderDropDown>
            </div>
          </Container>
        )}
      </Page>
    </>
  );
};

export default EditProduct;
