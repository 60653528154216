import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { Formik, Form, Field, ErrorMessage } from "formik";
import Loader from "components/UI/Loader";
import Button from "components/UI/Buttons/Button";
import * as Yup from 'yup'
import { UseErrorContext } from "contexts";
import { getProducts, followProduct } from "api/services/product";
import { UseOrganizationContext } from "contexts";

const FormWrapper = styled.div`
`;

const ValidationSchema = Yup.object().shape({
  productId: Yup.string().required("Required"),
}) 

export const FollowedProductForm = ( {onClose, followedProducts} ) => {  
  const { updateError } = UseErrorContext();
  const [products, setProducts] = useState([]);
  const organizationId = UseOrganizationContext()?.id;

  useEffect(async () => {
    try{
      var prod = await getProducts(organizationId);

      // TODO delete later
      let followedProducts2 = followedProducts.map(x => x.product);

      const filteredProducts = prod.filter(value => !followedProducts2.map(x => x.id).includes(value.id));
      setProducts(filteredProducts);
    } catch (err) {
      updateError(err);
    }
  }, []);

  return (
    <FormWrapper>
      <Formik
        initialValues={ { 
          productId: "",
         }} 
          validationSchema={ValidationSchema}          
          onSubmit={(values, { setSubmitting }) => {   
            values["productId"] = parseInt(values["productId"]);                   
            followProduct(values)
              .then((value) => {
                setSubmitting(false);
                onClose();
              })
              .catch((e) => {
                updateError(err);
                setSubmitting(false);
              });
          }}
          >
        {({ isSubmitting, handleSubmit }) => (
          <Form className="form">        
            <div className="form_row">
              <div className="field">
                <label htmlFor="productId" className="label required">
                  Product
                </label>
                <Field as="select" name="productId">
                  <option value=""></option>
                  {products?.map((product, idx) => (
                    <option value={product.id} key={idx}>
                      {product.title}
                    </option>
                  ))}
                </Field>
                <ErrorMessage className="error-message" name="productId" component="div" />           
              </div>
            </div>
            {!isSubmitting ? (
              <div className="buttons">
                <Button label="Close" onClick={() => onClose()} type="secondary"/>
                <Button onClick={()=>{handleSubmit()}} label="Confirm" disabled={isSubmitting} />         
              </div>
            ) : (
              <Loader />
            )}
          </Form>
        )}
      </Formik>
    </FormWrapper>
  );
};
