import React from "react";
import styled from "styled-components";
import HTMLRenderView from "components/UI/Blocks/HTMLRenderView";
import { UseOrganizationContext } from "contexts";

const Container = styled.div`
  .tab__thumb {
    padding-bottom: 0;
  }
`;

const SectionInfo = ({ section, backupImage }) => {
  const { logoUrl } = UseOrganizationContext();

  return (
    <>
      <Container>
        <div className="tab__content">
          <div className="tab__thumb">
            <img src={section?.imageUrl || backupImage || logoUrl} />
          </div>
          <div className="tab__text">
            <HTMLRenderView content={section?.text} />
          </div>
        </div>
      </Container>
    </>
  );
};

export default SectionInfo;
