import React, { useState, useEffect } from "react";
import styled from "styled-components";
import Button from "components/UI/Buttons/Button";
import { UseErrorContext } from "contexts";
import EventSection from "../Components/EventSection";
import AddButton from "components/UI/Buttons/AddButton";

const Container = styled.div`
  overflow: auto;
`;

const SectionsForm = ({ data, nextStep, backStep }) => {
  const [sections, setSections] = useState(data ?? []);
  const [isEdited, setIsEdited] = useState(0);
  const { updateError } = UseErrorContext();

  const nxtStep = () => {
    if (isEdited > 0){
      updateError({message: "Save edited section(s) first."});
    }
    else{
      nextStep(sections);
    }
  };

  const _add = () => {
    let newSection = {"id": sections.length, "title": "", "text": ""};
    setSections([...sections, newSection]);
  };

  const _edit = async (sec) => {
    // Create a copy of the original array
    const updatedItems = [...sections];

    // Find the index of the item you want to update
    const itemIndex = updatedItems.findIndex(x => x.id === sec.id);

    // If the item is found, update its value
    if (itemIndex !== -1) {
      updatedItems[itemIndex] = {
        ...updatedItems[itemIndex],
        title: sec.title,
        text: sec.text,
        cover: sec.cover,
        coverFile: sec.coverFile
      };

      // Set the modified array back to the state
      setSections(updatedItems);
    }
  };

  const _delete = async (section) => {
    let newSections = sections.filter(x => x.id !== section.id);
    for (let i = 0; i < newSections.length; i++) {
      newSections[i].id = i;
    }
    setSections(newSections);
  };

  const moveSequenceUp = async (section) => {
    let arr = sections;
    const oldId = section.id;
    const newId = oldId - 1;
    arr[oldId].id = newId
    arr[oldId - 1].id = newId + 1;

    // sort
    const sortedItems = [...arr].sort((a, b) => a.id > b.id ? 1 : -1);
    setSections(sortedItems);
  };

  const moveSequenceDown = async (section) => {
    let arr = sections;
    const oldId = section.id;
    const newId = oldId + 1;
    arr[oldId].id = newId
    arr[oldId + 1].id = newId - 1;
  
    // sort
    const sortedItems = [...arr].sort((a, b) => a.id > b.id ? 1 : -1);
    setSections(sortedItems);
  };

  const onChangeEdited = (val) => {
    const x = val ? isEdited + 1 : isEdited > 0 ? isEdited - 1 : 0;
    setIsEdited(x);
  }

  return (
    <Container className="form">
       <div>
            {sections.map((item, idx) => (
              <EventSection item={item} key={idx} idx={idx} save={_edit} removeTab={_delete} moveSequenceDown={moveSequenceDown} moveSequenceUp={moveSequenceUp} isFirst={idx == 0} isLast={idx == sections.length-1} onChangeEdited={onChangeEdited}/>
            ))}
            {sections.length < 25 && (
              <div className="button__container"><AddButton _onClick={() => _add()} /></div>  
            )}
        </div>
      <div className="buttons">
        <Button label="Back" onClick={() => backStep()} type="secondary" />
        <Button label="Next" onClick={() => nxtStep()} type="primary" />
      </div>
    </Container>
  );
};

SectionsForm.defaultProps = {
  sections: Array.from(Array(0)),
};

export default SectionsForm;
