import React, { useState, useEffect } from "react";
import styled from "styled-components";
import AddButton from "../../../../components/UI/Buttons/AddButton";
import Modal from "components/UI/Modal";
import Trash from "components/UI/Icons/Trash";
import Button from "components/UI/Buttons/Button";
import { LinkForm } from "components/LinkForm";

const Container = styled.div`
  overflow: auto;
`;

const LinksForm = ({ data, nextStep, backStep }) => {
  const [showModal, setShowModal] = useState(false);
  const [links, setLinks] = useState(data ?? []);

  const _add = (link) => {
    setLinks([...links, link]);
  };

  const _delete = async (link) => {
    setLinks(links.filter(x => x.name !== link.name))
  };

  return (
    <Container className="form">
      {showModal && (
        <Modal title="Link" hideActions>
          <LinkForm onClose={() => {setShowModal(false);}} add={_add}/>
        </Modal>
      )}
      <table cellSpacing="500">
        <thead>
          <tr>
            <th>Name</th>
            <th>Language</th>
            <th>Link</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {links?.map((link, idx) => (
            <tr key={idx}>
              <td>{link.name}</td>
              <td>{link.languageCode}</td>
              <td>{link.url}</td>
              <td>
                <div onClick={() => _delete(link)} className="icon-button">
                  <Trash />
                </div>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      <div onClick={() => setShowModal(true)} className="grid-add-btn">
        <AddButton label="Add link" />
      </div>
      <div className="buttons">
        <Button label="Back" onClick={() => backStep()} type="secondary" />
        <Button label="Next" onClick={() => nextStep(links)} type="primary" />
      </div>
    </Container>
  );
};

LinksForm.defaultProps = {
  links: Array.from(Array(0)),
};

export default LinksForm;
