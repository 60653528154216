import React, { useState, useEffect } from "react";
import styled from "styled-components";
import AddButton from "../../../components/UI/Buttons/AddButton";
import Modal from "components/UI/Modal";
import Trash from "components/UI/Icons/Trash";
import Edit from "components/UI/Icons/Edit";
import Open from "components/UI/Icons/Open";
import { CommunicationGroupForm } from "./CommunicationGroupForm";
import { getCommunicationGroups, removeCommunicationGroup } from "api/services/communication-groups";
import { UseOrganizationContext } from "contexts";
import CommunicationGroupUserGrid from "./CommunicationGroupUserGrid";
import { useHistory } from "react-router-dom";
import Loader from "components/UI/Loader";
import { UseErrorContext } from "contexts";

const Container = styled.div`
  overflow: auto;
  table {
    width:100%;
    min-width: 100rem;
  }
`;

const CommunicationGroupGrid = ( ) => {
  const history = useHistory();
  const organizationId = UseOrganizationContext()?.id;
  const [communicationGroups, setCommunicationGroups] = useState([]);  
  const [showModalEdit, setShowModalEdit] = useState(false);
  const [showModalDelete, setShowModalDelete] = useState(false);
  const [selectedCommunicationGroup, setSelectedCommunicationGroup] = useState(false);
  const [loading, setLoading] = useState(false);  
  const { updateError } = UseErrorContext();

  useEffect(async () => {
    if (!organizationId) return;
      refresh();
  }, [organizationId]);

  const refresh = async () => {    
    setLoading(true);
    try{
      var commGroups = await getCommunicationGroups(organizationId);
    } catch (err) {
      updateError(err);
    }
    setCommunicationGroups(commGroups);    
    setLoading(false);
  }

  const _addCommunicationGroup = () => {
    setSelectedCommunicationGroup(null);
    setShowModalEdit(true);
  };
  
  const _editCommunicationGroup = (communicationGroup) => {
    setSelectedCommunicationGroup(communicationGroup);
    setShowModalEdit(true);
  };

  const showDeleteModal = async (communicationGroup) => {
    setSelectedCommunicationGroup(communicationGroup);
    setShowModalDelete(true);
  };

  const _delete = async () => {
    try{
      await removeCommunicationGroup(selectedCommunicationGroup.id);      
    } catch (err) {
      updateError(err);
    }
    
    setShowModalDelete(false);
    refresh();
  };

  const openUsersView = (communicationGroup) => {
    history.push(`/communicationgroups/${communicationGroup.id}/users`)
  };

  return (
    <Container>        
      {showModalDelete && (
        <Modal title={`Delete "${selectedCommunicationGroup.name}"`} onClose={() => setShowModalDelete(false)} onSubmit={() => _delete()} cancelLabel={"Cancel"} submitLabel={"Delete"}>
          <div>Are you sure you want to delete this communication group?</div>
        </Modal>
      )}
      {showModalEdit && (
        <Modal title="Communication Group" hideActions>
          <CommunicationGroupForm communicationGroup={selectedCommunicationGroup} onClose={() => setShowModalEdit(false)} />
        </Modal>
      )}
      {loading && <Loader />}
        {!loading && (  
          <>
            <table cellSpacing="500">
              <thead>
                <tr>
                  <th>Name</th>
                  <th>Followers</th>
                  <th></th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                {communicationGroups?.map((communicationGroup, idx) => (
                  <tr key={idx}>
                    <td>{communicationGroup.name}</td>
                    <td>
                      <div onClick={() => openUsersView(communicationGroup)} className="icon-button">
                        <Open roles={["CommunicationGroups.Create"]}/>
                      </div>
                    </td>
                    <td>
                      <div onClick={() => _editCommunicationGroup(communicationGroup)} className="icon-button">
                        <Edit roles={["CommunicationGroups.Create"]}/>
                      </div>
                    </td>
                    <td>
                      <div onClick={() => showDeleteModal(communicationGroup)} className="icon-button">
                        <Trash roles={["CommunicationGroups.Create"]}/>
                      </div>
                    </td>             
                  </tr>
                ))}
              </tbody>
            </table>
            <div onClick={() => _addCommunicationGroup()} className="grid-add-btn">
              <AddButton label="Add communication group" roles={["CommunicationGroups.Create"]}/>
            </div>
          </>
        )}  
    </Container>
  );
};

CommunicationGroupGrid.defaultProps = {
  communicationGroups: Array.from(Array(0)),
};

export default CommunicationGroupGrid;
