import React, { useState, useEffect } from "react";
import PageHeaderSub from "../../components/PageHeaderSub";
import Page from "../../components/UI/Elements/Page";
import { useParams } from "react-router-dom";
import HeaderDropDown from "../../components/UI/HeaderDropDown";
import DocumentGrid from "./Components/DocumentGrid"
import LinkGrid from "./Components/LinkGrid";
import { getEvent } from "api/services/event";
import { BackButton } from "components/UI/Buttons/BackButton";
import { ValidateAccess } from 'api/services/authorization';
import { UseUserContext } from "contexts";
import { UseErrorContext } from "contexts";

const Documents = () => {
  const { id } = useParams();
  const [event, setEvent] = useState(null);
  const [documents, setDocuments] = useState([]);
  const [links, setLinks] = useState([]);
  const { permissions } = UseUserContext();
  const [isReadOnly, setIsReadOnly] = useState(false);
  const { updateError } = UseErrorContext();

  useEffect(async () => {    
    refresh();
  }, []);

  const refresh = async () => {  
    try{ 
      const eventData = await getEvent(id);
      setEvent(eventData)

      var docs = eventData.documents;
      setDocuments(docs);

      var links = eventData.links;
      setLinks(links);
    } catch (err) {
      updateError(err);
    }
  };

  //I don't like this is duplicated from edit-events. I suggest we move this entire page to a component
  useEffect(async () => {
    const access = ValidateAccess(["Events.Publish"], permissions);
    setIsReadOnly(!access && event.publishStatus !== "Hidden");
  }, [event, permissions]);

  return (
    <Page>  
      <div className="navigation-banner">
        <BackButton link={"/events/edit/" + id}/>
        <ul className="breadcrumbs">
          <li>
            <a href="/events">Actions</a>
            </li>
          <li>
            <a href={"/events/edit/" + id}>{event?.title}</a>
            </li>
          <li>            
            <span className="selected">Documents and Links</span>
          </li>
        </ul> 
      </div>

      <div className="page__header">
        <h1></h1>
        <PageHeaderSub />
      </div>
      <div className="page__inner">
        <HeaderDropDown titleHeader="Documents">
           <DocumentGrid eventId={id} documents={documents} refresh={refresh} isReadOnly={isReadOnly}/> 
         </HeaderDropDown>
        <HeaderDropDown titleHeader="Links">
           <LinkGrid links={links} refresh={refresh} isReadOnly={isReadOnly}/> 
         </HeaderDropDown>
      </div>
    </Page>
  );
};

export default Documents;
