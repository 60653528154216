import React from "react";
import { FaPlus } from "react-icons/fa";

export default function Add(props) {

  return (
    <>
      <div className="icon-button">
        <FaPlus/>
      </div>
    </>
  );
}
