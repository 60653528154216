import React, { useState, useEffect } from "react";
import HeaderDropDown from "components/UI/HeaderDropDown";
import PageHeaderSub from "components/PageHeaderSub";
import Page from "components/UI/Elements/Page";
import FollowedProductGrid from "pages/products/Components/FollowedProductGrid";
import SearchBar from "../../components/UI/SearchBar";
import { getProducts } from "../../api/services/product";
import { delay } from "api/helpers/datahelper";
import { UseErrorContext } from "contexts";
import { useParams } from "react-router-dom";
import { BackButton } from "components/UI/Buttons/BackButton";
import { getOrganization } from "api/services/organization";
import { useTranslation } from 'react-i18next';

const FollowedOrganizationProducts = () => {
  const { id } = useParams();
  const [search, setSearch] = useState("");
  const [products, setProducts] = useState(null);
  const [organization, setOrganization] = useState(null);
  const { updateError } = UseErrorContext();
  const { t, i18n } = useTranslation();

  useEffect(async () => {
    refresh();
  }, [search]);

  const refresh = async () => {  
    try{ 
      const productsData = await getProducts(id, {
        'search': search,
      });
      setProducts(productsData);
      
      const p = await getOrganization(id);
      setOrganization(p);
    } catch (err) {
      updateError(err);
    }
  };

  // Search
  const onSearch = async (e) => {
    await delay(1000);
    const val = e.target.value.toString();
    setSearch(val);
  };

  return (
    <>
      <Page>   
        <div className="navigation-banner">              
              <BackButton link={"/organizations/view/" + id}/>
              <ul className="breadcrumbs">
                <li>
                  <a href="/organizations">{t('organizations')}</a>
                </li>
                <li>
                  <a href={"/organizations/view/" + id}>{organization?.name}</a>
                </li>
                <li>
                  <span className="selected">{t('shares')}</span>
                </li>
              </ul>
        </div>  
        <div className="page__header">
          <h1></h1>
          <PageHeaderSub/>
        </div>
        <div className="page__inner">
          <div className="searchBarContainer">
            <SearchBar onChange={onSearch}/>
          </div>
          <HeaderDropDown titleHeader="">
            <FollowedProductGrid canEdit products={products} link={`/organizations/view/${id}/products/`} refresh={refresh}/>
          </HeaderDropDown>
        </div>
      </Page>
    </>
  );
};

export default FollowedOrganizationProducts;
