import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { Formik, Form, Field, ErrorMessage } from "formik";
import Loader from "components/UI/Loader";
import Button from "components/UI/Buttons/Button";
import { UseErrorContext } from "contexts";
import { GetCountries } from "api/services/countries";
import { editUser } from "api/services/user";
import * as Yup from "yup";
import { useTranslation } from "react-i18next";
import languageSettings from "language";

const FormWrapper = styled.div``;

const ValidationSchema = Yup.object().shape({
  languageCode: Yup.string().required("Required"),
});

export const UserForm2 = ({ user, possibleLanguages }) => {
  const { updateError } = UseErrorContext();
  const [languages, setLanguages] = useState(possibleLanguages);
  const { t, i18n } = useTranslation();

  useEffect(async () => {}, []);

  return (
    <FormWrapper>
      <Formik
        initialValues={{
          languageCode: user.languageCode,
        }}
        validationSchema={ValidationSchema}
        onSubmit={(values, { setSubmitting }) => {
          editUser(values)
            .then((value) => {
              i18n.changeLanguage(values.languageCode.toLowerCase());
              languageSettings.Code = values.languageCode;
              setSubmitting(false);
            })
            .catch((e) => {
              setSubmitting(false);
              updateError(e);
            });
        }}
      >
        {({ isSubmitting, handleSubmit }) => (
          <Form class="form">
            <div className="form_row">
              <div className="field">
                <label>{t("firstname")}</label>
                <input value={user.firstName} disabled />
              </div>
              <div className="field">
                <label>{t("lastname")}</label>
                <input value={user.lastName} disabled />
              </div>
            </div>
            <div className="form_row">
              <div className="field">
                <label>{t("email")}</label>
                <input value={user.email} disabled />
              </div>
            </div>
            <div className="form_row">
              <div className="field">
                <label>{t("country")}</label>
                <input value={user.country?.name} disabled />
              </div>
            </div>
            <div className="form_row">
              <div className="field">
                <label htmlFor="languageCode">{t("language")}</label>
                <Field as="select" name="languageCode">
                  {languages?.map((language, idx) => (
                    <option value={language} key={idx}>
                      {language}
                    </option>
                  ))}
                </Field>
                <ErrorMessage className="error-message" name="languageCode" component="div" />
              </div>
            </div>
            {!isSubmitting ? (
              <div className="buttons">
                <Button label={t("cancel")} onClick={(e) => {}} type="secondary" />
                <Button
                  onClick={() => {
                    handleSubmit();
                  }}
                  label={t("save")}
                  disabled={isSubmitting}
                />
              </div>
            ) : (
              <Loader />
            )}
          </Form>
        )}
      </Formik>
    </FormWrapper>
  );
};
