import React from "react";
import styled from "styled-components";
import Trash from "components/UI/Icons/Trash";

const Container = styled.div`
  display: inline-flex;
  text-align: center;
  border-radius: 100px;
  border: var(--color-accent-prim) solid 1px;
  background-color: var(--color-background-sec);
  padding: 0.4rem 1rem;
  margin-right: 1rem;
  .remove-button {
    cursor: pointer;
    margin-left: .8rem;
  }
`;

export default function Badge({ idx, label, onRemove }) {

  return (
    <Container>
      <span>{label}</span>
      <div onClick={event => onRemove(idx)} className="remove-button">
          <Trash className="icon-button"/>
       </div>
    </Container>
  );
}

Badge.defaultProps = {
  label: "",
};
