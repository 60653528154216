import React from "react";
import styled from "styled-components";
import { Formik, Form, Field, ErrorMessage } from "formik";
import { AddSupportMessage } from "api/services/organization";
import Button from "components/UI/Buttons/Button";
import { UseOrganizationContext } from "contexts";
import * as Yup from 'yup'
import { useHistory } from "react-router-dom";
import Loader from "components/UI/Loader";
import { UseErrorContext } from "contexts";

const FormWrapper = styled.div`
`;

const ValidationSchema = Yup.object().shape({
  name: Yup.string().required('Required'),
  email: Yup.string().email().required('Required'),
  message: Yup.string().required('Required'),
}) 

const callBack = async (values) => {
  try{
    const resp = await AddSupportMessage(organizationId, values);
    return resp;
  } catch (err) {
    updateError(err);
  }
};

export const SupportForm = ( { onClose }) => {
  const history = useHistory();
  const organizationId = UseOrganizationContext()?.id;
  const { updateError } = UseErrorContext();

  return (
      <FormWrapper>
          <Formik
            initialValues={{ 
              name: "",
              email: "", 
              message: ""               
              }}
            validationSchema={ValidationSchema}
            onSubmit={(values, { setSubmitting }) => {
              callBack(values)
                .then((value) => {
                  history.push("/support-success")
                })
                .catch((e) => {
                  setSubmitting(false);
                });
            }}
          >
            {({ isSubmitting, handleSubmit }) => (
              <Form className="form">
              <div className="form_row">
                <div className="field">
                  <label htmlFor="name" className="required">Name</label>
                  <Field type="text" name="name" />
                  <ErrorMessage className="error-message" name="name" component="div" />
                </div>
              </div>
                <div className="form_row">
                  <div className="field">
                    <label htmlFor="email" className="required">Email</label>
                    <Field type="email" name="email" />
                    <ErrorMessage className="error-message" name="email" component="div" />
                  </div>
                </div>
                <div className="form_row">
                  <div className="field">
                    <label htmlFor="message" className="required">Message</label>
                    <Field as="textarea" name="message" />
                    <ErrorMessage className="error-message" name="message" component="div" />
                  </div>
                </div>                
                {!isSubmitting ? (
                  <div className="buttons">
                    <Button label="cancel" onClick={(e) => {onClose();}} type="secondary"/>
                    <Button onClick={()=>{handleSubmit()}} label="Send message" disabled={isSubmitting} />
                  </div>
                ) : (
                  <Loader />
                )}
              </Form>
            )}
          </Formik>
        </FormWrapper>
  );
};
