import React, { useState, useEffect } from "react";
import PageHeaderSub from "../../components/PageHeaderSub";
import Page from "../../components/UI/Elements/Page";
import { useParams } from "react-router-dom";
import HeaderDropDown from "../../components/UI/HeaderDropDown";
import { addRelatedEvent, getEvent, removeRelatedEvent } from "api/services/event";
import { BackButton } from "components/UI/Buttons/BackButton";
import { ValidateAccess } from 'api/services/authorization';
import { UseUserContext } from "contexts";
import { UseErrorContext } from "contexts";
import RelatedEventGrid from "./Components/RelatedEventGrid";

const RelatedEvents = () => {
  const { id } = useParams();
  const [event, setEvent] = useState(null);
  const [relatedChildEvents, setRelatedChildEvents] = useState([]);
  const [relatedParentEvents, setRelatedParentEvents] = useState([]);
  const [relatedEventIds, setRelatedEventIds] = useState([]);
  const { permissions } = UseUserContext();
  const [isReadOnly, setIsReadOnly] = useState(false);
  const { updateError } = UseErrorContext();

  useEffect(async () => {    
    refresh();
  }, []);

  const refresh = async () => {  
    try{ 
      const eventData = await getEvent(id);
      setEvent(eventData)

      setRelatedChildEvents(eventData.children);
      let parentEvent = eventData.parent ? [eventData.parent] : [];
      setRelatedParentEvents(parentEvent);

      const childIds = eventData.children.map(x => x.id);
      const parentIds = [eventData.parent?.id];
      let ids = childIds.concat(parentIds);
      ids = ids.concat(parseInt(id))
      setRelatedEventIds(ids);
    } catch (err) {
      updateError(err);
    }
  };

  //I don't like this is duplicated from edit-events. I suggest we move this entire page to a component
  useEffect(async () => {
    const access = ValidateAccess(["Events.Publish"], permissions);
    setIsReadOnly(!access && event.publishStatus !== "Hidden");
  }, [event, permissions]);

  const _addParent = async (relatedEventId) => {
    try{
      const parentId = relatedEventId;
      const childId = id;
      await addRelatedEvent(parentId, childId);
      refresh();
    } catch (err) {
      updateError(err);
    }
  }; 

  const _deleteParent = async (relatedEventId) => {
    try{
      const parentId = relatedEventId;
      const childId = id;
      await removeRelatedEvent(parentId, childId);
      refresh();
    } catch (err) {
      updateError(err);
    }
  };

  const _addChild = async (relatedEventId) => {
    try{
      const parentId = id;
      const childId = relatedEventId;
      await addRelatedEvent(parentId, childId);
      refresh();
    } catch (err) {
      updateError(err);
    }
  }; 

  const _deleteChild = async (relatedEventId) => {
    try{
      const parentId = id;
      const childId = relatedEventId;
      await removeRelatedEvent(parentId, childId);
      refresh();
    } catch (err) {
      updateError(err);
    }
  };

  return (
    <Page>  
      <div className="navigation-banner">
        <BackButton link={"/events/edit/" + id}/>
        <ul className="breadcrumbs">
          <li>
            <a href="/events">Actions</a>
            </li>
          <li>
            <a href={"/events/edit/" + id}>{event?.title}</a>
            </li>
          <li>            
            <span className="selected">Related events</span>
          </li>
        </ul> 
      </div>

      <div className="page__header">
        <h1></h1>
        <PageHeaderSub />
      </div>
      <div className="page__inner">
        <HeaderDropDown titleHeader="Head action">
           <RelatedEventGrid relatedEvents={relatedParentEvents} relatedEventIds={relatedEventIds} type="parent" isReadOnly={isReadOnly} add={_addParent} remove={_deleteParent}/> 
         </HeaderDropDown>
        <HeaderDropDown titleHeader="Subactions">
           <RelatedEventGrid relatedEvents={relatedChildEvents} relatedEventIds={relatedEventIds} type="child" isReadOnly={isReadOnly} add={_addChild} remove={_deleteChild}/> 
         </HeaderDropDown>
      </div>
    </Page>
  );
};

export default RelatedEvents;
