import Q1_cover from "assets/q1.jpg";
import Q2_cover from "assets/q2.jpg";
import Q3_cover from "assets/q3.jpg";
import Q4_cover from "assets/q4.jpg";
import GM_cover from "assets/gm.jpg";
import PR_cover from "assets/press-release.jpg";
import AFR_cover from "assets/afr.jpg";

export const DISCLOSURESTATUS = {
  UPLOADED: "Uploaded",
  PROCESSING: "Processing",
  PROCESSED: "Processed",
  CREATEDMAILING: "CreatedMailing",
  MAILINGREADY: "MailingReady",
  SENTMAILING: "SentMailing",
};

export const DISCLOSURESTATUSARRAY = [DISCLOSURESTATUS.UPLOADED, DISCLOSURESTATUS.PROCESSING, DISCLOSURESTATUS.PROCESSED, DISCLOSURESTATUS.CREATEDMAILING, DISCLOSURESTATUS.MAILINGREADY];

export const disclosureStatusFullText = {
  Uploaded: "Uploading file ",
  Processing: "Processing shareholders",
  Processed: "Processed shareholders",
  CreatedMailing: "Generating mailing list",
  MailingReady: "Ready to send mailing list",
  SentMailing: "Completed",
};

export const MAPSTATUS = {
  Hidden: "PUBLISH",
  Started: "PUBLISHED",
  Finished: "PUBLISHED",
  PublishedSection: "PUBLISHED",
};

export const MAPSTATUSPOSTS = {
  Draft: "APPROVE",
  Approved: "PUBLISH",
  Published: "PUBLISHED",
  PublishStarted: "PUBLISHED",
};

//Only added the items that need a "translation" for now
export const PermissionGroupMapping = {
  InvestorInsights: "Finvictum Loader",
  CommunicationGroups: "Communication Groups",
  OrganizationUsers: "Organization Users",
};

export const EventCategory = {
  "annual-general-meeting": "Annual general meeting",
  "press-release": "Press release",
  other: "Other",
  "special-general-meeting": "Special general meeting",
  "dividend-payout": "Dividend payout",
  "capital-increase": "Capital increase",
  "capital-decrease": "Capital decrease",
  "share-buyback": "Share buyback",
  "first-quarter-financial-results": "Q1 financial results",
  "second-quarter-financial-results": "Q2 financial results",
  "third-quarter-financial-results": "Q3 financial results",
  "fourth-quarter-financial-results": "Q4 financial results",
  "annual-financial-results": "Annual financial results",

  other: "Other",
  Finished: "Published",
};

export const EventCategoryImages = {
  "annual-general-meeting": GM_cover,
  "press-release": PR_cover,
  other: PR_cover,
  "special-general-meeting": GM_cover,
  "dividend-payout": PR_cover,
  "capital-increase": PR_cover,
  "capital-decrease": PR_cover,
  "share-buyback":PR_cover,
  "first-quarter-financial-results": Q1_cover,
  "second-quarter-financial-results": Q2_cover,
  "third-quarter-financial-results": Q3_cover,
  "fourth-quarter-financial-results": Q4_cover,
  "annual-financial-results": AFR_cover
};
