import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { EditOrganizationProfileSection } from "api/services/organization";
import { EditImageOrganizationProfileSection } from "api/services/organization";
import Image from "components/UI/Images/Image";
import Modal from "components/UI/Modal";
import { FaTrash, FaSave } from "react-icons/fa";
import Status from "components/UI/Status";
import { UseErrorContext } from "contexts";
import { Link } from "react-router-dom";
import Button from "components/UI/Buttons/Button";
import { resizeImage } from "utils";
import MDEditor from "components/UI/Blocks/MDEditor";
import TextArea from "components/UI/Forms/TextArea";
import { useTranslation } from "react-i18next";
import { UseOrganizationContext } from "contexts";

const Container = styled.div`
  .tab__item {
    margin-bottom: 4rem;
    &.loading {
      opacity: 0.8;
    }
    .tab__header {
      display: grid;
      grid-template-columns: auto 400px;
      &__left {
        input {
          margin-left: 1rem;
        }
      }
      &__right {
        display: flex;
        justify-content: end;
        align-items: center;
        > * {
          margin-left: 1rem;
        }
        .switch-container > * {
          margin-left: 1rem;
        }
        .icon-button {
          margin-left: 1rem;
          svg {
            width: 2rem;
            height: auto;
          }
        }
      }
    }
    .tab__editor{
      margin-left: 3.2rem;
      width: calc(70% - 3.2rem);
    }
  }
`;

const ProfileSection = ({ item, idx, isLoading, removeTab, organizationId, onChangeEdited }) => {
  const [title, setTitle] = useState(item.title);
  const [text, setText] = useState(item.text);
  const [coverFile, setCoverFile] = useState(item.imageUrl);
  const [cover, setCover] = useState(item.imageUrl);
  const [isSaving, setIsSaving] = useState(false);
  const [isEdited, setIsEdited] = useState(false);
  const [uploadingCover, setUploadingCover] = useState(false);
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
  const [height, setHeight] = useState("100%");
  const { updateError } = UseErrorContext();
  const { t } = useTranslation();
  const {logoUrl} = UseOrganizationContext();

  useEffect(async () => {
    setTitle(item.title);
    setText(item.text);
    setCover(item.imageUrl);
  }, [item]);

  useEffect(async () => {
    onChangeEdited(isEdited);
  }, [isEdited]);

  useEffect(async () => {
    var edited = title != item.title || text != item.text || item.imageUrl != cover;
    setIsEdited(edited);
  }, [title, text, cover, item]);

  const publish = async () => {
    const isVis = !item.isVisible;
    try {
      setIsSaving(true);
      const resp = await EditOrganizationProfileSection(organizationId, item.id, title, text, isVis);
      item.isVisible = isVis;
      setIsSaving(false);
      setIsEdited(false);
    } catch (err) {
      updateError(err);
      setIsSaving(false);
    }
  };

  const saveTab = async () => {
    if (text?.length > 10000) {
      updateError({ message: t("Max. length of text is 10000 characters.") });
      return;
    }
    if (title?.length > 5000) {
      updateError({ message: t("Max. length of title is 50 characters.") });
      return;
    }

    if (title != item.title || text != item.text || item.imageUrl != cover) {
      try {
        setIsSaving(true);
        const resp = await EditOrganizationProfileSection(organizationId, item.id, title, text, false);
        item.title = title;
        item.text = text;
        item.isVisible = false;

        if (coverFile) {
          if (item.imageUrl != cover) {
            await EditImageOrganizationProfileSection(organizationId, item.id, coverFile);
          }
        }

        setIsSaving(false);
        setIsEdited(false);
      } catch (err) {
        updateError(err);
        setIsSaving(false);
      }
    }
  };

  const previewImage = async (e) => {
    setUploadingCover(true);
    let selectedFile = e.target.files[0];

    let fileSizeInMegabytes = selectedFile.size / (1024 * 1024);

    if (fileSizeInMegabytes > 10) {
      updateError({ message: t("Image should be smaller then 10mb.") });
      setUploadingCover(false);
      return;
    }

    const resizedImage = await resizeImage(selectedFile, 1250);

    setCoverFile(resizedImage);

    let reader = new FileReader();
    reader.onload = function (event) {
      setCover(event.target.result);
    };

    reader.readAsDataURL(selectedFile);
    setUploadingCover(false);
  };

  return (
    <Container>
      <div className={`tab__item ${isLoading ? "loading" : ""}`}>
        <div className="tab__header">
          <div className="tab__header__left">
            <span className="label">Section {idx + 1}</span>
            <input
              disabled={isLoading}
              maxLength={50}
              value={title}
              onChange={(e) => {
                setTitle(e.target.value);
              }}
              placeholder="Tab title"
            />
          </div>

          <div className="tab__header__right">
            <Link to={`/profile/sections/${item.id}/translations`}>
              <Button label="Translations" />
            </Link>
            <Status label={item.isVisible ? "PublishedSection" : "Publish"} onClick={() => publish()} />
            {isEdited && (
              <div onClick={() => saveTab()} className="icon-button">
                <span>{isSaving ? "Saving" : ""}</span>
                <FaSave />
              </div>
            )}
            <div onClick={() => setShowDeleteConfirmation(true)} className="icon-button">
              <FaTrash />
            </div>
          </div>
        </div>
        <div className="tab__content">
          <div className="tab__thumb">
            <Image url={cover || logoUrl} onChange={previewImage} isLoading={uploadingCover} />
          </div>
          <div className="tab__editor">
            <MDEditor value={text} setFieldValue={(val) => setText(val)} />
          </div>
        </div>
      </div>
      {showDeleteConfirmation && (
        <Modal
          title={`Delete "${item.title}"`}
          onClose={() => setShowDeleteConfirmation(false)}
          onSubmit={() => {
            removeTab(item.id);
            setShowDeleteConfirmation(false);
          }}
          cancelLabel={"Cancel"}
          submitLabel={"Delete"}
        >
          <div>Are you sure you want to delete this section?</div>
        </Modal>
      )}
    </Container>
  );
};

export default ProfileSection;
