import React from "react";
import HeaderDropDown from "../components/UI/HeaderDropDown";
import PageHeaderSub from "../components/PageHeaderSub";
import Page from "../components/UI/Elements/Page";
import Button from "components/UI/Buttons/Button";

const Survey = () => {
  return (
    <Page>
      <div className="page__header">
        <h1>Survey</h1>
        <PageHeaderSub />
      </div>
      <div className="page__inner">
        <HeaderDropDown titleHeader="Revolutionizing Investor Insights with AI-Powered Surveys">
          <p>
            In the fast-paced world of investment, understanding stakeholder sentiment and gathering actionable insights is crucial for strategic decision-making. Enter Conveo.io, your premier AI tool
            designed to transform the way you conduct surveys. Leveraging cutting-edge artificial intelligence, Conveo.io enables seamless and efficient survey creation, distribution, and analysis,
            ensuring you can reach out to investors with precision and ease. Whether you're seeking feedback or gauging market sentiment, Conveo.io provides the comprehensive, data-driven insights you
            need.
          </p>
          <p />
          <br />
          <br />
          <Button label={"Start a survey"} url={"https://conveo.ai/dashboard"} target="_blank" />
          {" "}
          <Button type={"secondary"} label={"Walkthrough video"} url={"https://www.loom.com/share/d16c5ca043614525999f40ee2d7f1a64?sid=e1617516-5cd7-4f14-85fb-bb177d67ae07"} target="_blank" />
          <br />
        </HeaderDropDown>
      </div>
    </Page>
  );
};

export default Survey;
