import React, { useState, useEffect } from "react";
import PageHeaderSub from "../../components/PageHeaderSub";
import Page from "../../components/UI/Elements/Page";
import { useParams } from "react-router-dom";
import HeaderDropDown from "../../components/UI/HeaderDropDown";
import TranslationGrid from "components/TranslationGrid";
import { getEvent } from "api/services/event";
import { BackButton } from "components/UI/Buttons/BackButton";
import { UseErrorContext } from "contexts";
import { updateTranslation, removeTranslation, getAdminProduct } from "api/services/product";
import * as Yup from "yup";

const ValidationSchema = Yup.object().shape({
  title: Yup.string().required("Required"),
  languageCode: Yup.string().required("Required"),
});

const Translations = () => {
  const { id } = useParams();
  const [product, setProduct] = useState(null);
  const [translations, setTranslations] = useState([]);
  const { updateError } = UseErrorContext();

  useEffect(async () => {
    refresh();
  }, []);

  const refresh = async () => {
    try {
      const data = await getAdminProduct(id);
      setProduct(data);
      var trans = data.translations.filter((item) => !item.isDefault);
      setTranslations(trans);
    } catch (err) {
      updateError(err);
    }
  };

  const add = async (values) => {
    try {
      const resp = await updateTranslation(id, values);
      refresh();
      return resp;
    } catch (err) {
      updateError(err);
    }
  };

  const edit = async (translationId, values) => {
    try {
      delete values.id;
      delete values.isDefault;
      const resp = await updateTranslation(id, values);
      refresh();
      return resp;
    } catch (err) {
      updateError(err);
    }
  };

  const remove = async (translationId, languageCode) => {
    try {
      await removeTranslation(id, languageCode);
      refresh();
    } catch (err) {
      updateError(err);
    }
  };

  return (
    <Page>
      <div className="navigation-banner">
        <BackButton link={"/productmanager/edit/" + id} />
        <ul className="breadcrumbs">
          <li>
            <a href="/productmanager">Shares</a>
          </li>
          <li>
            <a href={"/productmanager/edit/" + id}>{product?.title}</a>
          </li>
          <li>
            <span className="selected">Translations</span>
          </li>
        </ul>
      </div>

      <div className="page__header">
        <h1>Translations Share</h1>
        <PageHeaderSub />
      </div>
      <div className="page__inner">
          {product && (
            <TranslationGrid fields={["title", "subtitle", "description"]} item={product} translations={translations} validationSchema={ValidationSchema} remove={remove} add={add} edit={edit} />
          )}
      </div>
    </Page>
  );
};

export default Translations;
