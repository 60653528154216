import React, { useState, useEffect } from "react";
import HeaderDropDown from "components/UI/HeaderDropDown";
import PageHeaderSub from "components/PageHeaderSub";
import Page from "components/UI/Elements/Page";
import FollowedEventCalendar from "pages/events/Components/FollowedEventCalendar";
import SearchBar from "../../components/UI/SearchBar";
import { getFollowedEvents } from "../../api/services/event";
import { UseErrorContext } from "contexts";
import { delay } from "api/helpers/datahelper";
import { Link } from "react-router-dom";
import Button from "components/UI/Buttons/Button";
import moment from "moment";
import { useTranslation } from 'react-i18next';

const FollowedEventsCalendar = () => {
  const [search, setSearch] = useState("");
  const [events, setEvents] = useState(null);
  const { updateError } = UseErrorContext();
  const { t, i18n } = useTranslation();

  useEffect(async () => {
    refresh();
  }, [search]);

  const refresh = async () => {  
    try{     
      const eventsData = await getFollowedEvents({
        search: search,
        order: "ASC",
      });
      setEvents(eventsData);
    } catch (err) {
      updateError(err);
    }
  };

  // Search
  const onSearch = async (e) => {
    await delay(1000);
    const val = e.target.value.toString();
    setSearch(val);
  };

  return (
    <>
      <Page>
        <div className="page__header">
          <h1>{t('followedactions')}</h1>
          <PageHeaderSub show>
            <Link to="/followed-events">
              <Button label={t('listview')}/>
            </Link>
          </PageHeaderSub>
        </div>
        <div className="page__inner">
          <div className="searchBarContainer">
            <SearchBar onChange={onSearch}/>
          </div>
          <HeaderDropDown titleHeader={t('actions')}>
            <FollowedEventCalendar events={events}/>
          </HeaderDropDown>
        </div>
      </Page>
    </>
  );
};

export default FollowedEventsCalendar;
