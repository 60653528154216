import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { useMsal } from "@azure/msal-react";
import { useAccount } from "@azure/msal-react";
import PageHeaderSub from "../../components/PageHeaderSub";
import Page from "../../components/UI/Elements/Page";
import { useHistory } from "react-router-dom";
import { completeRegistration } from "api/services/user";
import { errorMessages, GetErrorMessage } from "errorMessages";
import { UseOrganizationContext } from "../../contexts";

const Registration = () => {
  const { code } = useParams();
  const history = useHistory();
  const { accounts } = useMsal();
  const account = useAccount(accounts?.[0] || {});
  const [errorMessage, setErrorMessage] = useState(null);
  const { updateOrgUserData } = UseOrganizationContext();

  useEffect(async () => {
    if (account){    
      try {
        const resp = await completeRegistration(code);
        await updateOrgUserData(account);
        history.push("/");  
      } catch (error) {
        let code = response?.response?.data?.extensions?.errorCode;
          setErrorMessage(GetErrorMessage(response?.response?.data));
          if (code === errorMessages.inviteAlreadyCompleted)
            history.push("/");
      }   
    }   
  }, []);

  return (
    <Page>
      <div className="page__header">
        <h1>Registration</h1>
        <PageHeaderSub />
      </div>
      <div className="page__inner">
        {errorMessage && 
          <div style={{"margin-top": "20px"}}>
            Registration failed: {errorMessage}
          </div>
        }
      </div>
    </Page>
  );
};

export default Registration;
