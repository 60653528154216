import React, { useState, useEffect } from "react";
import HeaderDropDown from "components/UI/HeaderDropDown";
import PageHeaderSub from "components/PageHeaderSub";
import Page from "components/UI/Elements/Page";
import FollowedOrganizationGrid from "pages/profile/Components/FollowedOrganizationGrid";
import SearchBar from "../../components/UI/SearchBar";
import Pagination from "components/Pagination";
import { GetNrOfPages } from "api/helpers/datahelper";
import { getOrganizations } from "../../api/services/organization";
import { delay } from "api/helpers/datahelper";
import { UseErrorContext } from "contexts";
import { Link } from "react-router-dom";
import Button from "components/UI/Buttons/Button";
import { useTranslation } from 'react-i18next';

const Organizations = () => {
  const [search, setSearch] = useState("");
  const [organizations, setOrganizations] = useState(null);
  const { updateError } = UseErrorContext();
  const [currentPage, setCurrentPage] = useState(1);
  const [numberOfPages, setNumberOfPages] = useState(1);
  const { t, i18n } = useTranslation();

  useEffect(async () => {
    refresh();
  }, [search, currentPage]);

  const refresh = async () => {  
    try{ 
      const organizationsData = await getOrganizations({
        'search': search,
        'page': currentPage,
      });

      let nbrOfPages = GetNrOfPages(organizationsData.headers);
      setNumberOfPages(nbrOfPages);
      setOrganizations(organizationsData.data);
    } catch (err) {
      updateError(err);
    }
  };

  // Search
  const onSearch = async (e) => {
    await delay(1000);
    const val = e.target.value.toString();
    setCurrentPage(1);
    setSearch(val);
  };

  return (
    <>
      <Page>
        <div className="page__header">
          <h1>DISCOVER COMPANIES</h1>
          <PageHeaderSub show>
            <Link to={`/organizations`}>
              <Button label={t('followedcompanies')}/>
            </Link>
          </PageHeaderSub>
        </div>
        <div className="page__inner">
          <div className="searchBarContainer">
            <SearchBar onChange={onSearch}/>
          </div>
          <HeaderDropDown titleHeader="">
            <FollowedOrganizationGrid canEdit organizations={organizations} refresh={refresh}/>
            <Pagination numberOfPages={numberOfPages} currentPage={currentPage} setCurrentPage={setCurrentPage} />
          </HeaderDropDown>
        </div>
      </Page>
    </>
  );
};

export default Organizations;
