import React, { useState, useEffect, useContext } from "react";
import styled from "styled-components";
import thumbnail from "../../../assets/thumbnail.jpg";
import PrivateCard from "components/PrivateCard";
import { UseErrorContext } from "contexts";
import { followProduct, unfollowProduct } from "api/services/product";
import Follow from "components/UI/Icons/Follow";
import Button from "components/UI/Buttons/Button";
import StockData from "../StockDataSmall";

const Div = styled.div`
  .card_image__container {
    background-color: black;
    border-top-left-radius: 0.8rem;
    border-top-right-radius: 0.8rem;
    min-height: 10rem;
  }
  .card_price {
    position: absolute;
    top: 10px;
    left: 10px;
    background-color: var(--color-medium-grey);
    padding: 0.6rem 1.2rem;
    border-radius: 0.4rem;
    display: none;
  }
  .cover{
    opacity: .2;
  }
  .logo{
    top: 1rem;
    bottom: auto;
   & img {
    width: 4rem;
   }
  }
  .stock-data{
    margin-left: 6rem;
    margin-top: 1rem;
  }
`;

const FollowedProductCard = ({ id, cover, title, subtitle, organization, link, isFollowed, refresh, stockSymbol, isin, primaryMarketMic }) => {
  const { updateError } = UseErrorContext();
  const [followed, setFollowed] = useState(isFollowed ?? true);

  const _followProduct = async (e) => { 
    e.preventDefault()
    e.stopPropagation();
    e.nativeEvent.stopImmediatePropagation();
   
    try {
      await followProduct({ productId: id });
      refresh();
    } catch (err) {
      updateError(err);
    }
  };

  const _unfollowProduct = async () => {
    try {
      await unfollowProduct(id);
      refresh();
    } catch (err) {
      updateError(err);
    }
  };

  useEffect(async () => {
    setFollowed(isFollowed ?? true);
  }, [isFollowed]);

  return (
    <>
      <Div className="card">
        <PrivateCard to={link + id}>
          <div className="card_image__container">
            <div className="cover">
              <img src={cover ?? thumbnail} alt="" />
            </div>
            <div className="logo">
              <img src={organization?.logoUrl} alt="" />
            </div>
            <div className="stock-data">
              <StockData stockSymbol={stockSymbol} isin={isin} mic={primaryMarketMic} organization={organization} />
            </div>
          </div>
          <div className="card__content">
            <div className="card__content__header">
              <div className="card__title">{title}</div>
            </div>
            {/* <div className="card__content__body"></div> */}
            <div className="card__content__footer">
            {!followed && (
              <div className="follow-button">
                <Button onClick={e => _followProduct(e)} label="Follow">
                  <Follow />
                </Button>
              </div>
            )}
            </div>
          </div>
        </PrivateCard>
      </Div>
    </>
  );
};

FollowedProductCard.defaultProps = {
  cover: thumbnail,
  title: "title",
  subtitle: "subtitle",
  updatedTimeUtc: "01-01-1900",
};

export default FollowedProductCard;
