import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { useParams } from "react-router-dom";
import HeaderDropDown from "../../components/UI/HeaderDropDown";
import Page from "../../components/UI/Elements/Page";
import Loader from "components/UI/Loader";
import { BackButton } from "components/UI/Buttons/BackButton";
import { UseErrorContext } from "contexts";
import PageHeaderSub from "components/PageHeaderSub";
import { followProduct, getProduct, unfollowProduct } from "api/services/product";
import { getOrganization } from "api/services/organization";
import Button from "components/UI/Buttons/Button";
import ProductInfo from "pages/products/Components/ProductInfo";
import { useTranslation } from 'react-i18next';

const Container = styled.div``;

const ViewFollowedProduct = () => {
  const { organizationId, productId } = useParams();
  const [product, setProduct] = useState(null);
  const { updateError } = UseErrorContext();
  const [showModalDelete, setShowModalDelete] = useState(false);
  const { t, i18n } = useTranslation();

  useEffect(async () => {
    await refresh();
  }, []);

  const refresh = async () => {
    try{
      const p = await getProduct(productId);
      const org = await getOrganization(organizationId);
      p['organization'] = org;
      setProduct(p);
    } catch (err) {
      updateError(err);
    }
  };  
  
  const _unfollowProduct = async () => {
    try{
      await unfollowProduct(productId);
      setShowModalDelete(false);
      refresh();
    } catch (err) {
      updateError(err);
    }
  };

  const _followProduct = async () => {
    try{      
      await followProduct({'productId': productId});
      refresh();
    } catch (err) {
      updateError(err);
    }
  };

  return (
    <>
      {showModalDelete && (
        <Modal title={`"${t('unfollow')}" "${title}"`} onClose={() => setShowModalDelete(false)} onSubmit={() => _unfollowProduct()} cancelLabel={"Cancel"} submitLabel={"Confirm"}>
          <div>{t('confirmunfollowshare')}</div>
        </Modal>
      )}
      <Page>
        {!product ? (
          <Loader />
        ) : (
          <Container>              
            <div className="navigation-banner">              
                  <BackButton link={"/organizations/view/" + organizationId + "/products"}/>
                  <ul className="breadcrumbs">
                    <li>
                      <a href="/organizations">{t('followedcompanies')}</a>
                    </li>
                    <li>
                      <a href={"/organizations/view/" + organizationId}>{product.organization?.name}</a>
                    </li>
                    <li>
                      <a href={"/organizations/view/" + organizationId + "/products"}>{t('shares')}</a>
                    </li>
                    <li>
                      <span className="selected">{product.title}</span>
                    </li>
                  </ul>
            </div>    
            <div className="page__header">             
              <h1></h1>
              <PageHeaderSub show>
                {product.isFollowed 
                  ? <Button onClick={() => setShowModalDelete(true)} label={t('unfollow')} type="tertiary"/>
                  : <Button onClick={() => _followProduct()} label={t('follow')}/>
                }
              </PageHeaderSub>
            </div>
            <div className="page__inner">
              <HeaderDropDown titleHeader={product.title}>              
                <ProductInfo product={product}/>
              </HeaderDropDown>
            </div>
          </Container>
        )}
      </Page>
    </>
  );
};

export default ViewFollowedProduct;
