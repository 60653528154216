import React, { useState, useEffect } from "react";
import { Route, Redirect } from 'react-router-dom';
import { ValidateAccess } from 'api/services/authorization';
import { UseUserContext } from "contexts";
import Unauthorized from "pages/Unauthorized";

 const PrivateRoute = ({ component: Component, roles, ...rest }) => {
    const [hasAccess, setHasAccess] = useState(false);
    const { permissions } = UseUserContext();

    useEffect(async () => {
      const access = ValidateAccess(roles, permissions);
      setHasAccess(access);
    }, [permissions]);
    
    return (
        <>
        <Route {...rest} render={props => {                
            // Check if route is allowed by role   
            if (hasAccess) {  
                // Authorised so return component
                return <Component {...props} />         
            }
            else{                
                // Role not authorised so redirect to home page
                return <Unauthorized />
            }
        }} />
        </>
    );
 };

 export default PrivateRoute;