import React, { useState } from "react";
import styled from "styled-components";
import Trash from "../../../components/UI/Icons/Trash";
import Open from "components/UI/Icons/Open";
import { deleteUser } from "../../../api/services/user";
import Modal from "components/UI/Modal";
import EditDepartmentsView from "./EditDepartmentsView";
import EditPermissionsView from "./EditPermissionsView";
import Status from "components/UI/Status";
import Button from "components/UI/Buttons/Button";
import { resendInvitation } from "../../../api/services/user";
import { UseErrorContext } from "contexts";
import { FormatDateTimeDisplay } from "api/helpers/datahelper";

const Container = styled.div`
  overflow: auto;
  table {
    width:100%;
    min-width: 100rem;
  }
  .resend > p{
    font-size: 1.2rem;
  
  }
`;

const UserGrid = ({ users, refresh }) => {
  const [showModalEditDepartments, setShowModalEditDepartments] = useState(false);
  const [showModalEditPermissions, setShowModalEditPermissions] = useState(false);
  const [user, setUser] = useState(null);
  const [showModalDelete, setShowModalDelete] = useState(false);
  const [isResending, setIsResending] = useState(false);
  const { updateError } = UseErrorContext();
  
  
  const showDeleteModal = async (user) => {
    setUser(user);
    setShowModalDelete(true);
  };

  const _delete = async () => {
    try{
      await deleteUser(user.id);
      setShowModalDelete(false);
      refresh();
    } catch (err) {
      updateError(err);
    }
  };

  const openDepartmentsView = (user) => {
    setUser(user);
    setShowModalEditDepartments(true);
  };

  const openPermissionsView = (user) => {
    setUser(user);
    setShowModalEditPermissions(true);
  };

  const closeForms = () => {
    setShowModalEditDepartments(false);
    setShowModalEditPermissions(false);
    refresh();
  }

  const _resendInvitation = async (userId) => {
    setIsResending(true);
    await resendInvitation(userId);
    setIsResending(false);
  };
console.log(users)
  return (
    <Container>        
      {showModalDelete && (
        <Modal title={`Delete "${user.firstName} ${user.lastName}"`} onClose={() => setShowModalDelete(false)} onSubmit={() => _delete()} cancelLabel={"Cancel"} submitLabel={"Delete"}>
          <div>Are you sure you want to delete this user?</div>
        </Modal>
      )}
      {showModalEditDepartments && (
        <Modal title="Edit departments" hideActions >
          <EditDepartmentsView user={user} onClose={() => closeForms()} />
        </Modal>
      )}
      {showModalEditPermissions && (
        <Modal title="Edit permissions" hideActions >
          <EditPermissionsView user={user} onClose={() => closeForms()} />
        </Modal>
      )}
      <table cellSpacing="500">
        <thead>
          <tr>
            <th>Name</th>
            <th>Email</th>
            <th>Departments</th>
            <th>Permissions</th>
            <th>Status</th>
            <th></th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {users?.map((user, idx) => (
            <tr key={idx}>
              <td>{`${user.firstName} ${user.lastName}`}</td>
              <td>{user.email}</td>
              <td>
                <div onClick={() => openDepartmentsView(user)} className="icon-button">
                  <Open roles={["OrganizationUsers.Create"]}/>
                </div>
              </td>
              <td>
                <div onClick={() => openPermissionsView(user)} className="icon-button">
                  <Open roles={["OrganizationUsers.Create"]}/>
                </div>
              </td>
              <td>
                <Status label={user.status} onClick={() => console.log()}/>
              </td>
              <td>
                { user?.status?.toLowerCase() == "invited" && 
                  <div className="resend">
                    <Button onClick={()=>{_resendInvitation(user.id)}} label="Resend invitation" disabled={isResending}/>
                    <p>Last sent: {FormatDateTimeDisplay(user.inviteUpdated)}</p>
                  </div>
                }
              </td>
              <td>
                <div onClick={() => showDeleteModal(user)} className="icon-button">
                  <Trash roles={["OrganizationUsers.Create"]}/>
                </div>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </Container>
  );
};

UserGrid.defaultProps = {
  users: Array.from(Array(0)),
};

export default UserGrid;
