import React from "react";
import styled from "styled-components";

const Div = styled.div`
  svg {
    width: 100%;
  }
  img{
    width: 6rem;
    height: auto;
    position: initial;
    object-fit: cover;
    border-radius: 8px;
  }
`;

export default function Logo({src}) {
  if(src){
    return <Div>
      <img src={src} />
    </Div>
  }
  return (
    <Div>
      <svg
        width="243"
        height="50"
        viewBox="0 0 243 50"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M67.0131 37.5547C66.917 37.559 66.8209 37.543 66.7314 37.5077C66.6418 37.4723 66.5607 37.4184 66.4934 37.3496C66.3713 37.2092 66.3033 37.0297 66.302 36.8436V13.1975C66.3001 13.1036 66.3172 13.0103 66.3523 12.9232C66.3874 12.836 66.4397 12.7569 66.5061 12.6905C66.5725 12.6241 66.6517 12.5718 66.7388 12.5367C66.8259 12.5016 66.9192 12.4845 67.0131 12.4863H80.5115C80.7014 12.4863 80.8837 12.5608 81.0193 12.6938C81.1548 12.8268 81.2328 13.0076 81.2363 13.1975C81.2428 13.2952 81.2264 13.393 81.1884 13.4832C81.1504 13.5735 81.0919 13.6536 81.0175 13.7172C80.9518 13.7843 80.8729 13.8372 80.7859 13.8725C80.6988 13.9078 80.6054 13.9247 80.5115 13.9223H67.5602L67.6696 13.7719V24.0837L67.5192 23.7965H78.843C79.0307 23.8067 79.2077 23.8867 79.3394 24.0208C79.471 24.155 79.5476 24.3335 79.5542 24.5214C79.5586 24.6188 79.5413 24.716 79.5034 24.8059C79.4656 24.8958 79.4081 24.9761 79.3353 25.041C79.2019 25.1657 79.0256 25.2343 78.843 25.2325H67.4508L67.6696 24.9453V36.8436C67.6698 37.0325 67.5962 37.2141 67.4645 37.3496C67.345 37.472 67.1839 37.5452 67.0131 37.5547Z"
          fill="#F4F4F4"
        />
        <path
          d="M87.6641 36.8025C87.6428 37.0065 87.5565 37.1983 87.4179 37.3495C87.2825 37.4813 87.1009 37.5549 86.9119 37.5547C86.8114 37.5612 86.7107 37.5463 86.6164 37.5109C86.5221 37.4755 86.4363 37.4205 86.3649 37.3495C86.2282 37.2007 86.1546 37.0045 86.1597 36.8025V13.2385C86.1567 13.1372 86.1746 13.0363 86.2122 12.9422C86.2499 12.8481 86.3065 12.7627 86.3786 12.6914C86.5245 12.5588 86.7147 12.4854 86.9119 12.4854C87.1092 12.4854 87.2993 12.5588 87.4453 12.6914C87.5174 12.7627 87.574 12.8481 87.6116 12.9422C87.6493 13.0363 87.6671 13.1372 87.6641 13.2385V36.8025Z"
          fill="#F4F4F4"
        />
        <path
          d="M112.473 12.4863C112.566 12.4821 112.658 12.497 112.745 12.5299C112.832 12.5628 112.912 12.6131 112.979 12.6778C113.093 12.8165 113.156 12.9904 113.156 13.1701V36.7752C113.163 36.8842 113.145 36.9933 113.105 37.0948C113.065 37.1964 113.003 37.288 112.924 37.3632C112.777 37.4834 112.594 37.5509 112.404 37.5547C112.295 37.5573 112.188 37.5338 112.09 37.4863C111.988 37.433 111.9 37.3584 111.83 37.2675L95.1997 14.5651L95.7057 14.3053V36.9256C95.7043 37.0056 95.6866 37.0844 95.6537 37.1573C95.6207 37.2302 95.5733 37.2956 95.5142 37.3496C95.3787 37.4693 95.2026 37.5328 95.0219 37.5274C94.932 37.5331 94.8419 37.5202 94.7572 37.4897C94.6725 37.4591 94.595 37.4114 94.5295 37.3496C94.4748 37.2938 94.432 37.2274 94.4038 37.1545C94.3755 37.0816 94.3625 37.0037 94.3654 36.9256V13.1975C94.354 13.0966 94.3666 12.9944 94.4023 12.8993C94.4379 12.8042 94.4956 12.7189 94.5706 12.6504C94.6943 12.5489 94.8482 12.4912 95.0082 12.4863C95.1029 12.4668 95.2007 12.4668 95.2954 12.4863C95.3971 12.5199 95.484 12.5875 95.5416 12.6778L112.144 35.2981L111.857 36.023V13.1701C111.855 13.077 111.873 12.9845 111.911 12.8994C111.949 12.8142 112.005 12.7385 112.076 12.6778C112.184 12.5728 112.323 12.5056 112.473 12.4863Z"
          fill="#F4F4F4"
        />
        <path
          d="M137.719 12.4863C137.815 12.4789 137.912 12.4909 138.003 12.5214C138.095 12.5519 138.18 12.6004 138.252 12.6641C138.313 12.725 138.36 12.7977 138.39 12.8778C138.421 12.9579 138.434 13.0435 138.43 13.1291C138.445 13.2471 138.445 13.3666 138.43 13.4847L128.556 37.0897C128.495 37.2288 128.395 37.3475 128.269 37.4316C128.157 37.5126 128.023 37.5557 127.886 37.5547C127.736 37.5539 127.589 37.5113 127.462 37.4316C127.336 37.3535 127.243 37.2318 127.202 37.0897L117.437 13.5941L117.369 13.4163C117.354 13.3349 117.354 13.2515 117.369 13.1701C117.373 13.0753 117.396 12.9824 117.439 12.8975C117.481 12.8126 117.541 12.7377 117.615 12.6778C117.742 12.5605 117.907 12.4926 118.08 12.4863C118.256 12.4862 118.427 12.5417 118.57 12.6449C118.712 12.748 118.818 12.8936 118.873 13.0607L128.187 35.4759H127.749L137.035 12.9513C137.091 12.8155 137.186 12.699 137.307 12.6164C137.429 12.5338 137.572 12.4885 137.719 12.4863Z"
          fill="#F4F4F4"
        />
        <path
          d="M144.324 36.7615C144.303 36.9655 144.217 37.1573 144.078 37.3085C143.943 37.4402 143.761 37.5139 143.572 37.5137C143.47 37.5188 143.367 37.5032 143.271 37.468C143.175 37.4327 143.086 37.3785 143.011 37.3085C142.884 37.1549 142.816 36.9608 142.82 36.7615V13.2385C142.817 13.1372 142.835 13.0363 142.873 12.9422C142.91 12.8481 142.967 12.7627 143.039 12.6914C143.185 12.5588 143.375 12.4854 143.572 12.4854C143.769 12.4854 143.96 12.5588 144.106 12.6914C144.178 12.7627 144.234 12.8481 144.272 12.9422C144.31 13.0363 144.327 13.1372 144.324 13.2385V36.7615Z"
          fill="#F4F4F4"
        />
        <path
          d="M169.311 14.8523C169.471 14.9619 169.583 15.128 169.625 15.3173C169.638 15.5114 169.585 15.7042 169.475 15.8643C169.43 15.938 169.368 16 169.294 16.0452C169.22 16.0904 169.137 16.1174 169.051 16.1242C168.873 16.1372 168.696 16.0942 168.545 16.0011C167.559 15.328 166.487 14.7899 165.358 14.401C164.173 14.0012 162.93 13.8024 161.679 13.8129C160.272 13.81 158.878 14.0842 157.577 14.6198C156.3 15.1631 155.142 15.9532 154.171 16.9447C153.155 17.9671 152.351 19.1801 151.805 20.5142C151.241 21.9512 150.957 23.4834 150.971 25.0273C150.958 26.5801 151.241 28.1212 151.805 29.5678C152.334 30.8865 153.114 32.0896 154.103 33.1099C155.074 34.1015 156.231 34.8916 157.508 35.4349C158.81 35.9705 160.204 36.2447 161.611 36.2418C162.853 36.2544 164.088 36.0555 165.263 35.6537C166.381 35.2677 167.447 34.744 168.435 34.0946C168.584 33.9818 168.769 33.9281 168.955 33.9442C169.047 33.9559 169.135 33.9878 169.213 34.0374C169.291 34.0871 169.357 34.1533 169.406 34.2314C169.522 34.3787 169.576 34.5649 169.557 34.7511C169.545 34.8428 169.513 34.9308 169.464 35.0088C169.414 35.0868 169.348 35.1529 169.27 35.2024C168.61 35.6585 167.904 36.0435 167.164 36.3512C166.281 36.7314 165.366 37.029 164.428 37.2401C163.491 37.4572 162.532 37.5673 161.57 37.5684C159.954 37.5793 158.352 37.2581 156.865 36.6247C155.473 35.9957 154.219 35.0989 153.173 33.9852C152.016 32.8764 151.087 31.5524 150.438 30.0875C149.161 26.8774 149.161 23.3004 150.438 20.0902C151.051 18.6089 151.947 17.2617 153.077 16.1242C154.193 14.9869 155.526 14.085 156.996 13.4719C158.467 12.8588 160.045 12.5469 161.638 12.5547C163.031 12.5474 164.415 12.7597 165.741 13.1838C167.01 13.5518 168.214 14.1147 169.311 14.8523Z"
          fill="#F4F4F4"
        />
        <path
          d="M172.771 14.5651C172.682 14.5679 172.594 14.5524 172.511 14.5194C172.429 14.4865 172.354 14.4368 172.292 14.3736C172.225 14.3164 172.172 14.2449 172.137 14.1644C172.101 14.084 172.084 13.9965 172.087 13.9086C172.086 13.821 172.104 13.7342 172.139 13.654C172.175 13.5738 172.227 13.5021 172.292 13.4436C172.422 13.3222 172.593 13.2539 172.771 13.2522H189.647C189.83 13.2504 190.006 13.319 190.14 13.4436C190.205 13.5021 190.257 13.5738 190.292 13.654C190.328 13.7342 190.346 13.821 190.345 13.9086C190.349 14.0007 190.332 14.0925 190.297 14.1776C190.261 14.2627 190.208 14.3389 190.14 14.401C189.999 14.5113 189.825 14.5693 189.647 14.5651H172.771ZM181.209 37.5547C181.117 37.5586 181.025 37.5423 180.94 37.5069C180.855 37.4714 180.779 37.4177 180.717 37.3495C180.603 37.2363 180.535 37.0855 180.525 36.9256V14.0864H181.893V36.9256C181.893 37.1106 181.819 37.288 181.689 37.4188C181.558 37.5496 181.38 37.6231 181.195 37.6231L181.209 37.5547Z"
          fill="#F4F4F4"
        />
        <path
          d="M212.432 12.4863C212.526 12.4822 212.62 12.4984 212.707 12.5338C212.794 12.5692 212.873 12.623 212.938 12.6915C213.066 12.8236 213.139 12.9996 213.143 13.1838V28.4464C213.156 30.0545 212.725 31.6351 211.898 33.0142C211.1 34.3756 209.971 35.5139 208.616 36.3239C207.231 37.1299 205.657 37.5545 204.055 37.5545C202.453 37.5545 200.879 37.1299 199.494 36.3239C198.128 35.5189 196.989 34.3801 196.184 33.0142C195.361 31.634 194.935 30.0533 194.954 28.4464V13.1838C194.95 13.001 195.019 12.8241 195.145 12.6915C195.216 12.6205 195.302 12.5655 195.396 12.5301C195.491 12.4948 195.592 12.4798 195.692 12.4863C195.79 12.4834 195.888 12.5 195.979 12.5352C196.071 12.5705 196.155 12.6236 196.225 12.6915C196.354 12.8236 196.427 12.9996 196.431 13.1838V28.4464C196.418 29.8053 196.772 31.1425 197.456 32.3168C198.122 33.4449 199.063 34.3859 200.191 35.052C201.356 35.7321 202.679 36.0906 204.028 36.0906C205.376 36.0906 206.7 35.7321 207.864 35.052C208.986 34.3786 209.926 33.4392 210.599 32.3168C211.294 31.1463 211.653 29.8074 211.638 28.4464V13.1838C211.642 13.0022 211.71 12.8277 211.83 12.6915C211.906 12.6111 212.001 12.5505 212.105 12.5147C212.21 12.479 212.322 12.4693 212.432 12.4863Z"
          fill="#F4F4F4"
        />
        <path
          d="M220.35 12.5C220.467 12.4753 220.588 12.4753 220.706 12.5C220.837 12.5473 220.952 12.6331 221.034 12.7462L231.305 28.0361H230.594L240.864 12.7462C240.932 12.6299 241.034 12.5374 241.157 12.4812C241.279 12.4249 241.416 12.4076 241.548 12.4316C241.637 12.4374 241.724 12.461 241.804 12.5009C241.884 12.5409 241.955 12.5963 242.013 12.6641C242.13 12.7962 242.193 12.9667 242.191 13.1428V36.8435C242.189 36.9393 242.167 37.0337 242.127 37.1208C242.087 37.2078 242.03 37.2857 241.958 37.3496C241.817 37.4798 241.631 37.5529 241.439 37.5547C241.341 37.5584 241.243 37.5422 241.151 37.5069C241.059 37.4716 240.976 37.4181 240.905 37.3496C240.836 37.285 240.78 37.2067 240.743 37.1196C240.705 37.0325 240.686 36.9385 240.687 36.8435V14.8249H241.083L231.441 29.39C231.401 29.4806 231.334 29.5569 231.25 29.6089C231.158 29.6601 231.054 29.6838 230.949 29.6772C230.831 29.6826 230.714 29.6591 230.607 29.6089C230.506 29.5667 230.423 29.4889 230.375 29.39L220.528 14.5377H221.102V36.8435C221.103 36.9385 221.084 37.0325 221.046 37.1196C221.008 37.2067 220.953 37.285 220.883 37.3496C220.753 37.4805 220.576 37.5542 220.391 37.5547C220.293 37.5584 220.195 37.5422 220.103 37.5069C220.012 37.4716 219.928 37.4181 219.858 37.3496C219.736 37.2092 219.668 37.0297 219.666 36.8435V13.2112C219.667 13.0355 219.73 12.8658 219.844 12.7325C219.903 12.6549 219.98 12.5931 220.068 12.5526C220.156 12.512 220.253 12.4939 220.35 12.5Z"
          fill="#F4F4F4"
        />
        <path
          d="M35.4212 39.5788L25 50L14.5788 39.5788L22.8255 31.3458L23.564 32.0706L16.0558 39.5788L25 48.523L33.9442 39.5788L21.5673 27.2019L22.3058 26.4634L35.4212 39.5788Z"
          fill="#FF8972"
        />
        <path
          d="M23.5503 22.2921L10.4212 35.4212L0 25L10.4212 14.5788L18.6816 22.8392L17.9431 23.5777L10.4212 16.0558L1.47702 25L10.4212 33.9442L18.0662 26.2993L22.8118 21.5536L23.5503 22.2921Z"
          fill="#FF8972"
        />
        <path
          d="M50 25L39.5788 35.4212L31.3321 27.1745L32.0706 26.436L39.5788 33.9442L48.523 25L39.5788 16.0558L27.1882 28.4601L26.4497 27.7216L39.5788 14.5788L50 25Z"
          fill="#FF8972"
        />
        <path
          d="M16.0558 10.4212L28.4464 22.8118L27.7079 23.5503L14.5788 10.4212L25 0L35.4212 10.4212L27.1745 18.6679L26.436 17.9294L33.9442 10.4212L25 1.47702L16.0558 10.4212Z"
          fill="#FF8972"
        />
      </svg>
    </Div>
  );
}