import React, { useState, useEffect } from "react";
import styled from "styled-components";
import Loader from "components/UI/Loader";
import MailsPerCategoryView from "./MailsPerCategoryView";
import { getMailCategories } from "api/services/communication";
import { UseOrganizationContext } from "contexts";
import { getOrganzationUser } from "api/services/user";
import { UseErrorContext } from "contexts";

const Container = styled.div``;

const MailView = ( {onchangeSelected, selectedMails, removeFromSelected} ) => {
  const [categories, setCategories] = useState([]);
  const organizationId = UseOrganizationContext()?.id;
  const { updateError } = UseErrorContext();

  useEffect(async () => {   
    await refresh();
  }, []);

  const refresh = async () => {  
    try{ 
      let allCategories = await getMailCategories();    
      let user = await getOrganzationUser(organizationId);
      let userDepartmentsIds = user.departments.map(x => x.id);

      let cats = allCategories.filter(item => userDepartmentsIds.includes(item.id));

      setCategories(cats);    
    } catch (err) {
      updateError(err);
    }
  }

  return (
    <Container>
      {!categories ? (
        <Loader center />
      ) : (
        categories?.map((catgory, idx) => {
          return (
            <MailsPerCategoryView category={catgory} key={idx} onchangeSelected={onchangeSelected} selectedMails={selectedMails} removeFromSelected={removeFromSelected} _refresh={refresh}/>          
          );
        })
      )}
    </Container>
  );
};

MailView.defaultProps = {};

export default MailView;
