import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { useHistory } from "react-router-dom";
import { Formik, Form, Field, ErrorMessage } from "formik";
import Loader from "components/UI/Loader";
import Button from "components/UI/Buttons/Button";
import { UseOrganizationContext } from "contexts";
import { AddOrganisationAddress, EditOrganisationAddress } from "api/services/organization";
import { UseErrorContext } from "contexts";
import { GetCountries } from "api/services/countries";

const FormWrapper = styled.div`
  label {
    display: block;
    margin-bottom: 0.4rem;
  }
  input {
    width: 100%;
  }
  textarea {
    width: 100%;
    height: 10rem;
  }
  .form_row {
    margin-bottom: 2rem;
    display: flex;
    width: 100%;
    .field {
      width: 100%;
      &:not(:first-of-type) {
        margin-left: 2.4rem;
      }
    }
  }
  .error-message {
    opacity: 0.6;
    color: red;
    padding: 1rem 0;
  }
  .buttons {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    button {
      margin-right: 1rem;
    }
  }
`;

export const AddressForm = ({ onClose, initialValue, action = "add" }) => {
  const history = useHistory();
  const { id } = UseOrganizationContext();
  const { updateError } = UseErrorContext();

  const [countries, setCountries] = useState([]);

  useEffect(async () => {
    const ctr = await GetCountries();
    setCountries(ctr);
  }, []);

  const callBack = async ({ line1, line2, line3, zipCode, city, country }) => {
    console.log("action", action)
    if (action == "add") {
      try {
        const rep = await AddOrganisationAddress(id, {
          addressLine1: line1,
          addressLine2: line2,
          addressLine3: line3,
          city: city,
          postalCode: zipCode,
          countryCode: country,
        });
        return rep;
      } catch (error) {
        updateError(err);
      }
    }
    if (action == "edit") {
      try {
        const rep = await EditOrganisationAddress(id, initialValue?.id, {
          addressLine1: line1,
          addressLine2: line2,
          addressLine3: line3,
          city: city,
          postalCode: zipCode,
          countryCode: country
        });
        return rep;
      } catch (error) {
        updateError(err);
      }
    }
  };

  return (
    <FormWrapper>
      <Formik
        initialValues={
          initialValue
            ? { line1: initialValue?.addressLine1, line2: initialValue?.addressLine2, line3: initialValue?.addressLine3, zipCode: initialValue?.postalCode, city: initialValue?.city, country: initialValue?.countryName }
            : { line1: "", zipCode: "", city: "" }
        }
        validate={(values) => {
          const errors = {};
          if (!values.line1) {
            errors.line1 = "Required";
          }
          if (!values.zipCode) {
            errors.zipCode = "Required";
          }
          if (!values.city) {
            errors.city = "Required";
          }
          if (!values.country) {
            errors.country = "Required";
          }
          return errors;
        }}
        onSubmit={(values, { setSubmitting }) => {
          console.log(values);
          callBack(values)
            .then((value) => {
              history.go(0)
            })
            .catch((e) => {
              console.log(e);
              alert("Something went wrong", e.message);
              setSubmitting(false);
            });
        }}
      >
        {({ isSubmitting, handleSubmit }) => (
          <Form>
            <div className="form_row">
              <div className="field">
                <label htmlFor="street">Address line 1</label>
                <Field type="text" name="line1" />
                <ErrorMessage className="error-message" name="line1" component="div" />
              </div>
            </div>
            <div className="form_row">
              <div className="field">
                <label htmlFor="street">Address line 2</label>
                <Field type="text" name="line2" />
                <ErrorMessage className="error-message" name="line2" component="div" />
              </div>
            </div>
            <div className="form_row">
              <div className="field">
                <label htmlFor="street">Address line 3</label>
                <Field type="text" name="line3" />
                <ErrorMessage className="error-message" name="line3" component="div" />
              </div>
            </div>
            <div className="form_row">
              <div className="field">
                <label htmlFor="zipCode">Zip</label>
                <Field type="text" name="zipCode" />
                <ErrorMessage className="error-message" name="zipCode" component="div" />
              </div>
              <div className="field">
                <label htmlFor="city">City</label>
                <Field type="text" name="city" />
                <ErrorMessage className="error-message" name="city" component="div" />
              </div>
              <div className="field">
                <label htmlFor="country">Country</label>
                <Field as="select" name="country">
                  {countries?.map((country, idx) => (
                    <option value={country.code} key={idx}>
                      {country.name}
                    </option>
                  ))}
                </Field>
                <ErrorMessage className="error-message" name="country" component="div" />
              </div>
            </div>
            {!isSubmitting ? (
              <div className="buttons">
                <Button
                  label="cancel"
                  onClick={(e) => {
                    //history.go(0)
                    onClose();
                  }}
                  type="secondary"
                />
                <Button onClick={()=>{console.log("click");handleSubmit()}} label="Save" disabled={isSubmitting} />
              </div>
            ) : (
              <Loader />
            )}
          </Form>
        )}
      </Formik>
    </FormWrapper>
  );
};
