import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { UseErrorContext } from "contexts";
import Button from "components/UI/Buttons/Button";
import * as Yup from 'yup'
import { Formik, Form, Field, ErrorMessage } from "formik";
import Loader from "components/UI/Loader";
import { getCategories } from "api/services/event";
import { EventCategory } from "constants";

const FormWrapper = styled.div`
`;

const ValidationSchema = Yup.object().shape({
  category: Yup.string().required("Verplicht"),
});

const CategoryForm = ({ setCategory }) => {
  const { updateError } = UseErrorContext();
  const [categories, setCategories] = useState([]);

  // Init data
  useEffect(async () => {
    try {
      var cats = await getCategories();
      setCategories(cats);
    } catch (err) {
      updateError(err);
    }
  }, []);

  return (
    <FormWrapper>
      {categories.length > 0 &&
      <Formik
        initialValues={{
          category: categories[0],
        }}
        validationSchema={ValidationSchema}
        onSubmit={(values, { setSubmitting }) => {
          setCategory(values.category)
            .then((value) => {
              setSubmitting(false);
            })
            .catch((err) => {
              setSubmitting(false);
              updateError(err);
            });
        }}
      >
        {({ isSubmitting, handleSubmit }) => (
          <Form className="form">  
            <div className="form_row">
              <div className="field">
                <label htmlFor="category" className="required">
                  Category
                </label>
                <Field as="select" name="category">
                  {categories.map((cat, idx) => (
                    <option key={idx} value={cat}>{EventCategory[cat]}</option>
                  ))}
                </Field>  
                <ErrorMessage className="error-message" name="category" component="div" />
              </div>
            </div>          
            {!isSubmitting ? (
              <div className="buttons">
                <Button onClick={handleSubmit} label="Next" disabled={isSubmitting} />
              </div>
            ) : (
              <Loader />
            )}
          </Form>
        )}
      </Formik>
      }
    </FormWrapper>
  );
};

export default CategoryForm;
