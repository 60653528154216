import React, { useState, useEffect } from "react";
import { FaArrowUp } from "react-icons/fa";

export default function ArrowUp({disabled}) {
  return (
    <>
      {!disabled &&
        <div className="icon-button">
          <FaArrowUp disabled/>
        </div>
      }
    </>
  );
}
