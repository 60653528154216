import React, { useState, useEffect } from "react";
import styled from "styled-components";
import Button from "components/UI/Buttons/Button";
import RelatedEventGrid from "pages/events/Components/RelatedEventGrid";
import HeaderDropDown from "components/UI/HeaderDropDown";
import { getEvent } from "api/services/event";

const Container = styled.div`
  overflow: auto;
`;

const RelatedEventsForm = ({ relChildEvents, relParentEvents, nextStep, backStep }) => {
  const [relatedChildEvents, setRelatedChildEvents] = useState(relChildEvents ?? []);
  const [relatedParentEvents, setRelatedParentEvents] = useState(relParentEvents ?? []);
  const [relatedEventIds, setRelatedEventIds] = useState([]);
  const [isReadOnly, setIsReadOnly] = useState(false);

  const _addParent = async (relatedEventId) => {
    const relatedEvent = await getEvent(relatedEventId);
    setRelatedParentEvents([...relatedParentEvents, relatedEvent]);
  };

  const _deleteParent = async (relatedEventId) => {
    setRelatedParentEvents(relatedParentEvents.filter(x => x.id !== relatedEventId))
  };

  const _addChild = async (relatedEventId) => {
    const relatedEvent = await getEvent(relatedEventId);
    setRelatedChildEvents([...relatedChildEvents, relatedEvent]);
  };

  const _deleteChild = async (relatedEventId) => {
    setRelatedChildEvents(relatedChildEvents.filter(x => x.id !== relatedEventId))
  };

  useEffect(async () => {
    const childIds = relatedChildEvents.map(x => x.id);
    const parentIds = relatedParentEvents.map(x => x.id);
    let ids = childIds.concat(parentIds);
    setRelatedEventIds(ids);
  }, [relatedChildEvents, relatedParentEvents]);

  return (
    <Container className="form">
      <HeaderDropDown titleHeader="Head action">
        <RelatedEventGrid relatedEvents={relatedParentEvents} relatedEventIds={relatedEventIds} type="parent" isReadOnly={isReadOnly} add={_addParent} remove={_deleteParent}/> 
      </HeaderDropDown>
     <HeaderDropDown titleHeader="Subactions">
        <RelatedEventGrid relatedEvents={relatedChildEvents} relatedEventIds={relatedEventIds} type="child" isReadOnly={isReadOnly} add={_addChild} remove={_deleteChild}/> 
      </HeaderDropDown>
      <div className="buttons">
        <Button label="Back" onClick={() => backStep()} type="secondary" />
        <Button label="Next" onClick={() => nextStep(relatedChildEvents, relatedParentEvents)} type="primary" />
      </div>
    </Container>
  );
};

export default RelatedEventsForm;
