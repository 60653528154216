import React, { useEffect, useState } from "react";
import Page from "components/UI/Elements/Page";
import styled from "styled-components";
import { UseUserContext, UseOrganizationContext, UseErrorContext } from "contexts";
import PageHeaderSub from "components/PageHeaderSub";
import Button from "components/UI/Buttons/Button";
import { resetInvestorDemoData } from "api/services/investor-insights";
import { resetDemoData, getAdminOrganization } from "api/services/organization";
import { getUser } from "api/services/user";
import { switchOrganization, getOrganizationsForSupport } from "api/services/support";
import HeaderDropDown from "components/UI/HeaderDropDown";
import Loader from "components/UI/Loader";
import Status from "components/UI/Status";
import { UserForm2 } from "./Components/UserForm2";
import { useTranslation } from "react-i18next";
import Modal from "components/UI/Modal";
import { getPossibleLanguages, updateOrganizationLanguages } from "api/services/translations";
import SwitchButtonOn from "components/UI/Buttons/SwitchButtonOn";
import { ValidateAccess } from "api/services/authorization";

const locales = {
  EN: { title: "English" },
  NL: { title: "Nederlands" },
  FR: { title: "Français" },
};

const spanStyle = {
  color: "red",
};

const LangContainer = styled.div`
  .lang-grid {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 1.6rem; /* Optional: Adds space between the grid items */
    padding: 1.2rem;
  }
  .lang-item {
    display: flex;
    align-items: center;
    gap: 0.8rem;
    & > label {
      min-width: 2.4rem;
    }
  }
`;

const User = () => {
  const [showResetData, setShowResetData] = useState({});
  const [isSupportUser, setIsSupportUser] = useState(false);
  const [user, setUser] = useState(null);
  const [companies, setCommpanies] = useState([]);
  const [newCompany, setNewCompany] = useState({});
  const [showModalSwitchCompany, setShowModalSwitchCompany] = useState(false);
  const [switchCompanyLoading, setSwitchCompanyLoading] = useState(false);
  const [resetDataLoading, setResetDataLoading] = useState(false);
  const [possibleLanguages, setPossibleLanguages] = useState([]);
  const [selectedLanguages, setSelectedLanguages] = useState([]);
  const [updatingLanguages, setUpdatingLanguages] = useState(false);
  const [hasAdminAccess, setHasAdminAccess] = useState(false);

  const { userName, permissions } = UseUserContext();
  const organizationId = UseOrganizationContext()?.id;
  const { updateError } = UseErrorContext();
  const { t, i18n } = useTranslation();

  useEffect(async () => {
    const access = ValidateAccess(["Admin"], permissions);
    setHasAdminAccess(access);
    console.log("access", access);
    console.log("access", access);

    const supportUsers = process.env.REACT_APP_SUPPORT_ACCOUNT.split(",");
    const supportUser = supportUsers.some((s) => userName === s);
    setIsSupportUser(supportUser);

    setShowResetData(userName === "demo@finvictum.com");

    if (supportUser && companies.length == 0) {
      const companiesResponse = await getOrganizationsForSupport();
      setCommpanies(companiesResponse);

      if (organizationId) {
        setSelectedCompany(organizationId);
      }
    }
  }, [userName, permissions]);

  useEffect(async () => {
    try {
      const languagesBE = await getPossibleLanguages();
      setPossibleLanguages(languagesBE);

      let u = await getUser();
      setUser(u);
      // TODO get user linked companies
    } catch (err) {
      updateError(err);
    }
  }, []);

  useEffect(async () => {
    if (!organizationId) return;
    try {
      const getCompanylanguages = await getAdminOrganization(organizationId);
      setSelectedLanguages(getCompanylanguages?.languages || []);
    } catch (err) {
      updateError(err);
    }
  }, [organizationId]);

  const resetData = async function () {
    try {
      //reset data
      setResetDataLoading(true);
      await resetInvestorDemoData();
      await resetDemoData();
      setResetDataLoading(false);
    } catch (err) {
      updateError(err);
    }
  };

  const onChangeProductFilter = async (e) => {
    console.log("Selected new company: " + e.target.value);

    const newCompany = companies.find((c) => c.id == e.target.value);
    setNewCompany(newCompany);

    console.log("Selected new company: " + e.target.value + " " + newCompany.name);

    setShowModalSwitchCompany(true);
  };

  const switchCompany = async () => {
    try {
      setSwitchCompanyLoading(true);
      await switchOrganization(newCompany.id);
      location.reload();
    } catch (err) {
      setSwitchCompanyLoading(false);
      setShowModalSwitchCompany(false);
      updateError(err);
    }
  };

  const updateLanguages = async (key, add) => {
    if (!add) {
      if (selectedLanguages?.length == 1) {
        updateError({ message: "You need to select at least 1 language" });
        return;
      }
    }
    if (updatingLanguages || !hasAdminAccess) return;

    add ? setSelectedLanguages((prevItems) => [...prevItems, key]) : setSelectedLanguages((prevItems) => prevItems.filter((item) => item !== key));
  };

  useEffect(async () => {
    if (selectedLanguages?.length == 0) return;
    if (!organizationId) return;
    setUpdatingLanguages(true);
    await updateOrganizationLanguages(organizationId, selectedLanguages);
    setUpdatingLanguages(false);
  }, [selectedLanguages]);

  return (
    <Page>
      <div className="page__header">
        <h1>{t("settings")}</h1>
        <PageHeaderSub show>
          {showResetData && <Button label="Reset Data" onClick={resetData} disabled={resetDataLoading} />}
          <Status label={user?.status} onClick={() => console.log()} />
          {/* <Link to="user/permissions">
            <Button label="My permissions"/>
          </Link> */}
        </PageHeaderSub>
      </div>
      <div className="page__inner">
        <HeaderDropDown titleHeader={t("personalinformation")}>{!user ? <Loader center /> : <UserForm2 user={user} possibleLanguages={possibleLanguages}/>}</HeaderDropDown>

        {organizationId && hasAdminAccess && (
          <HeaderDropDown titleHeader="Company Languages">
            <div>Default language: EN</div>
            <br />
            <LangContainer>
              <label>{hasAdminAccess ? t("Select languages you want to translate.") : t("Only admins can change languages")}</label>
              <div style={updatingLanguages || !hasAdminAccess ? { opacity: 0.5 } : {}} className="lang-grid">
                {!user ? (
                  <Loader center />
                ) : (
                  possibleLanguages?.map(
                    (lang) =>
                      lang != "EN" && (
                        <div key={lang} className="lang-item">
                          <label>{lang}</label>
                          <SwitchButtonOn on={selectedLanguages.includes(lang)} onSwitchOn={() => updateLanguages(lang, true)} onSwitchOff={() => updateLanguages(lang, false)} forceState />
                        </div>
                      )
                  )
                )}
              </div>
            </LangContainer>
          </HeaderDropDown>
        )}

        {isSupportUser && (
          <HeaderDropDown titleHeader="Support">
            {showModalSwitchCompany && (
              <Modal title="Switch Company" onClose={() => setShowModalSwitchCompany(false)} onSubmit={() => switchCompany()} cancelLabel={"Cancel"} submitLabel={"Switch"}>
                <div>
                  Are you sure you want to switch to company <span style={spanStyle}>{newCompany?.name}</span>?
                </div>
                {switchCompanyLoading && <Loader center />}
              </Modal>
            )}
            <div>
              <label>Switch from company</label>
              <select onChange={onChangeProductFilter} value={organizationId}>
                {companies.map((filter, idx) => (
                  <option key={idx} value={filter?.id}>
                    {filter?.name}
                  </option>
                ))}
              </select>
            </div>
          </HeaderDropDown>
        )}
        {/* <HeaderDropDown titleHeader="Linked companies">
          <CompanyGrid companies={companies}/>        
        </HeaderDropDown> */}
      </div>
    </Page>
  );
};

export default User;
