import React, { useState, useEffect, useContext } from "react";
import styled from "styled-components";
import { WebsocketContext } from "contexts/InfrontContext";
import { InfrontSDK } from "libs/infrontFramework";
import { useTranslation } from 'react-i18next';
import { FaCheckDouble } from "react-icons/fa";

const Container = styled.div`
  display: flex;
  justify-content: space-between;
  font-family: Montserrat;
  padding: 0 1rem;
  .stock-price {
    &__label {
      font-size: 2rem;
      font-weight: 500;
      text-align: left;
    }
    &__value {
      font-size: 1.6rem;
      gap: .8rem;
      text-align: right;
    }
    &__percentage {
      font-size: 1.2rem;
      gap: .8rem;
      text-align: right;
    }
  }
  .stock-items {
    display: flex;
    align-items: center;
    max-width: 100%;
    flex-wrap: wrap;
  }
  .stock-item {
    padding: .6rem 1.2rem;
    width: 50%;
    &:last-of-type{
      border-right: unset;
    }
    &__label {
      color: var(--color-accent-prim);
      font-size: 1.2rem;
      opacity: 1;
      padding-bottom: 0.4rem;
      text-transform: uppercase
    }
  }
`;

const StockData = ({ stockSymbol, isin, mic, organization }) => {
  const [cachedSymbols, setCachedSymbols] = useState({});
  const { ws, isReady } = useContext(WebsocketContext);
  const { t, i18n } = useTranslation();
  var unsubscribes;

  useEffect(() => {
    function unsubscribe() {
      if (unsubscribes) unsubscribes();
      unsubscribes = undefined;
    }

    const delayedPricing = (source, limit = 100, id = null) => {
      if (!ws || !isReady) return;
      unsubscribe = ws.get(
        InfrontSDK.symbolData({
          content: {
            Basic: true,
          },
          id: { mic: mic, isin: isin },
          subscribe: true,
          onData: (data) => {
            handleSymbolData(data);		
            data.observe(InfrontSDK.SymbolField.Last, value => { handleSymbolData(data); });			
          }
        })
      );
     };

    function handleSymbolData(data)
    {
      let newSymbol = {};
      newSymbol[data.get(InfrontSDK.SymbolField.Ticker)] = data;
      setCachedSymbols((prev) => ({ ...prev, ...newSymbol }));
    }

    delayedPricing();

    return function cleanup() {
      unsubscribe();
    };
  }, [isReady, stockSymbol, isin, mic]);

  const lastPrice = cachedSymbols[stockSymbol]?.get(InfrontSDK?.SymbolField.Last);
  const percentChange = cachedSymbols[stockSymbol]?.get(InfrontSDK?.SymbolField.ChangePercent);

  const low = cachedSymbols[stockSymbol]?.get(InfrontSDK?.SymbolField.Low);
  const high = cachedSymbols[stockSymbol]?.get(InfrontSDK?.SymbolField.High);
  const open = cachedSymbols[stockSymbol]?.get(InfrontSDK?.SymbolField.Open);
  const close = cachedSymbols[stockSymbol]?.get(InfrontSDK?.SymbolField.YesterdayClose);

  return stockSymbol ? (
    <Container>
      <div className="stock-price__label">
        <p>{stockSymbol}</p>
        <p>{organization.name}</p>
        {organization.isActive && <p className="card__subtitle">{t('onboarded')} <FaCheckDouble/></p>} 
      </div>
      <div>
        <p className="stock-price__percentage">{percentChange?.toFixed(2)} %</p>
        <p className={`stock-price__value ${percentChange >= 0 ? "good" : "bad"}`}>€ {lastPrice?.toFixed(2)}</p>
      </div>
      {/* <div className="stock-items">
        <div className="stock-item">
          <div className="stock-item__label">{t('stocklow')}</div> € {low?.toFixed(2)}
        </div>
        <div className="stock-item">
          <div className="stock-item__label">{t('stockhigh')}</div> € {high?.toFixed(2)}
        </div>
        <div className="stock-item">
          <div className="stock-item__label">{t('stockopen')}</div> € {open?.toFixed(2)}
        </div>
        <div className="stock-item">
          <div className="stock-item__label">{t('stockprevcloseshort')}</div> € {close?.toFixed(2)}
        </div>
      </div> */}
    </Container>
  ) : (
    <></>
  );
};

export default StockData;
