import React from "react";
import styled from "styled-components";
import Loader from "../Loader";
import imageThumb from "../../../assets/thumbnail.svg";

const Div = styled.div`
  .image {
    position: absolute;
    object-fit: cover;
    width: 100%;
    height: 100%;
  }
  .image-thumb {
    position: absolute;
    height: 50%;
  }
  input {
    opacity: 0;
  }  
  .loader{
    position: absolute;
    top: calc(50% - 4rem);
    left: calc(50% - 4rem);  
  }
  :hover{
    opacity: .7;
  }
`;



export default function Image( {url, onChange, isLoading, disabled} ) {
 
  return (
    <Div>   
        { url 
          ? <img className="image" src={url} alt="" style={{ opacity: isLoading ? .5 : 1 }}/>
          : <img className="image-thumb" src={imageThumb} alt="" style={{ opacity: isLoading ? .5 : 1 }}/>
        }
        { !disabled &&
          <>
            { !isLoading && <input title="Upload image" className="image" type="file" accept="image/*" onChange={onChange} /> }
            { isLoading && <div className="loader"><Loader /> </div>}
          </>
        }
    </Div>
  );
}