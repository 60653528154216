import React, { useState, useEffect } from "react";
import HeaderDropDown from "components/UI/HeaderDropDown";
import styled, { css } from "styled-components";
import PageHeaderSub from "components/PageHeaderSub";
import Page from "components/UI/Elements/Page";
import FollowedEventGrid from "pages/events/Components/FollowedEventGrid";
import SearchBar from "../../components/UI/SearchBar";
import { getFollowedEvents } from "../../api/services/event";
import { UseErrorContext } from "contexts";
import { delay } from "api/helpers/datahelper";
import { useParams } from "react-router-dom";
import { BackButton } from "components/UI/Buttons/BackButton";
import { getOrganization } from "api/services/organization";
import { Link } from "react-router-dom";
import Button from "components/UI/Buttons/Button";
import moment from "moment";
import { useTranslation } from "react-i18next";
import { getNews } from "api/services/news";
import { getProducts } from "api/services/product";
import Loader from "components/UI/Loader";

const Container = styled.div`
  display: flex;
  flex-wrap: wrap;
  .news {
    width: calc(100% / 1);
    padding: 1rem;
    ${({ direction }) =>
      direction == "column" &&
      css`
        width: calc(100% / 1);
        @media ${device.laptopM} {
          width: calc(100% / 1);
        }
      `}

    .news_title {
      font-size: 1.8rem;
      margin: 0.4rem 0;
    }
    .news_date {
      font-size: 1.2rem;
      opacity: 0.8;
    }
    .news_description {
      font-size: 1.6rem;
      line-height: 1.6rem;
      opacity: 0.6;
    }
    .news_image {
      position: relative;
      width: 100%;
      margin-bottom: 30%;
      height: 100%;
      img {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
      }
    }
  }
  .loadmore-btn {
    margin: 1rem;
  }
`;

const FollowedOrganizationNews = () => {
  const { id } = useParams();

  const [organization, setOrganization] = useState(null);
  const [newsItems, setNewsItems] = useState(null);

  const { t, i18n } = useTranslation();
  const { updateError } = UseErrorContext();

  const fetchNews = async () => {
    if (!id) return;
    try {
      let products = await getProducts(id);
      if (products.length > 0) {
        let productIds = products.map((x) => x.id).join();
        const res = await getNews(productIds);
        setNewsItems(res.data);
      } else {
        setNewsItems([]);
      }

      const p = await getOrganization(id);
      setOrganization(p);
    } catch (err) {
      updateError(err);
    }
  };

  useEffect(async () => {
    fetchNews();
  }, []);

  return (
    <>
      <Page>
        <div className="navigation-banner">
          <BackButton link={"/organizations/view/" + id} />
          <ul className="breadcrumbs">
            <li>
              <a href="/organizations">{t("organizations")}</a>
            </li>
            <li>
              <a href={"/organizations/view/" + id}>{organization?.name}</a>
            </li>
            <li>
              <span className="selected">{t("News")}</span>
            </li>
          </ul>
        </div>
        <div className="page__header">
          <h1>News</h1>
        </div>
        <div className="page__inner">
          <HeaderDropDown titleHeader={t("News")}>
            <Container direction={"row"}>
              {!newsItems ? (
                <Loader center />
              ) : (
                <div>
                  {newsItems.length  == 0 && <div>{t('No news')}</div>}
                  {newsItems?.map(
                    (item, idx) =>
                      item.url && (
                        <a href={item.url} target={"_blank"} key={idx} className="news">
                          <div className="news_date">{`${moment(item.published_at).format("YYYY-MM-DD HH:mm")}`}</div>
                          <div className="news_title">{item.title}</div>
                          <div className="news_description">{/*formatDescription(item.content)*/}</div>
                        </a>
                      )
                  )}
                  {/*<Button label="Load more" onClick={loadMore} className="loadmore-btn") />*/}
                </div>
              )}
            </Container>
          </HeaderDropDown>
        </div>
      </Page>
    </>
  );
};

export default FollowedOrganizationNews;
