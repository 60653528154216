import React, { useState, useEffect } from "react";
import styled from "styled-components";
import thumbnail from "../../../assets/thumbnail.jpg";
import PrivateCard from "components/PrivateCard";
import { formatDescription } from "api/helpers/datahelper";
import { followOrganization, unfollowOrganization } from "api/services/organization";
import { UseErrorContext } from "contexts";
import Unfollow from "components/UI/Icons/Unfollow";
import Follow from "components/UI/Icons/Follow";
import Button from "components/UI/Buttons/Button";
import { FaCheckDouble } from "react-icons/fa";
import { useTranslation } from 'react-i18next';

const Div = styled.div`
  .card_image__container {
    min-height: 12rem;
  }
`;

const FollowedOrganizationCard = ({ id, coverUrl, logoUrl, name, description, isFollowed, isActive, refresh }) => {
  const { updateError } = UseErrorContext();
  const [followed, setFollowed] = useState(isFollowed ?? true);
  const { t, i18n } = useTranslation();
 
  const _followOrganization = async (e) => {
    e.preventDefault()
    e.stopPropagation();
    e.nativeEvent.stopImmediatePropagation();
    try {
      await followOrganization({'organizationId': id});
      refresh();
    } catch (err) {
      updateError(err);
    }
  };

  const _unfollowOrganization = async () => {
    try {
      await unfollowOrganization(id);
      refresh();
    } catch (err) {
      updateError(err);
    }
  };

  useEffect(async () => {
    setFollowed(isFollowed ?? true);
  }, [isFollowed]);

  return (
    <>
      <Div className="card">
        <PrivateCard to={`/organizations/view/${id}`}>       
          <div className="card_image__container">
            <div className="cover">              
              <img src={coverUrl ?? thumbnail} alt="" />
             </div>
            <div className="logo">        
              <img src={logoUrl} alt="" />
            </div>
          </div>
          <div className="card__content">
            <div className="card__content__header">
              <div className="card__title">{name}</div> 
              {isActive && <div className="card__subtitle">{t('onboarded')} <FaCheckDouble/></div>} 
            </div>
            {/* <div className="card__content__body">
            <p>{formatDescription(description)}</p>
            </div> */}
            <div className="card__content__footer">
              { !followed  &&
                <div className="follow-button">
                  <Button onClick={(e) => _followOrganization(e)} label="Follow">
                    <Follow/>
                  </Button>
                </div>
              }
            </div>
          </div>
        </PrivateCard>
      </Div>
    </>
  );
};

FollowedOrganizationCard.defaultProps = {
  cover: thumbnail,
  title: "title",
  subtitle: "subtitle",
  updatedTimeUtc: "01-01-1900",
};

export default FollowedOrganizationCard;
