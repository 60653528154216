import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { UseErrorContext } from "contexts";
import PostComponent from "./PostComponent";

const Container = styled.div`
  overflow: auto;
`;

const PostGrid = ({ posts, refresh }) => {

  return (
    <Container>        
      {posts.map((post, idx) => (
          <PostComponent post={post} key={post.id + "_" + idx} refresh={refresh}/>
      ))}
    </Container>
  );
};

PostGrid.defaultProps = {
  posts: Array.from(Array(0)),
};

export default PostGrid;
