import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { useParams } from "react-router-dom";
import HeaderDropDown from "../../components/UI/HeaderDropDown";
import Page from "../../components/UI/Elements/Page";
import Loader from "components/UI/Loader";
import { BackButton } from "components/UI/Buttons/BackButton";
import { UseErrorContext } from "contexts";
import PageHeaderSub from "components/PageHeaderSub";
import { getFollowedEvent, getEventSections } from "api/services/event";
import Modal from "components/UI/Modal";
import Button from "components/UI/Buttons/Button";
import { getOrganization } from "api/services/organization";
import SectionInfo from "components/SectionInfo";
import { Link } from "react-router-dom";
import EventInfo from "pages/events/Components/EventInfo";
import { QuestionForm } from "pages/events/Components/QuestionForm";
import { useTranslation } from 'react-i18next';

const Container = styled.div``;

const ViewFollowedEvent = () => {
  const { organizationId, eventId } = useParams();
  const [event, setEvent] = useState(null);
  const [eventSections, setEventSections] = useState([]);
  const { updateError } = UseErrorContext();
  const [showModal, setShowModal] = useState(false);
  const { t, i18n } = useTranslation();

  useEffect(async () => {
    await refresh();
  }, []);

  const refresh = async () => {
    try{
      const e = await getFollowedEvent(eventId);
      const org = await getOrganization(organizationId);
      e['organization'] = org;
      setEvent(e);

      const sect = await getEventSections(eventId);
      setEventSections(sect);
    } catch (err) {
      updateError(err);
    }
  };

  return (
    <>
      {showModal && (
        <Modal title={t('askquestion')} hideActions>
          <QuestionForm event={event} onClose={() => setShowModal(false)}/>
        </Modal>
      )}
      <Page>
        {!event ? (
          <Loader />
        ) : (
          <Container>                        
              <div className="navigation-banner">              
                  <BackButton link={"/organizations/view/" + organizationId + "/events"}/>
                  <ul className="breadcrumbs">
                    <li>
                      <a href="/organizations">{t('organizations')}</a>
                    </li>
                    <li>
                      <a href={"/organizations/view/" + organizationId}>{event.organization?.name}</a>
                    </li>
                    <li>
                      <a href={"/organizations/view/" + organizationId + "/events"}>{t('actions')}</a>
                    </li>
                    <li>
                      <span className="selected">{event.title}</span>
                    </li>
                  </ul>
            </div>     
            <div className="page__header">              
              <h1></h1>
              <PageHeaderSub show>             
                <Link to={`/followed-events/view/${event.id}/documents`}>
                    <Button label={t('documentsandlinks')}/>
                </Link>
                <Button onClick={() => setShowModal(true)} label={t('askquestion')}/>   
              </PageHeaderSub>
            </div>
            <div className="page__inner">
              <HeaderDropDown titleHeader={t('intro')} showCollapse>           
                <EventInfo event={event}/>
              </HeaderDropDown>
              {eventSections.map((section, idx) => (
                section.isVisible &&
                //  <HeaderDropDown titleHeader={section.title} background="section.imageUrl" showCollapse bodyStyle={{backgroundImage: "url(" + section.imageUrl + ")", backgroundSize: "cover"}}>              
                 <HeaderDropDown titleHeader={section.title} showCollapse>              
                    <SectionInfo section={section}/>
                 </HeaderDropDown>
                ))}
            </div>
          </Container>
        )}
      </Page>
    </>
  );
};

export default ViewFollowedEvent;
