import React, { useState, useEffect } from "react";
import styled from "styled-components";
import HeaderDropDown from "components/UI/HeaderDropDown";
import MailGrid from "./MailGrid";
import SearchBar from "components/UI/SearchBar";
import { getQuestions, getFilters } from "api/services/communication";
import Pagination from "components/Pagination";
import { GetEventDate, GetPagination } from "api/helpers/datahelper";
import Loader from "components/UI/Loader";
import { delay } from "api/helpers/datahelper";
import { UseErrorContext, UseOrganizationContext, UseUserContext } from "contexts";
import { ValidateAccess } from "api/services/authorization";

const Container = styled.div``;

const MailsPerCategoryView = ( {category, onchangeSelected, selectedMails, removeFromSelected, _refresh} ) => {  
  const organizationId = UseOrganizationContext()?.id;
  const [mails, setMails] = useState([]);
  const [search, setSearch] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [numberOfPages, setNumberOfPages] = useState(1);
  const [loading, setLoading] = useState(false);
  const [eventFilters, setEventFilters] = useState([]);
  const [eventFilter, setEventFilter] = useState(null);
  const [statusFilter, setStatusFilter] = useState(null);
  const { updateError } = UseErrorContext();
  const { permissions = [] } = UseUserContext();

  // Init data
  useEffect(async () => {
    setStatusFilter("Unanswered")
  }, []);

  // Refresh data
  useEffect(async () => {
    refresh();
  }, [search, currentPage, eventFilter, statusFilter]);

  // Rerfesh data
  const refresh = async () => {  
    try{
      // Fix because not correct loaded on first time
      if (!statusFilter) return;
    
      setLoading(true);

      var params = {
        'page': currentPage,
        'search': search,
        'unanswered': statusFilter == "Unanswered",
      }
      if (eventFilter){
        params["eventIds"] = parseInt(eventFilter);
      }

      const mailResponse = await getQuestions(category.id, params);  

      let mailData = mailResponse.data;
      let mailHeader = mailResponse.headers;
        
      let pagination = GetPagination(mailHeader);
      let totalCount = pagination.totalCount;
      let pageSize = pagination.pageSize;
      let nbrOfPages = Math.ceil(totalCount / pageSize);
          
      setNumberOfPages(nbrOfPages);

      setMails(mailData); 

      // Set Filters
      let access = ValidateAccess(["Events.Read"], permissions);
      if (access){
        const filtersData = await getFilters(organizationId);
        setEventFilters(filtersData["events"]);
      }

      await _refresh();

      setLoading(false);
    } catch (err) {
      updateError(err);
    }
  }

  // Search
  const onSearch = async (e) => {    
    await delay(1000);
    setCurrentPage(1);
    const val = e.target.value.toString();
    setSearch(val);
  };

  // Filters
  const onChangeEventFilter = (e) => {
    setCurrentPage(1);
    const val = e.target.value.toString();
    setEventFilter(val);
  };

  const onChangeStatusFilter = (e) => {
    setCurrentPage(1);
    const val = e.target.value.toString();
    setStatusFilter(val);
  };

  return (
    <Container>
      <HeaderDropDown titleHeader={`${category.name} ${category.unansweredQuestions > 0 ? "(" + category.unansweredQuestions + ")" : ""}`} collapseBody showCollapse>  
        <div className="searchBarContainer">
          <SearchBar onChange={onSearch}/>
        </div>
        {loading && <Loader />}
        {!loading && (  
          <>
            <div className="filters">
              <div className="row">
                <div>
                  <label>Event</label>
                    <select onChange={onChangeEventFilter} value={eventFilter}>
                      <option value="">All</option>
                      {eventFilters?.map((filter, idx) => (                 
                        <option key={idx} value={filter.id}>{`${filter.title} (${GetEventDate(filter)})`}</option>
                      ))}
                    </select>
                </div>
                <div>
                  <label>Status</label>
                    <select onChange={onChangeStatusFilter} value={statusFilter}>
                      <option>All</option>
                      <option>Unanswered</option>
                    </select>
                </div>
              </div>
            </div>  
          <MailGrid mails={mails} onchangeSelected={onchangeSelected} selectedMails={selectedMails} refresh={refresh}/>
          <Pagination numberOfPages={numberOfPages} currentPage={currentPage} setCurrentPage={setCurrentPage}/>    
          </>
        )}  
      </HeaderDropDown>       
    </Container>
  );
};

MailsPerCategoryView.defaultProps = {};

export default MailsPerCategoryView;
