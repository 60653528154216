import React, { useState, useEffect} from "react";
import PageHeaderSub from "../../components/PageHeaderSub";
import Page from "components/UI/Elements/Page";
import { UseOrganizationContext, UseErrorContext } from "contexts";
import HeaderDropDown from "components/UI/HeaderDropDown";
import Pagination from "components/Pagination";
import Loader from "components/UI/Loader";
import { GetPagination } from "api/helpers/datahelper";
import { getNotifications } from "api/services/notifications";
import NotificationGrid from "./Components/NotificationGrid";
import { useTranslation } from 'react-i18next';

const Notifications = () => {
  const [notifications, setNotifications] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [numberOfPages, setNumberOfPages] = useState(1);
  const [loading, setLoading] = useState(false);
  const { updateError } = UseErrorContext();
  const { t, i18n } = useTranslation();

  // Refresh data
  useEffect(async () => {
    refresh();
  }, []);

  // Refresh data
  const refresh = async () => {  
    console.log("refresh")
    try{
        setLoading(true);

        var params = {
          'unread': false,
        }

        const notificationsResponse = await getNotifications(params);  

        let notificationsData = notificationsResponse.data;
        let notificationsHeader = notificationsResponse.headers;
          
        let pagination = GetPagination(notificationsHeader);
        let totalCount = pagination.totalCount;
        let pageSize = pagination.pageSize;
        let nbrOfPages = Math.ceil(totalCount / pageSize);            
        setNumberOfPages(nbrOfPages);

        setNotifications(notificationsData); 

        setLoading(false);
           
    } catch (err) {
      updateError(err);
    }
  }

  return (
      <>
      <Page>
        <div className="page__header">
          <h1>{t('notifications')}</h1>
          <PageHeaderSub>
          </PageHeaderSub>
        </div>
        <div className="page__inner">
          <HeaderDropDown titleHeader="">  
            { loading 
            ? <Loader />
            :  
              <>
              <NotificationGrid notifications={notifications} refresh={refresh}/>
              <Pagination numberOfPages={numberOfPages} currentPage={currentPage} setCurrentPage={setCurrentPage}/>    
              </>
            }  
          </HeaderDropDown>    
        </div>
      </Page>
      </>
  );
}

export default Notifications;