import React, { useState, useEffect } from "react";
import styled from "styled-components";
import Button from "components/UI/Buttons/Button";
import TranslationGrid from "components/TranslationGrid";
import { UseErrorContext } from "contexts";
import * as Yup from "yup";

const Container = styled.div`
  overflow: auto;
`;

const ValidationSchema = Yup.object().shape({
  title: Yup.string().required('Required').max(100),
  languageCode: Yup.string().required('Required'),
}) 

const TranslationsForm = ({ data, event, nextStep, backStep }) => {
  const [translations, setTranslations] = useState(data ?? []);
  const { updateError } = UseErrorContext();

  const add = async (tran) => {
    const itemIndex = translations.findIndex(x => x.languageCode === tran.languageCode);
    if (itemIndex !== -1) {
      updateError({"message": "Translation for give language already exists"});
    }
    else{
      setTranslations([...translations, tran]);
    }
  };

  const edit = async (id, tran) => {
    // Create a copy of the original array
    const updatedItems = [...translations];

    // Find the index of the item you want to update
    const itemIndex = updatedItems.findIndex(x => x.languageCode === tran.languageCode);

    // If the item is found, update its value
    if (itemIndex !== -1) {
      updatedItems[itemIndex] = {
        ...updatedItems[itemIndex],
        title: tran.title,
        description: tran.description
      };

      // Set the modified array back to the state
      setTranslations(updatedItems);
    }
  };

  const remove = async (id, languageCode) => {
    setTranslations(translations.filter(x => x.languageCode != languageCode));
  };

  return (
    <Container className="form">
      <TranslationGrid fields={['title', 'description']} item={event} translations={translations} validationSchema={ValidationSchema} remove={remove} add={add} edit={edit}/> 
      <div className="buttons">
        <Button label="Back" onClick={() => backStep()} type="secondary" />
        <Button label="Next" onClick={() => nextStep(translations)} type="primary" />
      </div>
    </Container>
  );
};


export default TranslationsForm;
