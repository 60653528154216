import React from "react";
import styled from "styled-components";

const Container = styled.div`
  position: relative;
  textarea {
    width: 100%;
    height: 100%;
    margin: 0;
  }
  .count__container{
    opacity: .8;
    font-size: 14px;
    position: absolute;
    top: -20px;
  }
`;

export default function TextArea(props) {
  const { showLength, value, maxlength = 50 } = props;

  if (showLength)
    return (
      <Container className="textarea__container">
        <div className="count__container">{`${value?.length || 0} / ${maxlength} max chars.`}</div>
        <textarea {...props} />
      </Container>
    );
  return <textarea {...props} />;
}
