import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { Formik, Form, Field, ErrorMessage } from "formik";
import Loader from "components/UI/Loader";
import Button from "components/UI/Buttons/Button";
import * as Yup from 'yup'
import { addRelatedEvent, getEvents, getEvent } from "api/services/event";
import { useParams } from "react-router-dom";
import { UseErrorContext } from "contexts";
import { UseOrganizationContext } from "contexts";
import { GetEventDate } from "api/helpers/datahelper";

const FormWrapper = styled.div`
  label {
    display: block;
    margin-bottom: 0.4rem;
  }
  input {
    width: 100%;
  }
  textarea {
    width: 100%;
    height: 10rem;
  }
  .form_row {
    margin-bottom: 2rem;
    display: flex;
    width: 100%;
    .field {
      width: 100%;
      &:not(:first-of-type) {
        margin-left: 2.4rem;
      }
    }
  }
  .error-message {
    opacity: 0.6;
    color: red;
    padding: 1rem 0;
  }
  .buttons {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    button {
      margin-right: 1rem;
    }
  }
`;

const ValidationSchema = Yup.object().shape({
  eventId: Yup.string().required('Required'),
}) 

export const RelatedEventForm = ({relatedEventIds, onClose, add }) => {
  const organizationId = UseOrganizationContext()?.id;
  const [events, setEvents] = useState([]);
  const { updateError } = UseErrorContext();

  useEffect(async () => {
    try{
      let data = await getEvents(organizationId);
      data = data.filter(x => !relatedEventIds.includes(x.id));
      setEvents(data);
    } catch (err) {
      updateError(err);
    }
  }, []);

  const _add = async (id) => {
    await add(id);
  };

  return (
    <FormWrapper>
      <Formik
        initialValues={{ 
          eventId: "", 
        }}
        validationSchema={ValidationSchema}
        onSubmit={(values, { setSubmitting }) => {
          let eventIdParsed = parseInt(values["eventId"]); 
          _add(eventIdParsed)
            .then((value) => {
              setSubmitting(false);
              onClose();
            })
            .catch((e) => {
              setSubmitting(false);
            });
        }}
      >
        {({ isSubmitting, handleSubmit }) => (
          <Form>
          <div className="form_row">
              <div className="field">
                <label htmlFor="eventId" className="label required">
                  Event
                </label>
                <Field as="select" name="eventId">
                  <option value=""></option>
                  {events?.map((event, idx) => (
                    <option value={event.id} key={idx}>
                      {`${event.title} (${GetEventDate(event)})`}
                    </option>
                  ))}
                </Field>
                <ErrorMessage className="error-message" name="eventId" component="div" />           
              </div>
            </div>          
            {!isSubmitting ? (
              <div className="buttons">
                <Button label="cancel" onClick={(e) => {onClose();}} type="secondary"/>
                <Button onClick={()=>{handleSubmit()}} label="Submit" disabled={isSubmitting} />
              </div>
            ) : (
              <Loader />
            )}
          </Form>
        )}
      </Formik>
    </FormWrapper>
  );
};
