import React, { useState, useEffect } from "react";
import styled from "styled-components";
import Button from "components/UI/Buttons/Button";
import HeaderDropDown from "components/UI/HeaderDropDown";
import Loader from "components/UI/Loader";
import { getFollowedEvent, getEventSections, getDocumentContent } from "api/services/event";
import EventInfo from "./EventInfo";
import SectionInfo from "components/SectionInfo";
import { pdfjs, Document, Page } from "react-pdf";
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;
import { useTranslation } from "react-i18next";
import { UseErrorContext } from "contexts";
import DocumentGrid from "./DocumentGrid";
import LinkGrid from "./LinkGrid";

const Container = styled.div`
  .react-pdf__Page__textContent,
  .react-pdf__Page__annotations {
    display: none;
  }

  .react-pdf__Page {
    min-height: 815px !important;
  }

  .pdf-buttons {
    display: flex;
    align-items: center;
  }
`;
const EventContent = ({ eventdata, id, language }) => {
  const { t, i18n } = useTranslation();
  const [event, setEvent] = useState(null);
  const [eventSections, setEventSections] = useState([]);
  const [docData, setDocData] = useState(null);
  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);
  const { updateError } = UseErrorContext();

  const {organization} = eventdata;

  useEffect(async () => {
    if (eventdata) {
      refresh(eventdata, eventdata.id, language);
    }
    if (id) {
      refresh(null, id, language);
    }
  }, [eventdata, id, language]);

  const refresh = async (e, id, language) => {
    try {
      if (!e) {
        e = await getFollowedEvent(id, language);
      }
      setEvent(e);

      const sect = await getEventSections(e.id, language);
      setEventSections(sect);

      let docshowAsContent = e?.documents?.find((doc) => doc.showAsContent && doc.languageCode == language) || e?.documents?.find((doc) => doc.showAsContent);
      console.log(docshowAsContent);
      console.log(language);
      if (docshowAsContent) {
        const docDataResp = await getDocumentContent(e.id, docshowAsContent.id, docshowAsContent.fileName, false);
        setDocData(docDataResp);
      }
    } catch (err) {
      updateError(err);
    }
  };

  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);
  }

  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);
    setPageNumber(1);
  }

  function changePage(offset) {
    setPageNumber((prevPageNumber) => prevPageNumber + offset);
  }

  function previousPage() {
    changePage(-1);
  }

  function nextPage() {
    changePage(1);
  }

  return (
    <>
      {!event ? (
        <Loader />
      ) : (
        <Container>
          <HeaderDropDown titleHeader={t("intro")} showCollapse>
            <EventInfo event={event} />
            <div className="">
              {docData && (
                <>
                  <Document file={docData} onLoadSuccess={onDocumentLoadSuccess}>
                    <Page pageNumber={pageNumber} />
                    <div className="pdf-buttons">
                      <Button label={t("previous")} disabled={pageNumber <= 1} onClick={previousPage} type="secondary" />
                      Page {pageNumber || (numPages ? 1 : "--")} of {numPages || "--"}
                      <Button label={t("next")} disabled={pageNumber >= numPages} onClick={nextPage} type="secondary" />
                    </div>
                  </Document>
                </>
              )}
            </div>
          </HeaderDropDown>
          {eventSections.map(
            (section, idx) =>
              section.isVisible && (
                <HeaderDropDown titleHeader={section.title} showCollapse>
                  <SectionInfo section={section} backupImage={organization?.logoUrl} />
                </HeaderDropDown>
              )
          )}

          <HeaderDropDown titleHeader={t("documents")}>
            <DocumentGrid eventId={id} documents={event.documents} isReadOnly={true} />
          </HeaderDropDown>
          <HeaderDropDown titleHeader={t("links")}>
            <LinkGrid links={event.links} isReadOnly={true} />
          </HeaderDropDown>
        </Container>
      )}
    </>
  );
};

export default EventContent;
