import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { useParams } from "react-router-dom";
import HeaderDropDown from "../../components/UI/HeaderDropDown";
import Page from "../../components/UI/Elements/Page";
import Loader from "components/UI/Loader";
import { BackButton } from "components/UI/Buttons/BackButton";
import { UseErrorContext } from "contexts";
import PageHeaderSub from "components/PageHeaderSub";
import { getProduct, unfollowProduct, followProduct } from "api/services/product";
import ProductInfo from "./Components/ProductInfo";
import { getOrganization } from "api/services/organization";
import Button from "components/UI/Buttons/Button";
import Modal from "components/UI/Modal";
import ProductCard from "./Components/ProductCard";
import { useTranslation } from 'react-i18next';

const Container = styled.div``;

const ViewFollowedProduct = () => {
  const { id } = useParams();
  const [product, setProduct] = useState(null);
  const { updateError } = UseErrorContext();
  const [showModalDelete, setShowModalDelete] = useState(false);
  const { t, i18n } = useTranslation();

  useEffect(async () => {
    await refresh();
  }, []);

  const refresh = async () => {
    try{
      const p = await getProduct(id);
      const org = await getOrganization(p.organizationId);
      p['organization'] = org;
      setProduct(p);
    } catch (err) {
      updateError(err);
    }
  };  
  
  const _unfollowProduct = async () => {
    try{
      await unfollowProduct(id);
      setShowModalDelete(false);
      refresh();
    } catch (err) {
      updateError(err);
    }
  };

  const _followProduct = async () => {
    try{      
      await followProduct({'productId': product.id});      
      refresh();
    } catch (err) {
      updateError(err);
    }
  };

  return (
    <>
      {showModalDelete && (
        <Modal title={`"${t('unfollow')}" "${product.title}"`} onClose={() => setShowModalDelete(false)} onSubmit={() => _unfollowProduct()} cancelLabel={"Cancel"} submitLabel={"Confirm"}>
          <div>{t('confirmunfollowshare')}</div>
        </Modal>
      )}
      <Page>
        {!product ? (
          <Loader />
        ) : (
          <Container>
            <div className="navigation-banner">              
              <BackButton link="/products"/>
              <ul className="breadcrumbs">
                <li>
                  <a href="/products">{t('shares')}</a>
                </li>
                <li>
                  <span className="selected">{product?.title}</span>
                </li>
              </ul>
            </div>     
            <div className="page__header">             
              <h1></h1>
              <PageHeaderSub show>
                {product.isFollowed 
                  ? <Button onClick={() => setShowModalDelete(true)} label={t('unfollow')} type="tertiary"/>
                  : <Button onClick={() => _followProduct()} label={t('follow')}/>
                }
              </PageHeaderSub>
            </div>
            <div className="page__inner">
              <HeaderDropDown titleHeader={product.title}>              
                <ProductInfo product={product}/>
              </HeaderDropDown>
            </div>
          </Container>
        )}
      </Page>
    </>
  );
};

export default ViewFollowedProduct;
