import React, { useState, useEffect } from "react";
import styled from "styled-components";
import HeaderDropDown from "../../components/UI/HeaderDropDown";
import PageHeaderSub from "../../components/PageHeaderSub";
import Page from "../../components/UI/Elements/Page";
import CommunicationGroupGrid from "./Components/CommunicationGroupGrid";
import { Link } from "react-router-dom";
import Button from "components/UI/Buttons/Button";

const CommunicationGroups = () => {

  return (
    <Page>
      <div className="page__header">
        <h1>COMMUNICATION GROUPS</h1>
        <PageHeaderSub show>
          <div></div>
        </PageHeaderSub>
      </div>
      <div className="page__inner">
        <HeaderDropDown titleHeader="">
          <CommunicationGroupGrid/>
        </HeaderDropDown>
      </div>
    </Page>
  );
};

export default CommunicationGroups;
