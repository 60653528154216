import React, { useEffect, useState } from "react";
import styled from "styled-components";
import HeaderDropDown from "../../components/UI/HeaderDropDown";
import PageHeaderSub from "../../components/PageHeaderSub";
import Page from "../../components/UI/Elements/Page";
import { BarChart, Bar, XAxis, YAxis, Tooltip, CartesianGrid, ResponsiveContainer, Legend } from "recharts";
import { UseOrganizationContext, UseErrorContext } from "contexts";
import { getAnalytics } from "../../api/services/investor-insights";
import AnalyticsBlock from "./components/AnalyticsBlock";
import ShareholderChanges from "./components/ShareholderChanges";
import ActionAnalytics from "./components/ActionInsights";
import ShareholdersFilteredGrid from "../loader/Components/ShareholdersFilteredGrid";
import DynamicTabs from "components/WithTabs";
import moment from "moment";

const StyledTooltip = styled.div`
  background-color: hsla(0, 0%, 100%, 0.8);
  border: 1px solid #f5f5f5;
  line-height: 24px;
  margin: 0;
  padding: 10px;
  width: 250px;

  .label {
    color: #666;
    font-weight: 700;
    margin: 0;
  }

  .desc {
    color: #999;
    margin: 0;
  }
`;

const EventsContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  justify-content: start;
  padding: 20px;
`;

const EventCard = styled.div`
  width: 100%;
  max-width: 300px;
`;

const CardContainer = styled.div`
  width: 100%;
  max-width: 400px;
  margin: 20px auto;
  padding: 20px;
  background-color: #f5f5f5;
  border-radius: 10px;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
  height: 100%;
  align-self: center;
`;

const CardTitle = styled.h3`
  font-size: 24px;
  color: #333;
  margin-bottom: 20px;
  text-align: center;
`;

const StatItem = styled.div`
  display: flex;
  justify-content: space-between;
  font-size: 18px;
  margin: 10px 0;
  color: #555;
`;

const Container = styled.div`
  display: flex;
  flex-wrap: wrap;
  .item {
    padding: 1rem;
    margin: 1rem;
    width: calc(96% / 2);
    background-color: var(--color-black);
  }
  .card {
    &__title {
      color: var(--color-text);
      text-decoration: underline;
    }
    &__content {
      border-top: 0px;
    }
    svg {
      tspan {
        fill: white;
      }
      line {
        stroke: white;
      }
    }
  }

  .recharts-pie-label-text {
    content: "%";
    fill: red;
    &:after {
      content: "%";
    }
  }
  .fluctuations-checkbox {
    color: white;
    position: absolute;
    top: 2rem;
    right: 2rem;
  }
`;

// Styled components for the post layout
const PostCard = styled.div`
  width: 100%;
  max-width: 500px;
  margin: 20px auto;
  padding: 20px;
  background-color: #ffffff;
  border: 1px solid #e0e0e0;
  border-radius: 10px;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.05);
`;

const PostHeader = styled.div`
  font-size: 18px;
  font-weight: bold;
  color: #333;
  margin-bottom: 10px;
`;

const PostDate = styled.div`
  font-size: 12px;
  color: #888;
  margin-bottom: 15px;
`;

const PostContent = styled.div`
  font-size: 16px;
  color: #555;
  line-height: 1.4;
  margin-bottom: 15px;
`;

const PostFooter = styled.div`
  display: flex;
  justify-content: space-between;
  font-size: 14px;
  color: #888;
  padding-top: 10px;
  border-top: 1px solid #e0e0e0;
`;

const Icon = styled.span`
  margin-right: 5px;
`;

// Styled component for the chart container
const ChartContainer = styled.div`
  width: 100%;
  max-width: 600px;
  margin: 20px auto;
`;

const PostAnalyticsCard = ({ post }) => {
  return (
    <PostCard>
      <PostHeader>{post.title}</PostHeader>
      <PostDate>{post.date}</PostDate>
      <PostContent>{post.content}</PostContent>
      <PostFooter>
        <StatItem>
          <Icon>👁️</Icon> {post.views} Views
        </StatItem>
        <StatItem>
          <Icon>👍</Icon> {post.likes} Likes
        </StatItem>
        <StatItem>
          <Icon>🔄</Icon> {post.shares} Shares
        </StatItem>
        <StatItem>
          <Icon>💬</Icon> {post.comments} Comments
        </StatItem>
      </PostFooter>
    </PostCard>
  );
};

const getRandomDateIn2024 = () => {
  const start = new Date("2024-01-01");
  const end = new Date("2024-12-31");
  return new Date(start.getTime() + Math.random() * (end.getTime() - start.getTime()));
};

// Sample event data
const eventsData = [
  { id: 1, title: "Annual General Meeting", date: getRandomDateIn2024(), views: 3450, likes: 670, attendees: 120, shares: 45, comments: 15 },
  { id: 2, title: "Quarterly Financial Report", date: getRandomDateIn2024(), views: 4520, likes: 530, attendees: 98, shares: 30, comments: 12 },
  { id: 3, title: "Investor Relations Briefing", date: getRandomDateIn2024(), views: 2780, likes: 450, attendees: 75, shares: 25, comments: 18 },
  { id: 4, title: "Product Launch", date: getRandomDateIn2024(), views: 3890, likes: 800, attendees: 220, shares: 55, comments: 24 },
  { id: 5, title: "Earnings Call Q3", date: getRandomDateIn2024(), views: 5000, likes: 620, attendees: 130, shares: 40, comments: 20 },
  // Additional events with random dates
  ...Array.from({ length: 15 }, (_, i) => ({
    id: i + 6,
    title: `Event #${i + 6}`,
    date: getRandomDateIn2024(),
    views: Math.floor(Math.random() * 5000),
    likes: Math.floor(Math.random() * 500),
    attendees: Math.floor(Math.random() * 200),
    shares: Math.floor(Math.random() * 100),
    comments: Math.floor(Math.random() * 50),
  })),
];

// Sample post data
const postsData = [
  {
    id: 1,
    title: "Market Update",
    content: "Today, we saw significant changes in the stock market...",
    views: 2300,
    likes: 150,
    shares: 35,
    comments: 22,
    date: "2024-03-10",
  },
  {
    id: 2,
    title: "Company Achievements",
    content: "We’re thrilled to announce a 20% growth in the past quarter...",
    views: 1750,
    likes: 120,
    shares: 40,
    comments: 10,
    date: "2024-05-25",
  },
  {
    id: 3,
    title: "Upcoming Investor Webinar",
    content: "Join us next week as we discuss our vision for the future...",
    views: 3250,
    likes: 210,
    shares: 80,
    comments: 45,
    date: "2024-07-15",
  },
];

const AnalyticsComumunication = () => {
  const organizationId = UseOrganizationContext()?.id;
  const { updateError } = UseErrorContext();
  const [entityData, setEntityData] = useState([]);
  const [barChartPerCountry, setBarChartPerCountry] = useState([]);
  const [barChartPerRequest, setBarChartPerRequest] = useState([]);
  const [percentActive, setPercentActive] = useState(false);

  useEffect(async () => {
    if (!organizationId) return;

    try {
      var rawData = await getAnalytics(organizationId);
      updateEntityData(rawData);
      updateCountryData(rawData);
      updateRequestData(rawData);
    } catch (err) {
      updateError(err);
    }
  }, [organizationId]);

  const updateEntityData = (rawData) => {
    const newData = rawData.requestDetails.at(-1);
    const entityData = [
      { name: "Legal entity", value: newData.legalEntity.sharesPercentage, number: newData.legalEntity.numberOfShareholders, percentage: `${newData.legalEntity.sharesPercentage}%` },
      { name: "Natural Person", value: newData.naturalPerson.sharesPercentage, number: newData.naturalPerson.numberOfShareholders, percentage: `${newData.naturalPerson.sharesPercentage}%` },
    ];
    setEntityData(entityData);
  };

  const updateCountryData = (rawData) => {
    const newData = [];

    rawData.countryDetails.forEach((country) => {
      newData.push({
        name: country.countryName,
        legal: country.legalEntity.numberOfShareholders,
        legalShares: country.legalEntity.sharesPercentage,
        natural: country.naturalPerson.numberOfShareholders,
        naturalShares: country.naturalPerson.sharesPercentage,
      });
    });

    setBarChartPerCountry(newData);
  };

  const updateRequestData = (rawData) => {
    const newData = [];

    rawData.requestDetails.forEach((request) => {
      newData.push({
        name: moment(request.requestDate).format("DD-MM"),
        legal: request.legalEntity.numberOfShareholders,
        legalShares: request.legalEntity.sharesPercentage,
        natural: request.naturalPerson.numberOfShareholders,
        naturalShares: request.naturalPerson.sharesPercentage,
      });
    });

    setBarChartPerRequest(newData);
  };

  const COLORS = ["#FF8972", "lightblue", "#FFBB28", "#FF8042"];

  const barChart = [
    { name: "1/3", legal: 1530, natural: 25391, legalShares: 3000000, naturalShares: 5000000 },
    { name: "1/4", legal: 1545, natural: 26391, legalShares: 2700000, naturalShares: 5300000 },
    { name: "1/5", legal: 1540, natural: 27391, legalShares: 2400000, naturalShares: 5600000 },
    { name: "1/6", legal: 1538, natural: 29391, legalShares: 1900000, naturalShares: 6100000 },
  ];

  const CustomTooltip = ({ active, payload, label }) => {
    if (active && payload && payload.length) {
      console.log(payload[0]);
      return (
        <StyledTooltip className="custom-tooltip">
          <p className="label">{`${payload[0].name} : ${payload[0].payload.percentage}`}</p>
          <p className="label">Number of holders : {payload[0].payload.number}</p>
        </StyledTooltip>
      );
    }

    return null;
  };

  // Function to get top 5 events by views
  const getTopEventsByViews = (data, topN) => {
    return [...data]
      .sort((a, b) => b.views - a.views) // Sort descending by views
      .slice(0, topN); // Get the top N events
  };

  // Get the top 5 events
  const top5Events = getTopEventsByViews(eventsData, 5);

  // Prepare data for Recharts
  const chartData = top5Events.map((event) => ({
    name: event.title,
    views: event.views,
  }));

  // Calculate totals for each action
  const calculateTotals = (data) => {
    return data.reduce(
      (acc, event) => ({
        views: acc.views + event.views,
        likes: acc.likes + event.likes,
        attendees: acc.attendees + event.attendees,
        shares: acc.shares + event.shares,
        comments: acc.comments + event.comments,
      }),
      { views: 0, likes: 0, attendees: 0, shares: 0, comments: 0 }
    );
  };

  const totals = calculateTotals(eventsData);

  return (
    <Page>
      <div className="page__header">
        <h1>ANALYTICS</h1>
        <PageHeaderSub />
      </div>
      <br />
      <br />
      <br />
      <div className="page__inner">
        <DynamicTabs
          tabsData={[
            {
              id: "actions",
              title: "Actions Insights",
              content: (
                <>
                  <HeaderDropDown showCollapse bodyStyle={{ position: "relative" }} titleHeader={"Action Insights"} id="shareholder-trends">
                    <div className="row">
                      <ChartContainer>
                        <h3>Top 5 Actions by engagement</h3>
                        <ResponsiveContainer width="100%" height={400}>
                          <BarChart data={chartData} margin={{ top: 20, right: 30, left: 20, bottom: 5 }}>
                            <CartesianGrid strokeDasharray="3 3" />
                            <XAxis dataKey="name" />
                            <YAxis />
                            <Tooltip />
                            <Legend />
                            <Bar dataKey="views" fill="#8884d8" />
                          </BarChart>
                        </ResponsiveContainer>
                      </ChartContainer>
                      <CardContainer>
                        <CardTitle>Total Actions</CardTitle>
                        <StatItem>
                          <span>Views:</span> <span>{totals.views}</span>
                        </StatItem>
                        <StatItem>
                          <span>Likes:</span> <span>{totals.likes}</span>
                        </StatItem>
                        <StatItem>
                          <span>Attendees:</span> <span>{totals.attendees}</span>
                        </StatItem>
                        <StatItem>
                          <span>Shares:</span> <span>{totals.shares}</span>
                        </StatItem>
                        <StatItem>
                          <span>Comments:</span> <span>{totals.comments}</span>
                        </StatItem>
                      </CardContainer>
                    </div>
                  </HeaderDropDown>

                  <HeaderDropDown showCollapse bodyStyle={{ position: "relative" }} titleHeader={"Action Insights"} id="shareholder-trends">
                    <EventsContainer>
                      {eventsData.map((event) => (
                        <EventCard key={event.id}>
                          <ActionAnalytics title={event.title} date={event.date} views={event.views} likes={event.likes} attendees={event.attendees} shares={event.shares} comments={event.comments} />
                        </EventCard>
                      ))}
                    </EventsContainer>
                  </HeaderDropDown>
                </>
              ),
            },
            {
              id: "posts",
              title: "Post Insights",
              content: (
                <>
                  <HeaderDropDown showCollapse bodyStyle={{ position: "relative" }} titleHeader={"Posts Insights"} id="shareholder-trends">
                    <div className="row">
                      <ChartContainer>
                        <h3>Top 5 Posts by engagement</h3>
                        <ResponsiveContainer width="100%" height={400}>
                          <BarChart data={chartData} margin={{ top: 20, right: 30, left: 20, bottom: 5 }}>
                            <CartesianGrid strokeDasharray="3 3" />
                            <YAxis />
                            <Tooltip />
                            <Legend />
                            <Bar dataKey="views" fill="#8884d8" />
                          </BarChart>
                        </ResponsiveContainer>
                      </ChartContainer>
                      <CardContainer>
                        <CardTitle>Total Posts</CardTitle>
                        <StatItem>
                          <span>Views:</span> <span>{totals.views}</span>
                        </StatItem>
                        <StatItem>
                          <span>Likes:</span> <span>{totals.likes}</span>
                        </StatItem>
                        <StatItem>
                          <span>Shares:</span> <span>{totals.shares}</span>
                        </StatItem>
                        <StatItem>
                          <span>Comments:</span> <span>{totals.comments}</span>
                        </StatItem>
                      </CardContainer>
                    </div>
                  </HeaderDropDown>

                  <HeaderDropDown showCollapse bodyStyle={{ position: "relative" }} titleHeader={"Posts Insights"} id="shareholder-trends">
                    <EventsContainer>
                      {postsData.map((post) => (
                        <PostAnalyticsCard key={post.id} post={post} />
                      ))}
                    </EventsContainer>
                  </HeaderDropDown>
                </>
              ),
            },
          ]}
        />
      </div>
    </Page>
  );
};

export default AnalyticsComumunication;
