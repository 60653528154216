import React, { useState } from "react";
import styled, { css } from "styled-components";
import Modal from "components/UI/Modal";
import thumbnail from "../../../assets/thumbnail.jpg";
import { ConvertToDateToUTC, ConvertToLocaleDate, FormatDate, FormatDateTimeDisplay } from "api/helpers/datahelper";
import PrivateCard from "components/PrivateCard";
import { formatDescription, htmlToRawText } from "api/helpers/datahelper";
import { Interweave } from "interweave";
import * as DOMPurify from "dompurify";
import draftToHtml from "draftjs-to-html";
import Status from "components/UI/Status";
import { useParams, useHistory } from "react-router-dom";
import Star from "components/UI/Icons/Star";
import { editIsFeatured } from "api/services/event";
import { checkIfStringIsJson } from "utils";

const Div = styled.div`
  position: relative;
  ${({ small }) =>
    small &&
    css`
      .card__content__header {
        flex-direction: column;
        align-items: flex-start;
      }
    `}
`;

const EventCard = ({
  id,
  coverUrl,
  numberOfDocuments,
  numberOfLinks,
  title,
  location,
  description,
  startDate,
  endDate,
  updatedTimeUtc,
  publishStatus,
  isFeatured,
  publishDateTime,
  refresh,
  canEdit,
  small,
}) => {
  const history = useHistory();

  const changeFeatured = async () => {
    await editIsFeatured(id, !isFeatured);
    await refresh();
  };

  return (
    <>
      <Div className="card" small>
        {canEdit && (
          <div className="card-button" onClick={() => changeFeatured()}>
            <Star roles={["Events.Create"]} active={isFeatured} />
          </div>
        )}
        <PrivateCard to={`/events/edit/${id}`} roles={["Events.Create"]} style={{ height: "100%" }}>
          <div className="card_image__container">
            <div className="cover">
              <img src={coverUrl ?? thumbnail} alt="" />
            </div>
          </div>
          <div className="card__content">
            <div className="card__content__header">
              <div className="card__title">{title}</div>
              <div className="card__subtitle">{FormatDate(startDate) == FormatDate(endDate) ? FormatDate(startDate) : `${FormatDate(startDate)} - ${FormatDate(endDate)}`}</div>
            </div>
            <div className="card__content__body">
              {checkIfStringIsJson(description) ? (
                <Interweave
                  content={DOMPurify.sanitize(
                    htmlToRawText(draftToHtml(JSON.parse(description)))
                      ?.slice(0, 120)
                      ?.concat(`…`)
                  )}
                />
              ) : (
                <p>{formatDescription(description)}</p>
              )}
            </div>
            <div className="docs-info">
              <div>
                <img src="/file.svg" />
                {numberOfDocuments}
              </div>
              <div>
                <img src="/link-horizontal.svg" />
                {numberOfLinks}
              </div>
            </div>
            <div className="card__content__footer">
              Last edited on: {FormatDate(updatedTimeUtc)}
              <Status
                type={publishStatus}
                label={publishStatus == "Hidden" && publishDateTime ? "Publishing on: " + FormatDateTimeDisplay(ConvertToLocaleDate(publishDateTime)) : publishStatus}
                onClick={() => console.log()}
              />
            </div>
          </div>
        </PrivateCard>
      </Div>
    </>
  );
};

EventCard.defaultProps = {
  thumb: thumbnail,
  title: "Title",
  description: "Description",
  location: "Belgium",
  small: false,
};

export default EventCard;
