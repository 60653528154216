import React from "react";
import HeaderDropDown from "../components/UI/HeaderDropDown";
import PageHeaderSub from "../components/PageHeaderSub";
import Page from "../components/UI/Elements/Page";
import Button from "components/UI/Buttons/Button";

const MailingCampaign = () => {
  return (
    <Page>
      <div className="page__header">
        <h1>Mailing campaign</h1>
        <PageHeaderSub />
      </div>
      <div className="page__inner">
        <HeaderDropDown titleHeader="Elevate Your Investor Communications with Precision Mailing Campaigns">
          <p>
            In the competitive realm of investment, effective communication is paramount. Meet Sendtex, the ultimate mailing platform crafted to revolutionize your mailing campaigns and enhance your
            outreach to investors. Sendtex empowers you to design, execute, and monitor mailing campaigns with unmatched accuracy and efficiency. Whether you're announcing new investment
            opportunities, sharing quarterly reports, or fostering investor relations, Sendtex ensures your messages are delivered promptly and professionally. Harness the power of Sendtex to
            streamline your communication efforts, engage investors effectively, and drive your investment strategies forward. Elevate your platform's communication capabilities with Sendtex, and
            experience the future of investor mailing campaigns.
          </p>
          <p />
          <br />
          <br />
          <Button label={"Start a campaign"} url={"https://sendtex.app"} target="_blank" />{" "}
          <br />
        </HeaderDropDown>
      </div>
    </Page>
  );
};

export default MailingCampaign;
