import React from "react";
import styled from "styled-components";

const AnalyticsBlock = ({ title, children }) => {
  return (
    <div className="card item">
        <div className="card__content">
            <div className="card__content__header">
                <div className="card__title">
                    {title}
                </div>
            </div>
            <div className="card__content__body">
                {children}
            </div>
        </div>
    </div>
  );
};

export default AnalyticsBlock;
