import React, { useState, useEffect } from "react";
import Select from "react-select";
import styled from "styled-components";
import { Formik, Form, Field, ErrorMessage } from "formik";
import Loader from "components/UI/Loader";
import AddButton from "components/UI/Buttons/AddButton";
import Button from "components/UI/Buttons/Button";
import * as Yup from "yup";
import { UseErrorContext, UseOrganizationContext } from "contexts";
import { getCommunicationGroups } from "api/services/communication-groups";
import DocumentGrid from "./DocumentGrid";
import ImagesGrid from "./ImagesGrid";
import { addPost, addPostAttachments, deletePostAttachment, deletePostAttachments, editPost } from "api/services/communication";
import MDEditor from "components/UI/Blocks/MDEditor";
import { resizeImage } from "utils";

const FormWrapper = styled.div``;

const ValidationSchema = Yup.object().shape({
  communicationGroups: Yup.array().when("isPublic", {
    is: true,
    then: Yup.array(),
    otherwise: Yup.array().min(1, "Required"),
  }),
  title: Yup.string().required("Required").max(50),
  message: Yup.string().required("Required"),
});

export const PostForm = ({ post, onClose }) => {
  const { name, id } = UseOrganizationContext();
  const { updateError } = UseErrorContext();
  const [isPublic, setIsPublic] = useState(post?.isPublic ?? true);
  const [communicationGroups, setCommunicationGroups] = useState([]);
  const [documents, setDocuments] = useState(post?.attachments ?? []);

  useEffect(async () => {
    try {
      var res = await getCommunicationGroups(id);
      setCommunicationGroups(res);
    } catch (err) {
      updateError(err);
    }
  }, [id]);

  const callBack = async (values) => {
    delete values.attachments;
    delete values.senderId;
    delete values.senderName;

    values.organizationId = id;

    if (values.isPublic) values.communicationGroups = [];
    else values.communicationGroups = values.communicationGroups.map((x) => x.id);

    // Add
    if (!post) {
      let p = await addPost(values);
      for (let i = 0; i < documents.length; i++) {
        await addPostAttachments(p.data.id, documents[i]);
      }
    }
    // Edit
    else {
      //values.communicationGroups = values.communicationGroups.map(x => x.id);
      await editPost(post.id, values);
      const attachmentsToDelete = post.attachments.filter((x) => !documents.includes(x));
      for (let i = 0; i < attachmentsToDelete.length; i++) {
        await deletePostAttachment(post.id, attachmentsToDelete[i].id);
      }

      const attachmentsToAdd = documents.filter((x) => !post.attachments.includes(x));
      for (let i = 0; i < attachmentsToAdd.length; i++) {
        await addPostAttachments(post.id, attachmentsToAdd[i]);
      }
    }
  };

  const _addDocument = async (doc) => {
    try {
      if (doc?.type == "Image" && doc?.file) {
        console.log("doc", doc);
        const resizedImage = await resizeImage(doc?.file, 1250);
        console.log("resizedImage", resizedImage.file);
        setDocuments([...documents, { ...doc, file: resizedImage }]);
      } else {
        setDocuments([...documents, doc]);
      }
    } catch (err) {
      updateError(err);
    }
  };

  const _editDocument = async (id, doc) => {
    try {
    } catch (err) {
      updateError(err);
    }
  };

  const _removeDocument = async (doc) => {
    try {
      setDocuments(documents.filter((d) => d.name !== doc.name));
    } catch (err) {
      updateError(err);
    }
  };

  const showPreview = async () => {
    console.log("PREVIEW");
  };

  return (
    <FormWrapper>
      <Formik
        initialValues={{
          senderId: post?.sender?.id ?? id,
          senderName: post?.sender?.name ?? name,
          isPublic: post?.isPublic ?? true,
          communicationGroups: post?.communicationGroups ?? [],
          attachments: post?.attachments ?? [],
          title: post?.title ?? "",
          message: post?.message ?? "",
        }}
        validationSchema={ValidationSchema}
        onSubmit={(values, { setSubmitting }) => {
          console.log("on submit");
          callBack(values)
            .then((value) => {
              console.log("then");
              setSubmitting(false);
              onClose();
            })
            .catch((err) => {
              console.log(err);
              setSubmitting(false);
              updateError(err);
            });
        }}
      >
        {({ isSubmitting, handleSubmit, setFieldValue, values }) => (
          <Form className="form">
            <div className="form_row">
              <div className="field">
                <label htmlFor="senderName">Sender:</label>
                {/* <Field as="text" name="senderName" disabled/> */}
                <p>{name}</p>
                <ErrorMessage className="error-message" name="senderName" component="div" />
              </div>
            </div>
            <div className="form_row">
              <div className="field">
                <label htmlFor="isPublic" className="label">
                  Visible to
                  {/* <Tooltip text="Should the published action be visible for everyone, or only for the linked communication groups?" /> */}
                </label>
                <select
                  onChange={(e) => {
                    const val = JSON.parse(e.target.value);
                    setIsPublic(val);
                    setFieldValue("isPublic", val);
                  }}
                  value={isPublic}
                  disabled={post?.status && post?.status != "Draft"}
                >
                  <option value="true">Public</option>
                  <option value="false">Communication groups only</option>
                </select>
              </div>
            </div>
            {!isPublic && (
              <div className="form_row">
                <div className="field">
                  <label htmlFor="communicationGroups" className="label">
                    Communication groups
                  </label>
                  <Select
                    className="react-selectcomponent"
                    onChange={(e) => {
                      setFieldValue("communicationGroups", e);
                    }}
                    options={communicationGroups}
                    getOptionLabel={(cg) => cg.name}
                    defaultValue={post?.communicationGroups}
                    isOptionSelected={(cg) => {
                      post?.communicationGroups?.find((el) => el.id === cg.id);
                    }}
                    isMulti
                    styles={{ multiValueRemove: (base) => ({ ...base, display: "none" }) }}
                    isDisabled={post?.status && post?.status != "Draft"}
                  />
                  <ErrorMessage className="error-message" name="communicationGroups" component="div" />
                </div>
              </div>
            )}
            <div className="form_row">
              <div className="field">
                <label htmlFor="senderName">Attachments:</label>
                <DocumentGrid documents={documents.filter((x) => x.type == "Document")} add={_addDocument} edit={_editDocument} remove={_removeDocument} />
              </div>
            </div>
            <div className="form_row">
              <div className="field">
                <label htmlFor="senderName">Images:</label>
                <ImagesGrid documents={documents.filter((x) => x.type == "Image")} add={_addDocument} edit={_editDocument} remove={_removeDocument} />
              </div>
            </div>
            <div className="form_row">
              <div className="field">
                <label htmlFor="message">Title:</label>
                <Field type="text" name="title" />
                <ErrorMessage className="error-message" name="title" component="div" />
              </div>
            </div>
            <div className="form_row">
              <div className="field">
                <label htmlFor="message">Post:</label>
                <MDEditor value={values.message} setFieldValue={(val) => setFieldValue("message", val)} />
                <ErrorMessage className="error-message" name="message" component="div" />
              </div>
            </div>
            {!isSubmitting ? (
              <div className="buttons">
                <Button label="Cancel" onClick={() => onClose()} type="secondary" disabled={isSubmitting} />
                {/* <Button label="Preview" onClick={()=>{showPreview()}} type="tertiary" disabled={isSubmitting} />   */}
                <Button
                  label="Save"
                  onClick={() => {
                    handleSubmit();
                  }}
                  disabled={isSubmitting}
                />
              </div>
            ) : (
              <Loader />
            )}
          </Form>
        )}
      </Formik>
    </FormWrapper>
  );
};
