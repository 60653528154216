import React from "react";
import styled from "styled-components";
import Image from "components/UI/Images/Image";
import thumbnail from "../../../assets/thumbnail.jpg";
import { useTranslation } from "react-i18next";
import HTMLRenderView from "components/UI/Blocks/HTMLRenderView";

const Header = styled.div`
  height: 30rem;
  width: 100%;
  position: relative;
  border-radius: 0.8rem;
  .company-logo {
    img {
      top: auto;
      width: 16rem;
      height: 16rem;
      left: 6rem;
      bottom: -8rem;
      z-index: 1;
      ;
    }
  }
`;

const Container = styled.div`
  margin-top: 10rem;
  textarea {
    height: 30rem;
  }
  .form_row {
    margin-top: 4rem;
  }
`;

const OrganizationInfo = ({ organization }) => {
  const { t, i18n } = useTranslation();

  return (
    <>
      <Header className="profile__header">
        <div className="company-banner">
          <Image url={organization.coverUrl ?? thumbnail} disabled />
        </div>
        <div className="company-logo">
          <Image url={organization.logoUrl ?? thumbnail} disabled />
        </div>
      </Header>
      <Container>
        <div className="form">
          <div className="form_row">
            <div className="field">
            <HTMLRenderView content={organization?.description} />
            </div>
          </div>
          <div className="form_row">
            <div className="field">
              <h2>{t("address")}</h2>
              <p>{organization.addresses[0]?.address?.addressLine1}</p>
              <p>
                {organization.addresses[0]?.address?.postalCode} {organization.addresses[0]?.address?.city}
              </p>
              <p>{organization.addresses[0]?.address?.countryName}</p>
            </div>
          </div>
        </div>
      </Container>
    </>
  );
};

export default OrganizationInfo;
