import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import Loader from "components/UI/Loader";
import Button from "components/UI/Buttons/Button";
import { pdfjs, Document, Page } from "react-pdf";
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

const FormWrapper = styled.div`
  .react-pdf__Page__textContent,
  .react-pdf__Page__annotations {
    display: none;
  }

  .react-pdf__Page {
    min-height: 815px !important;
  }

  .pdf-buttons {
    display: flex;
    align-items: center;
  }
`;

const ValidationSchema = Yup.object().shape({
});

export const DocumentForm = ({ file, nextStep, backStep}) => {
  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);

  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);
  }

  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);
    setPageNumber(1);
  }

  function changePage(offset) {
    setPageNumber((prevPageNumber) => prevPageNumber + offset);
  }

  function previousPage() {
    changePage(-1);
  }

  function nextPage() {
    changePage(1);
  }

  return (
    <FormWrapper>
      <Formik
        enableReinitialize={true}
        initialValues={{
          file: file?.file ?? ""
        }}
        validationSchema={ValidationSchema}
        onSubmit={(values, { setSubmitting }) => {
          nextStep(values);
          setSubmitting(false);
        }}
      >
        {({ isSubmitting, handleSubmit, setFieldValue, values }) =>
            <Form>
              
              <div className="form_row">
                {!values.file && (
                  <div className="field">
                    <label htmlFor="file">
                      File
                    </label>
                    <input
                      id="cover"
                      name="document"
                      type="file"
                      onChange={(e) => {
                        setFieldValue("file", e.currentTarget.files[0]);
                      }}
                    />
                  </div>
                )}

                {values.file && (
                  <div className="field">
                    <label htmlFor="delete-pdf">
                      Remove PDF
                    </label>
                    <Button
                      onClick={() => {
                        setFieldValue('file', null);
                      }}
                      label="Remove"
                      disabled={isSubmitting}
                    />
                  </div>
                )}
              </div>

              <div>
                {values.file && (
                  <>
                    <Document file={values.file} onLoadSuccess={onDocumentLoadSuccess}>
                      <Page pageNumber={pageNumber} />
                      <div className="pdf-buttons">
                        <Button label="Previous" disabled={pageNumber <= 1} onClick={previousPage} type="secondary" />
                        Page {pageNumber || (numPages ? 1 : "--")} of {numPages || "--"}
                        <Button label="Next" disabled={pageNumber >= numPages} onClick={nextPage} type="secondary" />
                      </div>
                    </Document>
                  </>
                )}
              </div>

              {!isSubmitting ? (
                <div className="buttons">
                  <Button label="Back" onClick={() => backStep()} type="secondary" />
                  <Button onClick={handleSubmit} label="Next" type="submit" />
                </div>
              ) : (
                <Loader />
              )}
            </Form>
        }
      </Formik>
    </FormWrapper>
  );
};
