import React, { useState, useEffect } from "react";
import styled from "styled-components";
import Button from "components/UI/Buttons/Button";
import AddButton from "../../../components/UI/Buttons/AddButton";
import Modal from "components/UI/Modal";
import Trash from "components/UI/Icons/Trash";
import View from "components/UI/Icons/View";
import Open from "components/UI/Icons/Open";
import { DocumentForm } from "./DocumentForm";
import { pdfjs, Document, Page } from "react-pdf";
import { deleteOrganizationDocument, getOrganizationDocumentContent } from "api/services/organization";
import { UseOrganizationContext } from "contexts";

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

const Container = styled.div`
  overflow: auto;

  .react-pdf__Page__textContent,
  .react-pdf__Page__annotations {
    display: none;
  }

  .react-pdf__Page {
    min-height: 815px !important;
  }

  .pdf-buttons {
    display: flex;
    align-items: center;
    gap: 1.2rem;
    margin-top: 0.8rem;
    margin-bottom: 0.8rem;
  }
`;

const DocumentGrid = ({ documents, refresh, isReadOnly }) => {
  const [showModal, setShowModal] = useState(false);
  const [selectedDocument, setSelectedDocument] = useState(null);
  const [showModalDelete, setShowModalDelete] = useState(false);
  const [showModalPreviewDoc, setShowModalPreviewDoc] = useState(false);
  const [docData, setDocData] = useState(false);
  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);
  const organizationId = UseOrganizationContext()?.id;

  const _addDocument = () => {
    //Clear selected document
    setSelectedDocument(null);
    setShowModal(true);
  };

  const PreviewDocument = async (document) => {
    console.log('doc', document)
    setSelectedDocument(document);
    setShowModalPreviewDoc(true);
    const docDataResp = await getOrganizationDocumentContent(organizationId, document.id, document.fileName, false);
    console.log("docDataResp", docDataResp);
    setDocData(docDataResp);
  };

  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);
    setPageNumber(1);
  }

  function changePage(offset) {
    setPageNumber((prevPageNumber) => prevPageNumber + offset);
  }

  function previousPage() {
    changePage(-1);
  }

  function nextPage() {
    changePage(1);
  }

  const showDeleteModal = async (document) => {
    setSelectedDocument(document);
    setShowModalDelete(true);
  };

  const _delete = async () => {
    await deleteOrganizationDocument(organizationId, selectedDocument.id);
    setShowModalDelete(false);
    setSelectedDocument(null);
    refresh();
  };

  const downloadFile = async (document) => {
    return await getOrganizationDocumentContent(organizationId, document.id, document.fileName);
  };

  const open = (document) => {
    setSelectedDocument(document);
    setShowModal(true);
  };

  return (
    <Container>
      {showModalDelete && (
        <Modal title={`Delete "${selectedDocument.name}"`} onClose={() => setShowModalDelete(false)} onSubmit={() => _delete()} cancelLabel={"Cancel"} submitLabel={"Delete"}>
          <div>Are you sure you want to delete this document?</div>
        </Modal>
      )}
      {showModal && (
        <Modal title="Document" hideActions>
          <DocumentForm
            document={selectedDocument}
            onClose={() => {
              setShowModal(false);
              refresh();
            }}
          />
        </Modal>
      )}
      {showModalPreviewDoc && (
        <Modal wide title="Preview document" hideActions showClose showCloseOnTop onClose={() => setShowModalPreviewDoc(false)}>
          <div className="">
            {selectedDocument && (
              <>
                <Document file={docData} onLoadSuccess={onDocumentLoadSuccess}>
                  <div className="pdf-buttons">
                    <Button label="Previous" disabled={pageNumber <= 1} onClick={previousPage} type="secondary" />
                    Page {pageNumber || (numPages ? 1 : "--")} of {numPages || "--"}
                    <Button label="Next" disabled={pageNumber >= numPages} onClick={nextPage} type="secondary" />
                  </div>
                  <Page pageNumber={pageNumber} />
                  <div className="pdf-buttons">
                    <Button label="Previous" disabled={pageNumber <= 1} onClick={previousPage} type="secondary" />
                    Page {pageNumber || (numPages ? 1 : "--")} of {numPages || "--"}
                    <Button label="Next" disabled={pageNumber >= numPages} onClick={nextPage} type="secondary" />
                  </div>
                </Document>
              </>
            )}
          </div>
        </Modal>
      )}
      <table cellSpacing="500">
        <thead>
          <tr>
            <th>Name</th>
            <th>Language</th>
            <th>File</th>
            <th></th>
            <th></th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {documents?.map((document, idx) => (
            <tr key={idx}>
              <td>{document.name}</td>
              <td>{document.languageCode ?? "ALL"}</td>
              <td>
                <a href="#" onClick={() => downloadFile(document)}>
                  {document.fileName}
                </a>
              </td>
              <td>
                {!isReadOnly && (
                  <div onClick={() => open(document)} className="icon-button">
                    <Open />
                  </div>
                )}
              </td>
              <td>
                {!isReadOnly && (
                  <div onClick={() => PreviewDocument(document)} className="icon-button">
                    <View />
                  </div>
                )}
              </td>
              <td>
                {!isReadOnly && (
                  <div onClick={() => showDeleteModal(document)} className="icon-button">
                    <Trash />
                  </div>
                )}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      {!isReadOnly && (
        <div onClick={() => _addDocument()} className="grid-add-btn">
          <AddButton label="Add document" />
        </div>
      )}
    </Container>
  );
};

DocumentGrid.defaultProps = {
  documents: Array.from(Array(0)),
};

export default DocumentGrid;
