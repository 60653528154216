import FollowedOrganizations from "./followed-organizations";
import ViewFollowedOrganization from "./view-followed-organization";
import FollowedOrganizationEvents from "./followed-organization-events";
import FollowedOrganizationEventsCalendar from "./followed-organization-events-calendar";
import FollowedOrganizationProducts from "./followed-organization-products";
import ViewFollowedOrganizationProduct from "./view-followed-organization-product";
import ViewFollowedOrganizationEvent from "./view-followed-organization-event";
import Organizations from "./organizations";
import ViewOrganizationDocumentsLinks from "./view-organization-documents-links";
import FollowedOrganizationNews from "./followed-organization-news";

export default {
  FollowedOrganizations,
  ViewFollowedOrganization,
  FollowedOrganizationEvents,
  FollowedOrganizationProducts,
  ViewFollowedOrganizationProduct,
  ViewFollowedOrganizationEvent,
  FollowedOrganizationEventsCalendar,
  Organizations,
  ViewOrganizationDocumentsLinks,
  FollowedOrganizationNews,
};
