import React, { useState, useEffect } from "react";
import { ValidateAccess } from "api/services/authorization";
import { UseUserContext } from "contexts";
import { FaExternalLinkAlt } from "react-icons/fa";

export default function Open({roles}) {
  const [hide, setHide] = useState(true);
  const { permissions } = UseUserContext();
  
  useEffect(async () => {
    const access = ValidateAccess(roles, permissions);
    setHide(!access);
  }, [permissions]);

  return (
    <>
    { !hide &&
      <FaExternalLinkAlt/>
    }  
    </>
  );
}
