import React, { useContext } from "react";
import styled from "styled-components";
import Iframe from "react-iframe";
import HTMLRenderView from "components/UI/Blocks/HTMLRenderView";
import StockData from "../StockData";
import { WebsocketContext } from "contexts/InfrontContext";
import { useTranslation } from 'react-i18next';

const Container = styled.div`
  .price {
    display: flex;
    align-items: center;
  }
  .price-change {
    display: inline-grid;
    margin-left: 2rem;
    .positive {
      color: green;
    }
    .negative {
      color: red;
    }
  }
`;

const ProductInfo = ({ product }) => {
  const { token } = useContext(WebsocketContext);
  const { t } = useTranslation();

  return (
    <>
      <Container>
        <div className="form">
          <div className="form_row">
            <div className="field">
              <p>{product.subtitle}</p>
              <i>{t("description")}:</i>
              <HTMLRenderView content={product?.description} />
            </div>
          </div>
          <div className="form_row">
            <StockData stockSymbol={product?.stockSymbol} isin={product?.isin} mic={product?.primaryMarketMic} />
          </div>
          <div className="form_row">
            <Iframe url={`https://platform-dev.finvictum.com/infront?symbol=${product.stockSymbol}&infrontfeedid=${product?.infrontFeedId}&token=${token}`} width="100%" height="500px" />
          </div>
        </div>
      </Container>
    </>
  );
};

export default ProductInfo;
