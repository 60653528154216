import React, { useState } from "react";
import styled from "styled-components";
import { useHistory } from "react-router-dom";
import { Formik, Form, Field, ErrorMessage } from "formik";
import Loader from "components/UI/Loader";
import Button from "components/UI/Buttons/Button";
import { sendAnswer } from "api/services/communication";
import * as Yup from 'yup'
import Badge from "components/UI/Badge";
import { UseErrorContext } from "contexts";

const FormWrapper = styled.div`
`;

const ValidationSchema = Yup.object().shape({
  mails: Yup.array().min(1, "Required"),
  text: Yup.string().required('Required'),
}) 

export const MailBuilderForm = ( {selectedMails, onClose} ) => {  
  const [mails, setMails] = useState(selectedMails);
  const history = useHistory();
  const { updateError } = UseErrorContext();

  const onRemove = (id) => {
    setMails(mails.filter(item => item.id != id));
  };

  const callBack = async (emailData) => {    
      try {
        emailData.questionIds = mails.map(x => x.id);
        delete emailData.mails;
        const rep = await sendAnswer(emailData);
        return rep;
      } catch (err) {
        updateError(err);
        return Promise.reject(error);
      }    
  };

  return (
    <FormWrapper>
      <Formik
        initialValues={ { 
          mails: mails,           
          text: "" } }
        validationSchema={ValidationSchema}
        enableReinitialize={true}
        onSubmit={(values, { setSubmitting }) => {
          callBack(values)
            .then((value) => {
              setSubmitting(false);
              history.go(0)
            })
            .catch((e) => {
              console.log(e);
              alert("Something went wrong", e.message);
              setSubmitting(false);
            });
        }}
      >
        {({ isSubmitting, handleSubmit, setFieldValue }) => (
          <Form className="form">
            <div className="form_row">
              <div className="field">
               <label htmlFor="mails">Question(s):</label>
              </div>
            </div>
            {
              mails?.map((mail, idx) => {
                return (          
                  <>
                    <div className="form_row">
                      <div className="field">
                        <label htmlFor="mails">From:</label>
                        <Badge key={idx} idx={idx} label={mail.user.email} onRemove={() => { onRemove(mail.id) }}></Badge>  
                      </div>
                    </div>            
                    <div className="form_row">
                      <div className="field">
                        <label htmlFor="message">Event:</label>                        
                        <span style={{fontStyle:"italic"}}><a href={"/events/edit/" + mail.event.id}>{mail.event.name}</a></span>
                      </div>
                    </div>         
                    <div className="form_row">
                      <div className="field">
                        <label htmlFor="message">Message:</label>
                        <input type="textarea" value={mail.question}/>
                      </div>
                    </div>
                  </> 
                );
              })
            }            
            <ErrorMessage className="error-message" name="mails" component="div" />             
            <div className="form_row">
            <div className="field">
                <label htmlFor="message">Answer:</label>
                <Field as="textarea" name="text" />
                <ErrorMessage className="error-message" name="text" component="div" />
              </div>
            </div>
            {!isSubmitting ? (
              <div className="buttons">
                <Button label="cancel" onClick={() => onClose()} type="secondary" />
                <Button onClick={()=>{handleSubmit()}} label="Send" disabled={isSubmitting} />
              </div>
            ) : (
              <Loader />
            )}
          </Form>
        )}
      </Formik>
    </FormWrapper>
  );
};
