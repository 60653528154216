import React, { useState } from "react";
import PageHeaderSub from "../../components/PageHeaderSub";
import Page from "components/UI/Elements/Page";
import Button from "components/UI/Buttons/Button";
import Modal from "components/UI/Modal";
import MailView from "./Components/MailView";
import { MailBuilderForm } from "./Components/MailBuilderForm";

const Communication = () => {
  const [showModal, setShowModal] = useState(false);
  const [selectedMails, setSelectedMails] = useState([]);

  const openNewMail = () => {
    setShowModal(true);
  };

  const onchangeSelected = async (checked, mail) => {
   setSelectedMails(checked 
    ? [...selectedMails, mail]
    : selectedMails.filter(item => item.id !== mail.id)); 
  };

  // TODO deprecated, could be needed again if submenu for events is added
  const removeFromSelected = (mails) => {
      let selMails = selectedMails;
      mails.map(mail => {
        selMails = selMails.filter(item => item.id !== mail.id);
      });
      setSelectedMails(selMails);     
  }

  return (
      <>
      {showModal && (
        <Modal title="Email builder" onClose={() => setShowModal(false)} hideActions>
          <MailBuilderForm selectedMails={selectedMails} onClose={() => {setShowModal(false);}} />        
        </Modal>
      )}
      <Page>
        <div className="page__header">
          <h1>Q/A</h1>
          <PageHeaderSub show={selectedMails.length > 0}>
              <Button label="Send reply" onClick={openNewMail}/>           
          </PageHeaderSub>
        </div>
        <div className="page__inner">
          <MailView onchangeSelected={onchangeSelected} selectedMails={selectedMails} removeFromSelected={removeFromSelected}/>
        </div>
      </Page>
      </>
  );
}

export default Communication;