import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import styled from "styled-components";
import { useHistory } from "react-router-dom";
import { Formik, Form, Field, ErrorMessage } from "formik";
import Loader from "components/UI/Loader";
import Button from "components/UI/Buttons/Button";
import * as Yup from 'yup'
import { addOrganizationDocument, editOrganizationDocument } from "api/services/organization";
import { UseOrganizationContext } from "contexts";

const FormWrapper = styled.div`
  label {
    display: block;
    margin-bottom: 0.4rem;
  }
  input {
    width: 100%;
  }
  textarea {
    width: 100%;
    height: 10rem;
  }
  .form_row {
    margin-bottom: 2rem;
    display: flex;
    width: 100%;
    .field {
      width: 100%;
      &:not(:first-of-type) {
        margin-left: 2.4rem;
      }
    }
  }
  .error-message {
    opacity: 0.6;
    color: red;
    padding: 1rem 0;
  }
  .buttons {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    button {
      margin-right: 1rem;
    }
  }
`;

const ValidationSchema = Yup.object().shape({
  file: Yup.string().required('Required'),
  type: Yup.string().required('Required'),
}) 

export const DocumentForm = ({ onClose, document, add, edit, type }) => {
  const languages = ['EN','NL','FR'];

  const callBack = async (values) => { 
    console.log('callback', values)
    const resp = !document 
      ? await add(values) 
      : await edit(values);
    return resp;
  };

  return (
    <FormWrapper>
      <Formik
        initialValues={{ 
          name: document?.name ?? "",
          file: document?.fileName, 
          type: type || "Document", 
        }}
        validationSchema={ValidationSchema}
        onSubmit={(values, { setSubmitting }) => {
          callBack({...values, name: values?.file.name})
            .then((value) => {
              onClose();
            })
            .catch((e) => {
              setSubmitting(false);
            });
        }}
      >
        {({ isSubmitting, handleSubmit, setFieldValue }) => (
          <Form>
          <div style={{display:"none"}} className="form_row">
            <div className="field">
              <label htmlFor="name">Name</label>
              <Field type="text" name="name" />
            </div>
          </div>
          {!document &&
          <>
            {/*<div style={''} className="form_row">
              <div className="field">
                <label htmlFor="type" className="required">Type</label>
                <Field as="select" name="type">
                  <option value="Document">Document</option>
                  <option value="Image">Image</option>
                </Field>
                <ErrorMessage className="error-message" name="type" component="div" />
              </div>
          </div> */}
              <div className="form_row">
                <div className="field">
                  <label htmlFor="file" className="required">File</label>
                  <input id="cover" name="document" type="file"  accept="image/jpeg,image/png,application/pdf" onChange={e => {  setFieldValue("file", e.currentTarget.files[0]);}}/> 
                  <ErrorMessage className="error-message" name="file" component="div" />
                </div>
            </div>  
          </> 
          }         
            {!isSubmitting ? (
              <div className="buttons">
                <Button label="cancel" onClick={(e) => {onClose();}} type="secondary"/>
                <Button onClick={()=>{handleSubmit()}} label="Submit" disabled={isSubmitting} />
              </div>
            ) : (
              <Loader />
            )}
          </Form>
        )}
      </Formik>
    </FormWrapper>
  );
};
