import React, { useState, useEffect } from "react";
import { UseOrganizationContext, UseErrorContext } from "contexts";
import { getShareholderChanges, getFilters } from "api/services/investor-insights";
import Loader from "components/UI/Loader";
import { getProducts } from "api/services/product";
import styled from "styled-components";
import Pagination from "components/Pagination";
import { GetPagination } from "api/helpers/datahelper";
import { FormatDate } from "api/helpers/datahelper";
import { FaArrowDown, FaArrowUp } from "react-icons/fa";

const Container = styled.div`
  table {
    width: 100%;
    min-width: 100rem;
    th{
      min-width: 9rem;
      &:first-of-type{
        min-width: initial;
      }
    }
  }

  .th-difference {

  }

  .th-prev {
  }

  .th-email {
  }

  .td-difference {
    display: flex;
    justify-content: flex-end;
  }

  .td-email {
  }
`;

const LoaderContainer = styled.div`
  position: absolute;
  z-index: 2;
  width: calc(100% + 16px);
  height: calc(100% + 16px);
  top: -16px;
  left: -16px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.3);
`;

const TYPES = { NEW: "New", SOLD: "Sold", CHANGED: "Changed" };

const ShareholderChanges = ({ scrollToID }) => {
  const [showAllFields, setShowAllFields] = useState(false);
  const [shareholders, setShareholders] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [numberOfPages, setNumberOfPages] = useState(1);
  const [productFilters, setProductFilters] = useState([]);
  const [productFilter, setProductFilter] = useState();
  const [typeFilter, setTypeFilter] = useState(TYPES.NEW);
  const [loading, setLoading] = useState(false);

  const organizationId = UseOrganizationContext()?.id;
  const { updateError } = UseErrorContext();

  useEffect(async () => {
    /*setShareholders([
      {
        accountHolderType: "NaturalPerson",
        legalPersonName: "",
        naturalPersonFirstName: "Sander",
        naturalPersonSurname: "Naert",
        email: "sandernaert@gmail.com",
        prevQuantity: 200,
        quantity: 100,
      },
      {
        accountHolderType: "NaturalPerson",
        legalPersonName: "",
        naturalPersonFirstName: "Glenn",
        naturalPersonSurname: "Marien",
        email: "glenn.marien@euronav.com",
        prevQuantity: 999,
        quantity: 10,
      },
      {
        accountHolderType: "NaturalPerson",
        legalPersonName: "",
        naturalPersonFirstName: "Johny",
        naturalPersonSurname: "Tester",
        email: "johnytester-finvictum@yopmail.com",
        prevQuantity: 167,
        quantity: 300,
      },
    ]);
    return;*/
    setLoading(true);
    try {
      //const filtersData = await getFilters(organizationId);

      let productsData = [];

      if (productFilters.length == 0) {
        productsData = await getProducts(organizationId, {
          search: null,
        });

        console.log("productsData", productsData);
        setProductFilters(productsData);
        setProductFilter(productsData[0]?.id);

        if (productsData.length < 1) {
          updateError("No products");
          return;
        }
      }

      const rawData = await getShareholderChanges({ productID: productFilter || productsData[0]?.id, type: typeFilter, currentPage });
      console.log(rawData);

      let shareholdersHeader = rawData.headers;

      let pagination = GetPagination(shareholdersHeader);
      let totalCount = pagination.totalCount;
      let pageSize = pagination.pageSize;
      let nbrOfPages = Math.ceil(totalCount / pageSize);

      setNumberOfPages(nbrOfPages);
      setShareholders(rawData.data);
      setLoading(false);
    } catch (err) {
      setLoading(false);
      updateError(err);
    }
  }, [typeFilter, currentPage]);

  const onChangeProductFilter = async (e) => {
    setCurrentPage(1);
    const val = e.target.value.toString();
    setProductFilter(val);

    const rawData = await getShareholderChanges({ productID: val, type: typeFilter, currentPage });
    console.log(rawData);

    let shareholdersHeader = rawData.headers;

    let pagination = GetPagination(shareholdersHeader);
    let totalCount = pagination.totalCount;
    let pageSize = pagination.pageSize;
    let nbrOfPages = Math.ceil(totalCount / pageSize);

    setNumberOfPages(nbrOfPages);
    setShareholders(rawData.data);
  };

  const onChangeTypeFilter = (e) => {
    setCurrentPage(1);
    const val = e.target.value.toString();
    setTypeFilter(val);
  };

  return (
    <Container>
      <div style={{ marginBottom: "2rem" }}>
        <div>
          <label>Product</label>
          <select onChange={onChangeProductFilter} value={productFilter}>
            {productFilters.map((filter, idx) => (
              <option key={idx} value={filter?.id}>
                {filter?.title} ({filter?.stockSymbol})
              </option>
            ))}
          </select>
        </div>
        <div>
          <label>Status</label>
          <select onChange={onChangeTypeFilter} value={typeFilter}>
            {Object.keys(TYPES).map((type, idx) => (
              <option key={idx} value={TYPES[type]}>
                {TYPES[type]}
              </option>
            ))}
          </select>
        </div>
      </div>
      <table className="table" cellSpacing="500" style={{ minWidth: showAllFields ? "180rem" : "110rem" }}>
        <thead>
          <tr>
            <th></th>
            <th>Name</th>
            <th>type</th>
            <th className="th-email">Email</th>
            <th>Quantity</th>
            <th className="th-prev">Prev. Quantity</th>
            {typeFilter == TYPES.CHANGED && <th className="th-difference">Difference</th>}
          </tr>
        </thead>
        <tbody>
          {loading && (
            <LoaderContainer>
              <Loader />
            </LoaderContainer>
          )}
          {shareholders.map((shareholder, idx) => {
            const difference = shareholder?.quantity - shareholder?.prevQuantity;
            return (
              <tr key={shareholder?.accountHolderType == "NaturalPerson" ? shareholder?.naturalPersonFirstName + "_" + shareholder?.naturalPersonSurname : shareholder?.legalPersonName + "_" + idx}>
                <td></td>
                <td>{shareholder?.accountHolderType == "NaturalPerson" ? shareholder?.naturalPersonFirstName + " " + shareholder?.naturalPersonSurname : shareholder?.legalPersonName}</td>
                <td>{shareholder?.accountHolderType}</td>
                <td className="td-email">{shareholder?.email}</td>
                <td>{shareholder?.quantity}</td>
                <td className="td-prev">{shareholder?.prevQuantity || "0"}</td>
                {typeFilter == TYPES.CHANGED && (
                  <td className={`td-difference ${difference > 0 ? "good" : "bad"}`}>
                    {difference} {difference > 0 ? <FaArrowUp /> : <FaArrowDown />}
                  </td>
                )}
              </tr>
            );
          })}
        </tbody>
      </table>
      <Pagination scrollToID={scrollToID} numberOfPages={numberOfPages} currentPage={currentPage} setCurrentPage={setCurrentPage} />
    </Container>
  );
};

export default ShareholderChanges;
