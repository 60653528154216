import React, { useState } from "react";
import { addEvent, uploadCover, addDocument, addLink, publishEvent, addRelatedEvent } from "api/services/event";
import { UseOrganizationContext } from "contexts";
import { useHistory } from "react-router-dom";
import { UseErrorContext } from "contexts";
import Loader from "components/UI/Loader";
import Stepper from "components/UI/Stepper/Stepper";
import ReviewSubmit from "./Pages/ReviewSubmit";
import { PressReleaseForm } from "./Pages/PressReleaseForm";
import { DocumentForm } from "./Pages/DocumentForm";
import TranslationsForm from "./Pages/TranslationsForm";
import CommunicationGroupsForm from "./Pages/CommunicationGroupsForm";
import RelatedEventsForm from "./Pages/RelatedEventsForm";
import LinksForm from "./Pages/LinksForm";

const steps = ['Information *', 'Communication groups', 'Document', 'Links', 'Related', 'Review'];

const PressReleaseWizard = ({ category }) => {
  const history = useHistory();
  const organizationId = UseOrganizationContext()?.id;
  const { updateError } = UseErrorContext();
  const [isLoading, setIsLoading] = useState(false);
  const [activeStep, setActiveStep] = useState(1);
  const [eventData, setEventData] = useState(null);
  const [document, setDocument] = useState(null);
  const [links, setLinks] = useState([]);
  const [translations, setTranslations] = useState([]);
  const [communicationGroups, setCommunicationGroups] = useState([]);
  const [relatedParentEvents, setRelatedParentEvents] = useState([]);
  const [relatedChildEvents, setRelatedChildEvents] = useState([]);

  const nextStep = () => {
    if (activeStep != steps.length)
    setActiveStep((prevActiveStep) => prevActiveStep + 1)
  };

  const backStep = () => {
    if (activeStep == 1){
      history.push("/events")
    }
    else{
      setActiveStep((prevActiveStep) => prevActiveStep - 1)
    }
  };

  const saveEventForm = (data) => {
    setEventData(data)
    nextStep();
  };

  const saveTranslationsForm = (data) => {
    setTranslations(data)
    nextStep();
  };

  const saveDocumentForm = (data) => {
    if (data.file){
      setDocument(data)
    }
    nextStep();
  };

  const saveLinksForm = (data) => {
    setLinks(data);
    nextStep();
  };

  const saveCommunicationGroupsForm = (data) => {
    setCommunicationGroups(data)
    nextStep();
  };

  const saveRelatedEventsForm = (relChildEvents, relParentEvents) => {
    setRelatedChildEvents(relChildEvents);
    setRelatedParentEvents(relParentEvents);
    nextStep();
  };

  const submit = async () => {
    setIsLoading(true);
    try {
      eventData.category = category;

      // Map data
      eventData.Details = {
        title: eventData.title,
        description: eventData.description,
        languageCode: "EN",
      };
      delete eventData.title;
      delete eventData.description;
      delete eventData.cover;
  
      const rep = await addEvent(organizationId, eventData);

      if (eventData.coverUrl && typeof eventData.coverUrl === "object") {
        await uploadCover(rep.id, eventData.coverUrl);
      }

      if (document) {
        await addDocument(rep.id, { file: document.file, name: eventData.Details.title }, true);
      }

      for (let i = 0; i < links.length; i++) {
        let link = links[i];
        await addLink(rep.id, link);
      }

      for (let i = 0; i < relatedParentEvents.length; i++) {
        let relatedParentEvent = relatedParentEvents[i];
        await addRelatedEvent(relatedParentEvent.id, rep.id);
      }

      for (let i = 0; i < relatedChildEvents.length; i++) {
        let relatedChildEvent = relatedChildEvents[i];
        await addRelatedEvent(rep.id, relatedChildEvent.id);
      }

      history.push("/events/edit/" + rep.id.toString());
      return rep;
    } catch (err) {
      updateError(err);
    }
    finally{
      setIsLoading(false);
    }
  };

  const publish = async (date) => {
    setIsLoading(true);
    try{
      const event = await submit();
      
      // TODO set date
      await publishEvent(event.id);
    } catch (err) {
      updateError(err);
    }
    finally{
      setIsLoading(false);
    }
  };

  const goToStep = async (step) => {
    setActiveStep(step);
  };

  if (isLoading) {
    return (
      <Loader />
    )
  }
  else {
    switch (activeStep) {
      case 1:
        return (
          <Stepper steps={steps} activeStep={activeStep} goToStep={goToStep}>
            <PressReleaseForm category={category} event={eventData} backStep={backStep} nextStep={saveEventForm} />
          </Stepper>
        );
      case 2:
        return (
          <Stepper steps={steps} activeStep={activeStep} goToStep={goToStep}>
            <CommunicationGroupsForm data={communicationGroups} eventIsPublic={eventData.isPublic} backStep={backStep} nextStep={saveCommunicationGroupsForm} /> 
          </Stepper>
        );
      case 3:
        return (
          <Stepper steps={steps} activeStep={activeStep} goToStep={goToStep}>
            <DocumentForm file={document} backStep={backStep} nextStep={saveDocumentForm}/>
          </Stepper>
        );
      case 4:
        return (
          <Stepper steps={steps} activeStep={activeStep} goToStep={goToStep}>
            <LinksForm data={links} backStep={backStep} nextStep={saveLinksForm} />
          </Stepper>
        );
      case 5:
        return (
          <Stepper steps={steps} activeStep={activeStep} goToStep={goToStep}>
            <RelatedEventsForm relChildEvents={relatedChildEvents} relParentEvents={relatedParentEvents} backStep={backStep} nextStep={saveRelatedEventsForm} />
          </Stepper>
        );
      case 6:
        return (
          <Stepper steps={steps} activeStep={activeStep} goToStep={goToStep}>
            <ReviewSubmit event={eventData} translations={translations} documents={document ? [document] : []} links={links} communicationGroups={communicationGroups} relatedChildEvents={relatedChildEvents} relatedParentEvents={relatedParentEvents} backStep={backStep} save={submit} publish={publish}/>  
          </Stepper>
        );
      default:
        return (
          <></>
        );
    }
  }

};

export default PressReleaseWizard;
