import React, { useState } from "react";
import styled from "styled-components";
import { useHistory } from "react-router-dom";
import { Formik, Form, Field, ErrorMessage } from "formik";
import Loader from "components/UI/Loader";
import Button from "components/UI/Buttons/Button";
import * as Yup from 'yup'
import { updateAnswer } from "api/services/communication";
import { sendAnswer } from "api/services/communication";
import { UseErrorContext } from "contexts";
import Confirm from "components/UI/Icons/Confirm";
import Send from "components/UI/Icons/Send";

const FormWrapper = styled.div`
  width: 100%;
  .wrapper{
    display: flex;
    justify-content: space-between;
  }
  .textbox {
          width: 95%;
          textarea{
            width: 100%;
          }
        }
        .actions {
        display: flex;
        margin-left: 1rem;
      }
`;

const ValidationSchema = Yup.object().shape({
  text: Yup.string().required('Required'),
}) 

export const AnswerForm = ({mail, refresh}) => {  
  const { updateError } = UseErrorContext();

  return (
    <FormWrapper>
      <Formik
        initialValues={ { 
          text: mail?.answer?.message,  
          }} 
          validationSchema={ValidationSchema}          
          onSubmit={(values, { setSubmitting }) => {
            values["questionIds"] = [mail.id];   
            sendAnswer(values)
              .then((value) => {
                setSubmitting(false);
                refresh();
              })
              .catch((err) => {
                updateError(err);
                setSubmitting(false);
              });
          }}
          >
        {({ isSubmitting, handleSubmit }) => (
          <div className="wrapper">
            {!isSubmitting ? 
              <>
              <div className="textbox">
                <Field as="textarea" name="text"/>
              </div>
                <div className="actions">
                  <div onClick={()=>{handleSubmit()}} className="icon-button"> 
                    <Send />
                  </div>       
                </div>
              </>
            : 
              <Loader />
            }
          </div>
        )}
      </Formik>
    </FormWrapper>
  );
};
