import React from "react";
import { FaEye } from "react-icons/fa";
import styled from "styled-components";

const Div = styled.div`
  .icon-button{
    svg{
      path{
        fill: var(--color-white);
      }
    }
  }
`;

export default function Follow(props) {

  return (
    <Div>
      <div className="icon-button">
        <FaEye/>
      </div>
    </Div>
  );
}
