import React, { useState, useEffect } from "react";
import { ValidateAccess } from "api/services/authorization";
import styled from "styled-components";
import { UseUserContext } from "contexts";
import { FaTrashAlt } from "react-icons/fa";

export default function Profile(props) {
  const [hide, setHide] = useState(true);
  const { permissions } = UseUserContext();

  useEffect(async () => {
    const access = ValidateAccess(props.roles, permissions);
    setHide(!access);
  }, [permissions]);

  return (
    <>
    { !hide &&
      <div className="icon-button">
        <FaTrashAlt/>
      </div>
    }  
    </>
  );
}
