import React from "react";
import HeaderDropDown from "../../components/UI/HeaderDropDown";
import PageHeaderSub from "../../components/PageHeaderSub";
import Page from "../../components/UI/Elements/Page";
import EditProfile from "./Components/EditProfile";
import Button from "components/UI/Buttons/Button";
import { Link } from "react-router-dom";
import LinksHeader from "components/LinksHeader";

export default class Profile extends React.Component {
  render() {
    return (
      <Page>
        <div className="page__header">
          <h1>Profile</h1>
            <LinksHeader>              
              <Link to={`/profile/links`}>
                <Button label="Links" type="secondary"/>
              </Link>
                <Link to={`/profile/translations`}>
                  <Button label="Translations" type="secondary"/>
                </Link>
            </LinksHeader>
        </div>
        <div className="page__inner">
          <HeaderDropDown titleHeader="">
            <EditProfile />
          </HeaderDropDown>
        </div>
      </Page>
    );
  }
}
