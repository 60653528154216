import React from "react";
import styled, {css} from "styled-components";
import FollowedOrganizationCard from "./FollowedOrganizationCard";
import Loader from "../../../components/UI/Loader";
import {device} from 'components/styles/GlobalStyle';
import { useTranslation } from 'react-i18next';

const Container = styled.div`
  display: flex;
  flex-wrap: wrap;
  .item {
    width: calc(100% / 3);
    padding: 1rem;
    ${({ direction }) =>
      direction == "column" &&
      css`
        width: calc(100% / 1);
        @media ${device.laptop} {
          width: calc(100% / 2);
        }
      `}
  }
`;

const FollowedOrganizationGrid = ({ organizations, refresh, direction}) => {
  const { t, i18n } = useTranslation();
  
  return (
    <Container direction={direction}>
      {!organizations ? (
        <Loader center />
      ) : (
        organizations.map((item, idx) => (
          <div className="item" key={idx}>
            <FollowedOrganizationCard {...item}  refresh={refresh}/>
          </div>
        ))
      )}
      {organizations && organizations?.length === 0 && <div>{t('noorganizations')}</div>}
    </Container>
  );
};

FollowedOrganizationGrid.defaultProps = {
  items: Array.from(Array(3)),
  direction: 'row'
};

export default FollowedOrganizationGrid;
