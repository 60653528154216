import { getAxiosClient } from "../axios";


export const getAllProducts = async (params) => {
  const axiosClient = await getAxiosClient();

  return await axiosClient
    .get(`/products`, { params: {...params}} )
    .then((response) => {
      return response;
    })
    .catch((error) => {
      console.log("error", error);
      return Promise.reject(error);
    });
};

export const getProducts = async (id, params) => {
  const axiosClient = await getAxiosClient();

  return await axiosClient
    .get(`/organizations/${id}/products`, { params: {...params}} )
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      console.log("error", error);
      return Promise.reject(error);
    });
};

export const addProduct = async (organizationId, product) => {
    const axiosClient = await getAxiosClient();
  
    return await axiosClient
      .post(`/organizations/${organizationId}/products`, product)
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        console.log("error", error);
        alert(error?.response?.data?.title);
        return Promise.reject(error);
      });
  };

  export const getProduct = async (productId) => {
    const axiosClient = await getAxiosClient();
  
    return await axiosClient
      .get(`/products/${productId}`)
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        return Promise.reject(error);
      });
  };

  export const getAdminProduct = async (productId) => {
    const axiosClient = await getAxiosClient();
  
    return await axiosClient
      .get(`/admin/products/${productId}`)
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        return Promise.reject(error);
      });
  };
  
  export const updateProduct = async (product) => {
    const axiosClient = await getAxiosClient();
  
    return await axiosClient
      .put(`/products/${product.id}`, product)
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        console.log("error", error);
        alert(error?.response?.data?.title);
        return Promise.reject(error);
      });
  };
  
  export const deleteProduct = async (productID) => {
    const axiosClient = await getAxiosClient();
  
    return await axiosClient
      .delete(`/products/${productID}`)
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        console.log("error", error);
        alert(error?.response?.data?.title);
        return Promise.reject(error);
      });
  };

  export const uploadCover = async (id, file) => {
    const axiosClient = await getAxiosClient();
  
    const formData = new FormData();
    formData.append("file", file);

    return await axiosClient
      .put(`/products/${id}/cover`, formData, { "content-type": "multipart/form-data" })
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        console.log("error", error);
        alert(error?.response?.data?.title);
        return Promise.reject(error);
      });
  };

  export const getFollowedProducts = async (params) => {
    const axiosClient = await getAxiosClient();

    return await axiosClient
      .get(`/me/followedproducts/`, { params: {...params}} )
      .then((response) => {
        return response;
      })
      .catch((error) => {
        console.log("error", error);
        return Promise.reject(error);
      });
  };

  export const followProduct = async (product) => {
    const axiosClient = await getAxiosClient();
  console.log(product)
    return await axiosClient
      .post(`/me/followedproducts/`, product)
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        console.log("error", error);
        alert(error?.response?.data?.title);
        return Promise.reject(error);
      });
  };

  export const unfollowProduct = async (productID) => {
    const axiosClient = await getAxiosClient();
  
    return await axiosClient
      .delete(`/me/followedproducts/${productID}`)
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        console.log("error", error);
        alert(error?.response?.data?.title);
        return Promise.reject(error);
      });
  };
  
  export const updateTranslation = async (id, translation) => {
    const axiosClient = await getAxiosClient();
  
    return await axiosClient
      .put(`/products/${id}/translations`, translation)
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        console.log("error", error);
        return Promise.reject(error);
      });
  };
  
  export const removeTranslation = async (id, languageCode) => {
    const axiosClient = await getAxiosClient();
  
    return await axiosClient
      .delete(`/products/${id}/translations/${languageCode}`)
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        console.log("error", error);
        return Promise.reject(error);
      });
  };