import React from "react";
import { FaPaperclip } from "react-icons/fa";

export default function Attachment() {

  return (
    <>
      <div className="icon-button">
        <FaPaperclip/>
      </div>
    </>
  );
}
