import React, { useState, useEffect } from "react";
import styled from "styled-components";
import AddButton from "components/UI/Buttons/AddButton";
import Modal from "components/UI/Modal";
import Trash from "components/UI/Icons/Trash";
import { DocumentForm } from "./DocumentForm";

const Container = styled.div`
  overflow: auto;
  .preview-image {
    max-width: 10rem;
    max-height: auto;
    object-fit: contain;
  }
  .preview__container {
    display: flex;
    gap: 2.4rem;
    flex-wrap: wrap;
  }

  .preview-image__container {
    position: relative;
    .icon_button {
      position: absolute;
      top: 0.4rem;
      right: 0.4rem;
    }
  }
`;

const ImagesGrid = ({ documents, isReadOnly, add, edit, remove }) => {
  const [selectedDocument, setSelectedDocument] = useState(null);
  const [showModalEdit, setShowModalEdit] = useState(false);
  const [showModalDelete, setShowModalDelete] = useState(false);
  const openDeleteModal = async (document) => {
    setSelectedDocument(document);
    setShowModalDelete(true);
  };

  const openAddModal = (e) => {
    e.preventDefault();
    e.stopPropagation();
    e.nativeEvent.stopImmediatePropagation();

    setShowModalEdit(true);
  };

  const openEditModal = (document) => {
    setSelectedDocument(document);
    setShowModalEdit(true);
  };

  const _add = async (values) => {
    await add(values);
    setShowModalEdit(false);
  };

  // TODO
  const _edit = async (values) => {
    await edit(selectedDocument.id, values);
    setShowModalEdit(false);
  };

  const _delete = async () => {
    console.log('selectedDocument', selectedDocument)
    await remove(selectedDocument);
    setShowModalDelete(false);
    setSelectedDocument(null);
  };

  const downloadFile = async (document) => {
    window.location.href = document.uri;
  };

  return (
    <Container>
      {showModalDelete && (
        <Modal title={`Delete "${selectedDocument.name}"`} onClose={() => setShowModalDelete(false)} onSubmit={() => _delete()} cancelLabel={"Cancel"} submitLabel={"Delete"}>
          <div>Are you sure you want to delete this document?</div>
        </Modal>
      )}
      {showModalEdit && (
        <Modal title="Upload image" hideActions>
          <DocumentForm
            type={"Image"}
            document={selectedDocument}
            onClose={() => {
              setShowModalEdit(false);
            }}
            add={_add}
            edit={_edit}
          />
        </Modal>
      )}
      {!isReadOnly && (
        <div onClick={(e) => openAddModal(e)} className="grid-add-btn">
          <AddButton label="Add images" />
        </div>
      )}
      <div className="preview__container">
        {documents.map((image) => {
          const imageUrl = image?.file?URL.createObjectURL(image.file):image?.uri;
          return (
            <div className="preview-image__container">
              <div onClick={() => openDeleteModal(image)} className="icon_button">
                <Trash />
              </div>
              <img className="preview-image" src={imageUrl} />
            </div>
          );
        })}
      </div>
    </Container>
  );
};

ImagesGrid.defaultProps = {
  documents: Array.from(Array(0)),
};

export default ImagesGrid;
