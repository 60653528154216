import React, { useState, useEffect } from "react";
import PageHeaderSub from "../../components/PageHeaderSub";
import Page from "../../components/UI/Elements/Page";
import styled from "styled-components";
import ShareholdersFilteredGrid from "./Components/ShareholdersFilteredGrid";

const Container = styled.div`
`;

const Shareholders= () => {
  return (
    <Page>
      <Container>
      <div className="page__header">
        <h1>Shareholders</h1>
        <PageHeaderSub />
      </div>
      <div className="page__inner">        
        <ShareholdersFilteredGrid showFilters={true} />    
      </div>
      </Container>
    </Page>
  );
};

export default Shareholders;
