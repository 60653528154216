import styled from "styled-components";
import React, { useState, useEffect } from "react";

const Stepper = styled.div`
  margin-top: 3.2rem;
  width: 100%;
`;
const StepperHeader = styled.div`
  background-color: var(--color-black);
  padding: 1.6rem;
  display: flex;
  justify-content: space-between;
`;
const Step = styled.div`
  .clickable{
    color: green;
    cursor: pointer;

    & > .label{
      border: 1px solid var(--color-accent-sec) !important;
    }
  }
  .label{
    color: var(--color-light-grey);
    padding: 1rem;
    border: 1px solid var(--color-medium-grey) !important;
    border-radius: .4rem;
    transition: all 0.25s ease-in-out;
  }
  .active{
    color: var(--color-accent-prim);
    border: 1px solid var(--color-accent-prim) !important;
  }
`;
const StepperBody = styled.div`
  background-color: var(--color-medium-grey);
  width: 100%;
  margin: auto;
  padding: 16px;
  align-items: center;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
  overflow: auto;
  box-shadow: 5px 6px 12px -5px black;
`;

export default function StepperComponent({ steps, activeStep, children, goToStep }) {

  return (
    <>
        <Stepper>
          <StepperHeader>
            {steps.map((label, idx) => (
              <Step key={idx}>
                <div className={idx+1 < activeStep ? "clickable" : ""} onClick={() => {idx+1 < activeStep && goToStep(idx+1)}}>
                  <div className={activeStep == idx+1 ? "label active" : "label"}>{idx+1}. {label}</div>
                </div>
              </Step>
            ))}
          </StepperHeader>
          <StepperBody>
            {children}
          </StepperBody>
        </Stepper>
      
    </>
  );
}
