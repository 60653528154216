import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { Formik, Form, Field, ErrorMessage } from "formik";
import Loader from "components/UI/Loader";
import Button from "components/UI/Buttons/Button";
import * as Yup from 'yup'
import { getFollowedEvents, sendMessage } from "api/services/event";
import { UseErrorContext } from "contexts";
import { getCategories, getTags } from "api/services/communication";
import Delete from "components/UI/Icons/Delete";
import Add from "components/UI/Icons/Add";
import { useTranslation } from 'react-i18next';

const FormWrapper = styled.div`
  .tags{
    margin: 1rem 0;
    > * {
      margin: .4rem;
    }
  }
`;

const ValidationSchema = Yup.object().shape({
  eventId: Yup.string().required('Required'),
  text: Yup.string().required('Required'),
  categoryId: Yup.string().required("Required"),
}) 

export const QuestionForm = ( {event, onClose} ) => {  
  const { updateError } = UseErrorContext();
  const [categories, setCategories] = useState([]);
  const [tags, setTags] = useState([]);
  const [suggestedTags, setSuggestedTags] = useState([]);
  const [tag, setTag] = useState("");
  const [selectedTags, setSelectedtags] = useState([]);
  const [events, setEvents] = useState([]);
  const { t, i18n } = useTranslation();

  useEffect(async () => {
    try{
      var cats = await getCategories();
      setCategories(cats);

      var tgs = await getTags();
      setTags(tgs);

      if (!event){
        let ev = await getFollowedEvents();
        setEvents(ev);
      }
    } catch (err) {
      updateError(err);
    }
  }, []);

  const onChangeTags = (e) => {
    const val = e.target.value.toString();
    setTag(val);
    if (!val){
      setSuggestedTags([]);
    }
    else{
      var sugTags = tags.filter(x => x.includes(val));
      if (sugTags.length > 5){
        sugTags = sugTags.slice(0, 5);
      }
      setSuggestedTags(sugTags);
    }
  };

  const onEnterTag = (e) => {
   var code = (e.keyCode ? e.keyCode : e.which);
   if(code == 13) { //Enter keycode
    if (tag != ""){
      addTag(tag);
    }
    e.preventDefault();
   }
  };

  const addTag = (tag) => {
    if (!selectedTags.includes(tag))
    {
      setSelectedtags([...selectedTags, tag]);
    }
    setTag("");
    setSuggestedTags([]);
  };

  const removeTag = (tag) => {
    if (selectedTags.includes(tag))
    {
      setSelectedtags(selectedTags.filter(item => item !== tag))
    }
  };

  return (
    <FormWrapper>
      <Formik
        initialValues={ { 
          text: "",
          categoryId: categories[0]?.id,
          eventId: event?.id,
          keywords: [],
         }} 
          validationSchema={ValidationSchema}          
          onSubmit={(values, { setSubmitting }) => {
            values["categoryId"] = parseInt(values["categoryId"]);
            values["keywords"] = selectedTags;
            const eventId = event?.id ?? values["eventId"];
            sendMessage(eventId, values)
              .then((value) => {
                setSubmitting(false);
                onClose();
              })
              .catch((err) => {
                updateError(err);
               setSubmitting(false);
              });
          }}
          >
        {({ isSubmitting, handleSubmit, setFieldValue }) => (
          <Form className="form">
            <div className="form_row">
              <div className="field">
                <label className="label required">{t('action')}:</label>     
                { event             
                  ? <span>{event.title}</span>
                  : 
                  <>
                    <Field as="select" name="eventId">
                      <option value=""></option>
                      {events?.map((event, idx) => (
                        <option value={event.id} key={idx}>
                          {event.title}
                        </option>
                      ))}
                    </Field>
                    <ErrorMessage className="error-message" name="eventId" component="div" /> 
                  </>  
                }
              </div>
            </div>            
            <div className="form_row">
              <div className="field">
                <label htmlFor="category" className="label required">
                  {t('category')}:
                </label>
                <Field as="select" name="categoryId">
                  <option value="0"></option>
                  {categories?.map((category, idx) => (
                    <option value={category.id} key={idx}>
                      {category.name}
                    </option>
                  ))}
                </Field>
                <ErrorMessage className="error-message" name="categoryId" component="div" />           
              </div>
            </div>          
            <div className="form_row">
              <div className="field">
                { selectedTags.length < 5 &&
                  <>
                    <label htmlFor="keywords" className="label">
                      {t('tagsmax5')}:
                    </label>
                    <input onChange={onChangeTags} value={tag} onKeyDown={(e) => { onEnterTag(e)}}/>
                  </>
                }
                { suggestedTags.length > 0 &&
                  <div className="tags">
                    <span>{t('suggestions')}: </span>
                    {suggestedTags.map((tag, idx) => (
                      <Button label={tag} onClick={() => addTag(tag)} type="secondary">
                        <Add/>
                      </Button>
                    ))}
                  </div>
                } 
                { selectedTags.length > 0 &&
                  <div className="tags">
                    <span>{t('tags')}: </span> 
                    {selectedTags.map((tag, idx) => (
                      <Button label={tag} onClick={() => removeTag(tag)} type="primary">
                        <Delete/>
                      </Button>
                    ))}
                  </div>
                }
                <ErrorMessage className="error-message" name="keywords" component="div" />           
              </div>
            </div>
            <div className="form_row">
              <div className="field">
                <label htmlFor="text" className="label required">
                  {t('message')}:
                </label>
                <Field as="textarea" name="text"/>
                <ErrorMessage className="error-message" name="text" component="div" />     
              </div>
            </div>
            {!isSubmitting ? (
              <div className="buttons">
                <Button label={t('close')} onClick={() => onClose()} type="secondary"/>
                <Button onClick={()=>{handleSubmit()}} label={t('send')} disabled={isSubmitting} />         
              </div>
            ) : (
              <Loader />
            )}
          </Form>
        )}
      </Formik>
    </FormWrapper>
  );
};
