import React, { useState } from "react";
import styled from "styled-components";
import HeaderDropDown from "../../components/UI/HeaderDropDown";
import { UseUserContext } from "contexts";
import Page from "components/UI/Elements/Page";
import PageHeaderSub from "components/PageHeaderSub";
import { BackButton } from "components/UI/Buttons/BackButton";

const Container = styled.div``;

const PermissionsView = () => {
  const { permissions } = UseUserContext();

  return (
    <Page>
      {!permissions 
       ? <Loader />
       : <Container>       
          <div className="navigation-banner">
            <BackButton link="/user"/>
          </div>
          <div className="page__header">
            <h1>Permissions</h1>   
            <PageHeaderSub/>
          </div>
          <div className="page__inner">  
            <HeaderDropDown titleHeader="Allowed permissions">  
                <table cellSpacing="500">
                  <thead>
                    <tr>
                      <th></th>
                      <th>Name</th>
                      <th>Description</th>
                      <th></th>
                    </tr>
                  </thead>
                  <tbody>
                    {permissions?.sort((a, b) => a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1).map((permission, idx) => (
                      <tr key={idx}>
                        <td></td>
                        <td>{permission.name}</td>
                        <td>{permission.description}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
            </HeaderDropDown> 
          </div>
          </Container>
        }
    </Page>

    
  );
};
export default PermissionsView;
