import styled from "styled-components";
import React, { useState, useEffect } from "react";

const B = styled.div`

.toggle-switch{
  position: relative;
  background: linear-gradient(#ccc, #eee);
  height: 3rem;
  width: 20rem;
  border-radius: .8rem;
  cursor: ${props => props.disabled ? 'auto' : 'pointer'}};
}
.knob{
  position: absolute;
  width: 10rem;
  height: 2.9rem;
  border-radius: .8rem;
  background: var(--color-accent-prim);
  border: 1px solid #ddd;
  transition: left 0.3s ease-out;
  left: 0rem;
  &.active{
    left: 10rem;
    //background: var(--color-accent-prim);
  }
  }
  .label-left{
    color: var(--color-black);
    font-size: 1.2rem;
    position: absolute;
    top: .8rem;
    left: 2rem;
    transition: all 0.3s ease-out;
    &.active{
      color: var(--color-text);
      top: .8rem;
  }
  }
  .label-right{
    color: var(--color-black);
    font-size: 1.2rem;
    position: absolute;
    top: .8rem;
    right: 2rem;
    transition: all 0.3s ease-out;
    &.active{
      color: var(--color-text);
      top: .8rem;
  }
  }
`;

export default function ToggleButton({ on, label1, label2, onSwitchOn, onSwitchOff, disabled }) {
  const [isToggleOn, setIsToggleOn] = useState();

  useEffect(async () => {
    const val = on;
    setIsToggleOn(val);
  }, [on]);

  const onSwitch = (e) => { 
    if (disabled) 
      return;  
    if(isToggleOn){
      onSwitchOff()
    }else{
      onSwitchOn()
    }
    setIsToggleOn(!isToggleOn);
  };

  return (
    <B disabled={disabled}>
      <div onClick={onSwitch} className="toggle-switch">
        <div>
            <div className={isToggleOn ? "knob active disabled" : "knob"} />
            <span className={!isToggleOn ? "label-left active" : "label-left"}>{label1}</span>
            <span className={isToggleOn ? "label-right active" : "label-right"}>{label2}</span>        
        </div>
      </div>
    </B>
  );
}

ToggleButton.defaultProps = {
  on: false,
  onSwitchOn: () => {
    console.log("switch on");
  },
  onSwitchOff: () => {
    console.log("switch off");
  },
};
