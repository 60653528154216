import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { Formik, Form, Field, ErrorMessage } from "formik";
import Loader from "components/UI/Loader";
import Button from "components/UI/Buttons/Button";
import * as Yup from "yup";

const FormWrapper = styled.div``;

const ValidationSchema = Yup.object().shape({
  name: Yup.string().required("Required"),
  file: Yup.string().required("Required"),
});

export const DocumentForm = ({ onClose, document, add, edit }) => {
  const languages = ["EN", "NL", "FR"];

  const callBack = async (values) => {
    const resp = !document ? await add(values) : await edit(values);
    return resp;
  };

  return (
    <FormWrapper className="form">
      <Formik
        initialValues={{
          name: document?.name,
          languageCode: "",
          file: document?.fileName,
        }}
        validationSchema={ValidationSchema}
        onSubmit={(values, { setSubmitting }) => {
          callBack(values)
            .then((value) => {
              onClose();
            })
            .catch((e) => {
              setSubmitting(false);
            });
        }}
      >
        {({ isSubmitting, handleSubmit, setFieldValue }) => (
          <Form>
            <div className="form_row">
              <div className="field">
                <label htmlFor="name" className="required">
                  Name
                </label>
                <Field type="text" name="name" />
                <ErrorMessage className="error-message" name="name" component="div" />
              </div>
            </div>
            {!document && (
              <>
                <div className="form_row">
                  <div className="field">
                    <label htmlFor="languageCode">Language</label>
                    <Field as="select" name="languageCode">
                      <option value="">ALL</option>
                      {languages?.map((language, idx) => (
                        <option value={language} key={idx}>
                          {language}
                        </option>
                      ))}
                    </Field>
                    <ErrorMessage className="error-message" name="languageCode" component="div" />
                  </div>
                </div>
                <div className="form_row">
                  <div className="field">
                    <label htmlFor="file" className="required">
                      File
                    </label>
                    <input
                      multiple
                      id="cover"
                      name="document"
                      type="file"
                      accept="image/jpeg,image/png,application/pdf"
                      onChange={(e) => {
                        setFieldValue("file", e.currentTarget.files[0]);
                      }}
                    />
                    <ErrorMessage className="error-message" name="file" component="div" />
                  </div>
                </div>
              </>
            )}
            {!isSubmitting ? (
              <div className="buttons">
                <Button
                  label="cancel"
                  onClick={(e) => {
                    onClose();
                  }}
                  type="secondary"
                />
                <Button
                  onClick={() => {
                    handleSubmit();
                  }}
                  label="Add"
                  disabled={isSubmitting}
                />
              </div>
            ) : (
              <Loader />
            )}
          </Form>
        )}
      </Formik>
    </FormWrapper>
  );
};
