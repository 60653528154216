import { getAxiosClient } from "../axios";

const uploadFile = async () => {};

export const getShareholders = async (ogranizationId, params) => {
  const axiosClient = await getAxiosClient();

  return await axiosClient
    .get(`/loader/organizations/${ogranizationId}/investors`, { params })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      console.log("error", error);
      return Promise.reject(error);
    });
};

export const getFilters = async (ogranizationId) => {
  const axiosClient = await getAxiosClient();

  return await axiosClient
    .get(`/loader/organizations/${ogranizationId}/investors/filters`)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      console.log("error", error);
      return Promise.reject(error);
    });
};

export const resetInvestorDemoData = async () => {
  const axiosClient = await getAxiosClient();

  return await axiosClient
    .post(`/loader/demodata/reset`)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      console.log("error", error);
      return Promise.reject(error);
    });
};

export const getAnalytics = async (organizationId) => {
  const axiosClient = await getAxiosClient();

  return await axiosClient
    .get(`/loader/organizations/${organizationId}/analytics`)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      console.log("error", error);
      return Promise.reject(error);
    });
};

export const getShareholderChanges = async ({ type = "New", productID = 1, currentPage=1 }) => {
  //analytics/shareholderchanges?productid=1&changetype=New&page=1
  const axiosClient = await getAxiosClient();

  return await axiosClient
    .get(`loader/analytics/shareholderchanges?productid=${productID}&changetype=${type}&page=${currentPage}`)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      console.log("error", error);
      return Promise.reject(error);
    });
};
