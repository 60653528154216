import styled from "styled-components";
import React from "react";

const Container = styled.div`
  text-align: right;
  a button, button{
   // background-color: var(--color-light-grey);
   // color: var(--color-black);
    margin: 1rem 0 0 1rem;
  }
`;

export default function LinksHeader({ children }) {
  return (
    <Container>
      {children}
    </Container>
  );
}

