import React from 'react';
import styled from 'styled-components';

// Styled Components
const AnalyticsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  background-color: #f9f9f9;
  border-radius: 8px;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
  max-width: 400px;
  margin: auto;
`;

const Title = styled.h3`
  font-size: 24px;
  color: #333;
  margin-bottom: 15px;
`;

const EventDate = styled.p`
  font-size: 14px;
  color: #666;
  margin-bottom: 15px;
`;

const StatsList = styled.ul`
  list-style: none;
  padding: 0;
  width: 100%;
`;

const StatItem = styled.li`
  display: flex;
  justify-content: space-between;
  padding: 10px 0;
  border-bottom: 1px solid #e0e0e0;

  &:last-child {
    border-bottom: none;
  }
`;

const StatLabel = styled.span`
  font-size: 16px;
  color: #555;
`;

const StatValue = styled.span`
  font-size: 16px;
  font-weight: bold;
  color: #1f1f1f;
`;

const AdditionalInfo = styled.div`
  font-size: 14px;
  color: #999;
  margin-top: 15px;
`;

// Main Component
const ActionAnalytics = ({ views, likes, attendees, shares, comments, title, date }) => {
  return (
    <AnalyticsWrapper>
      <Title>{title}</Title>
      <EventDate>{date.toLocaleDateString('en-US', { year: 'numeric', month: 'long', day: 'numeric' })}</EventDate>
      <StatsList>
        <StatItem>
          <StatLabel>Views</StatLabel>
          <StatValue>{views}</StatValue>
        </StatItem>
        <StatItem>
          <StatLabel>Likes</StatLabel>
          <StatValue>{likes}</StatValue>
        </StatItem>
        <StatItem>
          <StatLabel>Attendees</StatLabel>
          <StatValue>{attendees}</StatValue>
        </StatItem>
        <StatItem>
          <StatLabel>Shares</StatLabel>
          <StatValue>{shares}</StatValue>
        </StatItem>
        <StatItem>
          <StatLabel>Comments</StatLabel>
          <StatValue>{comments}</StatValue>
        </StatItem>
      </StatsList>
      <AdditionalInfo>Updated 5 minutes ago</AdditionalInfo>
    </AnalyticsWrapper>
  );
};

// Default Props for optional values
ActionAnalytics.defaultProps = {
  views: 0,
  likes: 0,
  attendees: 0,
  shares: 0,
  comments: 0,
};

export default ActionAnalytics;
