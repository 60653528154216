import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { useHistory } from "react-router-dom";
import { Formik, Form, Field, ErrorMessage } from "formik";
import Loader from "components/UI/Loader";
import Button from "components/UI/Buttons/Button";
import { capitalizeFirstLetter, checkIfStringIsJson } from "utils";
import HTMLRenderView from "components/UI/Blocks/HTMLRenderView";
import MDEditor from "components/UI/Blocks/MDEditor";
import SectionInfo from "./SectionInfo";
import EventInfo from "pages/events/Components/EventInfo";
import draftToHtml from "draftjs-to-html";
import { translateContent } from "api/services/translations";
import { UseErrorContext } from "contexts";
import htmlToDraft from "html-to-draftjs";

import * as Yup from "yup";

const FormWrapper = styled.div`
  label {
    display: block;
    margin-bottom: 0.4rem;
  }
  input {
    width: 100%;
  }
  textarea {
    width: 100%;
    height: 10rem;
  }
  .form_row {
    margin-bottom: 2rem;
    display: flex;
    width: 100%;
    .field {
      width: 100%;
      &:not(:first-of-type) {
        margin-left: 2.4rem;
      }
    }
  }
  .field__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 1.2rem;
  }
  .error-message {
    opacity: 0.6;
    color: red;
    padding: 1rem 0;
  }
  .buttons {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 1.2rem;
    span {
      font-size: 1.2rem !important;
    }
  }
`;

export const TranslationForm = ({ fields, item, type, translation, languages, validationSchema, onClose, save, targetLanguage, initialValues, sourceContent }) => {
  console.log("validationSchema", validationSchema);
  const [formChanged, setFormChanged] = useState(false);
  const [loadingTranslation, setLoadingTranslation] = useState(false)

  const { updateError } = UseErrorContext();

  const callBack = async (values) => {
    console.log("callBack", values);
    setFormChanged(false);
    values.setDefault = false;
    const resp = await save({ ...translation, ...values, languageCode: targetLanguage });
    return resp;
  };

  const fetchTranslations = async (field) => {
    //return "Euronav is <b>marktleider</b> in het transport van ruwe olie. Als 's werelds grootste, onafhankelijke beursgenoteerde platform voor ruwe tankers, op 31 maart 2022, bezit en beheert Euronav een vloot van 72 schepen (zie Euronav vloot p 41). De vennootschap, opgericht in België, heeft haar hoofdkantoor in Antwerpen. Wereldwijd heeft Euronav ongeveer 200 vaste werknemers aan de wal en kantoren over heel Europa en Azië.\nOngeveer 3.200 mensen werken op de schepen. Euronav is uitgegroeid van een familiebedrijf met 17 schepen tot een sterke internationale speler die genoteerd is op Euronext Brussel en op de NYSE onder het symbool 'EURN'. De noodzaak om een veilige en betrouwbare vloot uit te baten is nog nooit zo cruciaal geweest en is de belangrijkste strategische doelstelling voor de Vennootschap. Euronav wil een efficiënte organisatie zijn en streeft ernaar de hoogste kwaliteit en de best mogelijke service te leveren aan haar klanten.";

    if (!field) return;

    const sourceContent = item[field];

    try {
      const htmlContent = checkIfStringIsJson(sourceContent) ? draftToHtml(JSON.parse(sourceContent)) : sourceContent;

      console.log("htmlContent", htmlContent);

      const translation = await translateContent(htmlContent, "EN", targetLanguage);

      console.log("translation", translation?.text);

      return translation?.text;
    } catch (error) {
      updateError(err);
      return null;
    }
  };

  const needTextArea = (string) => {
    if (string?.length > 100) return true;
    return false;
  };

  if (type == "section") {
    return <SectionInfo section={item} />;
  }

  if (type == "action") {
    return <EventInfo event={item} />;
  }

  return (
    <FormWrapper>
      <Formik
        initialValues={{ languageCode: targetLanguage, ...initialValues }}
        validationSchema={validationSchema}
        onSubmit={(values, { setSubmitting }) => {
          callBack(values)
            .then((value) => {
              //onClose();
              setSubmitting(false);
            })
            .catch((e) => {
              setSubmitting(false);
            });
        }}
      >
        {({ isSubmitting, handleSubmit, setFieldValue, values, handleChange }) => (
          <Form>
            <div className="form_row">
              <div className="field" style={{ display: "none" }}>
                <label htmlFor="languageCode" className="required">
                  Language
                </label>
                {!translation && false ? (
                  <Field
                    as="select"
                    name="languageCode"
                    onChange={(e) => {
                      handleChange(e);
                      console.log("changed");
                    }}
                  >
                    <option value=""></option>
                    {languages?.map((language, idx) => (
                      <option value={language} key={idx}>
                        {language}
                      </option>
                    ))}
                  </Field>
                ) : (
                  <span>{translation.languageCode}</span>
                )}
                <ErrorMessage className="error-message" name="languageCode" component="div" />
              </div>
            </div>
            {fields.map((field, idx) => (
              <>
                {!targetLanguage && (
                  <div key={idx} className="form_row">
                    <div className="field">
                      <h3>{capitalizeFirstLetter(field)}</h3>
                      <div>
                        <HTMLRenderView content={item[field]} />
                      </div>
                    </div>
                  </div>
                )}
                {targetLanguage && (
                  <div className="form_row">
                    <div className="field">
                      <div className="field__header">
                        <h3 htmlFor={field}>Translated {capitalizeFirstLetter(field)}</h3>
                        <Button
                          type="secondary"
                          onClick={async () => {
                            setLoadingTranslation(true)
                            const translatedContent = await fetchTranslations(field);
                            console.log("translatedContent", translatedContent);
                            setFieldValue(field, translatedContent);
                            setFormChanged(true);
                            setLoadingTranslation(false)

                          }}
                          label="Generate translation"
                        />
                      </div>
                      {needTextArea(item[field]) || field == "text" ? (
                        <MDEditor
                          value={values[field]}
                          setFieldValue={(val) => {
                            setFieldValue(field, val);
                            setFormChanged(true);
                          }}
                          onTranslate={loadingTranslation}
                          languageCode={values?.languageCode}
                        />
                      ) : (
                        <Field type="text" name={field} value={values[field]} />
                      )}
                      <ErrorMessage className="error-message" name={field} component="div" />
                    </div>
                  </div>
                )}
              </>
            ))}
            {!isSubmitting && targetLanguage ? (
              <div className="buttons">
                {formChanged && <span>Changes not saved yet! </span>}
                <Button
                  onClick={() => {
                    handleSubmit();
                  }}
                  label="Save"
                  disabled={isSubmitting}
                />
              </div>
            ) : (
              targetLanguage && <Loader />
            )}
          </Form>
        )}
      </Formik>
    </FormWrapper>
  );
};
