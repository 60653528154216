import React from "react";
import styled from "styled-components";
import { number } from "yup";

const Nav = styled.nav`
  .pagination{
    flex-direction: row;
    width: 30%;
    margin: auto;
  }

  .active a{
    color: var(--color-accent-prim);
  }

  a {
    padding-left: 0;
    font-size: 1.2rem;
  }
`;

export const Pagination = ({ numberOfPages, currentPage, setCurrentPage, scrollToID }) => {
  const pageNumbers = [...Array(numberOfPages + 1).keys()].slice(1)    
  
  const nextPage = (e) => {
    if(currentPage !== numberOfPages) 
      setCurrentPage(currentPage + 1)
      shouldScrollToID(e)
  }

  const prevPage = (e) => {
    if(currentPage !== 1) 
      setCurrentPage(currentPage - 1)
      shouldScrollToID(e)
  }

  const shouldScrollToID = (e)=>{
    console.log(e)
    if(!scrollToID) return;
    e.preventDefault();
    document.getElementById(scrollToID).scrollIntoView();
  }

  return (
          <Nav>
            { 
              numberOfPages > 1 && 
              <ul className='pagination'>
                {currentPage > 1 ? (<li className="page-item">
                      <a className="page-link" 
                          onClick={prevPage} 
                          href={`#${scrollToID}`}>                        
                          Previous
                      </a>
                  </li>) : (<li/>)
                }
                  {/* Show all pages */}
                  {/* {pageNumbers.map(pgNumber => (
                      <li key={pgNumber} 
                          className= {`page-item ${currentPage == pgNumber ? 'active' : ''} `} >
                          <a onClick={() => setCurrentPage(pgNumber)}  
                              className='page-link' 
                              href='#'>                            
                              {pgNumber}
                          </a>
                      </li>
                  ))} */}
                  {/* Show only current page */}
                  <li key={currentPage} className="page-item active">
                    <a className='page-link' href='#'>                            
                      {currentPage} / {numberOfPages}
                    </a>
                  </li>
                  <li className="page-item">
                      <a className="page-link" 
                          onClick={nextPage}
                          href={`#${scrollToID}`}>                        
                          Next
                      </a>
                  </li>
              </ul>
            }
        </Nav>
  );
}

export default Pagination