import React, { useEffect, useState } from "react";
import styled from "styled-components";
import Button from "../Buttons/Button";
import { MAPSTATUS } from "constants";

const Container = styled.span`
    .status{        
        background-color: transparent;
        &:hover {
            opacity: 1;
            transition: none;
        }
    }
    .INVITED{
        border: var(--color-status-new) solid 1px;
        color: var(--color-status-new);
        cursor: default;
    }
    .CREATED{
        border: var(--color-status-good) solid 1px;
        color: var(--color-status-good);
        cursor: default;
    }
    .REQUESTED-DELETION{
        border: var(--color-status-warning) solid 1px;
        color: var(--color-status-warning);
        cursor: default;
    }
    .DELETED{
        border: var(--color-status-bad) solid 1px;
        color: var(--color-status-bad);
        cursor: default;
    }
    .HIDDEN{
        border: var(--color-status-new) solid 1px;
        color: var(--color-status-new);        
        opacity: 0.7;
        transition: all 0.25s ease-in;
    }
    .FINISHED{
        border: var(--color-status-good) solid 1px;
        color: var(--color-status-good);
        cursor: default;
    }
    .STARTED{
        border: var(--color-status-good) solid 1px;
        color: var(--color-status-good);
        cursor: default;
    }
    .PUBLISHED{
        border: var(--color-status-good) solid 1px;
        color: var(--color-status-good);
        cursor: default;
    }
    .PUBLISHEDSECTION{
        border: var(--color-status-good) solid 1px;
        color: var(--color-status-good);
    }
    .APPROVE{
        border: var(--color-status-new) solid 1px;
        color: var(--color-status-new);        
        opacity: 0.7;
        transition: all 0.25s ease-in;
    }
    .PUBLISHSTARTED{
        border: var(--color-status-good) solid 1px;
        color: var(--color-status-good);
        cursor: default;
    }
    .DRAFT{
        border: var(--color-status-new) solid 1px;
        color: var(--color-status-new);        
        opacity: 0.7;
        transition: all 0.25s ease-in;
    }
    .NOTALLOWED{
        cursor: not-allowed;
    }
    .PUBLISH{
        border: var(--color-status-warning) solid 1px;
        color: var(--color-status-warning);
    }
    .APPROVED{
        border: var(--color-status-good) solid 1px;
        color: var(--color-status-good);
        cursor: default;
    }
`;

export default function Status({ label, type, onClick, notAllowed }) {
  const [lbl, setLbl] = useState(label);
  const [tpe, setTpe] = useState(type ?? label);

  useEffect(async () => { 
    setLbl(label); 
    setTpe(type ?? label)
    }, [label, type]) 

    const advancedOnClick = () => {
        if (notAllowed) {
            return false;
        }

        onClick();
    }

    return (
        <Container>
            <Button type={`status ` + tpe.toUpperCase() + (notAllowed ? ' NOTALLOWED' : '')} label={MAPSTATUS[lbl] ?? lbl} onClick={advancedOnClick} />
        </Container>
    );
}

Status.defaultProps = {
    label: "",
};
