import React, { useState, useEffect } from "react";
import styled from "styled-components";
import Button from "components/UI/Buttons/Button";
import SwitchButtonOn from "components/UI/Buttons/SwitchButtonOn";
import { getCommunicationGroups } from "api/services/communication-groups";
import { updateEvent } from "api/services/event";
import { UseOrganizationContext } from "contexts";
import { UseErrorContext } from "contexts";
import { Tooltip } from "components/UI/Tooltip";

const Container = styled.div`
 `;

const EditCommunicationGroupsView = ( { event, onClose, isReadOnly } ) => {
  const organizationId = UseOrganizationContext()?.id;
  const [communicationGroups, setCommunicationGroups] = useState(null);  
  const [selectedCommunicationGroups, setSelectedCommunicationGroups] = useState(event.communicationGroups);
  const { updateError } = UseErrorContext();
  const [isPublic, setIsPublic] = useState(event.isPublic); 

  useEffect(async () => {
    if (!organizationId) return;
      refresh();
  }, [organizationId]);

  const refresh = async () => {
    try{
      var commGroups = await getCommunicationGroups(organizationId);
      setCommunicationGroups(commGroups);
    } catch (err) {
      updateError(err);
    }
  }

  const _addCommunicationGroup = async (communicationGroup) => {
    setSelectedCommunicationGroups([...selectedCommunicationGroups, communicationGroup]);
  };

  const _removeCommunicationGroup = async (communicationGroup) => {
    setSelectedCommunicationGroups(selectedCommunicationGroups.filter(item => item.id !== communicationGroup.id))
  };

  const handleSubmit = async () => {
    try{
      const eventData = event;
      eventData.isPublic = isPublic;
      eventData.communicationGroups = selectedCommunicationGroups;
      await updateEvent(eventData);
      onClose();    
    } catch (err) {
      updateError(err);
    }
  };

  const onChangeIsPublic = (e) => {
    const val = JSON.parse(e.target.value);
    if (val){
      setSelectedCommunicationGroups([]);
    }
    setIsPublic(val);
  };

  return (
    <Container>
      <div className="form_row mb-3">
        <div className="field">
          <label htmlFor="isPublic" className="label">
            Visible to
            <Tooltip text="Should the published action be visible for everyone, or only for the linked communication groups?" />
          </label>
          <select onChange={onChangeIsPublic} value={isPublic}>
            <option value="true">Public</option>
            <option value="false">Communication groups only</option>
          </select>
        </div>
       </div>
       { !isPublic &&
          <div className="switch-container">
          {communicationGroups?.map((communicationGroup, idx) => (
            <div key={idx} className="switch-item">
              <label>{communicationGroup.name}</label>
              <SwitchButtonOn
                  on={event.communicationGroups?.find((el) => el.id === communicationGroup.id)}
                  onSwitchOn={() => _addCommunicationGroup(communicationGroup)}
                  onSwitchOff={() => _removeCommunicationGroup(communicationGroup)}
              />
            </div>                
            ))}
          </div>
      }
      <div className="modal-buttons">
        <Button label="cancel" onClick={() => onClose()} type="secondary" />
        <Button onClick={handleSubmit} label="Save" disabled={isReadOnly}/>
      </div>
    </Container>
  );
};

export default EditCommunicationGroupsView;
