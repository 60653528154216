import React, { useState, useEffect } from "react";
import styled from "styled-components";
import AddButton from "../../../components/UI/Buttons/AddButton";
import Modal from "components/UI/Modal";
import Trash from "components/UI/Icons/Trash";
import { RelatedEventForm } from "./RelatedEventForm";

const Container = styled.div`
  overflow: auto;
`;

const RelatedEventGrid = ({ relatedEvents, relatedEventIds, isReadOnly, type, add, remove }) => {
  const [showModal, setShowModal] = useState(false);
  const [selectedRelatedEvent, setSelectedRelatedEvent] = useState(null);
  const [showModalDelete, setShowModalDelete] = useState(false);
  
  const showDeleteModal = async (relatedEvent) => {
    setSelectedRelatedEvent(relatedEvent);
    setShowModalDelete(true);
  };

  return (
    <Container>
      {showModalDelete && (
        <Modal title={`Delete "${selectedRelatedEvent.title}"`} onClose={() => {setShowModalDelete(false);}} onSubmit={() => {remove(selectedRelatedEvent.id); setShowModalDelete(false);}} cancelLabel={"Cancel"} submitLabel={"Delete"}>
          <div>Are you sure you want to delete this related event?</div>
        </Modal>
      )}
      {showModal && (
        <Modal title="Related event" hideActions>
          <RelatedEventForm relatedEventIds={relatedEventIds} onClose={() => {setShowModal(false);}} add={add}/>
        </Modal>
      )}
      <table cellSpacing="500">
        <thead>
          <tr>
            <th>Action</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {relatedEvents?.map((relatedEvent, idx) => (
            <tr key={idx}>
              <td><a href={`/events/edit/${relatedEvent.id}`}>{relatedEvent.title}</a></td>
              <td>
                {!isReadOnly && (
                  <div onClick={() => showDeleteModal(relatedEvent)} className="icon-button grid-action-button">
                    <Trash />
                  </div>
                )}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      {!isReadOnly && (
         type == "parent" 
          ? 
            relatedEvents.length == 0 &&
              <div onClick={() => setShowModal(true)} className="grid-add-btn">
                <AddButton label="Add event" />
              </div>
          : 
            <div onClick={() => setShowModal(true)} className="grid-add-btn">
              <AddButton label="Add event" />
            </div>
      )}
    </Container>
  );
};

RelatedEventGrid.defaultProps = {
  relatedEvents: Array.from(Array(0)),
};

export default RelatedEventGrid;
