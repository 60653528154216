import React, { useState, useEffect, useContext } from "react";
import styled from "styled-components";
import { WebsocketContext } from "contexts/InfrontContext";
import { InfrontSDK } from "libs/infrontFramework";
import { useTranslation } from 'react-i18next';

const Container = styled.div`
  font-family: Montserrat;
  .stock-price {
    font-size: 2.4rem;
    font-weight: 500;
    &__label {
    }
    &__container {
      display: flex;
      align-items: center;
      gap: 1.6rem;
      padding-top: 1rem;
      padding-bottom: 2.4rem;
    }
  }
  .stock-items {
    display: flex;
    align-items: center;
  }
  .stock-item {
    border: 1px solid rgba(255,255,255,.3);
    border-left: unset;
    padding: 1.2rem 2.4rem;
    &:last-of-type{
      border-right: unset;
    }
    &__label {
      color: var(--color-accent-prim);
      font-size: 1.6rem;
      opacity: 1;
      padding-bottom: 0.4rem;
      text-transform: uppercase
    }
  }
`;

const StockData = ({ stockSymbol, isin, mic }) => {
  const [cachedSymbols, setCachedSymbols] = useState({});
  const { ws, isReady } = useContext(WebsocketContext);
  const { t, i18n } = useTranslation();

  var unsubscribes;

  useEffect(() => {
    function unsubscribe() {
      if (unsubscribes) unsubscribes();
      unsubscribes = undefined;
    }

    const delayedPricing = (source, limit = 100, id = null) => {
      if (!ws || !isReady) return;
      unsubscribe = ws.get(
        InfrontSDK.symbolData({
          content: {
            Basic: true,
          },
          id: { mic: mic, isin: isin },
          subscribe: true,
          onData: (data) => {
            handleSymbolData(data);	
            data.observe(InfrontSDK.SymbolField.Last, value => { handleSymbolData(data); });	
          }
        })
      );
     };

    function handleSymbolData(data)
    {
      let newSymbol = {};
      newSymbol[data.get(InfrontSDK.SymbolField.Ticker)] = data;
      setCachedSymbols((prev) => ({ ...prev, ...newSymbol }));
    }

    delayedPricing();

    return function cleanup() {
      unsubscribe();
    };
  }, [isReady, stockSymbol, isin, mic]);

  

  const lastPrice = cachedSymbols[stockSymbol]?.get(InfrontSDK?.SymbolField.Last);
  const percentChange = cachedSymbols[stockSymbol]?.get(InfrontSDK?.SymbolField.ChangePercent);

  const low = cachedSymbols[stockSymbol]?.get(InfrontSDK?.SymbolField.Low);
  const high = cachedSymbols[stockSymbol]?.get(InfrontSDK?.SymbolField.High);
  const open = cachedSymbols[stockSymbol]?.get(InfrontSDK?.SymbolField.Open);
  const close = cachedSymbols[stockSymbol]?.get(InfrontSDK?.SymbolField.YesterdayClose);

  return stockSymbol ? (
    <Container>
      <div className="stock-price__label">{stockSymbol}</div>
      <div className="stock-price__container">
        <span className="stock-price">€ {lastPrice?.toFixed(2)}</span>
        <span className={`stock-price__percent ${percentChange >= 0 ? "good" : "bad"}`}>{percentChange?.toFixed(2)} %</span>
      </div>
      <div className="stock-items">
        <div className="stock-item">
          <div className="stock-item__label">{t('stocklow')}</div> € {low?.toFixed(2)}
        </div>
        <div className="stock-item">
          <div className="stock-item__label">{t('stockhigh')}</div> € {high?.toFixed(2)}
        </div>
        <div className="stock-item">
          <div className="stock-item__label">{t('stockopen')}</div> € {open?.toFixed(2)}
        </div>
        <div className="stock-item">
          <div className="stock-item__label">{t('stockprevclose')}</div> € {close?.toFixed(2)}
        </div>
      </div>
    </Container>
  ) : (
    <></>
  );
};

export default StockData;
