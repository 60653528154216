import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { Formik, Form, Field, ErrorMessage } from "formik";
import Loader from "components/UI/Loader";
import Button from "components/UI/Buttons/Button";
import { UseOrganizationContext } from "contexts";
import { addOrganizationUser } from "api/services/user";
import * as Yup from "yup";
import { getDepartments, getPermissions } from "api/services/user";
import Select from "react-select";
import SwitchButtonOn from "components/UI/Buttons/SwitchButtonOn";
import { UseErrorContext } from "contexts";
import { PermissionGroupMapping } from "../../../constants";

const FormWrapper = styled.div`
  label {
    display: block;
    margin-bottom: 0.4rem;
  }
  input {
    width: 100%;
  }
  textarea {
    width: 100%;
    height: 10rem;
  }
  .form_row {
    margin-bottom: 2rem;
    display: flex;
    width: 100%;
    .field {
      width: 100%;
      &:not(:first-of-type) {
        margin-left: 2.4rem;
      }
    }
  }
  .error-message {
    opacity: 0.6;
    color: red;
    padding: 1rem 0;
  }
  .buttons {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    button {
      margin-right: 1rem;
    }
  }
  .switch-group {
    display: flex;
    border-bottom: 1px solid rgba(255, 255, 255, 0.2);
    margin-bottom: 1.2rem;
  }
  .switch-item {
    p {
      padding-bottom: 0.4rem;
      font-weight: normal;
      font-size: 1.6rem;
      min-height: 3.3rem;
    }
  }
  table {
    width: 100%;
    min-width: 0;
    margin-bottom: 4rem;
  }
`;

const ValidationSchema = Yup.object().shape({
  firstname: Yup.string().required("Required"),
  lastname: Yup.string().required("Required"),
  email: Yup.string().email().required("Required"),
  departments: Yup.array().min(1, "Required"),
});

export const UserForm = ({ onClose, user }) => {
  const organizationId = UseOrganizationContext()?.id;
  const [departments, setDepartments] = useState(null);
  const [permissions, setPermissions] = useState([]);
  const [selectedPermissions, setSelectedPermissions] = useState([]);
  const { updateError } = UseErrorContext();

  useEffect(async () => {
    try{
      var dep = await getDepartments(organizationId);
      setDepartments(dep);
    } catch (err) {
      updateError(err);
    }
  }, []);

  useEffect(async () => {
    try{
      var perm = await getPermissions(organizationId);
      setPermissions(perm);
    } catch (err) {
      updateError(err);
    }
  }, []);

  const callBack = async (user) => {
    try{
      const resp = await addOrganizationUser(organizationId, {
        ...user,
        permissions: selectedPermissions.map(function (x) {
          return x.id;
        }),
      });
      return resp;
    } catch (err) {
      updateError(err);
    }
  };

  const _addPermission = async (permission) => {
    setSelectedPermissions([...selectedPermissions, permission]);
  };

  const _removePermission = async (permission) => {
    setSelectedPermissions(selectedPermissions.filter((item) => item.id !== permission.id));
  };

  var groupBy = function (xs, key) {
    const objByTpe = xs.reduce(function (rv, x) {
      (rv[x[key].split(".")[0]] = rv[x[key].split(".")[0]] || []).push(x);
      return rv;
    }, {});

    let array = [];
    Object.keys(objByTpe).forEach((permissiongroup) => array.push(objByTpe[permissiongroup]));
    return array;
  };

  const convertPermissionToHeaderText = (permission) => {
    let permissionGroup = permission.name.split(".")[0];
    return PermissionGroupMapping[permissionGroup] ?? permissionGroup;
  }


  const permissionsByType = permissions && groupBy(permissions, "name");

  return (
    <FormWrapper>
      <Formik
        initialValues={{
          firstname: "",
          lastname: "",
          email: "",
          departments: [],
          permissions: [],
        }}
        validationSchema={ValidationSchema}
        onSubmit={(values, { setSubmitting }) => {
          callBack(values)
            .then((value) => {
              onClose();
            })
            .catch((e) => {
              setSubmitting(false);
            });
        }}
      >
        {({ isSubmitting, handleSubmit, setFieldValue }) => (
          <Form>
            <div className="form_row">
              <div className="field">
                <label htmlFor="firstname" className="required">
                  Firstname
                </label>
                <Field type="text" name="firstname" />
                <ErrorMessage className="error-message" name="firstname" component="div" />
              </div>
            </div>
            <div className="form_row">
              <div className="field">
                <label htmlFor="lastname" className="required">
                  Lastname
                </label>
                <Field type="text" name="lastname" />
                <ErrorMessage className="error-message" name="lastname" component="div" />
              </div>
            </div>
            <div className="form_row">
              <div className="field">
                <label htmlFor="email" className="required">
                  Email
                </label>
                <Field type="email" name="email" />
                <ErrorMessage className="error-message" name="email" component="div" />
              </div>
            </div>
            <div className="form_row">
              <div className="field">
                <label htmlFor="departments" className="required">
                  Departments
                </label>
                <Select
                  className="react-selectcomponent"
                  onChange={(e) => {
                    setFieldValue(
                      "departments",
                      e.map((x) => x.id)
                    );
                  }}
                  options={departments}
                  getOptionLabel={(department) => department.name}
                  defaultValue={user?.departments}
                  isOptionSelected={(department) => {
                    user?.departments?.find((el) => el.id === department.id);
                  }}
                  isMulti
                  styles={{ multiValueRemove: (base) => ({ ...base, display: "none" }) }}
                />
                <ErrorMessage className="error-message" name="departments" component="div" />
              </div>
            </div>
            <div className="form_row">
              <div className="field">
                <label htmlFor="permissions">Permissions</label>     
                <table cellSpacing="500">
                  <thead>
                      <tr>
                        <th>Type</th>
                        <th>Name</th>
                        <th><SwitchButtonOn onSwitchOn={() => setSelectedPermissions(permissions)} onSwitchOff={() => setSelectedPermissions([])} /></th>
                      </tr>
                    </thead>
                    <tbody>
                      {permissionsByType?.map((permissions) => (
                        permissions.map((permission, idx) => (
                          <tr key={idx}>
                            <td>{convertPermissionToHeaderText(permissions[0])}</td>
                            <td>{permission.description}</td>
                            <td><SwitchButtonOn on={selectedPermissions?.find((el) => el.id === permission.id)} onSwitchOn={() => _addPermission(permission)} onSwitchOff={() => _removePermission(permission)} /></td>
                          </tr>
                        ))
                      ))}
                    </tbody>
                  </table>
                <ErrorMessage className="error-message" name="permissions" component="div" />
              </div>
            </div>
            {!isSubmitting ? (
              <div className="buttons">
                <Button
                  label="cancel"
                  onClick={(e) => {
                    onClose();
                  }}
                  type="secondary"
                />
                <Button
                  onClick={() => {
                    handleSubmit();
                  }}
                  label="Save"
                  disabled={isSubmitting}
                />
              </div>
            ) : (
              <Loader />
            )}
          </Form>
        )}
      </Formik>
    </FormWrapper>
  );
};
