import React, { useState, useEffect } from "react";
import styled from "styled-components";
import AddButton from "../../../../components/UI/Buttons/AddButton";
import Modal from "components/UI/Modal";
import Trash from "components/UI/Icons/Trash";
import Button from "components/UI/Buttons/Button";
import { DocumentForm } from "components/DocumentForm";

const Container = styled.div`
  overflow: auto;
`;

const DocumentsForm = ({ data, nextStep, backStep }) => {
  const [showModal, setShowModal] = useState(false);
  const [documents, setDocuments] = useState(data ?? []);

  const _add = async (doc) => {
    setDocuments([...documents, doc]);
  };

  const _delete = async (doc) => {
    console.log(doc, documents)
    setDocuments(documents.filter(x => x.name !== doc.name))
  };

  return (
    <Container className="form">
      {showModal && (
        <Modal title="Document" hideActions>
          <DocumentForm onClose={() => {setShowModal(false);}} add={_add}/>
        </Modal>
      )}
      <table cellSpacing="500">
        <thead>
          <tr>
            <th>Name</th>
            <th>Language</th>
            <th>File</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {documents?.map((document, idx) => (
            <tr key={idx}>
              <td>{document.name}</td>
              <td>{document.languageCode}</td>
              <td>{document.file.name}</td>
              <td>
                <div onClick={() => _delete(document)} className="icon-button">
                  <Trash />
                </div>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      <div onClick={() => setShowModal(true)} className="grid-add-btn">
        <AddButton label="Add document" />
      </div>
      <div className="buttons">
        <Button label="Back" onClick={() => backStep()} type="secondary" />
        <Button label="Next" onClick={() => nextStep(documents)} type="primary" />
      </div>
    </Container>
  );
};

export default DocumentsForm;
