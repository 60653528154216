import React, { useState, useEffect } from "react";
import styled from "styled-components";
import Trash from "components/UI/Icons/Trash";
import Reply from "components/UI/Icons/Reply";
import Edit from "components/UI/Icons/Edit";
import { MailForm } from "./MailForm";
import Modal from "components/UI/Modal";
import { deleteAnswer, deleteMail, sendAnswer } from "api/services/communication";
import { UseErrorContext } from "contexts";
import { FormatDateTimeDisplay } from "api/helpers/datahelper";
import { AnswerForm } from "./AnswerForm";


const Container = styled.div`
  overflow: auto;
  .question {
    margin-bottom: 1.2rem;
    padding: 1.2rem;
    border: 1px solid rgba(255, 255, 255, 0.6);
    border-radius: 0.8rem;
    &__header {
      cursor: pointer;
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      justify-content: space-between;
      :hover{
        color: var(--color-accent-prim);
        opacity: .7;
      }
      &__event {
        font-weight: bold;
        font-size: 1.8rem;
        margin-bottom: 0.4rem;
      }
      &__question {
        font-size: 1.6rem;
        opacity: 0.8;
        width: 100%;
      }
      &__actions {
        margin-bottom: 0.4rem;
        text-align: right;
      }
      &__time {
        font-size: 1.2rem;
        opacity: 0.8;
        margin-bottom: 0.4rem;
        text-align: right;
      }
      .status{
        margin-top: .2rem;
        margin-bottom: .2rem;
      }
      .keywords {
        margin-top: .8rem;
        span {
          margin-left: 0.4rem;
          padding: 0.1rem 0.4rem;
          border: 1px solid rgba(255, 255, 255, 0.6);
          border-radius: 0.8rem;
        }
      }
    }
    &__body {
      margin-top: 1.2rem;
      & > div {
        width: 80%;
        padding: 1.2rem;
        border-radius: 0.8rem;
        margin-bottom: 1.2rem;
        box-shadow: 5px 6px 12px -5px black;
        display: flex;
        justify-content: space-between;
      }
      &__actions {
        display: flex;
      }
      &__question {
        background-color: black;
      }
      &__answer {
        background-color: rgba(255, 255, 255, 0.6);
        color: #1a1a1a;
        margin-left: auto;
        &__textbox {
          width: 95%;
          textarea{
            width: 100%;
          }
        }
      }
    }
    .sender {
      font-size: 1.2rem;
      margin-bottom: 0.4rem;
      opacity: 0.8;
    }
  }
`;

const MailComponent = ({ mail, refresh }) => {
  const [showModalMail, setShowModalMail] = useState(false);
  const [showModalDelete, setShowModalDelete] = useState(false);
  const [open, setOpen] = useState(false);
  const { updateError } = UseErrorContext();

  const showDeleteModal = async (mail) => {
    setSelectedMail(mail);
    setShowModalDelete(true);
  };

  const _delete = async () => {
    try{
      await deleteAnswer(mail.id);
    } catch (err) {
    updateError(err);
  }
    setShowModalDelete(false);
    refresh();
  };

  const _open = () => {
    setShowModalMail(true);
  };

  const _sendReply = async (mail) => {
    // TODO
    console.log("SEND REPLY", mail)
    let data = {"questionIds": [mail.id], "text": mail.newAnswer};
    console.log(data);
    //await sendAnswer(data);
  };

  return (
    <Container>        
      {showModalDelete && (
        <Modal title={`Delete mail from "${mail.user.name}"`} onClose={() => setShowModalDelete(false)} onSubmit={() => _delete()} cancelLabel={"Cancel"} submitLabel={"Delete"}>
          <div>Are you sure you want to delete this mail?</div>
        </Modal>
      )}
      {showModalMail && (
      <Modal title="Email" hideActions>
        <MailForm mail={mail} onClose={() => {setShowModalMail(false); refresh();}} />
      </Modal>
    )}
          <div className="question">
            <div className="question__header" onClick={() => setOpen(!open)}>
              <div className="question__header__event">{mail.event.name}</div>
              <div className="question__header__time">
                {`${FormatDateTimeDisplay(mail.createdTime)}`}
                <div className={mail.answer ? "status good" : "status bad"}>{!mail.answer ? "unanswered" : "answered"}</div>
                <div className="keywords">
                  {mail.keywords.map((keyword) => (
                    <span>{keyword}</span>
                  ))}
                </div>
              </div>
              {!open && <div className="question__header__question">{mail.question.length < 60 ? mail.question : mail.question.substring(0, 60) + "..."}</div> }
            </div>
            { open &&
              <div className="question__body">
                <div className="question__body__question">
                  <div>
                    <div className="sender">{mail.user?.name}</div>
                    {mail.question}
                  </div>
                  {/* TODO uncomment me if possible to answer multiple questions */}
                  {/* <div className="question__body__actions">
                      <div onClick={() => _open(mail)} className="icon-button">
                        <Reply />
                      </div>
                  </div> */}
                </div>
                {mail.answer ? 
                  <div className="question__body__answer">
                    <div>
                      <div className="sender">{mail.answer?.answeredBy?.name}</div>
                      {mail.answer?.message}
                    </div>
                    <div className="question__body__actions">
                      <div onClick={() => _open(mail)} className="icon-button">
                        <Edit />
                      </div> 
                      {/* TODO BE needs to add delete answer */}
                      {/* <div onClick={() => showDeleteModal(mail)} className="icon-button">
                        <Trash />
                      </div>  */}
                    </div>
                  </div>
                : 
                  <div className="question__body__answer">
                    <AnswerForm mail={mail} refresh={refresh}/>
                  </div>
                }
              </div>
            }
          </div>
     
    </Container>
  );
};

MailComponent.defaultProps = {
};

export default MailComponent;
