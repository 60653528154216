import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import HeaderDropDown from "components/UI/HeaderDropDown";
import PageHeaderSub from "components/PageHeaderSub";
import Page from "components/UI/Elements/Page";
import ProductGrid from "pages/products/Components/ProductGrid";
import Button from "components/UI/Buttons/Button";
import Modal from "components/UI/Modal";
import { ProductForm } from "pages/products/Components/ProductForm";
import SearchBar from "../../components/UI/SearchBar";
import { getProducts } from "../../api/services/product";
import { UseOrganizationContext } from "contexts";
import { delay } from "api/helpers/datahelper";
import { UseErrorContext } from "contexts";

const ProductManager = () => {
  const [showModal, setShowModal] = useState(false);
  const [search, setSearch] = useState("");
  const [products, setProducts] = useState(null);
  const organizationId = UseOrganizationContext()?.id;
  const { updateError } = UseErrorContext();

  useEffect(async () => {
    if (!organizationId) return;
    refresh();
  }, [organizationId, search]);

  const refresh = async () => {  
    try{ 
      const productsData = await getProducts(organizationId, {
        'search': search,
      });

      setProducts(productsData);
    } catch (err) {
      updateError(err);
    }
  };

  // Search
  const onSearch = async (e) => {
    await delay(1000);
    const val = e.target.value.toString();
    setSearch(val);
  };

  return (
    <>
      {showModal && (
        <Modal title="Add a new share" onClose={() => setShowModal(false)}>
          <ProductForm />
        </Modal>
      )}
      <Page>
        <div className="page__header">
          <h1>Shares</h1>
          <PageHeaderSub show>
            <Link to="productmanager/add">
              <Button label="Add share" roles={["Products.Create"]}/>
            </Link>
          </PageHeaderSub>
        </div>
        <div className="page__inner">
          <div className="searchBarContainer">
            <SearchBar onChange={onSearch}/>
          </div>
          <HeaderDropDown titleHeader="">
            <ProductGrid canEdit products={products} refresh={refresh}/>
          </HeaderDropDown>
        </div>
      </Page>
    </>
  );
};

export default ProductManager;
