import React, { useState, useEffect } from "react";
import styled from "styled-components";
import PageHeaderSub from "../../components/PageHeaderSub";
import Page from "components/UI/Elements/Page";
import Modal from "components/UI/Modal";
import { MailBuilderForm } from "./Components/MailBuilderForm";
import Pagination from "components/Pagination";
import { UseErrorContext } from "contexts";
import SearchBar from "components/UI/SearchBar";
import HeaderDropDown from "components/UI/HeaderDropDown";
import { getMyPosts } from "api/services/communication";
import { GetPagination } from "api/helpers/datahelper";
import { delay } from "api/helpers/datahelper";
import MyPostGrid from "./Components/MyPostGrid";
import { useTranslation } from "react-i18next";

const PageHeader = styled.div`
  margin-bottom: 2.4rem;
`;

const MyPosts = ({ hideTitle }) => {
  const [showModal, setShowModal] = useState(false);
  const [posts, setPosts] = useState([]);
  const [search, setSearch] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [numberOfPages, setNumberOfPages] = useState(1);
  const [loading, setLoading] = useState(false);
  const [fromDateFilter, setFromDateFilter] = useState(null);
  const [toDateFilter, setToDateFilter] = useState(null);
  const { updateError } = UseErrorContext();
  const { t, i18n } = useTranslation();

  useEffect(async () => {
    refresh();
  }, [search, currentPage, fromDateFilter, toDateFilter]);

  // Rerfesh data
  const refresh = async () => {
    try {
      setLoading(true);

      var params = {
        page: currentPage,
        search: search,
        from: fromDateFilter,
        to: toDateFilter,
      };

      const response = await getMyPosts(params);
      console.log(response);

      let data = response.data;
      let header = response.headers;

      let pagination = GetPagination(header);
      let totalCount = pagination.totalCount;
      let pageSize = pagination.pageSize;
      let nbrOfPages = Math.ceil(totalCount / pageSize);

      setNumberOfPages(nbrOfPages);

      setPosts(data);
    } catch (err) {
      updateError(err);
    } finally {
      setLoading(false);
    }
  };

  // Search
  const onSearch = async (e) => {
    await delay(1000);
    const val = e.target.value.toString();
    setSearch(val);
  };

  // Filters
  const onChangeFromDateFilter = async (e) => {
    setCurrentPage(1);
    const val = e.target.value.toString();
    setFromDateFilter(val);
  };

  const onChangeToDateFilter = async (e) => {
    setCurrentPage(1);
    const val = e.target.value.toString();
    setToDateFilter(val);
  };

  return (
    <>
      {showModal && (
        <Modal title="Add post" onClose={() => setShowModal(false)} hideActions>
          <MailBuilderForm
            selectedMails={selectedMails}
            onClose={() => {
              setShowModal(false);
            }}
          />
        </Modal>
      )}
      <Page fullWidth={hideTitle}>
        {!hideTitle && (
          <PageHeader>
            <h1>Posts</h1>
            <PageHeaderSub></PageHeaderSub>
          </PageHeader>
        )}
        <div className="page__inner">
          <div style={{ display: "none" }} className="searchBarContainer">
            <SearchBar onChange={onSearch} />
          </div>
          <div style={{ display: "none" }} className="filters">
            <div className="row">
              <div>
                <label>{t("From")}: </label>
                <input type="datetime-local" onChange={onChangeFromDateFilter} value={fromDateFilter} />
              </div>
              <div>
                <label>{t("To")}: </label>
                <input type="datetime-local" onChange={onChangeToDateFilter} value={toDateFilter} />
              </div>
            </div>
          </div>
          <MyPostGrid posts={posts} refresh={refresh} />
          <Pagination numberOfPages={numberOfPages} currentPage={currentPage} setCurrentPage={setCurrentPage} />
        </div>
      </Page>
    </>
  );
};

export default MyPosts;
