import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { UseErrorContext } from "contexts";
import MailComponent from "./MailComponent";

const Container = styled.div`
  overflow: auto;
`;

const MailGrid = ({ mails, onchangeSelected, selectedMails=[], refresh }) => {
  const [selectedMail, setSelectedMail] = useState(null); 
  const { updateError } = UseErrorContext();

  const isChecked = (mail) => {
    return selectedMails.some(item => item.id === mail.id);
  }

  return (
    <Container>        
      {mails.map((mail, idx) => (
          <MailComponent mail={mail} key={mail.id + "_" + idx} refresh={refresh}/>
      ))}
    </Container>
  );
};

MailGrid.defaultProps = {
  mails: Array.from(Array(0)),
};

export default MailGrid;
