import React from "react";
import styled, { css } from "styled-components";
import Loader from "../../../components/UI/Loader";
import EventCard from "pages/events/Components/EventCard";
import {device} from 'components/styles/GlobalStyle'

const Container = styled.div`
  display: flex;
  flex-wrap: wrap;
  .item {
    width: calc(100% / 3);
    padding: 1rem;
    ${({ direction }) =>
      direction == "column" &&
      css`
        width: calc(100% / 1);
        @media ${device.tablet} {
          width: calc(100% / 2);
        }
        @media ${device.laptop} {
          width: calc(100% / 3);
        }
      `}
  }
  .no-events {
    margin: auto;
  }
`;

const EventGrid = ({ canEdit, direction, events, refresh }) => {

  return (
    <Container direction={direction}>
      {!events ? (
        <Loader center />
      ) : (
        events.map((item, idx) => (
          <div className="item" key={idx}>
            <EventCard small {...item} refresh={refresh} canEdit={canEdit} />
          </div>
        ))
      )}
      {events && events?.length === 0 && <div className="no-events">No actions to show</div>}
    </Container>
  );
};

EventGrid.defaultProps = {
  canEdit: true,
  showLatest: false,
  direction: "row",
};

export default EventGrid;
