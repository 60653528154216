import React, { useState, useEffect } from "react";
import styled from "styled-components";
import Image from "components/UI/Images/Image";
import { FaTrash, FaSave } from "react-icons/fa";
import ArrowUp from "components/UI/Icons/ArrowUp";
import ArrowDown from "components/UI/Icons/ArrowDown";
import MDEditor from "components/UI/Blocks/MDEditor";
import { UseOrganizationContext } from "contexts";

const Container = styled.div`
  .tab__editor {
    margin-left: 3.2rem;
    width: calc(70% - 3.2rem);
  }
`;

const EventSection = ({ item, idx, isLoading, save, removeTab, moveSequenceDown, moveSequenceUp, isFirst, isLast, onChangeEdited }) => {
  const [title, setTitle] = useState(item.title);
  const [text, setText] = useState(item.text);
  const [coverFile, setCoverFile] = useState(item.coverFile);
  const [cover, setCover] = useState(item.cover);
  const [uploadingCover, setUploadingCover] = useState(false);
  const [height, setHeight] = useState("auto");
  const [isEdited, setIsEdited] = useState(false);
  const {logoUrl} = UseOrganizationContext();

  useEffect(async () => {
    setTitle(item.title);
    setText(item.text);
    setCover(item.cover);
  }, [item]);

  useEffect(async () => {
    var edited = title != item.title || text != item.text || item.cover != cover || (title == "" && text == "");
    setIsEdited(edited);
  }, [title, text, cover, item]);

  useEffect(async () => {
    onChangeEdited(isEdited);
  }, [isEdited]);

  const previewImage = async (e) => {
    setUploadingCover(true);
    var selectedFile = e.target.files[0];
    setCoverFile(selectedFile);

    var reader = new FileReader();
    reader.onload = function (es) {
      setCover(es.target.result);
    };

    reader.readAsDataURL(selectedFile);
    setUploadingCover(false);
  };

  const saveTab = async () => {
    let obj = item;
    obj.title = title;
    obj.text = text;
    obj.cover = cover;
    obj.coverFile = coverFile;

    save(obj);
  };

  return (
    <Container>
      <div className="tab__item">
        <div className="tab__header">
          <div className="tab__header__left">
            <span className="label">Section {idx + 1}</span>
            <input
              value={title}
              maxLength="50"
              onChange={(e) => {
                setTitle(e.target.value);
              }}
              placeholder="Tab title"
            />
          </div>
          <div className="tab__header__right">
            {isEdited && (
              <div onClick={() => saveTab()} className="icon-button">
                <FaSave />
              </div>
            )}
            <div onClick={() => removeTab(item)} className="icon-button">
              <FaTrash />
            </div>
          </div>
        </div>
        <div className="tab__content">
          <div className="sequence">
            <div
              onClick={() => {
                saveTab();
                moveSequenceUp(item);
              }}
              className="icon-button"
            >
              <ArrowUp disabled={isFirst} />
            </div>
            <div
              onClick={() => {
                saveTab();
                moveSequenceDown(item);
              }}
              className="icon-button"
            >
              <ArrowDown disabled={isLast} />
            </div>
          </div>
          <div className="tab__thumb">
            <Image url={cover||logoUrl} onChange={previewImage} isLoading={uploadingCover} />
          </div>
          <div className="tab__editor">
            <MDEditor value={text} setFieldValue={(val) => setText(val)} />
          </div>
        </div>
      </div>
    </Container>
  );
};

export default EventSection;
