import React, { useState, useEffect, useContext } from "react";
import styled from "styled-components";
import thumbnail from "../../../assets/thumbnail.jpg";
import Modal from "components/UI/Modal";
import Followers from "components/UI/Icons/Followers";
import { FormatDate } from "api/helpers/datahelper";
import PrivateCard from "components/PrivateCard";
import { UseErrorContext } from "contexts";
import { WebsocketContext } from "contexts/InfrontContext";
import { InfrontSDK } from "libs/infrontFramework";

const Div = styled.div`
  position: relative;
  .card {
    &__content {
      color: var(--color-dark-grey);
      &__footer {
        font-size: 1.2rem;
      }
    }
    &_price {
      position: absolute;
      top: 10px;
      left: 10px;
      background-color: var(--color-medium-grey);
      padding: .6rem 1.2rem;
      border-radius: .4rem;
    }
  }

  .card_image__container {
    img {
      cursor: pointer;
    }
  }
`;

const ProductCard = ({ id, cover, title, subtitle, updatedTimeUtc, totalFollowers, refresh, canEdit, stockSymbol }) => {
  const [cachedSymbols, setCachedSymbols] = useState({});
  const { updateError } = UseErrorContext();

  const { ws, isReady } = useContext(WebsocketContext);
  var unsubscribes;

  useEffect(() => {
    function unsubscribe() {
      if (unsubscribes) unsubscribes();
      unsubscribes = undefined;
    }

    const delayedPricing = (source, limit = 100, id = null) => {
      if (!ws || !isReady) return;
      unsubscribe = ws.get(
        InfrontSDK.symbolData({
          content: {
            Basic: true
          },
          id: [{ feed: 2010, ticker: stockSymbol }],
          subscribe: true,
          onData: (symbolList) => {
            let binding = {
              itemAdded: (symbol, index) => {
                let newSymbol = {};
                newSymbol[symbol.get(InfrontSDK.SymbolField.Ticker)] = symbol;
                setCachedSymbols((prev) => ({ ...prev, ...newSymbol }));
              },
              itemChanged: (symbol, index) => {
                console.log("Symbol updated: ", symbol.get(InfrontSDK.SymbolField.Ticker), index);
              },
            };
            symbolList.observe(binding);
          },
        })
      );
    };

    delayedPricing();

    return function cleanup() {
      unsubscribe();
    };
  }, [isReady]);

  const lastPrice = cachedSymbols[stockSymbol]?.get(InfrontSDK?.SymbolField.Last);

  return (
    <>
      <Div className="card">
        <PrivateCard to={`/productmanager/edit/${id}`} roles={["Products.Create"]}>
          <div className="card_image__container">
            <div className="cover">     
              <img src={cover ?? thumbnail} alt="" />
            </div>
            {lastPrice && <div className="card_price"> € {(((lastPrice + Number.EPSILON) * 100) / 100).toFixed(2)}</div>}
          </div>
          <div className="card__content">
            <div className="card__content__header">
              <div className="card__title">{title}</div>
              { totalFollowers&&
                <div className="card__subtitle">
                  {totalFollowers} 
                  <Followers />
                </div>
              }
            </div>
            <div className="card__content__body">
              <p>{subtitle}</p>
            </div>
            <div className="card__content__footer">Last edited on: {FormatDate(updatedTimeUtc)}</div>
          </div>
        </PrivateCard>
      </Div>
    </>
  );
};

ProductCard.defaultProps = {
  cover: thumbnail,
  title: "title",
  subtitle: "subtitle",
  updatedTimeUtc: "01-01-1900",
};

export default ProductCard;
