import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import Loader from "components/UI/Loader";
import Button from "components/UI/Buttons/Button";
import Image from "components/UI/Images/Image";
import { getTimeZones } from "api/services/event";
import { getOrganization } from "api/services/organization";
import MDEditor from "components/UI/Blocks/MDEditor";
import { UseErrorContext, UseOrganizationContext } from "contexts";
import { useTranslation } from "react-i18next";
import { resizeImage } from "utils";
import { EventCategoryImages } from "constants";
import SwitchButtonOn from "components/UI/Buttons/SwitchButtonOn";
import PR_cover from "assets/press-release.jpg";

const FormWrapper = styled.div`
  .top-field {
    display: flex;
    gap: 4rem;
    .actions {
      display: flex;
      gap: 2rem;
      button {
        align-self: baseline;
      }
    }
  }
  .enable-end-date{
    display: flex;
    align-items: center;
    gap: 1.2rem;
  }
  .cover__buttons {
    gap: 2.4rem;
  }
`;

const ValidationSchema = Yup.object().shape({
  title: Yup.string().required("Required").max(100),
  description: Yup.string().required("Required").max(10000),
  // location: Yup.string().required("Required"),
  startDate: Yup.date().required("Required"),
  endDate: Yup.date()
  // timeZone: Yup.string().required("Required"),
});

export const PressReleaseForm = ({ event, category, docData, children, isReadOnly, nextStep, backStep }) => {
  const { id } = UseOrganizationContext();
  const [coverFile, setCoverFile] = useState(event?.coverUrl);
  const [cover, setCover] = useState(event?.cover);
  const [uploadingCover, setUploadingCover] = useState(false);
  const [timeZones, setTimeZones] = useState([]);
  const [profileAddress, setProfileAddress] = useState("");
  const [enableEndDate, setEnableEndDate] = useState(false);
  const { updateError } = UseErrorContext();

  const { t } = useTranslation();

  const fetchImageAsBlob = async (url) => {
    const response = await fetch(url,{ mode: 'no-cors' } );
    const blob = await response.blob();
    return blob;
  };

  const blobToFile = (blob, fileName) => {
    return new File([blob], fileName, { type: blob.type });
  };

  // Init data
  useEffect(async () => {
    try {
      var tz = await getTimeZones();
      setTimeZones(tz);

      let organizationData = await getOrganization(id);

      if (!event) {
        loadCategoryCover();
        //setCover(organizationData?.logoUrl);
        //setCoverFile(organizationData?.logoUrl);
        if (organizationData?.addresses.length > 0) {
          const { addressLine1, addressLine2, addressLine3, postalCode, city, countryName } = organizationData?.addresses[0]?.address;
          setProfileAddress(`${addressLine1} ${addressLine2 || ""} ${addressLine3 || ""}, ${postalCode} ${city}, ${countryName}`);
        }
      }
    } catch (err) {
      updateError(err);
    }
  }, []);

  const loadLogo = async () => {
    let organizationData = await getOrganization(id);
    setCover(organizationData?.logoUrl);
    setCoverFile(null);
  };

  const loadCategoryCover = async () => {
    const blob = await fetchImageAsBlob(EventCategoryImages[category] || PR_cover);
    const file = blobToFile(blob);
    setCover(EventCategoryImages[category]);
    setCoverFile(file);
    previewImage(file);
  };

  const previewImage = async (e) => {
    setUploadingCover(true);
    let selectedFile = e?.target?.files[0] || e;

    let fileSizeInMegabytes = selectedFile.size / (1024 * 1024);

    if (fileSizeInMegabytes > 10) {
      updateError({ message: t("Image should be smaller then 10mb.") });
      setUploadingCover(false);
      return;
    }

    const resizedImage = await resizeImage(selectedFile, 1250);

    setCoverFile(resizedImage);

    let reader = new FileReader();
    reader.onload = function (event) {
      setCover(event.target.result);
    };

    reader.readAsDataURL(selectedFile);
    setUploadingCover(false);
  };

  return (
    <FormWrapper>
      <Formik
        enableReinitialize={true}
        initialValues={{
          title: event?.title,
          description: event?.description,
          location: event?.location || profileAddress,
          startDate: event?.startDate,
          endDate: event?.endDate,
          isPublic: event?.isPublic ?? true,
          timeZone: event?.timeZone ?? Intl.DateTimeFormat().resolvedOptions().timeZone,
          file: docData || null,
        }}
        validationSchema={ValidationSchema}
        onSubmit={(values, { setSubmitting }) => {
          values.coverUrl = coverFile;
          values.cover = cover;
          if(!enableEndDate){
            values.endDate = values.startDate
          }
          nextStep(values);
          setSubmitting(false);
        }}
      >
        {({ isSubmitting, handleSubmit, setFieldValue, values }) => (
          <Form>
            <label className="label">Cover</label>
            <div className="form_row">
              <div className="field top-field">
                <div className="cover-image">
                  <Image url={cover} onChange={previewImage} isLoading={uploadingCover} />
                </div>
                <div className="actions">{children}</div>
              </div>
            </div>
            <div className="cover__buttons form_row">
              <Button label="Use category cover" onClick={() => loadCategoryCover()} type="secondary" />
              <Button label="Use logo" onClick={() => loadLogo()} type="secondary" />
            </div>
            <div className="form_row">
              <div className="field">
                <label htmlFor="title" className="required">
                  Title
                </label>
                <Field type="text" name="title" />
                <ErrorMessage className="error-message" name="title" component="div" />
              </div>
            </div>
            <div className="form_row">
              <div className="field">
                <label htmlFor="location" className="required">
                  Location
                </label>
                <Field type="text" name="location" />
                <ErrorMessage className="error-message" name="location" component="div" />
              </div>
            </div>
            <div className="form_row">
              <div className="field">
                <label htmlFor="description" className="required">
                  Description
                </label>
                <MDEditor value={values.description} setFieldValue={(val) => setFieldValue("description", val)} />
                <ErrorMessage className="error-message" name="description" component="div" />
              </div>
            </div>
            <div className="form_row enable-end-date">
                <label>Enable start and end date</label> <SwitchButtonOn onSwitchOff={()=>setEnableEndDate(false)} onSwitchOn={()=>setEnableEndDate(true)} forceState on={enableEndDate} />
              </div>
              <div className="form_row">
                {enableEndDate ? (
                  <>
                    <div className="field">
                      <label htmlFor="startDate" className="required">
                        Start date
                      </label>
                      <Field type="datetime-local" name="startDate" className="date" max="9999-01-01T00:00" />
                      <ErrorMessage className="error-message" name="startDate" component="div" />
                    </div>
                    <div className="field">
                      <label htmlFor="endDate" className="required">End date</label>
                      <Field type="datetime-local" name="endDate" className="date" max="9999-01-01T00:00" />
                      <ErrorMessage className="error-message" name="endDate" component="div" />
                    </div>
                  </>
                ) : (
                  <>
                  <div className="field">
                    <label htmlFor="startDate" className="required">
                      Date
                    </label>
                    <Field type="datetime-local" name="startDate" className="date" max="9999-01-01T00:00" />
                    <ErrorMessage className="error-message" name="startDate" component="div" />
                  </div>
                </>
                )}
              <div className="field">
                <label htmlFor="timeZone" className="required">
                  Timezone
                </label>
                <Field as="select" name="timeZone">
                  {timeZones?.map((timeZone, idx) => (
                    <option value={timeZone} key={idx}>
                      {timeZone}
                    </option>
                  ))}
                </Field>
                <ErrorMessage className="error-message" name="timeZone" component="div" />
              </div>
            </div>
            {!isSubmitting ? (
              <div className="buttons">
                <Button label="cancel" onClick={() => backStep()} type="secondary" />
                <Button onClick={handleSubmit} label="Next" type="submit" disabled={isSubmitting || isReadOnly} />
              </div>
            ) : (
              <Loader />
            )}
          </Form>
        )}
      </Formik>
    </FormWrapper>
  );
};
