import React, { useState, useEffect } from "react";
import PageHeaderSub from "../../components/PageHeaderSub";
import Page from "../../components/UI/Elements/Page";
import HeaderDropDown from "../../components/UI/HeaderDropDown";
import LinkGrid from "./Components/LinkGrid";
import { getOrganization } from "api/services/organization";
import { UseOrganizationContext } from "contexts";
import { useParams, useHistory } from "react-router-dom";
import { BackButton } from "components/UI/Buttons/BackButton";
import DocumentGrid from "./Components/DocumentGrid";
import { UseErrorContext } from "contexts";
import { useTranslation } from 'react-i18next';

const ViewOrganizationDocumentsLinks = () => {
  const [links, setLinks] = useState([]);
  const [documents, setDocuments] = useState([]);
  const { id } = useParams();
  const history = useHistory();
  const { updateError } = UseErrorContext();
  const { t, i18n } = useTranslation();
  
  useEffect(async () => {
    refresh();
  }, []);

  const refresh = async () => {
    try{
      if (id) {
        let organizationData = await getOrganization(id);
        var links = organizationData.links;
        setLinks(links);

        var documents = organizationData.documents;
        setDocuments(documents);
      }
    } catch (err) {
      updateError(err);
    }
  };

  return (
    <Page>
      <div className="navigation-banner">
        <BackButton link={"/organizations/view/" + id}/>
      </div>

      <div className="page__header">
        <PageHeaderSub />
      </div>
      <div className="page__inner">
        <HeaderDropDown titleHeader={t('documents')}>
           <DocumentGrid documents={documents} refresh={refresh} isReadOnly/> 
         </HeaderDropDown>
        <HeaderDropDown titleHeader={t('links')}>
          <LinkGrid links={links} refresh={refresh} isReadOnly/>
        </HeaderDropDown>
      </div>
    </Page>
  );
};

export default ViewOrganizationDocumentsLinks;
