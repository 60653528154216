import React, { useState, useEffect } from "react";
import styled from "styled-components";
import * as DOMPurify from "dompurify";
import { Interweave } from "interweave";
import { checkIfStringIsJson } from "utils";
import draftToHtml from "draftjs-to-html";

const HTMLRenderViewContainer = styled.div`
  strong {
    font-weight: 500;
  }
  p {
    margin-bottom: 1.2rem;
    min-height: 1px;
  }
  em {
    font-style: italic;
  }
  a {
    color: var(--color-accent-prim);
    cursor: pointer;
    font-family: "nunito";
  }
`;

const HTMLRenderView = ({ content }) => {
  const isJson = checkIfStringIsJson(content);
  const parsedContent = isJson ? draftToHtml(JSON.parse(content)) : content;
  return (
    <HTMLRenderViewContainer>
      {isJson ? (
        <Interweave content={DOMPurify.sanitize(parsedContent)} />
      ) : (
        <p>
          <Interweave content={DOMPurify.sanitize(parsedContent)} />
        </p>
      )}
    </HTMLRenderViewContainer>
  );
};

export default HTMLRenderView;
