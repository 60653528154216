import { getAxiosClient } from "../axios";

export const getCommunicationGroups = async (id) => {
  const axiosClient = await getAxiosClient();

  return await axiosClient
    .get(`/organizations/${id}/communicationgroups`)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      console.log("error", error);
      return Promise.reject(error);
    });
};

export const addCommunicationGroup = async (id, communicationgroup) => {
  const axiosClient = await getAxiosClient();

  return await axiosClient
    .post(`/organizations/${id}/communicationgroups`, communicationgroup)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      console.log("error", error);
      return Promise.reject(error);
    });
};

export const updateCommunicationGroup = async (communicationgroup) => {
  const axiosClient = await getAxiosClient();
  
  return await axiosClient
    .put(`/communicationgroups/${communicationgroup.id}`, communicationgroup)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      console.log("error", error);
      return Promise.reject(error);
    });
};

export const removeCommunicationGroup = async (id) => {
  const axiosClient = await getAxiosClient();

  return await axiosClient
    .delete(`/communicationgroups/${id}`)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      console.log("error", error);
      return Promise.reject(error);
    });
};

export const previewUsers = async (id, communicationgroup, parameters) => {
  const axiosClient = await getAxiosClient();

  return await axiosClient
    .post(`/organizations/${id}/communicationgroups/previews`, communicationgroup, { params: {...parameters}})
    .then((response) => {
      return response;
    })
    .catch((error) => {
      console.log("error", error);
      return Promise.reject(error);
    });
};

export const getCommunicationGroupUsers = async (id, parameters) => {
  const axiosClient = await getAxiosClient();

  return await axiosClient
    .get(`/communicationgroups/${id}/users`, { params: {...parameters}})
    .then((response) => {
      return response;
    })
    .catch((error) => {
      console.log("error", error);
      return Promise.reject(error);
    });
};

export const getCountries = async () => {
  const axiosClient = await getAxiosClient();

  return await axiosClient
    .get(`/countries`)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      console.log("error", error);
      return Promise.reject(error);
    });
};

