import React, { useState, useEffect } from "react";
import PageHeaderSub from "../../components/PageHeaderSub";
import Page from "components/UI/Elements/Page";
import Button from "components/UI/Buttons/Button";
import Modal from "components/UI/Modal";
import { MailBuilderForm } from "./Components/MailBuilderForm";
import PostGrid from "./Components/PostGrid";
import Pagination from "components/Pagination";
import { UseErrorContext } from "contexts";
import { Link } from "react-router-dom";
import SearchBar from "components/UI/SearchBar";
import HeaderDropDown from "components/UI/HeaderDropDown";
import { getPosts } from "api/services/communication";
import { delay } from "api/helpers/datahelper";
import { GetPagination } from "api/helpers/datahelper";
import { UseOrganizationContext } from "contexts";
import { FormatLocalToUtcDateTime } from "api/helpers/datahelper";

const Posts = () => {
  const [showModal, setShowModal] = useState(false);
  const [posts, setPosts] = useState([]);
  const [search, setSearch] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [numberOfPages, setNumberOfPages] = useState(1);
  const [loading, setLoading] = useState(false);
  const [statusFilter, setStatusFilter] = useState(null);
  const [fromDateFilter, setFromDateFilter] = useState(null);
  const [toDateFilter, setToDateFilter] = useState(null);
  const [statusFilters, setStatusFilters] = useState(["Draft", "Published"]);
  const { updateError } = UseErrorContext();
  const organizationId = UseOrganizationContext()?.id;

useEffect(async () => {
  refresh();
}, [search, currentPage, statusFilter, fromDateFilter, toDateFilter]);

// Rerfesh data
const refresh = async () => {  
  try{ 
    setLoading(true);

    var params = {
      'page': currentPage,
      'search': search,
      'from': fromDateFilter ? FormatLocalToUtcDateTime(fromDateFilter) : null,
      'to': toDateFilter ? FormatLocalToUtcDateTime(toDateFilter) : null,
      'status': statusFilter,
    }

    const response = await getPosts(organizationId, params);  

    let data = response.data;
    let header = response.headers;
      
    let pagination = GetPagination(header);
    let totalCount = pagination.totalCount;
    let pageSize = pagination.pageSize;
    let nbrOfPages = Math.ceil(totalCount / pageSize);
        
    setNumberOfPages(nbrOfPages);

    setPosts(data); 
  } catch (err) {
    updateError(err);
  }
  finally{
    setLoading(false);
  }
}

  // Search
  const onSearch = async (e) => {
    await delay(1000);
    setCurrentPage(1);
    const val = e.target.value.toString();
    setSearch(val);
  };

  // Filters
  const onChangeStatusFilter = async (e) => {
    setCurrentPage(1);
    const val = e.target.value.toString();
    setStatusFilter(val);
  };

  const onChangeFromDateFilter = async(e) => {
    setCurrentPage(1);
    const val = e.target.value.toString();
    setFromDateFilter(val);
  };


  const onChangeToDateFilter = async(e) => {
    setCurrentPage(1);
    const val = e.target.value.toString();
    setToDateFilter(val);
  };

  return (
      <>
      {showModal && (
        <Modal title="Add post" onClose={() => setShowModal(false)} hideActions>
          <MailBuilderForm selectedMails={selectedMails} onClose={() => {setShowModal(false);}} />        
        </Modal>
      )}
      <Page>
        <div className="page__header">
          <h1>Posts</h1>
          <PageHeaderSub show>
            <Link to="posts/add">
              <Button label="Add post" roles={["Posts.Create"]}/>
            </Link> 
          </PageHeaderSub>
        </div>
        <div className="page__inner">
          <div className="searchBarContainer">
            <SearchBar onChange={onSearch}/>
          </div>
          <div className="filters">
                <div className="row">
                  <div>
                   <label>Status</label>
                      <select onChange={onChangeStatusFilter} value={statusFilter}>
                        <option value="">All</option>
                        {statusFilters.map((filter, idx) => (                 
                            <option key={idx} value={filter}>{filter}</option>
                        ))}
                      </select>
                  </div>
                  <div>
                    <label>From: </label>
                    <input type="datetime-local" onChange={onChangeFromDateFilter} value={fromDateFilter}/>
                  </div>
                  <div>
                    <label>To: </label>
                    <input type="datetime-local" onChange={onChangeToDateFilter} value={toDateFilter}/>
                  </div>
                </div>
          </div>
          <HeaderDropDown titleHeader="">
            <PostGrid posts={posts} refresh={refresh}/>
            <Pagination numberOfPages={numberOfPages} currentPage={currentPage} setCurrentPage={setCurrentPage} />
          </HeaderDropDown>
        </div>
      </Page>
      </>
  );
}

export default Posts;