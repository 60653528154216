import styled from "styled-components";
import React, { useState, useEffect } from "react";
import { ValidateAccess } from "api/services/authorization";
import { UseUserContext } from "contexts";

const B = styled.button`
  border: none;
  background-color: transparent;
  display: flex;
  align-items: center;
  justify-content: center;
  :hover {
    opacity: 0.8;
  }
  .icon{
    margin: 0 0.8rem;
  }
  .label{
    color: var(--color-light-grey);
  }
`;

export default function Button({ _onClick, label, roles }) {
  const [hide, setHide] = useState(true);
  const { permissions } = UseUserContext();

  useEffect(async () => {
    const access = ValidateAccess(roles, permissions);
    setHide(!access);
  }, [permissions]);
  
  return (
    <>
    { !hide &&
      <B onClick={_onClick}>
        <svg className="icon"
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <circle cx="12" cy="12" r="12" fill="var(--color-accent-prim)" />
          <rect
            x="10.8572"
            y="4.57141"
            width="2.28571"
            height="14.8571"
            rx="1.14286"
            fill="#FFF"
          />
          <rect
            x="19.4285"
            y="10.8571"
            width="2.28571"
            height="14.8571"
            rx="1.14286"
            transform="rotate(90 19.4285 10.8571)"
            fill="#FFF"
          />
        </svg>
        <span className="label">{label}</span>
      </B>      
    }  
    </>
  );
}

Button.defaultProps = {
  _onClick: () => console.log("default click")
};
