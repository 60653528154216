import Events from "./events";
import AddEvent from "./add-event";
import EditEvent from "./edit-event";
import Translations from "./translations";
import DocumentsAndLinks from "./documents-and-links";
import EventSections from "./eventsections";
import EventSectionTranslations from "./eventsectiontranslations";
import FollowedEvents from "./followed-events";
import ViewFollowedEvents from "./view-followed-events";
import ViewDocumentsAndLinks from "./view-documents-and-links"
import FollowedEventsCalendar from "./followed-events-calendar";
import RelatedEvents from "./related-events";

export default {Events, AddEvent, EditEvent, Translations, DocumentsAndLinks, EventSections, EventSectionTranslations, FollowedEvents, ViewFollowedEvents, ViewDocumentsAndLinks, FollowedEventsCalendar, RelatedEvents};


