import React, { useState, useEffect } from "react";
import PageHeaderSub from "../../components/PageHeaderSub";
import Page from "../../components/UI/Elements/Page";
import { useParams } from "react-router-dom";
import HeaderDropDown from "../../components/UI/HeaderDropDown";
import DocumentGrid from "./Components/DocumentGrid"
import LinkGrid from "./Components/LinkGrid";
import { getFollowedEvent } from "api/services/event";
import { BackButton } from "components/UI/Buttons/BackButton";
import { UseErrorContext } from "contexts";
import { useTranslation } from 'react-i18next';

const Documents = () => {
  const { id } = useParams();
  const [event, setEvent] = useState(null);
  const [documents, setDocuments] = useState([]);
  const [links, setLinks] = useState([]);
  const { updateError } = UseErrorContext();
  const { t, i18n } = useTranslation();

  useEffect(async () => {    
    refresh();
  }, []);

  const refresh = async () => {  
    try{ 
      const eventData = await getFollowedEvent(id);
      setEvent(eventData)

      var docs = eventData.documents;
      setDocuments(docs);

      var links = eventData.links;
      setLinks(links);
    } catch (err) {
      updateError(err);
    }
  };

  return (
    <Page>  
      <div className="navigation-banner">
        <BackButton link={"/followed-events/view/" + id}/>
        <ul className="breadcrumbs">
          <li>
            <a href="/followed-events">{t('actions')}</a>
            </li>
          <li>
            <a href={"/followed-events/view/" + id}>{event?.title}</a>
            </li>
          <li>            
            <span className="selected">{t('documentsandlinks')}</span>
          </li>
        </ul> 
      </div>

      <div className="page__header">
        <h1></h1>
        <PageHeaderSub />
      </div>
      <div className="page__inner">
        <HeaderDropDown titleHeader={t('documents')}>
           <DocumentGrid eventId={id} documents={documents} refresh={refresh} isReadOnly={true}/> 
         </HeaderDropDown>
        <HeaderDropDown titleHeader={t('links')}>
           <LinkGrid links={links} refresh={refresh} isReadOnly={true}/> 
         </HeaderDropDown>
      </div>
    </Page>
  );
};

export default Documents;
