import React, {useContext} from "react";
import styled from "styled-components";

const Container = styled.div`
 .keyword {
    margin-left: 0.4rem;
    margin-top: 1rem;
    padding: 0.1rem 0.4rem;
    border: 1px solid rgba(255, 255, 255, 0.6);
    border-radius: 0.8rem;
  }   
`;

const QuestionInfo = ({ question }) => {
console.log(question)
  return (
    <>
      <Container>
        <div className="form">
          <div className="form_row">
            <div className="field">
              <label htmlFor="mails">Event:</label>                 
              <span style={{fontStyle:"italic"}}><a href={"/followed-events/view/" + question.event.id}>{question.event.name}</a></span>
            </div>
          </div>
          <div className="form_row">
            <div className="field">
              <label htmlFor="message">Question:</label>
              <textarea value={question.question} disabled/>
            </div>
          </div>
          <div className="form_row">
            <div className="field">
              <label htmlFor="message">Created on:</label>
              <input value={question.createdTime} disabled/>
            </div>
          </div>
          <div className="form_row">
            <div className="field">
              <label htmlFor="message">Category:</label>
              <input value={question.category} disabled/>
            </div>
          </div>
          <div className="form_row">
            <div className="field">
              <label htmlFor="message">Keywords:</label>
              {question.keywords.map((keyword, idx) => (
                <span className="keyword">{keyword}</span>
              ))}
            </div>
          </div>
          <div className="form_row">
            <div className="field">
              <label htmlFor="message">Answer:</label>
              <textarea value={question.answer?.message} disabled/>
            </div>
          </div>
        </div>
      </Container>
    </>
  );
};

export default QuestionInfo;
