import React, { useState, useEffect } from "react";
import HeaderDropDown from "components/UI/HeaderDropDown";
import PageHeaderSub from "components/PageHeaderSub";
import Page from "components/UI/Elements/Page";
import FollowedProductGrid from "pages/products/Components/FollowedProductGrid";
import Modal from "components/UI/Modal";
import SearchBar from "../../components/UI/SearchBar";
import Pagination from "components/Pagination";
import { GetNrOfPages } from "api/helpers/datahelper";
import { getAllProducts } from "../../api/services/product";
import { delay } from "api/helpers/datahelper";
import { UseErrorContext } from "contexts";
import { FollowedProductForm } from "./Components/FollowedProductForm";
import { Link } from "react-router-dom";
import Button from "components/UI/Buttons/Button";

const Products = () => {
  const [showModal, setShowModal] = useState(false);
  const [search, setSearch] = useState("");
  const [products, setProducts] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [numberOfPages, setNumberOfPages] = useState(1);
  const { updateError } = UseErrorContext();

  useEffect(async () => {
    refresh();
  }, [search, currentPage]);

  const refresh = async () => {  
    try{ 
      const productsData = await getAllProducts({
        'search': search,
        'page': currentPage,
      });

      let nbrOfPages = GetNrOfPages(productsData.headers);
      setNumberOfPages(nbrOfPages);
      setProducts(productsData.data);
    } catch (err) {
      updateError(err);
    }
  };

  // Search
  const onSearch = async (e) => {
    await delay(1000);
    const val = e.target.value.toString();
    setCurrentPage(1);
    setSearch(val);
  };

  return (
    <>
      {showModal && (
        <Modal title="Follow new share" hideActions>
           <FollowedProductForm onClose={() => {setShowModal(false); refresh();}} followedProducts={products}/>
        </Modal>
      )}
      <Page>
        <div className="page__header">
          <h1>DISCOVER SHARES</h1>
          <PageHeaderSub show>
            <Link to={`/products`}>
              <Button label="Followed"/>
            </Link>
          </PageHeaderSub>
        </div>
        <div className="page__inner">
          <div className="searchBarContainer">
            <SearchBar onChange={onSearch}/>
          </div>
          <HeaderDropDown titleHeader="">
            <FollowedProductGrid products={products} refresh={refresh} link="/products/view/"/>
            <Pagination numberOfPages={numberOfPages} currentPage={currentPage} setCurrentPage={setCurrentPage} />
          </HeaderDropDown>
        </div>
      </Page>
    </>
  );
};

export default Products;
