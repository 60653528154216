import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { device } from "components/styles/GlobalStyle";
import HeaderDropDown from "../components/UI/HeaderDropDown";
import PageHeaderSub from "../components/PageHeaderSub";
import Page from "../components/UI/Elements/Page";
import { UseOrganizationContext, UseUserContext } from "contexts";
import { getFollowedProducts } from "api/services/product";
import { getFollowedEvents } from "api/services/event";
import moment from "moment";
import NewsGrid from "components/UI/Blocks/News/Newsgrid";
import { UseErrorContext } from "contexts";
import FollowedProductGrid from "./products/Components/FollowedProductGrid";
import FollowedEventGrid from "./events/Components/FollowedEventGrid";
import FollowedOrganizationGrid from "./profile/Components/FollowedOrganizationGrid";
import { getFollowedOrganizations } from "api/services/organization";
import Discover from "components/UI/Icons/Discover";
import { Link } from "react-router-dom";
import thumbnail from "../assets/banner.jpg";
import MyPosts from "./communication/my-posts";
import { useTranslation } from "react-i18next";

const Container = styled.div`
  padding-bottom: 2rem;
  .dashboard_row {
    max-width: 120rem;
    margin: 0 auto;
    padding: 0 2rem;
    @media ${device.laptop} {
      display: flex;
      gap: 2rem;
    }
  }
  .page__header {
    position: relative;
    padding-top: 16%;
    padding-bottom: 2rem;
    background: black;
    box-shadow: 5px 6px 12px -5px black;
    h1 {
      position: relative;
      z-index: 1;
      margin-left: 2rem;
    }
    .background-image {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-position: center;
      background-size: cover;
      opacity: 0.4;
    }
  }
  .disclosure-record {
    margin-bottom: 2rem;
  }
`;

const DashboardFollowers = () => {
  const [followedProducts, setFollowedProducts] = useState([]);
  const [followedOrganizations, setFollowedOrganizations] = useState([]);
  const [followedEvents, setFollowedEvents] = useState([]);
  const [followedPastEvents, setFollowedPastEvents] = useState([]);
  const { permissions = [], userName = "" } = UseUserContext();
  const { coverUrl } = UseOrganizationContext();
  const { updateError } = UseErrorContext();
  const { t, i18n } = useTranslation();

  useEffect(async () => {
    if (!userName) return;

    await loadFollowedProducts();
    await loadFollowedEvents();
    await loadFollowedOrganizations();
  }, [userName]);

  const loadFollowedOrganizations = async () => {
    try {
      const orgData = await getFollowedOrganizations({
        pageSize: 6,
      });
      setFollowedOrganizations(orgData.data);
    } catch (err) {
      updateError(err);
    }
  };

  const loadFollowedProducts = async () => {
    try {
      const productsData = await getFollowedProducts({
        pageSize: 6,
      });
      setFollowedProducts(productsData.data);
    } catch (err) {
      updateError(err);
    }
  };

  const loadFollowedEvents = async () => {
    try {
      const eventsData = await getFollowedEvents({
        pageSize: 6,
        from: moment().startOf("day").format("YYYY-MM-DD"),
        order: "ASC",
      });

      const pastEventsData = await getFollowedEvents({
        pageSize: 6,
        to: moment().startOf("day").format("YYYY-MM-DD"),
        order: "DESC",
      });
      setFollowedEvents(eventsData);
      setFollowedPastEvents(pastEventsData);
    } catch (err) {
      updateError(err);
    }
  };

  return (
    <Page fullWidth={true}>
      <div className="page__inner">
        <Container>
          <div className="page__header">
            <h1>{t("dashboard")}</h1>
            <PageHeaderSub />
            <div className="background-image" style={{ backgroundImage: "url('" + thumbnail + "')" }}></div>
          </div>
          <div className="dashboard_row">
            <HeaderDropDown containerStyle={{ width: "calc(50% - 1rem)" }} titleHeader="Feed" bodyStyle={{ maxHeight: "60vh", minHeight: "60vh" }}>
              <MyPosts hideTitle />
            </HeaderDropDown>

            <HeaderDropDown
              containerStyle={{ width: "calc(50% - 1rem)" }}
              bodyStyle={{ maxHeight: "60vh", minHeight: "60vh" }}
              titleHeader={t("followedshares")}
              link="/products"
              extraOption={
                <Link to="/products">
                  <Discover />
                </Link>
              }
            >
              <FollowedProductGrid small canEdit={false} products={followedProducts} refresh={loadFollowedProducts} direction={"column"} link="/products/view/" />
            </HeaderDropDown>
          </div>
          <div className="dashboard_row">
            <HeaderDropDown titleHeader={t("upcomingactions")} bodyStyle={{ maxHeight: "45vh", minHeight: "45vh", overflowX: "hidden" }} link="/followed-events">
              <FollowedEventGrid canEdit={false} events={followedEvents} refresh={loadFollowedEvents} direction={"column"} link={"/followed-events/view/"} />
            </HeaderDropDown>
            <HeaderDropDown titleHeader={t("pastactions")} bodyStyle={{ maxHeight: "45vh", minHeight: "45vh", overflowX: "hidden" }} link="/followed-events">
              <FollowedEventGrid canEdit={false} events={followedPastEvents} refresh={loadFollowedEvents} direction={"column"} link={"/followed-events/view/"} />
            </HeaderDropDown>
          </div>
          <div className="dashboard_row">
            <HeaderDropDown
              titleHeader={t("followedcompanies")}
              link="/organizations"
              extraOption={
                <Link to="/organizations">
                  <Discover />
                </Link>
              }
            >
              <FollowedOrganizationGrid canEdit={false} organizations={followedOrganizations} refresh={loadFollowedOrganizations} />
            </HeaderDropDown>
          </div>
          <div className="dashboard_row">
            <HeaderDropDown titleHeader={t("latestnews")} bodyStyle={{ maxHeight: "45vh", minHeight: "45vh" }}>
              {<NewsGrid direction={"column"} />}
            </HeaderDropDown>
          </div>
        </Container>
      </div>
    </Page>
  );
};

export default DashboardFollowers;
