import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import styled from "styled-components";
import AddButton from "../../../components/UI/Buttons/AddButton";
import Modal from "components/UI/Modal";
import Trash from "components/UI/Icons/Trash";
import { LinkForm } from "./LinkForm";
import { DeleteOrganizationLink } from "api/services/organization";
import { UseOrganizationContext } from "contexts";
import { UseErrorContext } from "contexts";

const Container = styled.div`
  overflow: auto;
  table {
    width:100%;
    min-width: 100rem;
  }
`;

const LinkGrid = ( { links, refresh, isReadOnly } ) => {
  const [showModal, setShowModal] = useState(false);
  const organizationId = UseOrganizationContext()?.id;
  const [selectedLink, setSelectedLink] = useState(null);
  const [showModalDelete, setShowModalDelete] = useState(false);
  const { updateError } = UseErrorContext();
  
  const _addLink = () => {
    setShowModal(true);
  };
  
  const showDeleteModal = async (link) => {
    setSelectedLink(link);
    setShowModalDelete(true);
  };

  const _delete = async () => {
    try{
      await DeleteOrganizationLink(organizationId, selectedLink.id);
      setShowModalDelete(false);
      refresh();
    } catch (err) {
      updateError(err);
    }
  };

  return (
    <Container>
      {showModalDelete && (
        <Modal title={`Delete "${selectedLink.link}"`} onClose={() => setShowModalDelete(false)} onSubmit={() => _delete()} cancelLabel={"Cancel"} submitLabel={"Delete"}>
          <div>Are you sure you want to delete this link?</div>
        </Modal>
      )}
      {showModal && (
        <Modal title="Link" hideActions>
          <LinkForm onClose={() => setShowModal(false)} />
        </Modal>
      )}
      <table cellSpacing="500">
        <thead>
          <tr>
            <th></th>
            <th>Type</th>
            <th>URL</th>
            <th></th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {links?.map((link, idx) => (
            <tr key={idx}>
              <td></td>
              <td>{link.type}</td>
              <td><a href={link.link} target="_blank">{link.link}</a></td>
              <td>
                {!isReadOnly && (
                  <div onClick={() => showDeleteModal(link)} className="icon-button">
                    <Trash />
                  </div>
                )}
              </td>          
            </tr>
          ))}
        </tbody>
      </table>
      {!isReadOnly && (
        <div onClick={() => _addLink()} className="grid-add-btn">
          <AddButton label="Add link"/>
        </div>
      )}
    </Container>
  );
};

LinkGrid.defaultProps = {
  links: Array.from(Array(0)),
};

export default LinkGrid;
