import React, { useState, useEffect } from "react";
import PageHeaderSub from "../../components/PageHeaderSub";
import Page from "../../components/UI/Elements/Page";
import { useParams } from "react-router-dom";
import HeaderDropDown from "../../components/UI/HeaderDropDown";
import TranslationGrid from "components/TranslationGrid";
import { getEvent } from "api/services/event";
import { BackButton } from "components/UI/Buttons/BackButton";
import { UseErrorContext } from "contexts";
import { addTranslation, updateTranslation, removeTranslation } from "api/services/event";

const Translations = () => {
  const { id } = useParams();
  const [event, setEvent] = useState(null);
  const [translations, setTranslations] = useState([]);
  const { updateError } = UseErrorContext();

  useEffect(async () => {
    refresh();
  }, []);

  const refresh = async () => {
    try {
      const eventData = await getEvent(id);
      setEvent(eventData);
      var trans = eventData.translations.filter((item) => !item.isDefault);
      setTranslations(trans);
    } catch (err) {
      updateError(err);
    }
  };

  const add = async (values) => {
    try {
      const resp = await addTranslation(id, values);
      refresh();
      return resp;
    } catch (err) {
      updateError(err);
    }
  };

  const edit = async (translationId, values) => {
    try {
      delete values.id;
      delete values.languageCode;
      delete values.isDefault;
      const resp = await updateTranslation(id, translationId, values);
      refresh();
      return resp;
    } catch (err) {
      updateError(err);
    }
  };

  const remove = async (translationId) => {
    try {
      await removeTranslation(id, translationId);
      refresh();
    } catch (err) {
      updateError(err);
    }
  };

  return (
    <Page>
      <div className="navigation-banner">
        <BackButton link={"/events/edit/" + id} />
        <ul className="breadcrumbs">
          <li>
            <a href="/events">Actions</a>
          </li>
          <li>
            <a href={"/events/edit/" + id}>{event?.title}</a>
          </li>
          <li>
            <span className="selected">Translations</span>
          </li>
        </ul>
      </div>

      <div className="page__header">
        <h1>Translations event</h1>
        <PageHeaderSub />
      </div>
      <div className="page__inner">
          {event && <TranslationGrid type="action" fields={["title", "description"]} item={event} translations={translations} remove={remove} add={add} edit={edit} />}
      </div>
    </Page>
  );
};

export default Translations;
