import React, { useState, useEffect } from "react";
import styled from "styled-components";
import Trash from "components/UI/Icons/Trash";
import Edit from "components/UI/Icons/Edit";
import Modal from "components/UI/Modal";
import { UseErrorContext, UseUserContext } from "contexts";
import { FormatDateTimeDisplay, htmlToRawText } from "api/helpers/datahelper";
import Status from "components/UI/Status";
import Attachment from "components/UI/Icons/Attachment";
import Button from "components/UI/Buttons/Button";
import { ValidateAccess } from "api/services/authorization";
import { PostForm } from "./PostForm";
import { approvePost, deletePost, publishPost } from "api/services/communication";
import draftToHtml from "draftjs-to-html";
import DocumentGrid from "./DocumentGrid";
import HTMLRenderView from "components/UI/Blocks/HTMLRenderView";
import { MAPSTATUSPOSTS } from "constants";
import Loader from "components/UI/Loader";
import { Interweave } from "interweave";
import * as DOMPurify from "dompurify";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const Container = styled.div`
  overflow: auto;
  .post {
    background-color: var(--color-black);
    max-width: 70rem;
    margin: 0 auto;
    margin-bottom: 2.4rem;
    padding: 2.4rem;
    border-radius: 0.8rem;
    box-shadow: black 5px 6px 12px -5px;
    &__body {
      display: flex;
      justify-content: space-between;
      &__left {
        width: 70%;
        &__content {
          color: var(--color-light-grey);
          & > * {
            margin-top: 1rem;
          }
          a {
            text-decoration: underline;
            color: var(--color-accent-prim);
          }
          strong {
            font-weight: 500;
          }
          ul {
            list-style: disc;
            margin: 1.2rem 1.2rem;
            li {
              margin: 0.6rem;
              ul {
                margin-top: 0;
                list-style: circle;
              }
            }
          }
        }
        &__action {
          & > button {
            padding: 0;
            font-size: 1rem;
            opacity: 0.5;
          }
          &__hide {
            & > button {
              padding: 0;
              font-size: 1rem;
              opacity: 0.5;
            }
          }
        }
        &__receivers {
          margin-top: 1rem;
          span {
            margin-left: 0.4rem;
            padding: 0.1rem 0.4rem;
            font-style: italic;
          }
        }
      }
      &__right {
        font-size: 1.2rem;
        opacity: 0.8;
        display: flex;
        justify-content: space-between;
        flex-direction: column;
        width: 30%;
        &__info {
          margin-top: 0.6rem;
          display: flex;
          flex-direction: column;
          align-items: end;
          & > * {
            margin-top: 0.8rem;
          }
        }
        &__actions {
          display: flex;
          justify-content: end;
          margin: 0.8rem 0;
          & > * {
            margin-left: 0.8rem;
          }
        }
        .icon-button {
          font-size: 1.6rem;
        }
      }
    }
    .attachments {
      svg {
        path {
          fill: var(--color-light-grey);
        }
      }
    }
    .images {
      margin: 2rem;
      padding-top: 1rem;
    }
    .slider {
      .slick-slide img {
        display: block;
        max-height: 250px;
        width: auto;
        margin: auto;
      }
      .slick-dots li button::before {
        color: var(--color-accent-prim);
      }
    }
  }
`;

const PostComponent = ({ post, refresh }) => {
  const [showModalEdit, setShowModalEdit] = useState(false);
  const [showModalDelete, setShowModalDelete] = useState(false);
  const [showModalAttachments, setShowModalAttachments] = useState(false);
  const [open, setOpen] = useState(false);
  const { updateError } = UseErrorContext();
  const { permissions } = UseUserContext();
  const [attachments, setAttachments] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [images, setImages] = useState([]);
  const [documents, setDocuments] = useState([]);

  useEffect(async () => {
    const img = post.attachments.filter((x) => x.type == "Image");
    setImages(img);
    const docs = post.attachments.filter((x) => x.type == "Document");
    setDocuments(docs);
  }, [post]);

  const settings = {
    dots: true,
    infinite: false,
    lazyLoad: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  const _delete = async () => {
    setIsLoading(true);
    try {
      await deletePost(post.id);
      await refresh();
    } catch (err) {
      updateError(err);
    } finally {
      setShowModalDelete(false);
      setIsLoading(false);
    }
  };

  const _publish = async () => {
    try {
      if (post.status === "Draft") {
        const access = ValidateAccess(["Posts.Approve"], permissions);
        if (access) {
          const resp = await approvePost(post.id);
          refresh();
        } else {
          throw { message: "You have insufficient rights to perform this action" };
        }
      }
      if (post.status === "Approved") {
        const access = ValidateAccess(["Posts.Publish"], permissions);
        if (access) {
          const resp = await publishPost(post.id);
          refresh();
        } else {
          throw { message: "You have insufficient rights to perform this action" };
        }
      }
    } catch (err) {
      updateError(err);
    }
  };

  const _openAttachments = async (post) => {
    setAttachments(post.attachments);
    setShowModalAttachments(true);
  };

  return (
    <Container>
      {showModalDelete && (
        <Modal title={`Delete post`} onClose={() => setShowModalDelete(false)} onSubmit={() => _delete()} cancelLabel={"Cancel"} submitLabel={"Delete"} loading={isLoading}>
          <div>Are you sure you want to delete this post?</div>
        </Modal>
      )}
      {showModalEdit && (
        <Modal title="Edit" hideActions>
          <PostForm
            post={post}
            onClose={() => {
              setShowModalEdit(false);
              refresh();
            }}
          />
        </Modal>
      )}
      {showModalAttachments && (
        <Modal title="Attachments" showClose hideActions onClose={() => setShowModalAttachments(false)}>
          <DocumentGrid documents={attachments} isReadOnly />
        </Modal>
      )}
      {isLoading ? (
        <Loader />
      ) : (
        <div className="post">
          <div className="post__body">
            <div className="post__body__left">
              <h2>{post.title}</h2>
              <div className="post__body__left__content">
                <div>
                  {post.message.length < 50 ? (
                    <Interweave content={DOMPurify.sanitize(htmlToRawText(draftToHtml(JSON.parse(post.message))))} />
                  ) : (
                    <>
                      {!open ? (
                        <>
                          <Interweave
                            content={DOMPurify.sanitize(
                              htmlToRawText(draftToHtml(JSON.parse(post.message)))
                                ?.slice(0, 120)
                                ?.concat(`…`)
                            )}
                          />
                          <div className="post__body__left__action">
                            <Button onClick={() => setOpen(true)} label="See full message" type="secondary" />
                          </div>
                        </>
                      ) : (
                        <>
                          <HTMLRenderView content={draftToHtml(JSON.parse(post.message))} />
                          {images.length > 0 && (
                            <div className="images">
                              <Slider {...settings} className="slider">
                                {images?.map((image, idx) => (
                                  <div key={idx} className="cover">
                                    <img src={image.uri} alt="" />
                                  </div>
                                ))}
                              </Slider>
                            </div>
                          )}
                          <div className="post__body__left__action__hide">
                            <Button onClick={() => setOpen(false)} label="Hide full message" type="secondary" />
                          </div>
                        </>
                      )}
                    </>
                  )}
                </div>
                <div>
                  {post.communicationGroups.length > 0 && (
                    <div className="post__body__left__receivers">
                      <p>
                        Receiver(s):
                        {post.communicationGroups.map((cg) => (
                          <span key={cg?.name}>{cg.name}</span>
                        ))}
                      </p>
                    </div>
                  )}
                </div>
              </div>
            </div>
            <div className="post__body__right">
              <div className="post__body__right__info">
                <Status label={MAPSTATUSPOSTS[post.status]} onClick={() => _publish()} />
                <span>{`${FormatDateTimeDisplay(post.createdTime + "Z")}`}</span>
              </div>
              <div className="post__body__right__actions">
                <div onClick={() => setShowModalEdit(true)} className="icon-button">
                  <Edit className="icon-button" />
                </div>
                <div onClick={() => setShowModalDelete(true)} className="icon-button">
                  <Trash className="icon-button" />
                </div>
              </div>
              <div className="post__body__right__info">
                <div>
                  {post.attachments.length > 0 && (
                    <div onClick={() => _openAttachments(post)} className="icon-button attachments">
                      {post.attachments.length}
                      <Attachment />
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </Container>
  );
};

PostComponent.defaultProps = {};

export default PostComponent;
