import React, { useState } from "react";
import HeaderDropDown from "../../components/UI/HeaderDropDown";
import PageHeaderSub from "../../components/PageHeaderSub";
import Page from "../../components/UI/Elements/Page";
import { ProductForm } from "pages/products/Components/ProductForm";
import { addProduct, uploadCover } from "api/services/product";
import { UseOrganizationContext } from "contexts";
import { useHistory } from "react-router-dom";
import { UseErrorContext } from "contexts";

const AddProduct = () => {
  const history = useHistory();
  const organizationId = UseOrganizationContext()?.id;
  const { updateError } = UseErrorContext();

  const onSave = async (productData) => {
    try{
      const rep = await addProduct(organizationId, productData);    
      
      if (productData.cover){
        await uploadCover(rep.id, productData.cover);
      }

      history.push("/productmanager");
      return rep;
      
    } catch (err) {
      updateError(err);
    }
  };

  return (
    <>
      <Page>
        <div className="page__header">
          <PageHeaderSub>
          </PageHeaderSub>
        </div>
        <div className="page__inner">
          <HeaderDropDown titleHeader="Add a new share">
            <ProductForm save={onSave}/>
          </HeaderDropDown>
        </div>
      </Page>
    </>
  );
};

export default AddProduct;
