import { getAxiosClient } from "../axios";

export const getNotifications = async (params) => {
  const axiosClient = await getAxiosClient();

  return await axiosClient
    .get(`/me/notifications`, { params: {...params}} )
    .then((response) => {
      return response;
    })
    .catch((error) => {
      console.log("error", error);
      return Promise.reject(error);
    });
};

export const setNotificationRead = async (id) => {
  const axiosClient = await getAxiosClient();

  return await axiosClient
    .put(`/me/notifications/${id}/read/`)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      console.log("error", error);
      return Promise.reject(error);
    });
};