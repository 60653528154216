import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { useParams } from "react-router-dom";
import PageWrapper from "../../components/UI/Elements/Page";
import Loader from "components/UI/Loader";
import { BackButton } from "components/UI/Buttons/BackButton";
import EventContent from "./Components/EventContent";
import { UseErrorContext } from "contexts";
import PageHeaderSub from "components/PageHeaderSub";
import { getFollowedEvent, getEventSections, getDocumentContent } from "api/services/event";
import Modal from "components/UI/Modal";
import Button from "components/UI/Buttons/Button";
import { QuestionForm } from "./Components/QuestionForm";
import { Link } from "react-router-dom";
import { pdfjs, Document, Page } from "react-pdf";
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;
import { useTranslation } from 'react-i18next';
import languageSettings from "language";

const Container = styled.div`
`;

const ViewFollowedEvent = () => {
  const { id } = useParams();
  const [event, setEvent] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const { t, i18n } = useTranslation();
  const { updateError } = UseErrorContext();

  useEffect(async () => {
    await refresh();
  }, []);
  

  const refresh = async () => {
    console.log('languageSettings', languageSettings)
    try {
      const e = await getFollowedEvent(id);
      setEvent(e);
    } catch (err) {
      updateError(err);
    }
  };

  return (
    <>
      {showModal && (
        <Modal title={t('askquestion')} hideActions>
          <QuestionForm event={event} onClose={() => setShowModal(false)} />
        </Modal>
      )}
      <PageWrapper>
        {!event ? (
          <Loader />
        ) : (
          <Container>
            <div className="navigation-banner">
              <BackButton link="/followed-events" />
              <ul className="breadcrumbs">
                <li>
                  <a href="/followed-events">{t('followedactions')}</a>
                </li>
                <li>
                  <span className="selected">{event?.title}</span>
                </li>
              </ul>
            </div>
            <div className="page__header">
              <h1></h1>
              <PageHeaderSub show>
                <Button onClick={() => setShowModal(true)} label={t('askquestion')} />
              </PageHeaderSub>
            </div>
            <div className="page__inner">
              <EventContent eventdata={event} language={languageSettings.Code}/>
            </div>
          </Container>
        )}
      </PageWrapper>
    </>
  );
};

export default ViewFollowedEvent;
