import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { getDepartments, updateDepartments } from "../../../api/services/user";
import Button from "components/UI/Buttons/Button";
import SwitchButtonOn from "components/UI/Buttons/SwitchButtonOn";
import { UseErrorContext } from "contexts";

const Container = styled.div`
 `;

const EditDepartmentsView = ( { user, onClose } ) => {
  const [departments, setDepartments] = useState(null);  
  const [selectedDepartments, setSelectedDepartments] = useState(user.departments);
  const { updateError } = UseErrorContext();

  useEffect(async () => {
    try{
      var dep = await getDepartments();
      setDepartments(dep);
    } catch (err) {
      updateError(err);
    }
  }, []);

  const _addDepartment = async (department) => {
    setSelectedDepartments([...selectedDepartments, department]);
  };

  const _removeDepartment = async (department) => {
    setSelectedDepartments(selectedDepartments.filter(item => item.id !== department.id))
  };

  const handleSubmit = async () => {
    try{
      var departmentIds = selectedDepartments.map(function(x){return x.id;});
      await updateDepartments(user.id, departmentIds);
      onClose();
    } catch (err) {
      updateError(err);
    }
  };

  return (
    <Container>
      <div className="switch-container">
       {departments?.map((department, idx) => (
        <div key={idx} className="switch-item">
          <label>{department.name}</label>
          <SwitchButtonOn
              on={user.departments?.find((el) => el.id === department.id)}
              onSwitchOn={() => _addDepartment(department)}
              onSwitchOff={() => _removeDepartment(department)}
          />
        </div>                
        ))}
      </div>
      <div className="modal-buttons">
        <Button label="cancel" onClick={() => onClose()} type="secondary" />
        <Button onClick={handleSubmit} label="Save" disabled={selectedDepartments.length === 0}/>
      </div>
    </Container>
  );
};

export default EditDepartmentsView;
