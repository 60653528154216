import React, { useState, useEffect, useContext } from "react";
import styled, { css } from "styled-components";
import moment from "moment";
import Loader from "components/UI/Loader";
import { device } from "components/styles/GlobalStyle";
import { getMyNews, getNews } from "api/services/news";
import { getProducts } from "api/services/product"
import { UseOrganizationContext } from "contexts";

const Container = styled.div`
  display: flex;
  flex-wrap: wrap;
  .news {
    width: calc(100% / 1);
    padding: 1rem;
    ${({ direction }) =>
      direction == "column" &&
      css`
        width: calc(100% / 1);
        @media ${device.laptopM} {
          width: calc(100% / 1);
        }
      `}

    .news_title {
      font-size: 1.8rem;
      margin: 0.4rem 0;
    }
    .news_date {
      font-size: 1.2rem;
      opacity: 0.8;
    }
    .news_description {
      font-size: 1.6rem;
      line-height: 1.6rem;
      opacity: 0.6;
    }
    .news_image {
      position: relative;
      width: 100%;
      margin-bottom: 30%;
      height: 100%;
      img {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
      }
    }
  }
  .loadmore-btn {
    margin: 1rem;
  }
`;

const NewsGrid = ({ direction, asCompany }) => {
  const [reload, setReload] = useState(false);
  const organizationId = UseOrganizationContext()?.id;

  const [newsItems, setNewsItems] = useState(null);

  const fetchNews = async () => {
    if (asCompany)
    {
      if (!organizationId) return;
      
      let products = await getProducts(organizationId);
      let productIds = products.map(x => x.id).join();
      const res = await getNews(productIds)
      setNewsItems(res.data);
    }
    else
    {
      const res = await getMyNews();
      setNewsItems(res.data);
    }
  };

  useEffect(async () => {
    await fetchNews();
  }, [organizationId]);

  return (
    <Container direction={direction}>
      {!newsItems || reload ? (
        <Loader center />
      ) : (
        <div>
          {newsItems?.map(
            (item, idx) =>
              item.url && (
                <a href={item.url} target={"_blank"} key={idx} className="news">
                  <div className="news_date">{`${moment(item.published_at).format("YYYY-MM-DD HH:mm")}`}</div>
                  <div className="news_title">{item.title}</div>
                  <div className="news_description">{/*formatDescription(item.content)*/}</div>
                </a>
              )
          )}
          {/*<Button label="Load more" onClick={loadMore} className="loadmore-btn") />*/}
        </div>
      )}
    </Container>
  );
};

NewsGrid.defaultProps = {
  direction: "row",
};

export default NewsGrid;
