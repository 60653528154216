import React, { useState, useEffect, useRef } from "react";
import styled from "styled-components";
import { useHistory } from "react-router-dom";
import { Formik, Form, Field, ErrorMessage } from "formik";
import Loader from "components/UI/Loader";
import Button from "components/UI/Buttons/Button";
import * as Yup from 'yup'
import { addCommunicationGroup, updateCommunicationGroup, getCountries, previewUsers } from "api/services/communication-groups";
import { getProducts } from "api/services/product";
import { UseOrganizationContext } from "contexts";
import SwitchButtonOn from "components/UI/Buttons/SwitchButtonOn";
import Select from "react-select";
import Pagination from "components/Pagination";
import { GetPagination } from "api/helpers/datahelper";
import { UseErrorContext } from "contexts";

const FormWrapper = styled.div`
  label {
    display: block;
    margin-bottom: 0.4rem;
  }
  input {
    width: 100%;
  }
  textarea {
    width: 100%;
    height: 10rem;
  }
  .form_row {
    margin-bottom: 2rem;
    display: flex;
    width: 100%;
    .field {
      width: 100%;
      &:not(:first-of-type) {
        margin-left: 2.4rem;
      }
    }
  }
  .error-message {
    opacity: 0.6;
    color: red;
    padding: 1rem 0;
  }
  .buttons {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    button {
      margin-right: 1rem;
    }
  }
  table {
    width: 100%;
    min-width: 0;
  }
`;

const ValidationSchema = Yup.object().shape({
  name: Yup.string().required('Required'),
}) 

export const CommunicationGroupForm = ({ communicationGroup, onClose }) => {
  const history = useHistory();  
  const organizationId = UseOrganizationContext()?.id;
  const [products, setProducts] = useState(null);  
  const [countries, setCountries] = useState(null);  
  const [users, setUsers] = useState([]);  
  const ref = useRef(null);
  const [currentPage, setCurrentPage] = useState(1); 
  const [numberOfPages, setNumberOfPages] = useState(1);
  const { updateError } = UseErrorContext();
  const [isLoading, setIsLoading] = useState(false);

  useEffect(async () => {
    try{
      var p = await getProducts(organizationId);
      setProducts(p);
    } catch (err) {
      updateError(err);
  }
  }, []);

  useEffect(async () => {
    try{
      var c = await getCountries();
      setCountries(c);
    } catch (err) {
      updateError(err);
    }
  }, []);

  const callBack = async (values) => { 
    const resp = !communicationGroup 
      ? await _addCommunicationGroup(values)
      : await _updateCommunicationGroup({ ...communicationGroup, ...values });   
    return resp;
  };
 
  const _addCommunicationGroup = async (values) => {  
    try{
      return await addCommunicationGroup(organizationId, values)
    } catch (err) {
      updateError(err);
    }
  };

  const _updateCommunicationGroup = async (values) => {  
    try{ 
      return await updateCommunicationGroup(values);  
    } catch (err) {
      updateError(err);
    }
  };

  const getPreviewUsers = async () => {
    setIsLoading(true);
    try{
      let values = ref.current.values;
      values.name = values.name ?? "";

      let userReponse = await previewUsers(organizationId, values,
      {
        'page': currentPage,
      });

      let userData = userReponse.data;
      let userHeader = userReponse.headers;

      let pagination = GetPagination(userHeader);
      let totalCount = pagination.totalCount;
      let pageSize = pagination.pageSize;
      let nbrOfPages = Math.ceil(totalCount / pageSize);
        
      setNumberOfPages(nbrOfPages);

      setUsers(userData);
    } catch (err) {
      updateError(err);
    }
    setIsLoading(false);
  }

  return (
    <FormWrapper>
      <Formik
        initialValues={{ 
          name: communicationGroup?.name, 
          isConfirmedProductOwner: communicationGroup?.isConfirmedProductOwner ?? false,
          countries: communicationGroup?.countries?.map(c => c.code) ?? [],
          productIds: communicationGroup?.products?.map(p => p.id) ?? [],
        }}
        validationSchema={ValidationSchema}
        onSubmit={(values, { setSubmitting }) => {
          callBack(values)
            .then((value) => {
              history.go(0)
            })
            .catch((e) => {
              setSubmitting(false);
            });
        }}        
       innerRef={ref}
      >
        {({ isSubmitting, handleSubmit, setFieldValue }) => (
          <Form>
          <div className="form_row">
            <div className="field">
              <label htmlFor="name" className="required">Name</label>
              <Field type="text" name="name" />
              <ErrorMessage className="error-message" name="name" component="div" />
            </div>
          </div>   
          <div className="form_row">
              <div className="field">
                <label htmlFor="isConfirmedProductOwner" className="label required">
                  Is confirmed shareholder
                </label>
                <SwitchButtonOn
                  on={communicationGroup?.isConfirmedProductOwner}
                  onSwitchOn={e => { setFieldValue("isConfirmedProductOwner", true);}}
                  onSwitchOff={e => { setFieldValue("isConfirmedProductOwner", false);}}
                />
              </div>
           </div>       
           <div className="form_row">    
            <div className="field">
                <label htmlFor="countries" className="label">
                  Countries
                </label>    
                <Select
                  className="react-selectcomponent"
                  onChange={e => { setFieldValue("countries", e?.map(x => x.code));}}
                  options={countries}
                  getOptionLabel={country => country.name}
                  defaultValue={communicationGroup?.countries}
                  isOptionSelected={country => {communicationGroup?.countries?.find((el) => el.code === country.code);}}                
                  isMulti
                  styles={{ multiValueRemove: (base) => ({ ...base, display: 'none' }) }}
                />
                <ErrorMessage className="error-message" name="countries" component="div" />
              </div>                
          </div>   
           <div className="form_row">    
            <div className="field">
                <label htmlFor="productIds" className="label">
                  Products
                </label>    
                <Select
                  className="react-selectcomponent"
                  onChange={e => { setFieldValue("productIds", e?.map(x => x.id));}}
                  options={products}
                  getOptionLabel={product => product.title}
                  defaultValue={communicationGroup?.products}
                  isOptionSelected={product => {communicationGroup?.products?.find((el) => el.id === product.id);}}                
                  isMulti
                  styles={{ multiValueRemove: (base) => ({ ...base, display: 'none' }) }}
                />
              </div>                
          </div>           
          <div className="form_row">  
            <Button label="Preview users" onClick={() => {getPreviewUsers();}}/>            
          </div> 
          {isLoading 
            ? <Loader />
            : <>
                {users.length > 0 &&
                  <div className="form_row">               
                    <div className="field">
                      <label className="label">
                        Users
                      </label>        
                      <table style={{minWidth: '0'}}>
                          <thead>
                            <tr>
                              <th></th>
                              <th>Name</th>
                              <th>Email</th>
                              <th>Country</th>
                              <th></th>
                            </tr>
                          </thead>
                          <tbody>
                            {users?.map((user, idx) => (
                              <tr key={idx}>
                                <td></td>
                                <td>{`${user.firstName} ${user.lastName}`}</td>
                                <td>{user.email}</td>
                                <td>{user.country}</td>
                                <td></td>
                              </tr>
                            ))}
                          </tbody>
                        </table>  
                        <Pagination numberOfPages={numberOfPages} currentPage={currentPage} setCurrentPage={setCurrentPage}/>
                      </div>
                    </div>
                  }
                </>
            }
            {!isSubmitting ? (
              <div className="buttons">             
                <Button label="cancel" onClick={(e) => {onClose();}} type="secondary"/>   
                <Button onClick={()=>{handleSubmit()}} label="Submit" disabled={isSubmitting} />
              </div>
            ) : (
              <Loader />
            )}
          </Form>
        )}
      </Formik>
    </FormWrapper>
  );
};
