import React, { useState } from "react";
import styled from "styled-components";
import { FormatDate, FormatDateTimeDisplay } from "api/helpers/datahelper";
import { FaCircle } from "react-icons/fa";
import { setNotificationRead } from "api/services/notifications";
import { useHistory } from "react-router";

const Container = styled.div`
  overflow: auto;
  .notification {
    margin-bottom: 1.2rem;
    padding: 1.2rem;
    border: 1px solid rgba(255, 255, 255, 0.6);
    border-radius: 0.8rem;
    cursor: pointer;
    &:hover {
      opacity: 0.7;
    }
    &__header {
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      justify-content: space-between;
      &__unread {
        margin-right: 1rem;
        svg {
          path {
            fill: var(--color-accent-prim);
          }
        }
      }
      &__title {
        display: flex;
        margin-bottom: 0.4rem;
        max-width: 75%;
        text-align: left;
      }
      &__subtitle {
        font-size: 1.2rem;
        margin-bottom: 0.4rem;
        text-align: right;
      }
    }
  }
  .read{
    opacity: 0.8;
  }
`;

const NotificationGrid = ({ notifications, refresh }) => {
  const history = useHistory();

  const open = async (notification) => {
    await setNotificationRead(notification.id);
    if (notification.category == "Questions"){
      history.push(`/my-questions/view/${notification.metadata.questionId}`)
    }
    if (notification.category == "Events"){
      history.push(`followed-events/view/${notification.metadata.eventId}`)
    }
    if (notification.category == "Posts"){
      history.push(`my-posts`);
    }
  };
 
  return (
    <Container>
      {notifications.map((notification, idx) => {
        return (
          <div onClick={() => open(notification)} key={idx} className={notification.isRead ? "notification read" : "notification"}>
            <div className="notification__header">
              <div className="notification__header__title">
                {!notification.isRead && <div className="notification__header__unread"><FaCircle/></div>}
                {notification.message}
              </div>
              <div className="notification__header__subtitle">
                {`${notification.category} - ${FormatDateTimeDisplay(notification.createdDate + 'Z')}`} 
              </div>
            </div>
          </div>
        );
      })}
    </Container>
  );
};

NotificationGrid.defaultProps = {
  notifications: Array.from(Array(0)),
};

export default NotificationGrid;
