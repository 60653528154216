import React, { useState, useEffect} from "react";
import PageHeaderSub from "../../components/PageHeaderSub";
import Page from "components/UI/Elements/Page";
import { QuestionForm } from "pages/events/Components/QuestionForm";
import Button from "components/UI/Buttons/Button";
import Modal from "components/UI/Modal";
import { UseOrganizationContext, UseErrorContext } from "contexts";
import HeaderDropDown from "components/UI/HeaderDropDown";
import SearchBar from "components/UI/SearchBar";
import QuestionGrid from "./Components/QuestionGrid";
import Pagination from "components/Pagination";
import Loader from "components/UI/Loader";
import { getMyQuestions, getFollowedFilters } from "api/services/communication";
import { GetEventDate, GetPagination } from "api/helpers/datahelper";
import { delay } from "api/helpers/datahelper";
import { useTranslation } from 'react-i18next';

const MyQuestions = () => {
  const [showModal, setShowModal] = useState(false); 
  const organizationId = UseOrganizationContext()?.id;
  const [mails, setMails] = useState([]);
  const [search, setSearch] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [numberOfPages, setNumberOfPages] = useState(1);
  const [loading, setLoading] = useState(false);
  const [eventFilters, setEventFilters] = useState([]);
  const [eventFilter, setEventFilter] = useState(null);
  const [statusFilter, setStatusFilter] = useState(null);
  const [categoryFilters, setCategoryFilters] = useState([]);
  const [categoryFilter, setCategoryFilter] = useState(null);
  const { updateError } = UseErrorContext();
  const { t, i18n } = useTranslation();

  // Init data
  useEffect(async () => {
    setStatusFilter("All")
  }, []);

  // Refresh data
  useEffect(async () => {
    refresh();
  }, [search, currentPage, eventFilter, categoryFilter, statusFilter, organizationId]);

  // Refresh data
  const refresh = async () => {  
    try{
        // Fix because not correct loaded on first time
        if (!statusFilter) return;
            
        setLoading(true);

        var params = {
          'page': currentPage,
          'search': search,
          'unanswered': statusFilter == "unanswered",
        }
        if (eventFilter){
          params["eventIds"] = parseInt(eventFilter);
        }
        if (categoryFilter){
          params["categoryIds"] = parseInt(categoryFilter);
        }

        const mailResponse = await getMyQuestions(params);  

        let mailData = mailResponse.data;
        let mailHeader = mailResponse.headers;
          
        let pagination = GetPagination(mailHeader);
        let totalCount = pagination.totalCount;
        let pageSize = pagination.pageSize;
        let nbrOfPages = Math.ceil(totalCount / pageSize);
            
        setNumberOfPages(nbrOfPages);

        setMails(mailData); 

        // Set Filters
        const filtersData = await getFollowedFilters(organizationId);
        setEventFilters(filtersData["events"]);
        console.log(filtersData["events"])
        setCategoryFilters(filtersData["categories"]);

        setLoading(false);
           
    } catch (err) {
      updateError(err);
    }
  }

  // Search
  const onSearch = async (e) => {    
    await delay(1000);
    setCurrentPage(1);
    const val = e.target.value.toString();
    setSearch(val);
  };

  // Filters
  const onChangeEventFilter = (e) => {
    setCurrentPage(1);
    const val = e.target.value.toString();
    setEventFilter(val);
  };
  const onChangeCategoryFilter = (e) => {
    setCurrentPage(1);
    const val = e.target.value.toString();
    setCategoryFilter(val);
  };
  const onChangeStatusFilter = (e) => {
    setCurrentPage(1);
    const val = e.target.value.toString();
    setStatusFilter(val);
  };


  return (
      <>
      {showModal && (
        <Modal title={t('askquestion')} hideActions>
          <QuestionForm onClose={() => {setShowModal(false); refresh();}}/>
        </Modal>
      )}
      <Page>
        <div className="page__header">
          <h1>{t('myquestions')}</h1>
          <PageHeaderSub show>
            <Button onClick={() => setShowModal(true)} label={t('askquestion')}/>           
          </PageHeaderSub>
        </div>
        <div className="page__inner">
          <HeaderDropDown titleHeader="">  
            <div className="searchBarContainer">
              <SearchBar onChange={onSearch}/>
            </div>
            { loading 
            ? <Loader />
            :  
              <>
                <div className="filters">
                  <div className="row">
                    <div>
                      <label>{t('action')}</label>
                        <select onChange={onChangeEventFilter} value={eventFilter}>
                          <option value="">{t('all')}</option>
                          {eventFilters?.map((filter, idx) => (                 
                            <option key={idx} value={filter.id}>{`${filter.organization.name}: ${filter.title} (${GetEventDate(filter)})`}</option>
                          ))}
                        </select>
                    </div>
                    <div>
                      <label>{t('department')}</label>
                        <select onChange={onChangeCategoryFilter} value={categoryFilter}>
                          <option value="">{t('all')}</option>
                          {categoryFilters?.map((filter, idx) => (                 
                            <option key={idx} value={filter.id}>{filter.name}</option>
                          ))}
                        </select>
                    </div>
                    <div>
                      <label>{t('status')}</label>
                        <select onChange={onChangeStatusFilter} value={statusFilter}>
                          <option>{t('all')}</option>
                          <option value="unanswered">{t('unanswered')}</option>
                        </select>
                    </div>
                  </div>
                </div>  
              <QuestionGrid mails={mails}/>
              <Pagination numberOfPages={numberOfPages} currentPage={currentPage} setCurrentPage={setCurrentPage}/>    
              </>
            }  
          </HeaderDropDown>    
        </div>
      </Page>
      </>
  );
}

export default MyQuestions;