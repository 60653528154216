import React, { useState, useEffect } from "react";
import PageHeaderSub from "../../components/PageHeaderSub";
import Page from "../../components/UI/Elements/Page";
import { useParams } from "react-router-dom";
import HeaderDropDown from "../../components/UI/HeaderDropDown";
import TranslationGrid from "components/TranslationGrid";
import { BackButton } from "components/UI/Buttons/BackButton";
import { UseErrorContext, UseOrganizationContext } from "contexts";
import { getAdminOrganization, getOrganizationProfileSection } from "api/services/organization";
import { getProfileSectionTranslations, addProfileSectionTranslation, updateProfileSectionTranslation, removeProfileSectionTranslation } from "api/services/organization";
import * as Yup from "yup";

const validationSchema = Yup.object().shape({
  title: Yup.string().required("Required"),
  text: Yup.string().required("Required"),
  languageCode: Yup.string().required("Required"),
});

const ProfileSectionTranslations = () => {
  const { id } = useParams();
  const organizationId = UseOrganizationContext()?.id;
  const [object, setObject] = useState(null);
  const [filteredSection, setFilteredSection] = useState(null);
  const [translations, setTranslations] = useState([]);
  const { updateError } = UseErrorContext();

  useEffect(async () => {
    refresh();
  }, []);

  const refresh = async () => {
    try {
      const allSections = await getOrganizationProfileSection(organizationId);
      const filteredSection = allSections.filter((x) => x.id == id)[0];
      const trans = await getProfileSectionTranslations(organizationId, id);
      const section = trans.filter((x) => x.isDefault)[0];
      setObject(section);
      setFilteredSection(filteredSection);
      setTranslations(trans);
    } catch (err) {
      updateError(err);
    }
  };

  const add = async (values) => {
    try {
      const resp = await updateProfileSectionTranslation(organizationId, id, values);
      refresh();
      return resp;
    } catch (err) {
      updateError(err);
    }
  };

  const edit = async (translationId, values) => {
    try {
      delete values.id;
      const resp = await updateProfileSectionTranslation(organizationId, id, values);
      refresh();
      return resp;
    } catch (err) {
      updateError(err);
    }
  };

  const remove = async (translationId, languageCode) => {
    try {
      await removeProfileSectionTranslation(organizationId, id, languageCode);
      refresh();
    } catch (err) {
      updateError(err);
    }
  };

  return (
    <Page>
      <div className="navigation-banner">
        <BackButton link={"/profile/"} />
        <ul className="breadcrumbs">
          <li>
            <a href="/profile">Profile</a>
          </li>
          <li>
            <span className="selected">Translations</span>
          </li>
        </ul>
      </div>

      <div className="page__header">
        <h1>Translations section</h1>
        <PageHeaderSub />
      </div>
      <div className="page__inner">
        {object && (
          <TranslationGrid
            type="section"
            item={{ ...filteredSection, ...object }}
            fields={["title", "text"]}
            validationSchema={validationSchema}
            translations={translations}
            remove={remove}
            add={add}
            edit={edit}
          />
        )}
      </div>
    </Page>
  );
};

export default ProfileSectionTranslations;
