import React, { useState, useEffect, useContext } from "react";
import styled from "styled-components";
import { useHistory } from "react-router-dom";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import Loader from "components/UI/Loader";
import Button from "components/UI/Buttons/Button";
import Image from "components/UI/Images/Image";
import { uploadCover, getProduct } from "api/services/product";
import StockData from "../StockData";
import { WebsocketContext } from "contexts/InfrontContext";
import { resizeImage } from "utils";
import MDEditor from "components/UI/Blocks/MDEditor";

const FormWrapper = styled.div`
  label {
    display: block;
    margin-bottom: 0.4rem;
    opacity: 0.8;
  }
  input {
    width: 100%;
  }
  textarea {
    width: 100%;
    height: 10rem;
  }
  .form_row {
    margin-bottom: 2rem;
    display: flex;
    width: 100%;
    .field {
      width: 100%;
      &:not(:first-of-type) {
        margin-left: 2.4rem;
      }
    }
    .field__cover {
      width: 37rem;
    }
  }
  .error-message {
    opacity: 0.6;
    color: red;
    padding: 1rem 0;
  }
  .buttons {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    button {
      margin-right: 1rem;
    }
  }

  iframe{
    margin: 2rem auto;
  }
`;

const ValidationSchema = Yup.object().shape({
  title: Yup.string().required("Required").max(100),
  subtitle: Yup.string().required("Required").max(100),
  description: Yup.string().required("Required"),
  stockSymbol: Yup.string().required("Required"),
});

export const ProductForm = ({ save, product }) => {
  const history = useHistory();
  const [coverFile, setCoverFile] = useState(product?.cover);
  const [cover, setCover] = useState(product?.cover);
  const [uploadingCover, setUploadingCover] = useState(false);

  const { token } = useContext(WebsocketContext);

  const previewImage = async (e) => {
    setUploadingCover(true);
    let selectedFile = e.target.files[0];

    let fileSizeInMegabytes = selectedFile.size / (1024 * 1024);

    if (fileSizeInMegabytes > 10) {
      updateError({ message: t("Image should be smaller then 10mb.") });
      setUploadingCover(false);
      return;
    }

    const resizedImage = await resizeImage(selectedFile, 1250);

    setCoverFile(resizedImage);

    let reader = new FileReader();
    reader.onload = function (event) {
      setCover(event.target.result);
    };

    reader.readAsDataURL(selectedFile);
    setUploadingCover(false);
  };

  return (
    <FormWrapper>
      <Formik
        initialValues={{
          title: product?.title,
          subtitle: product?.subtitle,
          description: product?.description,
          stockSymbol: product?.stockSymbol,
        }}
        validationSchema={ValidationSchema}
        onSubmit={(values, { setSubmitting }) => {
          values.cover = coverFile;
          save(values);
          setSubmitting(false);
        }}
      >
        {({ isSubmitting, handleSubmit, setFieldValue, values }) => (
          <Form>
            <label className="label">Cover</label>
            <div className="form_row">
              <div className="field field__cover">
                <div className="cover-image">
                  <Image url={cover} onChange={previewImage} isLoading={uploadingCover} />
                </div>
              </div>
              <div className="field">
                <StockData stockSymbol={product?.stockSymbol} isin={product?.isin} mic={product?.primaryMarketMic}/>
              </div>
            </div>

            <div className="form_row">
              <div className="field">
                <label htmlFor="title" className="required">
                  Title
                </label>
                <Field type="text" name="title" />
                <ErrorMessage className="error-message" name="title" component="div" />
              </div>
            </div>
            <div className="form_row">
              <div className="field">
                <label htmlFor="subtitle" className="required">
                  Subtitle
                </label>
                <Field type="text" name="subtitle" />
                <ErrorMessage className="error-message" name="subtitle" component="div" />
              </div>
            </div>
            <div className="form_row">
              <div className="field">
                <label htmlFor="description" className="required">
                  Description
                </label>
                <MDEditor value={values.description} setFieldValue={(val) => setFieldValue("description", val)} />
                <ErrorMessage className="error-message" name="description" component="div" />
              </div>
            </div>
            <div className="form_row">
              <div className="field">
                <label htmlFor="stockSymbol" className="required">
                  Stock symbol
                </label>
                <Field type="text" name="stockSymbol" />
                <ErrorMessage className="error-message" name="stockSymbol" component="div" />
              </div>
            </div>
            {!isSubmitting ? (
              <div className="buttons">
                <Button label="cancel" onClick={() => history.push("/productmanager")} type="secondary" />
                <Button onClick={handleSubmit} label="Save" disabled={isSubmitting} />
              </div>
            ) : (
              <Loader />
            )}
          </Form>
        )}
      </Formik>
      {product?.stockSymbol && <iframe width="100%" height="500px" src={`https://platform-dev.finvictum.com/infront?symbol=${product?.stockSymbol}&mic=${product?.primaryMarketMic}&isin=${product?.isin}&token=${token}`} />}
    </FormWrapper>
  );
};
