import React from "react";
import styled from "styled-components";
import { useTranslation } from 'react-i18next';

const Container = styled.div`
  width: 100%;
  input {
    border-radius: 0.8rem;
    border: 1px solid black;
    background: var(--color-text);
    width: 100%;
    padding: 0.8rem;
    color: var(--color-dark-grey);
  }
`;

export default function SearchBar({ value, onChange }) {
  const { t, i18n } = useTranslation();

  return (
    <Container>
      <input onChange={onChange} value={value} placeholder={t('search')} />
    </Container>
  );
}

SearchBar.defaultProps = {
  value: undefined, //null causes an error in console
  placeholder: "..Search.",
  onChange: (e) => console.log("searchbar onchange", e.target.value)
};
