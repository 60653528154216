export default function Arrow( { transform } ) {
  const rotateDegree = transform ? "rotate(0)" : "rotate(180)";

    return (
    <svg
      width="19"
      height="14"
      viewBox="0 0 24 24"
      fill="none"
      transform={rotateDegree}
      xmlns="http://www.w3.org/2000/svg"
    >

      <path
        fillRule="evenodd"
        d="M18.53 9.47a.75.75 0 010 1.06l-6 6a.75.75 0 01-1.06 0l-6-6a.75.75 0 111.06-1.06L12 14.94l5.47-5.47a.75.75 0 011.06 0z"
        clipRule="evenodd"
        fill="#C4C4C4"
      />
    </svg>
  );
}
