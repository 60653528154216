import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import HeaderDropDown from "../../components/UI/HeaderDropDown";
import PageHeaderSub from "../../components/PageHeaderSub";
import Page from "../../components/UI/Elements/Page";
import EventGrid from "./Components/EventGrid";
import Button from "../../components/UI/Buttons/Button";
import Modal from "../../components/UI/Modal";
import { EventForm } from "./Components/EventForm";
import { getEvents } from "api/services/event";
import { UseOrganizationContext } from "contexts";
import SearchBar from "components/UI/SearchBar";
import moment from "moment";
import { ConvertToDate, FormatDateTime, delay } from "api/helpers/datahelper";
import { UseErrorContext } from "contexts";
import { getFeaturedEvents } from "api/services/organization";

const Events = () => {
  const organizationId = UseOrganizationContext()?.id;
  const [showModal, setShowModal] = useState(false);
  const [pastEvents, setPastEvents] = useState([]);
  const [currentEvents, setCurrentEvents] = useState([]);
  const [featuredEvents, setFeaturedEvents] = useState([]);
  const [search, setSearch] = useState("");
  const [fromDateFilter, setFromDateFilter] = useState(null);
  const [toDateFilter, setToDateFilter] = useState(null);
  const { updateError } = UseErrorContext();
  
  useEffect(async () => {
    if (!organizationId) return;
    refresh();
  }, [organizationId, search, fromDateFilter, toDateFilter]);

  const refresh = async () => {
    try{
      var today = moment().startOf("day").format("YYYY-MM-DDTHH:mm");

      const pastEventsData = await getEvents(organizationId, {
        search: search,
        from: fromDateFilter ?? "",
        to: toDateFilter && toDateFilter < today ? toDateFilter : today,
        order: "DESC",
      });

      setPastEvents(pastEventsData);
    
      const currentEventsData = await getEvents(organizationId, {
        search: search,
        from: fromDateFilter && fromDateFilter > today ? fromDateFilter : today,
        to: toDateFilter ?? "",
        order: "ASC",
      });

      setCurrentEvents(currentEventsData);

      const events = pastEventsData.concat(currentEventsData).filter(x => x.isFeatured);
      setFeaturedEvents(events);
    } catch (err) {
      updateError(err);
    }
  };

  // Search
  const onSearch = async (e) => {
    await delay(1000);
    const val = e.target.value.toString();
    setSearch(val);
  };

  // Filters
  const onChangeFromDateFilter = async (e) => {
    const val = e.target.value.toString();
    setFromDateFilter(val);
  };

  const onChangeToDateFilter = async(e) => {
    const val = e.target.value.toString();
    setToDateFilter(val);
  };

  return (
    <>
      {showModal && (
        <Modal title="Add a new action" onClose={() => setShowModal(false)}>
          <EventForm />
        </Modal>
      )}
      <Page>
        <div className="page__header">
          <h1>Actions</h1>
          <PageHeaderSub show>
            <Link to="events/add">
              <Button label="Add action" roles={["Events.Create"]}/>
            </Link>
          </PageHeaderSub>
        </div>
        <div className="page__inner">
          <div className="searchBarContainer">
            <SearchBar onChange={onSearch}/>
          </div>
          <div className="filters">
                <div className="row">
                  <div>
                    <label>From: </label>
                    <input type="datetime-local" onChange={onChangeFromDateFilter} value={fromDateFilter}/>
                  </div>
                  <div>
                    <label>To: </label>
                    <input type="datetime-local" onChange={onChangeToDateFilter} value={toDateFilter}/>
                  </div>
                </div>
          </div>
          { featuredEvents.length > 0 &&
            <HeaderDropDown titleHeader="Featured Actions">
              <EventGrid canEdit events={featuredEvents} refresh={refresh}/>
            </HeaderDropDown>
          }
          <HeaderDropDown titleHeader="Upcoming Actions">
            <EventGrid canEdit events={currentEvents} refresh={refresh}/>
          </HeaderDropDown>
          <HeaderDropDown titleHeader="Past Actions">
            <EventGrid canEdit events={pastEvents} refresh={refresh}/>
          </HeaderDropDown>
        </div>
      </Page>
    </>
  );
};

export default Events;
