import React from "react";
import styled from "styled-components";
import { NavLink } from "react-router-dom";
import { useMsal } from "@azure/msal-react";
import { useAccount } from "@azure/msal-react";
import { FaQuestion, FaPowerOff, FaCog } from "react-icons/fa";
import { useTranslation } from "react-i18next";

const Container = styled.div`
  .logout-text {
    cursor: pointer;
  }

  ul {
    width: 100%;
    margin-bottom: 2.4rem;
    &.side_menu__secondary {
      align-self: flex-end;
      justify-self: flex-end;
      margin-bottom: 5rem;
    }
  }
`;

export default function Navigation2({ hideSettings }) {
  const { instance, accounts } = useMsal();
  const account = useAccount(accounts?.[0] || {});
  const { given_name } = account?.idTokenClaims;
  const { t, i18n } = useTranslation();

  const LogoutHandler = () => {
    try {
      instance.logout();
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <Container>
      <div>
        <ul className="side_menu__secondary">
          {!hideSettings && (
            <li>
              <NavLink to="/user">
                <FaCog />
                {t("settings")}
              </NavLink>
            </li>
          )}
          <li>
            <a href="mailto:support@finvictum.com">
              <FaQuestion />
              {t("support")}
            </a>
          </li>
          <li>
            <a className="logout-text" onClick={() => LogoutHandler()}>
              <FaPowerOff />
              {t("logout")}
            </a>
          </li>
        </ul>
      </div>
    </Container>
  );
}
