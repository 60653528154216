import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { FormatDate, FormatDateTime, FormatDateTimeDisplay, GetEventDate } from "api/helpers/datahelper";
import HTMLRenderView from "components/UI/Blocks/HTMLRenderView";
import Button from "components/UI/Buttons/Button";
import { useHistory } from "react-router";
import Url from "components/UI/Icons/Url";
import { useTranslation } from "react-i18next";

const Container = styled.div`
  .test .icon-button svg path {
    fill: white;
  }
  .cover-image{
    height: initial;
    margin-bottom: 1.2rem;
  }
`;

const EventInfo = ({ event }) => {
  const [featuredLink, setFeaturedLink] = useState(null);
  const history = useHistory();
  const { t, i18n } = useTranslation();

  useEffect(async () => {
    if (event) {
      let featuredL = event.links.filter((x) => x.isFeatured)[0];
      setFeaturedLink(featuredL);
    }
  }, [event]);

  return (
    <>
      <Container>
        <div className="form">
          {featuredLink && (
            <div className="form_row">
              <div className="field test">
                <Button label={featuredLink.name} onClick={() => history.push(featuredLink.url)} type="primary">
                  <Url />
                </Button>
              </div>
            </div>
          )}
          <div className="form_row">
            <div className="field">
              <h2>{event.title}</h2>
            </div>
          </div>
          <div>
            <div className="cover-image">
              <img src={event?.coverUrl} />
            </div>
          </div>
          <div className="form_row">
            <div className="field">
              <i>{t("description")}:</i>
              <HTMLRenderView content={event?.description} />
            </div>
          </div>
          <div className="form_row">
            <div className="field">
              <i>{t("location")}:</i>
              <p>{event.location}</p>
            </div>
          </div>
          <div className="form_row">
            <div className="field">
              <i>{t("date")}:</i>
              <p>{GetEventDate(event)}</p>
            </div>
          </div>
        </div>
      </Container>
    </>
  );
};

export default EventInfo;
