import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { useMsal } from "@azure/msal-react";
import { useAccount } from "@azure/msal-react";
import { UseOrganizationContext, UseUserContext } from "../contexts";
import Header from "./Header";
import Footer from "./Footer";
import Navigation from "./Navigation";
import LoaderContainer from "./UI/Loader";
import Navigation2 from "./Navigation2";
import { device } from "components/styles/GlobalStyle";
import HeaderDropDown from "components/UI/HeaderDropDown";

const ContainerDesktop = styled.div`
  display: none;
  @media ${device.laptop} {
    display: flex;
  }
`;

const ContainerMobile = styled.div`
  display: flex;
  @media ${device.laptop} {
    display: none;
  }
`;

const Main = styled.main`
  margin: 0 auto;
  padding: 0;
  max-width: 80%;
  overflow-y: auto;
  @media ${device.laptop} {
    max-width: calc(100% - 32rem);
  }
  .fixed-bg {
    background-image: url("/bg.jpeg");
    background-position: right;
    background-size: cover;
    background-repeat: no-repeat;
    position: fixed;
    width: 100vw;
    height: 100vh;
    z-index: -1;
    top: 0;
    right: 0;
    opacity: 0.8;
  }
`;

const LoadOverlay = styled.div`
  position: fixed;
  width: 100vw;
  height: 100vh;
  z-index: 9999;
  display: flex;
  align-items: center;
  justify-content: center;
  background: var(--color-background-prim);
`;

const Nav = styled.nav`
  position: absolute;
  background-color: var(--color-black);
  width: 16rem;
  min-height: 25vh;
  box-shadow: 5px 6px 12px -5px black;
  bottom: 0;
  @media ${device.laptop} {
    width: 32rem;
    min-height: 100vh;
    position: relative;
  }
  .nav__inner {
    margin: 2rem 0;
    position: fixed;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 16rem;
    overflow: auto;
    @media ${device.laptop} {
      width: 32rem;
    }
  }
`;

const MobileContent = styled.div`
  margin-top: 2.4rem;
  a {
    text-decoration: underline;
  }
`;

const Layout = ({ children }) => {
  const { accounts } = useMsal();
  const account = useAccount(accounts?.[0] || {});
  const { name } = UseOrganizationContext();
  const { id, updateOrganizationData, updateOrgUserData } = UseOrganizationContext();
  const { updateUserData, updateUserName } = UseUserContext();
  const [hasOrganization, setHasOrganization] = useState(false);
  const [loading, setLoading] = useState(true);
  const [isRegistrationPage, setIsRegistrationPage] = useState(false);

  useEffect(async () => {
    setLoading(true);
    if (account) {
      await updateOrgUserData(account);
    }
    setLoading(false);
  }, [accounts]);

  useEffect(() => {
    setHasOrganization(id);
  }, [id]);

  useEffect(async () => {
    document.title = "Finvictum | " + (name || "Loading");
  }, [name]);

  useEffect(async () => {
    setIsRegistrationPage(window.location.pathname.includes("registration"));
  });

  return (
    <>
      {loading && (
        <LoadOverlay>
          <LoaderContainer />
        </LoadOverlay>
      )}
      <Header />
      <ContainerDesktop className="row">
        <Nav>
          <div className="nav__inner">
            <Navigation />
            {/* Empty div to push Navigation2 down */}
            <div />
            <Navigation2 />
          </div>
        </Nav>
        <Main className="col">
          <div className="fixed-bg" />
          {children}
        </Main>
      </ContainerDesktop>
      <ContainerMobile className="row">
        <Nav>
          <div className="nav__inner">
            <Navigation2 hideSettings />
          </div>
        </Nav>
        <Main className="col">
          <div className="fixed-bg" />
          <MobileContent>
            <h2>Download our mobile app</h2>
            <br />
            <div>
              You can download the app for{" "}<br/>
              <a target="_blank" href="https://apps.apple.com/be/app/finvictum/id6444575602">
                IOS
              </a>{" "}
              or{" "}
              <a target="_blank" href="https://play.google.com/store/apps/details?id=com.finvictum">
                Android
              </a>
            </div>
          </MobileContent>
        </Main>
      </ContainerMobile>
      <Footer />
    </>
  );
};

export default Layout;
