import { getAxiosClient } from "../axios";

// export const getNews = async (company, amount=10) => {
//   const axiosClient = await getAxiosClient();

//   return await axiosClient
//     .get(`https://eodhistoricaldata.com/api/news?api_token=demo&s=${company}&offset=0&limit=${amount}`)
//     .then((response) => {
//       return response.data;
//     })
//     .catch((error) => {
//       console.log("error", error);
//       return Promise.reject(error);
//     });
// };

export const getNews = async (productIds) => {
  const axiosClient = await getAxiosClient();

  return await axiosClient
    .get(`/news?productids=${productIds}`)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      console.log("error", error);
      return Promise.reject(error);
    });
};

export const getMyNews = async () => {
  const axiosClient = await getAxiosClient();

  return await axiosClient
    .get(`/me/news`)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      console.log("error", error);
      return Promise.reject(error);
    });
};