import React, { useState, useEffect } from "react";
import PageHeaderSub from "../../components/PageHeaderSub";
import Page from "../../components/UI/Elements/Page";
import { useParams } from "react-router-dom";
import HeaderDropDown from "../../components/UI/HeaderDropDown";
import TranslationGrid from "components/TranslationGrid";
import { getEvent, getAdminEventSections, removeEventSectionTranslation, updateEventSectionTranslation } from "api/services/event";
import { BackButton } from "components/UI/Buttons/BackButton";
import { UseErrorContext } from "contexts";
import * as Yup from "yup";

const ValidationSchema = Yup.object().shape({
  title: Yup.string().required("Required"),
  text: Yup.string().required("Required"),
  languageCode: Yup.string().required("Required"),
});

const EventSectionTranslations = () => {
  const { eventId, id } = useParams();
  const [event, setEvent] = useState(null);
  const [eventSection, setEventSection] = useState(null);
  const [translations, setTranslations] = useState([]);
  const { updateError } = UseErrorContext();

  useEffect(async () => {
    refresh();
  }, []);

  const refresh = async () => {
    try {
      const eventData = await getEvent(eventId);
      setEvent(eventData);

      const eventSections = await getAdminEventSections(eventId);
      const eventSectionData = eventSections.find((item) => item.id === Number(id));
      setEventSection(eventSectionData);
      var trans = eventSectionData.translations.filter((item) => !item.isDefault);
      setTranslations(trans);
    } catch (err) {
      updateError(err);
    }
  };

  const add = async (values) => {
    try {
      const resp = await updateEventSectionTranslation(id, values);
      refresh();
      return resp;
    } catch (err) {
      updateError(err);
    }
  };

  const edit = async (translationId, values) => {
    try {
      delete values.id;
      const resp = await updateEventSectionTranslation(id, values);
      refresh();
      return resp;
    } catch (err) {
      updateError(err);
    }
  };

  const remove = async (translationId, languageCode) => {
    try {
      await removeEventSectionTranslation(id, languageCode);
      refresh();
    } catch (err) {
      updateError(err);
    }
  };

  return (
    <Page>
      <div className="navigation-banner">
        <BackButton link={"/events/edit/" + eventId + "/eventsections"} />
        <ul className="breadcrumbs">
          <li>
            <a href="/events">Actions</a>
          </li>
          <li>
            <a href={"/events/edit/" + eventId}>{event?.title}</a>
          </li>
          <li>
            <a href={"/events/edit/" + eventId + "/eventsections"}>Sections</a>
          </li>
          <li>
            <a href={"/events/edit/" + eventId + "/eventsections"}>{eventSection?.title}</a>
          </li>
          <li>
            <span className="selected">Translations</span>
          </li>
        </ul>
      </div>

      <div className="page__header">
        <h1>Translations event section</h1>
        <PageHeaderSub />
      </div>
      <div className="page__inner">
        {eventSection && <TranslationGrid type='section' item={eventSection} fields={["title", "text"]} translations={translations} validationSchema={ValidationSchema} remove={remove} add={add} edit={edit} />}
      </div>
    </Page>
  );
};

export default EventSectionTranslations;
