import React from "react";
import styled from "styled-components";
import { useHistory } from "react-router-dom";
import { Formik, Form, Field, ErrorMessage } from "formik";
import Loader from "components/UI/Loader";
import Button from "components/UI/Buttons/Button";
import * as Yup from "yup";
import { AddOrganizationLink } from "api/services/organization";
import { UseOrganizationContext } from "contexts";
import { UseErrorContext } from "contexts";

const FormWrapper = styled.div`
  label {
    display: block;
    margin-bottom: 0.4rem;
  }
  input {
    width: 100%;
  }
  textarea {
    width: 100%;
    height: 10rem;
  }
  .form_row {
    margin-bottom: 2rem;
    display: flex;
    width: 100%;
    .field {
      width: 100%;
      &:not(:first-of-type) {
        margin-left: 2.4rem;
      }
    }
  }
  .error-message {
    opacity: 0.6;
    color: red;
    padding: 1rem 0;
  }
  .buttons {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    button {
      margin-right: 1rem;
    }
  }
`;

const ValidationSchema = Yup.object().shape({
  type: Yup.string().required("Required"),
  link: Yup.string().required("Required"),
});

export const LinkForm = ({ onClose }) => {
  const history = useHistory();
  const organizationId = UseOrganizationContext()?.id;
  const { updateError } = UseErrorContext();

  const callBack = async (values) => {
    try {
      const resp = await AddOrganizationLink(organizationId, values);
      return resp;
    } catch (err) {
      updateError(err);
      throw err;
    }
  };

  return (
    <FormWrapper>
      <Formik
        initialValues={{
          type: "",
          link: "",
        }}
        validationSchema={ValidationSchema}
        onSubmit={(values, { setSubmitting }) => {
          callBack(values)
            .then((value) => {
              history.go(0);
            })
            .catch((e) => {
              setSubmitting(false);
            });
        }}
      >
        {({ isSubmitting, handleSubmit }) => (
          <Form>
            <div className="form_row">
              <div className="field">
                <label htmlFor="type" className="label required">
                  Type
                </label>
                <Field as="select" name="type">
                  <option value=""></option>
                  <option value="HomePage">Homepage</option>
                  <option value="Instagram">Instagram</option>
                  <option value="Linkedin">Linkedin</option>
                  <option value="Facebook">Facebook</option>
                  <option value="Facebook">Video</option>
                </Field>
              </div>
            </div>
            <div className="form_row">
              <div className="field">
                <label htmlFor="link" className="required">
                  URL
                </label>
                <Field type="text" name="link" />
                <ErrorMessage className="error-message" name="link" component="div" />
              </div>
            </div>
            {!isSubmitting ? (
              <div className="buttons">
                <Button
                  label="cancel"
                  onClick={(e) => {
                    onClose();
                  }}
                  type="secondary"
                />
                <Button
                  onClick={() => {
                    handleSubmit();
                  }}
                  label="Submit"
                  disabled={isSubmitting}
                />
              </div>
            ) : (
              <Loader />
            )}
          </Form>
        )}
      </Formik>
    </FormWrapper>
  );
};
