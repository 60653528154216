import React, { useState, useEffect } from "react";
import styled from "styled-components";
import AddButton from "components/UI/Buttons/AddButton";
import Modal from "components/UI/Modal";
import Trash from "components/UI/Icons/Trash";
import { DocumentForm } from "./DocumentForm";

const Container = styled.div`
  overflow: auto;
`;

const DocumentGrid = ( { documents, isReadOnly, add, edit, remove } ) => {
  const [selectedDocument, setSelectedDocument] = useState(null);
  const [showModalEdit, setShowModalEdit] = useState(false);
  const [showModalDelete, setShowModalDelete] = useState(false);
  const openDeleteModal = async (document) => {

    setSelectedDocument(document);
    setShowModalDelete(true);
  };

  const openAddModal = (e) => {
    e.preventDefault()
    e.stopPropagation();
    e.nativeEvent.stopImmediatePropagation();
    
    setShowModalEdit(true);
  };

  const openEditModal = (document) => {
    setSelectedDocument(document);
    setShowModalEdit(true);
  };

  const _add = async (values) => {
    await add(values);
    setShowModalEdit(false);
  };

  // TODO
  const _edit = async (values) => {
    await edit(selectedDocument.id, values);
    setShowModalEdit(false);
  };

  const _delete = async () => {
    await remove(selectedDocument);
    setShowModalDelete(false);
    setSelectedDocument(null)
  };

  const downloadFile = async (document) => {
    window.location.href = document.uri;
  };

  return (
    <Container>
      {showModalDelete && (
        <Modal title={`Delete "${selectedDocument.name}"`} onClose={() => setShowModalDelete(false)} onSubmit={() => _delete()} cancelLabel={"Cancel"} submitLabel={"Delete"}>
          <div>Are you sure you want to delete this document?</div>
        </Modal>
      )}
      {showModalEdit && (
        <Modal title="Upload Document" hideActions>
          <DocumentForm document={selectedDocument} onClose={() => {setShowModalEdit(false);}} add={_add} edit={_edit}/>
        </Modal>
      )}
      <table cellSpacing="500">
        <thead>
          <tr>
            <th>Filename</th>
            <th></th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {documents?.map((document, idx) => (
            <tr key={idx}>
              <td><a href="#" onClick={() => downloadFile(document)}>{document.file?.name ?? document.fileName}</a></td>
              {/* <td>
                {!isReadOnly && (
                   <div onClick={() => openEditModal(document)} className="icon-button">
                   <Open />
                 </div>
                )}
              </td> */}
              <td>
                {!isReadOnly && (
                  <div onClick={() => openDeleteModal(document)} className="icon-button">
                    <Trash />
                  </div>
                )}
              </td>             
            </tr>
          ))}
        </tbody>
      </table>
      {!isReadOnly && (
        <div onClick={(e) => openAddModal(e)} className="grid-add-btn">
          <AddButton label="Add document"/>
        </div>
      )}
    </Container>
  );
};

DocumentGrid.defaultProps = {
  documents: Array.from(Array(0)),
};

export default DocumentGrid;
