import React, { useState, useEffect } from "react";
import styled from "styled-components";
import Arrow from "./UI/Icons/Arrow";
import { UseUserContext } from "contexts";
import { ValidateAccess } from "api/services/authorization";

const Container = styled.div`
  .nav-link {
    cursor: pointer;
  }
`;

const MenuHeader = styled.div`
  display: flex;
  grid-template-columns: auto auto;
  align-items: end;
  padding-right: 1rem;
  :hover {
    & a {
      color: var(--color-accent-prim);
      opacity: 0.7;
    }
    & svg {
      path {
        fill: var(--color-accent-prim);
        fill-opacity: 0.7;
      }
    }
  }
  .arrow {
    svg {
      width: 2.8rem;
      height: auto;
    }
  }
`;

const MenuBody = styled.div``;

const MenuItem = ({ icon: Icon, label, children, roles, showCollapse }) => {
  const [collapse, setCollapse] = useState(true);
  const { permissions } = UseUserContext();
  const [hasAccess, setHasAccess] = useState(false);

  useEffect(async () => {    
    const access = ValidateAccess(roles, permissions);
    setHasAccess(access);
  }, [permissions]);

  const toggleCollapse = () => {
    setCollapse(!collapse);
  };

  return (
    <Container>
      {hasAccess &&
        <MenuHeader onClick={() => showCollapse && toggleCollapse()}>
          <a href={undefined} className="nav-link">
            <Icon />
            {label}
          </a>
          {showCollapse && (
            <div className="arrow">
              <Arrow transform={collapse} />
            </div>
          )}
        </MenuHeader>
      }
      {!collapse && <MenuBody>{children}</MenuBody>}
    </Container>
  );
};

export default MenuItem;
