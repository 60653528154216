import React, { useState, useEffect } from "react";
import HeaderDropDown from "../../../components/UI/HeaderDropDown";
import SearchBar from "../../../components/UI/SearchBar";
import ShareholdersGrid from "./ShareholdersGrid";
import Loader from "components/UI/Loader";
import { getFilters, getShareholders } from "api/services/investor-insights";
import Pagination from "components/Pagination";
import { GetPagination } from "api/helpers/datahelper";
import { UseOrganizationContext } from "contexts";
import { delay } from "api/helpers/datahelper";
import { UseErrorContext } from "contexts";

const ShareholdersFilteredGrid = ({ showFilters, product, type, hasRegistered, hasEmail }) => {
    const organizationId = UseOrganizationContext()?.id;
    const [shareholders, setShareholders] = useState([]);
    const [loading, setLoading] = useState(false);
    const [search, setSearch] = useState("");
    const [currentPage, setCurrentPage] = useState(1);
    const [numberOfPages, setNumberOfPages] = useState(1);
    const [productFilters, setProductFilters] = useState([]);
    const [bankFilters, setBankilters] = useState([]);
    const [countryFilters, setCountryFilters] = useState([]);
    const [typeFilters, setTypeFilters] = useState([]);
    const [productFilter, setProductFilter] = useState(product);
    const [bankFilter, setBankFilter] = useState(null);
    const [countryFilter, setCountryFilter] = useState(null);
    const [typeFilter, setTypeFilter] = useState(type);
    const [hasRegisteredFilter, setHasRegisteredFilter] = useState(hasRegistered);
    const [hasEmailFilter, setHasEmailFilter] = useState(hasEmail);
    const { updateError } = UseErrorContext();

    // Init
    useEffect(async () => {
      try{
        if (organizationId) {     
          setLoading(true);

          const shareholdersResponse = await getShareholders(organizationId, 
          {
            'page': currentPage,
            'search': search,
            'product': productFilter,
            'bank': bankFilter,
            'country': countryFilter,
            'accountHolderType': typeFilter,
            'hasRegistered': hasRegisteredFilter,
            'hasEmail': hasEmailFilter
          });

          let shareholdersData = shareholdersResponse.data;
          let shareholdersHeader = shareholdersResponse.headers;

          let pagination = GetPagination(shareholdersHeader);
          let totalCount = pagination.totalCount;
          let pageSize = pagination.pageSize;
          let nbrOfPages = Math.ceil(totalCount / pageSize);
            
          setNumberOfPages(nbrOfPages);

          setShareholders(shareholdersData);  
          
          // Set Filters
          const filtersData = await getFilters(organizationId);
          setProductFilters(filtersData["products"]);
          setBankilters(filtersData["banks"]);
          setCountryFilters(filtersData["countries"]);
          setTypeFilters(filtersData["types"]);

          setLoading(false);
        }
      } catch (err) {
        updateError(err);
      }
  }, [organizationId, currentPage, search, productFilter, bankFilter, countryFilter, typeFilter, hasRegisteredFilter]);

  // Search
  const onSearch = async (e) => {
    await delay(1000);    
    setCurrentPage(1);
    const val = e.target.value.toString();
    setSearch(val);
  };

  // Filters
  const onChangeProductFilter = (e) => {
    setCurrentPage(1);
    const val = e.target.value.toString();
    setProductFilter(val);
  };
  const onChangeBankFilter = (e) => {
    setCurrentPage(1);
    const val = e.target.value.toString();
    setBankFilter(val);
  };
  const onChangeCountryFilter = (e) => {
    setCurrentPage(1);
    const val = e.target.value.toString();
    setCountryFilter(val);
  };
  const onChangeTypeFilter = (e) => {
    setCurrentPage(1);
    const val = e.target.value.toString();
    setTypeFilter(val);
  };

  return (
    <>
        <div className="searchBarContainer">
            <SearchBar onChange={onSearch}/>
        </div>
        <HeaderDropDown titleHeader="">
            {loading && <Loader />}
            {!loading && (    
                <> 
                {showFilters &&
                  <div className="filters">
                    <div className="row">
                        <div>
                            <label>Product</label>
                            <select onChange={onChangeProductFilter} value={productFilter}>
                            <option value="">All</option>
                            {productFilters.map((filter, idx) => (                 
                                <option key={idx} value={filter}>{filter}</option>
                            ))}
                            </select>
                        </div>
                        <div>
                            <label>Bank</label>
                            <select onChange={onChangeBankFilter} value={bankFilter}>
                            <option value="">All</option>
                            {bankFilters.map((filter, idx) => (
                                <option key={idx} value={filter}>{filter}</option>
                            ))}
                            </select>
                        </div>
                        <div>
                            <label>Country</label>
                            <select onChange={onChangeCountryFilter} value={countryFilter}>
                            <option value="">All</option>
                            {countryFilters.map((filter, idx) => (
                                <option key={idx} value={filter}>{filter}</option>
                            ))}
                            </select>
                        </div>
                        <div>
                            <label>Type</label>
                            <select onChange={onChangeTypeFilter} value={typeFilter}>
                            <option value="">All</option>
                            {typeFilters.map((filter, idx) => (
                                <option key={idx} value={filter}>{filter}</option>
                            ))}
                            </select>
                        </div>
                    </div>
                  </div>  
                }    

                <ShareholdersGrid shareholders={shareholders}/>
                <Pagination numberOfPages={numberOfPages} currentPage={currentPage} setCurrentPage={setCurrentPage}/>    
                </> 
            )}
        </HeaderDropDown>
    </>
  );
};

ShareholdersGrid.defaultProps = {
  shareholders: Array.from(Array(0)),
};

export default ShareholdersFilteredGrid;
