import React, { useState, useEffect } from "react";
import styled from "styled-components";
import PageHeaderSub from "../../components/PageHeaderSub";
import Page from "../../components/UI/Elements/Page";
import { useParams } from "react-router-dom";
import HeaderDropDown from "../../components/UI/HeaderDropDown";
import { getEvent, getAdminEventSections, addEventSection, deleteEventSection, moveEventSequenceDown, moveEventSequenceUp } from "api/services/event";
import { BackButton } from "components/UI/Buttons/BackButton";
import { UseErrorContext } from "contexts";
import EventSection from "./Components/EventSection";
import Loader from "components/UI/Loader";
import AddButton from "../../components/UI/Buttons/AddButton";

const Container = styled.div`
`;

const EventSections = () => {
  const { id } = useParams();
  const [event, setEvent] = useState(null);
  const [eventSections, setEventSections] = useState([]);
  const { updateError } = UseErrorContext();
  const [isLoading, setIsLoading] = useState(false);
  const [isEdited, setIsEdited] = useState(0);

  useEffect(async () => {    
    refresh();
  }, []);

  useEffect(() => {
    setEventSections(event?.eventSections || []);
  }, [event]);

  const refresh = async () => {  
    try{ 
      const eventData = await getEvent(id);
      setEvent(eventData);

      const eventSectionData = await getAdminEventSections(id);
      setEventSections(eventSectionData)
    } catch (err) {
      updateError(err);
    }
  };

  const _addEventSection = async () => {
    if (isEdited > 0){
      updateError({message: "Save edited section(s) first."});
      return;
    }
    setIsLoading(true);
    addEventSection(event.id, { title: "title", text: "text", isVisible: false })
      .then(async (value) => {
        const resp = await getAdminEventSections(event.id);
        setEvent({ ...event, eventSections: resp });
        setIsLoading(false);
      })
      .catch((err) => {
        updateError(err);
        setIsLoading(false);
      });
  };

  const _removeEventSection = (index) => {
    setIsLoading(true);
    deleteEventSection(index)
      .then(async (value) => {
        const resp = await getAdminEventSections(event.id);
        setEvent({ ...event, eventSections: resp });
        setIsLoading(false);
      })
      .catch((e) => {
        updateError(err);
        setIsLoading(false);
      });
  };

  const moveSequenceUp = async (sectionId) => {
    try {
      setIsLoading(true);
      await moveEventSequenceUp(id, sectionId);
      refresh();
      setIsLoading(false);
    } 
    catch (err) {        
      updateError(err);
    }
  };

  const moveSequenceDown = async (sectionId) => {
    try {
      setIsLoading(true);
      await moveEventSequenceDown(id, sectionId);
      await refresh();
      setIsLoading(false);
    }
    catch (err) {        
      await updateError(err);
    }
  };

  const onChangeEdited = (val) => {
    const x = val ? isEdited + 1 : isEdited > 0 ? isEdited - 1 : 0;
    setIsEdited(x);
  }

  return (
    <Page>  
      <div className="navigation-banner">
        <BackButton link={"/events/edit/" + id}/>
        <ul className="breadcrumbs">
          <li>
            <a href="/events">Actions</a>
            </li>
          <li>
            <a href={"/events/edit/" + id}>{event?.title}</a>
            </li>
          <li>            
            <span className="selected">Sections</span>
          </li>
        </ul> 
      </div>

      <div className="page__header">
        <h1></h1>
        <PageHeaderSub/>  
      </div>
      <div className="page__inner">
        <HeaderDropDown titleHeader="Event sections">
          <Container>
            {eventSections.map((item, idx) => (
              <EventSection item={item} event={event} key={idx} idx={idx} isLoading={isLoading} removeTab={_removeEventSection} moveSequenceUp={moveSequenceUp} moveSequenceDown={moveSequenceDown} isFirst={idx == 0} isLast={idx == eventSections.length-1} onChangeEdited={onChangeEdited}/>
            ))}
            {eventSections.length < 25 && (
              <div className="button__container">{isLoading ? <Loader center={false} /> : <AddButton _onClick={() => _addEventSection()} />}</div>  
            )}
          </Container>
         </HeaderDropDown>
      </div>
    </Page>
  );
};

export default EventSections;
