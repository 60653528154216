import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { ValidateAccess } from 'api/services/authorization';
import { NavLink } from 'react-router-dom';
import { UseUserContext } from "contexts";

const Container = styled.div`
  .cover {
    cursor: inherit;
  }
`;

 const PrivateCard = ({ to, roles, ...rest }) => {
  const [ hasAccess, setHasAccess ] = useState(false);
  const { permissions } = UseUserContext();

  useEffect(async () => {    
    const access = ValidateAccess(roles, permissions);
    setHasAccess(access);
  }, [permissions]);

  const handleClick = (e) => {
    if (!hasAccess)
      e.preventDefault()
  }

    return (
      <Container>
        <NavLink to={to} {...rest} onClick={handleClick} style={{ cursor: hasAccess ? "pointer" : "default" }}/>
      </Container>
    );
 };

 export default PrivateCard;