import React, { useState, useEffect } from "react";
import InputMask from "react-input-mask";
import { UseOrganizationContext } from "contexts";
import { getAxiosClient } from "api/axios";
import HeaderDropDown from "../../components/UI/HeaderDropDown";
import styled from "styled-components";
import PageHeaderSub from "../../components/PageHeaderSub";
import Page from "../../components/UI/Elements/Page";
import Button from "../../components/UI/Buttons/Button";
import { useHistory } from "react-router-dom";
import Loader from "components/UI/Loader";
import { getProducts } from "api/services/product";
import DisclosureBar from "components/UI/Bars/DisclosureBar";
import { DISCLOSURESTATUS } from "constants";
import { UseErrorContext } from "contexts";
import DynamicTabs from "components/WithTabs";

const Form = styled.div`
  input {
    width: 15rem;
  }
  input[type="file"] {
    margin-bottom: 3rem;
    width: 30rem;
  }

  .request-id {
    input {
      width: 25rem;
    }
  }

  .disclosure-record {
    &:not(:last-of-type) {
      margin-bottom: 1.2rem;
    }
  }

  &.history__container {
    a {
      color: white !important;
      display: block;
      margin-bottom: 0.5rem;
      &:hover {
        text-decoration: underline;
      }
    }
  }
  .row-item {
    display: flex;
  }
  select {
    width: 18rem;
    height: 3rem;
    margin-top: 0.4rem;
  }
  .form-element-container {
    display: flex;
    flex-direction: column;
    margin-right: 2.4rem;
  }
  .error-field {
    font-size: 1.6rem;
    color: red;
    margin-top: 0.4rem;
    opacity: 0.8;
  }
`;

const JobGrid = styled.div`
  .disclosure-record {
    &:not(:last-of-type) {
      margin-bottom: 1.2rem;
    }
  }

  &.history__container {
    a {
      color: white !important;
      display: block;
      margin-bottom: 0.5rem;
      &:hover {
        text-decoration: underline;
      }
    }
  }
  .row-item {
    display: flex;
  }
`;



const LoaderScreen = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [loadingText, setLoadingText] = useState("");
  const [loadingData, setLoadingData] = useState(true);
  const [sendingMail, setSendingMail] = useState(false);
  const [products, setProducts] = useState([]);
  const [processingData, setProcessingData] = useState([]);
  const [file1, setFile1] = useState(null);
  const [select1, setSelect1] = useState(-1);
  const [date1, setDate1] = useState("");
  const [requestID1, setRequestID1] = useState("");
  const [errors, setErrors] = useState({});
  const [autoRefresh, setAutoRefresh] = useState(true);
  const { updateError } = UseErrorContext();

  const id = UseOrganizationContext()?.id;

  const getProcessingJobs = async (orgid) => {
    if (!orgid) return;
    const axiosClient = await getAxiosClient();
    try {
      return await axiosClient
        .get(`/loader/organizations/${orgid}/processingJobs?from=2021-01-01`)
        .then((response) => {
          return response.data;
        })
        .catch((error) => {
          return Promise.reject(error);
          //alert("error", error);
        });
    } catch (error) {
      console.log("error2", error);
    }
  };

  const uploadFileApi = async (file, product, requestDate, requestID) => {
    try {
      const axiosClient = await getAxiosClient();
      let formdata = new FormData();
      formdata.append("file", file);
      formdata.append("productId", product);
      formdata.append("disclosureRecordDate", requestDate);
      formdata.append("disclosureRequestId", requestID);

      if (!file || !product || !requestDate || !requestID) {
        throw new Error("Somehting went wrong");
      }

      return await axiosClient
        .post(`/loader/organizations/${id}/investorinsights`, formdata, { "content-type": "multipart/form-data" })
        .then((response) => {
          console.log(response.data);
          return response.data;
        })
        .catch((error) => {
          console.log("error1", error);
          return Promise.reject(error);
          //alert("error", error);
        });
    } catch (error) {
      console.log("error2", error);
    }
  };

  const uploadFile = async () => {
    let errorsObj = {};

    if (file1) {
      if (select1 == -1) errorsObj.select1 = "Required";
      if (!date1) errorsObj.date1 = "Required";
      if (!requestID1) errorsObj.requestID1 = "Required";
    }

    if (Object.keys(errorsObj).length > 0) {
      setErrors(errorsObj);
      return;
    }

    setIsLoading(true);
    setLoadingText("Uploading data...");

    let upload1;

    if (file1) {
      upload1 = await uploadFileApi(file1, select1, date1, requestID1);
    }

    setTimeout(() => {
      setLoadingText("Merging shareholders");
    }, 1000);
    setTimeout(async () => {
      const processingJobsData = await getProcessingJobs(id);
      setProcessingData(processingJobsData);
      setLoadingText(null);
      setIsLoading(false);
      setAutoRefresh(true);
    }, 2000);
  };

  useEffect(async () => {
    try {
      if (id) {
        const productsData = await getProducts(id);
        const processingJobsData = await getProcessingJobs(id);
        setProducts(productsData);
        setProcessingData(processingJobsData);
        setLoadingData(false);
      }
    } catch (err) {
      updateError(err);
    }
  }, [id]);

  let interval;
  useEffect(() => {
    if (!id) return;

    if (interval) {
      if (!autoRefresh) clearInterval(interval);
    } else {
      interval = setInterval(async () => {
        const processingJobsData = await getProcessingJobs(id);
        setProcessingData(processingJobsData);

        let keepRefreshing = processingJobsData.some((el) => el.status !== DISCLOSURESTATUS.SENTMAILING);
        if (!keepRefreshing) {
          setAutoRefresh(keepRefreshing);
          clearInterval(interval);
        }
      }, 60000);

      return () => clearInterval(interval); // This represents the unmount function, in which you need to clear your interval to prevent memory leaks.
    }
  }, [id, autoRefresh]);

  const _sendInvites = async (jobID) => {
    setSendingMail(true);
    const axiosClient = await getAxiosClient();
    try {
      return await axiosClient
        .post(`/loader/processingjobs/${jobID}/sendinvites`)
        .then(async (response) => {
          console.log(response.data);
          const processingJobsData = await getProcessingJobs(id);
          setProcessingData(processingJobsData);
          setSendingMail(false);
          return response.data;
        })
        .catch((error) => {
          console.log("error", error);
          setSendingMail(false);
          return Promise.reject(error);
          //alert("error", error);
        });
    } catch (error) {
      console.log("error", error);
      setSendingMail(false);
      //alert("error", error);
    }
  };

  const tabsData = [
    { id: "company", title: "COMPANY", content: "This tab contains details about the company." },
    { id: "investor", title: "INVESTOR", content: "This tab provides information for investors." },
    { id: "employee", title: "EMPLOYEE", content: "This tab contains employee-related information." },
  ];

  return (
    <Page>
      <div className="page__header">
        <h1>Finvictum Loader</h1>
        <PageHeaderSub />
      </div>
      <div className="page__inner">
        <HeaderDropDown titleHeader="Upload your Shareholder disclosures or Nominative list">
          {loadingData ? (
            <Loader />
          ) : (
            <Form>
              <DynamicTabs
                tabsData={[
                  {
                    id: "disclosure",
                    title: "Disclosure",
                    content: (
                      <div className="row-item">
                        <div className="form-element-container">
                          <label htmlFor="productselect">Select a file #1</label>
                          <input type="file" accept=".xls,.xlsx,.csv,.tsv" placeholder="Upload a new file" title="Upoad" onChange={(e) => setFile1(e.target.files[0])} />
                        </div>
                        <div className="form-element-container">
                          <label htmlFor="productselect">Select a product </label>
                          <select value={select1} id="productselect" onChange={(e) => setSelect1(e.target.value)}>
                            <option value={-1}>none</option>
                            {products.map((item, key) => (
                              <option value={item.id} key={key}>
                                {item.title}
                              </option>
                            ))}
                          </select>
                          <div className="error-field">{errors.select1}</div>
                        </div>
                        <div className="form-element-container">
                          <label htmlFor="productselect">Request date</label>
                          <InputMask mask="9999-99-99" placeholder="YYYY-MM-DD" value={date1} onChange={(e) => setDate1(e.target.value)}></InputMask>
                          <div className="error-field">{errors.date1}</div>
                        </div>
                        <div className="form-element-container request-id">
                          <label htmlFor="productselect">Request ID</label>
                          <input placeholder="EIIBEXXXXXXXXXXXXXXXXXXXXXXXX" value={requestID1} onChange={(e) => setRequestID1(e.target.value)} />
                          <div className="error-field">{errors.requestID1}</div>
                        </div>
                      </div>
                    ),
                  },
                  {
                    id: "nominative",
                    title: "Nominative",
                    content: (
                      <div className="row-item">
                        <div className="form-element-container">
                          <label htmlFor="productselect">Select a file #1</label>
                          <input type="file" accept=".xls,.xlsx,.csv,.tsv" placeholder="Upload a new file" title="Upoad" onChange={(e) => setFile1(e.target.files[0])} />
                        </div>
                        <div className="form-element-container">
                          <label htmlFor="productselect">Select a product </label>
                          <select value={select1} id="productselect" onChange={(e) => setSelect1(e.target.value)}>
                            <option value={-1}>none</option>
                            {products.map((item, key) => (
                              <option value={item.id} key={key}>
                                {item.title}
                              </option>
                            ))}
                          </select>
                          <div className="error-field">{errors.select1}</div>
                        </div>
                        <div className="form-element-container">
                          <label htmlFor="productselect">Request date</label>
                          <InputMask mask="9999-99-99" placeholder="YYYY-MM-DD" value={date1} onChange={(e) => setDate1(e.target.value)}></InputMask>
                          <div className="error-field">{errors.date1}</div>
                        </div>
                      </div>
                    ),
                  },
                ]}
              />

              {!isLoading && <Button disabled={!file1} onClick={uploadFile} label={"Upload"} />}
              {isLoading && <div>{loadingText}</div>}
              {isLoading && <Loader center={false} />}
            </Form>
          )}
        </HeaderDropDown>
        <HeaderDropDown titleHeader="In Progress">
          <JobGrid className="history__container">
            {loadingData && <Loader />}
            {processingData &&
              processingData.map(
                (item, key) =>
                  item.status !== DISCLOSURESTATUS.SENTMAILING && (
                    <div key={key} className="disclosure-record">
                      <DisclosureBar key={key} data={item} _onClick={() => _sendInvites(item.id)} buttonDisabled={sendingMail} />
                    </div>
                  )
              )}
          </JobGrid>
        </HeaderDropDown>
        <HeaderDropDown titleHeader="Completed">
          <JobGrid className="history__container">
            {loadingData && <Loader />}
            {processingData &&
              processingData.map(
                (item, key) =>
                  item.status === DISCLOSURESTATUS.SENTMAILING && (
                    <div key={key} className="disclosure-record">
                      <DisclosureBar key={key} _onClick={() => _sendInvites(item.id)} data={item} />
                    </div>
                  )
              )}
          </JobGrid>
        </HeaderDropDown>
      </div>
    </Page>
  );
};

export default LoaderScreen;
