import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { editEventSection, editImageEventSection, moveEventSequenceUp, moveEventSequenceDown } from "api/services/event";
import Image from "components/UI/Images/Image";
import Modal from "components/UI/Modal";
import { FaTrash, FaSave } from "react-icons/fa";
import Status from "components/UI/Status";
import { UseErrorContext } from "contexts";
import { Link } from "react-router-dom";
import Button from "components/UI/Buttons/Button";
import ArrowUp from "components/UI/Icons/ArrowUp";
import ArrowDown from "components/UI/Icons/ArrowDown";
import { resizeImage } from "utils";
import MDEditor from "components/UI/Blocks/MDEditor";
import TextArea from "components/UI/Forms/TextArea";
import { useTranslation } from "react-i18next";
import { UseOrganizationContext } from "contexts";

const Container = styled.div`
  .tab__editor {
    margin-left: 3.2rem;
    width: calc(70% - 3.2rem);
  }
`;

const EventSection = ({ item, event, idx, isLoading, removeTab, moveSequenceUp, moveSequenceDown, isFirst, isLast, onChangeEdited }) => {
  const [title, setTitle] = useState(item.title);
  const [text, setText] = useState(item.text);
  const [isVisible, setIsVisible] = useState(item.isVisible);
  const [coverFile, setCoverFile] = useState(item.imageUrl);
  const [cover, setCover] = useState(item.imageUrl);
  const [isSaving, setIsSaving] = useState(false);
  const [isEdited, setIsEdited] = useState(false);
  const [uploadingCover, setUploadingCover] = useState(false);
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
  const [height, setHeight] = useState("100%");
  const { updateError } = UseErrorContext();

  const {logoUrl} = UseOrganizationContext();
  const { t } = useTranslation();

  useEffect(async () => {
    setTitle(item.title);
    setText(item.text);
    setIsVisible(item.isVisible);
    setCover(item.imageUrl);
  }, [item]);

  useEffect(async () => {
    var edited = title != item.title || text != item.text || item.imageUrl != cover || item.isVisible != isVisible;
    setIsEdited(edited);
  }, [title, text, cover, isVisible, item]);

  useEffect(async () => {
    onChangeEdited(isEdited);
  }, [isEdited]);

  const saveTab = async () => {
    if (text?.length > 10000) {
      updateError({ message: t("Max. length of text is 5000 characters.") });
      return;
    }
    if (title?.length > 5000) {
      updateError({ message: t("Max. length of title is 50 characters.") });
      return;
    }
    if (title != item.title || text != item.text || item.imageUrl != cover || item.isVisible != isVisible) {
      try {
        setIsSaving(true);
        const resp = await editEventSection(item.id, title, text, isVisible);
        item.title = title;
        item.text = text;
        item.isVisible = isVisible;

        if (coverFile) {
          if (item.imageUrl != cover) {
            await editImageEventSection(item.id, coverFile);
          }
        }
        setIsSaving(false);
        setIsEdited(false);
      } catch (err) {
        updateError(err);
        setIsSaving(false);
      }
    }
  };

  // const onChangeImage = async (e) => {
  //   if (uploading) return;
  //   setUploading(true);
  //   try {
  //     const resp = await EditImageOrganizationEventSection(organizationId, item.id, e);
  //     setUploading(false);
  //     await refresh();
  //   } catch (err) {
  //     alert(err);
  //     setUploading(false);
  //   }
  // };

  const previewImage = async (e) => {
    setUploadingCover(true);
    let selectedFile = e.target.files[0];

    let fileSizeInMegabytes = selectedFile.size / (1024 * 1024);

    if (fileSizeInMegabytes > 10) {
      updateError({ message: t("Image should be smaller then 10mb.") });
      setUploadingCover(false);
      return;
    }

    const resizedImage = await resizeImage(selectedFile, 1250);

    setCoverFile(resizedImage);

    let reader = new FileReader();
    reader.onload = function (event) {
      setCover(event.target.result);
    };

    reader.readAsDataURL(selectedFile);
    setUploadingCover(false);
  };

  return (
    <Container>
      <div className={`tab__item ${isLoading ? "loading" : ""}`}>
        <div className="tab__header">
          <div className="tab__header__left">
            <span className="label">Section {idx + 1}</span>
            <input
              disabled={isLoading}
              value={title}
              maxLength="50"
              onChange={(e) => {
                setTitle(e.target.value);
              }}
              placeholder="Tab title"
            />
          </div>

          <div className="tab__header__right">
            {/* Translations are not yet supported by the full application  */}
            <Link to={`/events/edit/${event?.id}/eventsections/${item.id}/translations`}>
              <Button label="Translations" />
            </Link>
            <Status label={isVisible ? "Published" : "Publish"} onClick={() => setIsVisible(!isVisible)} />
            {/* <div className="switch-container">
              Visible to public
              <SwitchButtonOn on={isVisible} onSwitchOn={() => setIsVisible(true)} onSwitchOff={() => setIsVisible(false)}/>
            </div>         */}
            {isEdited && (
              <div onClick={() => saveTab()} className="icon-button">
                <span>{isSaving ? "Saving" : ""}</span>
                <FaSave />
              </div>
            )}
            <div onClick={() => setShowDeleteConfirmation(true)} className="icon-button">
              <FaTrash />
            </div>
          </div>
        </div>
        <div className="tab__content">
          <div className="sequence">
            <div onClick={() => moveSequenceUp(item.id)} className="icon-button">
              <ArrowUp disabled={isFirst} />
            </div>
            <div onClick={() => moveSequenceDown(item.id)} className="icon-button">
              <ArrowDown disabled={isLast} />
            </div>
          </div>
          <div className="tab__thumb">
            <Image url={cover||logoUrl} onChange={previewImage} isLoading={uploadingCover} />
          </div>
          <div className="tab__editor">
            <MDEditor value={text} setFieldValue={(val) => setText(val)} />
          </div>
        </div>
      </div>
      {showDeleteConfirmation && (
        <Modal
          title={`Delete "${item.title}"`}
          onClose={() => setShowDeleteConfirmation(false)}
          onSubmit={() => {
            removeTab(item.id);
            setShowDeleteConfirmation(false);
          }}
          cancelLabel={"Cancel"}
          submitLabel={"Delete"}
        >
          <div>Are you sure you want to delete this section?</div>
        </Modal>
      )}
    </Container>
  );
};

export default EventSection;
