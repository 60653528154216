import React, { useState } from "react";
import styled from "styled-components";
import { useHistory } from "react-router-dom";
import { Formik, Form, Field, ErrorMessage } from "formik";
import Loader from "components/UI/Loader";
import Button from "components/UI/Buttons/Button";
import * as Yup from 'yup'
import { updateAnswer } from "api/services/communication";
import { sendAnswer } from "api/services/communication";
import { UseErrorContext } from "contexts";

const FormWrapper = styled.div`
`;

const ValidationSchema = Yup.object().shape({
  response: Yup.string().required('Required'),
}) 

export const MailForm = ( {mail, onClose} ) => {  
  const history = useHistory();
  const { updateError } = UseErrorContext();

  const callBack = async (response) => {    
    try {
      let obj = {"text": response.response};
      if (mail.answer){
        return await updateAnswer(mail.answer.id, obj);
      }
      else{        
        obj.questionIds = [mail.id];
        return await sendAnswer(obj);
      }
    } catch (error) {
      updateError(err);
    }    
  };

  return (
    <FormWrapper>
      <Formik
        initialValues={ { 
          sender: mail?.user.email, 
          message: mail?.question,
          response: mail?.answer?.message } } 
          validationSchema={ValidationSchema}          
          onSubmit={(values, { setSubmitting }) => {
            callBack(values)
              .then((value) => {
                setSubmitting(false);
                onClose();
              })
              .catch((e) => {
                console.log(e);
                alert("Something went wrong", e.message);
                setSubmitting(false);
              });
          }}
          >
        {({ isSubmitting, handleSubmit }) => (
          <Form className="form">
          <div className="form_row">
            <div className="field">
              <label htmlFor="mails">Event:</label>                 
              <span style={{fontStyle:"italic"}}><a href={"/events/edit/" + mail.event.id}>{mail.event.name}</a></span>
            </div>
          </div>
            <div className="form_row">
              <div className="field">
                <label htmlFor="mails">From:</label>                 
                <Field type="text" name="sender" disabled/>
              </div>
            </div>
            <div className="form_row">
              <div className="field">
                <label htmlFor="message">Message:</label>
                <Field as="textarea" name="message" disabled/>
              </div>
            </div>
            <div className="form_row">
            <div className="field">
                <label htmlFor="message">Response:</label>
                <Field as="textarea" name="response"/>
              </div>
            </div>
            {!isSubmitting ? (
              <div className="buttons">
                <Button label="Close" onClick={() => onClose()} type="secondary"/>
                <Button onClick={()=>{handleSubmit()}} label="Send" disabled={isSubmitting} />         
              </div>
            ) : (
              <Loader />
            )}
          </Form>
        )}
      </Formik>
    </FormWrapper>
  );
};
