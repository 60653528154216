import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import styled from "styled-components";
import AddButton from "../../../components/UI/Buttons/AddButton";
import Modal from "components/UI/Modal";
import Trash from "components/UI/Icons/Trash";
import { LinkForm } from "./LinkForm";
import { editLinkIsFeatured, removeLink } from "api/services/event";
import { UseErrorContext } from "contexts";
import Star from "components/UI/Icons/Star";
import Open from "components/UI/Icons/Open";

const Container = styled.div`
  overflow: auto;
`;

const LinkGrid = ( { links, refresh, isReadOnly } ) => {
  const { id } = useParams();
  const [showModal, setShowModal] = useState(false);
  const [selectedLink, setSelectedLink] = useState(null);
  const [showModalDelete, setShowModalDelete] = useState(false);
  const { updateError } = UseErrorContext();

  const _addLink = () => {
    setShowModal(true);
  };

  const showDeleteModal = async (link) => {
    setSelectedLink(link);
    setShowModalDelete(true);
  };

  const _delete = async () => {
    try{
      await removeLink(id, selectedLink.id);
      setShowModalDelete(false);
      refresh();
    } catch (err) {
      updateError(err);
    }
  };
  const changeFeatured = async (link) => { 
    await editLinkIsFeatured(link.id, !link.isFeatured);
    await refresh();
  };

  const open = (link) => {
    setSelectedLink(link);
    setShowModal(true);
  };

  return (
    <Container>
      {showModalDelete && (
        <Modal title={`Delete "${selectedLink.name}"`} onClose={() => setShowModalDelete(false)} onSubmit={() => _delete()} cancelLabel={"Cancel"} submitLabel={"Delete"}>
          <div>Are you sure you want to delete this link?</div>
        </Modal>
      )}
      {showModal && (
        <Modal title="Link" hideActions>
          <LinkForm onClose={() => {setShowModal(false); refresh()}} link={selectedLink}/>
        </Modal>
      )}
      <table cellSpacing="500">
        <thead>
          <tr>
            <th></th>
            <th>Name</th>
            {!isReadOnly && <th>Language</th>}
            <th>URL</th>
            <th></th>
            <th></th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {links?.map((link, idx) => (
            <tr key={idx}>
              <td></td>
              <td style={{minWidth:"20rem"}}>{link.name}</td>
              {!isReadOnly && <td>{link.languageCode ?? "ALL" }</td>}
              <td><a href={link.url} target="_blank">{link.url}</a></td>      
              <td>
                {!isReadOnly && (
                  <div onClick={() => changeFeatured(link)} className="icon-button">
                    <Star roles={["Events.Create"]} active={link.isFeatured}/>
                  </div>
                )}
              </td> 
              <td>
                {!isReadOnly && (
                   <div onClick={() => open(link)} className="icon-button">
                   <Open />
                 </div>
                )}
              </td>
              <td>
                {!isReadOnly && (
                  <div onClick={() => showDeleteModal(link)} className="icon-button">
                    <Trash />
                  </div>
                )}
              </td>            
            </tr>
          ))}
        </tbody>
      </table>
      {!isReadOnly && (
        <div onClick={() => _addLink()} className="grid-add-btn">
          <AddButton label="Add link"/>
        </div>
      )}
    </Container>
  );
};

LinkGrid.defaultProps = {
  links: Array.from(Array(0)),
};

export default LinkGrid;
