import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { UseOrganizationContext } from "contexts";
import { uploadLogo, uploadCover } from "api/services/organization";
import Image from "components/UI/Images/Image";
import { UseErrorContext } from "contexts";

const Container = styled.div`
  height: 30rem;
  width: 100%;
  position: relative;
  border-radius: 0.8rem;  
  .company-logo {    
    img{
      top: auto;
      width: 16rem;
      height: 16rem;
      left: 6rem;
      bottom: -25%;
      z-index: 1;
      ;
    } 
    input {
      width: 16rem;
      height: 16rem;
      left: 6rem;
      bottom: -25%;
      z-index: 1;
      ;
    }
  }
  .company-banner {  
    top: 0;
    left: 0;
  }
`;

const ProfileHeader = ({ logoUrl, coverUrl, updatedTimeUtc, refreshData }) => {
  const [uploadingCover, setUploadingCover] = useState(false);
  const [uploadingBanner, setUploadingBanner] = useState(false);
  const { id } = UseOrganizationContext();
  const { updateError } = UseErrorContext();

  const onChangeLogo = async (e) => {
    if (uploadingCover) return;
    setUploadingCover(true);
    try {
      const resp = await uploadLogo(id, e);
      await refreshData();
      setUploadingCover(false);
    } catch (err) {      
      updateError(err);
      setUploadingCover(false);
    }
  };

  const onChangeBanner = async (e) => {
    if (uploadingBanner) return;
    setUploadingBanner(true);
    try {
      const resp = await uploadCover(id, e);
      await refreshData();
      setUploadingBanner(false);
    } catch (err) {
      updateError(err);
      setUploadingBanner(false);
    }
  };

  return (
    <Container className="profile__header">
      <div className="company-banner" >
        <Image url={coverUrl} onChange={onChangeBanner} isLoading={uploadingBanner}/>    
      </div>
      <div className="company-logo" >
        <Image url={logoUrl} onChange={onChangeLogo} isLoading={uploadingCover}/>    
      </div>
    </Container>
  );
};

export default ProfileHeader;
