import React, { useState, useEffect, createContext, useRef } from "react";
import { InfrontSDK } from "../libs/infrontFramework";
import { getAxiosClient } from "../api/axios";

export const WebsocketContext = createContext();

export const WebsocketProvider = ({ children }) => {
  const [isReady, setIsReady] = useState(false);
  const [token, setToken] = useState(false);

  const ws = useRef(null);

  const initInfrontSDK = async () => {
    const axiosClient = await getAxiosClient();

    const tokenAPI = await axiosClient
      .get(`/infront/token`)
      .then((response) => {
        //console.log("infront token", response.data?.access_Token);
        setToken(response.data?.access_Token);
        return response.data?.access_Token;
      })
      .catch((error) => {
        console.log("Infront token error", error);
        //alert("error", error);
        return Promise.reject(error);
      });

    //console.log("Infront token response", token);

    if (tokenAPI) {
      var sdk = new InfrontSDK.SDK({
        signedToken: tokenAPI,
        onReady: () => {
          setIsReady(true);
        },
        onLoginFailedCall: (event) => {
          console.log("Infrontlogin failed", event);
        },
        onDisconnect: (event) => {
          console.warn("Disconnected:", event);
        },
      });
    }

    ws.current = sdk;
  };

  useEffect(() => {
    initInfrontSDK();
    return () => {
      //TODO: disconnect
    };
  }, []);

  return <WebsocketContext.Provider value={{ ws: ws?.current, isReady, token }}>{children}</WebsocketContext.Provider>;
};
