import React, { useState, useEffect } from "react";
import styled from "styled-components";
import HeaderDropDown from "../components/UI/HeaderDropDown";
import PageHeaderSub from "../components/PageHeaderSub";
import Page from "../components/UI/Elements/Page";
import SearchBar from "../components/UI/SearchBar";
import { getFollowers, getFilters } from "api/services/user";
import { UseOrganizationContext } from "contexts";
import Pagination from "components/Pagination";
import { GetPagination } from "api/helpers/datahelper";
import Loader from "components/UI/Loader";
import { delay } from "api/helpers/datahelper";
import { UseErrorContext } from "contexts";

const Container = styled.div`
`;

const Followers = () => {
  const organizationId = UseOrganizationContext()?.id;
  const [followers, setFollowers] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [numberOfPages, setNumberOfPages] = useState(1);
  const [loading, setLoading] = useState(false);
  const [search, setSearch] = useState("");
  const [productFilters, setProductFilters] = useState([]);
  const [productFilter, setProductFilter] = useState(null);
  const { updateError } = UseErrorContext();

  useEffect(async () => {
    try{
      if (organizationId){         
        setLoading(true);

        var params = {
          'page': currentPage,
          'search': search
        }
        if (productFilter){
          params["productIds"] = parseInt(productFilter);
        }

        let followersReponse = await getFollowers(organizationId, params);

        let followersData = followersReponse.data;

        let followersHeaders = followersReponse.headers;
        let pagination = GetPagination(followersHeaders);
        let totalCount = pagination.totalCount;
        let pageSize = pagination.pageSize;

        let nbrOfPages = Math.ceil(totalCount / pageSize);
        setNumberOfPages(nbrOfPages);

        setFollowers(followersData);   

        // Set Filters
        const filtersData = await getFilters(organizationId);
        setProductFilters(filtersData["products"]);

        setLoading(false);
      }      
    } catch (err) {
      updateError(err);
    }
  }, [organizationId, currentPage, search, productFilter]);

  // Search
  const onSearch = async (e) => {
    await delay(1000);
    setCurrentPage(1);
    const val = e.target.value.toString();
    setSearch(val);
  };

  // Filters
  const onChangeProductFilter = (e) => {
    setCurrentPage(1);
    const val = e.target.value.toString();
    setProductFilter(val);
  };

  return (
    <Page>
      <Container>
        <div className="page__header">
          <h1>FOLLOWERS</h1>
          <PageHeaderSub />
        </div>
        <div className="page__inner">
          <div className="searchBarContainer">
            <SearchBar onChange={onSearch}/>
          </div>
          <HeaderDropDown titleHeader="">
          {loading && <Loader />}
          {!loading && (  
            <>
              <div className="filters">
                <div className="row">
                  <div>
                    <label>Product</label>
                      <select onChange={onChangeProductFilter} value={productFilter}>
                        <option value="">All</option>
                        {productFilters?.map((filter, idx) => (                 
                          <option key={idx} value={filter.id}>{filter.title}</option>
                        ))}
                      </select>
                  </div>
                </div>
              </div>  
              <table cellSpacing="500">
                <thead>
                  <tr>
                    <th>Country</th>
                    <th>Name</th>
                    <th>Email</th>
                    <th>Product(s)</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  {followers?.map((follower, idx) => (
                    <tr key={idx}>
                      <td>{follower.country}</td>
                      <td>{`${follower.firstName} ${follower.lastName}`}</td>
                      <td>{follower.email}</td>
                      <td>{follower.followedProducts?.map(product => product.productName)}</td>
                      <td></td>
                    </tr>
                  ))}
                </tbody>
              </table>
              <Pagination numberOfPages={numberOfPages} currentPage={currentPage} setCurrentPage={setCurrentPage} />
              </>
            )}  
          </HeaderDropDown>
        </div>
      </Container>
    </Page>
  );
};

export default Followers;
