import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { ValidateAccess } from 'api/services/authorization';
import { NavLink } from 'react-router-dom';
import { UseUserContext } from "contexts";

const Container = styled.div`
  .disabled {
    color: var(--color-medium-grey);
    & svg{
      path{
        fill: var(--color-medium-grey);
      }
    }
  }
`;

 const PrivateNavLink = ({ to, roles, icon: Icon, label }) => {
  const [hasAccess, setHasAccess] = useState(false);
  const { permissions } = UseUserContext();

  useEffect(async () => {    
    const access = ValidateAccess(roles, permissions);
    setHasAccess(access);
  }, [permissions]);

    return (
        <Container>
          { hasAccess &&
            // <NavLink to={hasAccess ? to : "/unauthorized"}> 
            <NavLink to={to}> 
              {Icon && <Icon/>}
              {label}
            </NavLink>  
          }
        </Container>
    );
 };

 export default PrivateNavLink;