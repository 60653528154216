import styled from "styled-components";
import React from "react";

const B = styled.button`
  border: none;
  background-color: transparent;
`;

export default function Followers() {
  return (
    <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 512 512"
    width="14"
    height="14"
    xmlSpace="preserve"
  >
    <path d="M506.245 220.091c-69.79-74.59-157.899-112.363-246.339-113.374-.728-.019-1.46-.021-2.191-.029-.49-.002-.979-.019-1.469-.019-.082 0-.164-.003-.247-.003-1.399 0-2.792.023-4.182.061-88.164 1.228-176.113 38.985-246.025 113.324-8.072 8.583-7.657 22.084.925 30.156 8.583 8.072 22.084 7.657 30.156-.925 27.43-29.167 57.876-51.949 90.009-68.348-12.849 22.055-20.216 47.698-20.216 75.067 0 82.481 66.853 149.333 149.333 149.333S405.333 338.481 405.333 256c0-27.469-7.423-53.2-20.36-75.31 32.217 16.41 62.706 39.257 90.116 68.552 8.05 8.603 21.55 9.052 30.153 1.003 8.604-8.05 9.052-21.55 1.003-30.154zM362.667 256c0 58.917-47.75 106.667-106.667 106.667S149.333 314.917 149.333 256c0-57.89 46.102-104.996 103.599-106.62 1.098-.013 2.195-.044 3.293-.044.191 0 .38.006.571.007.668.001 1.335.02 2.003.027 57.623 1.484 103.868 48.649 103.868 106.63z"
     fill="white"
    />
    <path d="M256 213.333c11.782 0 21.333-9.551 21.333-21.333 0-11.782-9.551-21.333-21.333-21.333-47.131 0-85.333 38.202-85.333 85.333s38.202 85.333 85.333 85.333 85.333-38.202 85.333-85.333c0-11.782-9.551-21.333-21.333-21.333s-21.333 9.551-21.333 21.333c0 23.567-19.099 42.667-42.667 42.667s-42.667-19.1-42.667-42.667 19.1-42.667 42.667-42.667z"
     fill="white"
    />
  </svg>
  );
}
