import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { GetEventDate } from "api/helpers/datahelper";
import Button from "components/UI/Buttons/Button";
import PublishButton from "components/PublishButton";
import HTMLRenderView from "components/UI/Blocks/HTMLRenderView";

const Container = styled.div`  
  .table{
    margin: 4rem 0;
    > .field h2{
      margin: 1rem 0;
    }
  }
  .image {
      position: absolute;
      object-fit: cover;
      width: 100%;
      height: 100%;
    }
`;

const ReviewSubmit = ({ event, translations, documents, links, communicationGroups, backStep, sections, relatedChildEvents, relatedParentEvents, save, publish }) => {

  return (
    <Container>       
        <div className="form">
          <div className="form_row">
            <div className="cover-image">
              <img src={event.cover} alt="" className="image"/>
            </div>
          </div>
          <div className="form_row">
            <div className="field">
              <label>Title</label>
              <input disabled value={event.title}/>
            </div>
          </div> 
          <div className="form_row">
            <div className="field">
              <label>Description</label>
              <HTMLRenderView content={event?.description} />
            </div>
          </div> 
          <div className="form_row">
            <div className="field">
              <label>Location</label>
              <input disabled value={event.location}/>
            </div>
          </div>  
          <div className="form_row">
            <div className="field">
              <label>Date</label>
              <input disabled value={GetEventDate(event)}/>
            </div>
            <div className="field">
              <i>Timezone:</i>
              <input disabled value={event.timeZone}/>
            </div>
          </div>  
          { sections?.length > 0 && 
            <div className="table">
              <div className="field">
                <h2>Sections</h2>
              </div>
              <table cellSpacing="500">
                <thead>
                  <tr>
                    <th>Title</th>
                  </tr>
                </thead>
                <tbody>
                  {sections?.map((section, idx) => (
                    <tr key={idx}>
                      <td>{section.title}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          }
          <div className="table">
              <div className="field">
                <h2>Communication groups</h2>
              </div>
              <table cellSpacing="500">
                <thead>
                  <tr>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  {communicationGroups?.map((cg, idx) => (
                    <tr key={idx}>
                      <td>{cg.name}</td>
                    </tr>
                  ))}
                  {communicationGroups.length == 0 && <td>Public</td>}
                </tbody>
              </table>
            </div>
          { translations?.length > 0 && 
            <div className="table">
              <div className="field">
                <h2>Translations</h2>
              </div>
              <table cellSpacing="500">
                <thead>
                  <tr>
                    <th>Language</th>
                    <th>Title</th>
                    <th>Description</th>
                  </tr>
                </thead>
                <tbody>
                  {translations?.map((t, idx) => (
                    <tr key={idx}>
                      <td>{t.languageCode}</td>
                      <td>{t.title}</td>
                      <td><HTMLRenderView content={t?.description} /></td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          }
          { documents?.length > 0 && 
            <div className="table">
              <div className="field">
                <h2>Documents</h2>
              </div>
              <table cellSpacing="500">
                <thead>
                  <tr>
                    <th>Name</th>
                    <th>Language</th>
                    <th>File</th>
                  </tr>
                </thead>
                <tbody>
                  {documents?.map((doc, idx) => (
                    <tr key={idx}>
                      <td>{doc.name}</td>
                      <td>{doc.languageCode}</td>
                      <td>{doc.file.name}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          }
          { links?.length > 0 && 
            <div className="table">
              <div className="field">
                <h2>Links</h2>
              </div>
              <table cellSpacing="500">
                <thead>
                  <tr>
                    <th>Name</th>
                    <th>Language</th>
                    <th>Link</th>
                  </tr>
                </thead>
                <tbody>
                  {links?.map((link, idx) => (
                    <tr key={idx}>
                      <td>{link.name}</td>
                      <td>{link.languageCode}</td>
                      <td>{link.url}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          }
          { (relatedParentEvents?.length > 0 || relatedChildEvents?.length > 0) && 
            <div className="table">
              <div className="field">
                <h2>Related events</h2>
              </div>
              { relatedParentEvents?.length > 0 && 
              <table cellSpacing="500">
                <thead>
                  <tr>
                    <th>Parent</th>
                  </tr>
                </thead>
                <tbody>
                  {relatedParentEvents?.map((e, idx) => (
                    <tr key={idx}>
                      <td>{e.title}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
              }
              { relatedChildEvents?.length > 0 && 
              <table cellSpacing="500">
                <thead>
                  <tr>
                    <th>Childs</th>
                  </tr>
                </thead>
                <tbody>
                  {relatedChildEvents?.map((e, idx) => (
                    <tr key={idx}>
                      <td>{e.title}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
              }
            </div>
          }
          <div className="buttons">
            <Button label="Back" onClick={() => backStep()} type="secondary" />
            <Button label="Save (as draft)" onClick={() => save()} type="primary" />
            {/* <PublishButton publish={publish}/> */}
          </div>
        </div> 
      </Container>
  );
};

export default ReviewSubmit;
