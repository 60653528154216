import { createGlobalStyle } from "styled-components";
import reset from "reset-css";

// All icons
// https://react-icons.github.io/react-icons/icons?name=fa

const size = {
  mobileS: "320px",
  mobileM: "375px",
  mobileL: "425px",
  tablet: "768px",
  laptop: "1024px",
  laptopM: "1200px",
  laptopL: "1440px",
  desktop: "2560px",
};

export const device = {
  mobileS: `(min-width: ${size.mobileS})`,
  mobileM: `(min-width: ${size.mobileM})`,
  mobileL: `(min-width: ${size.mobileL})`,
  tablet: `(min-width: ${size.tablet})`,
  laptop: `(min-width: ${size.laptop})`,
  laptopM: `(min-width: ${size.laptopM})`,
  laptopL: `(min-width: ${size.laptopL})`,
  desktop: `(min-width: ${size.desktop})`,
  desktopL: `(min-width: ${size.desktop})`,
};

export default createGlobalStyle`
  ${reset}

  
html {
    --color-background-prim: #2f2f2f;
    --color-background-sec: #5f5f5f;
    --color-accent-prim: #FF8972;
    --color-accent-sec: #AA6355;
    --color-text: #f2f2f2;
    --color-black: #1A1A1A;
    --color-light-grey: #D5D5D5;
    --color-medium-grey: #3A3A3A;
    --color-dark-grey: #2A2A2A;
    --color-status-new: #D5D5D5;
    --color-status-good: #31de96;
    --color-status-bad: #b32d15;
    --color-status-warning: #fc8803;
    font-size: 62.5%;
    }

:root {
    position: relative;
    min-height: 100vh;
    width:100vw;
    margin:auto;
    font-size: 62.5%;
    color: var(--color-text);
    font-family: 'Nunito', sans-serif;}
html, *{box-sizing: border-box;}
*,*:before,*:after {box-sizing: inherit;}
::-webkit-scrollbar {
    /*display: none;*/
}
::-webkit-scrollbar {
  -webkit-appearance: none;
  width: 7px;
}

::-webkit-scrollbar-thumb {
  border-radius: 4px;
  background-color: lightgray;
  box-shadow: 0 0 1px rgba(255, 255, 255, .5);
}
body{
    background-color: black;
    color: var(--color-text);
    font-family: 'Nunito', sans-serif;
    text-align: left;
    font-size: 1.6rem;
    overflow-x: hidden;
}

/*-------main_style-------*/

a{
  text-decoration: none;
  //display: inline-block;
  font-family: 'Montserrat', sans-serif;
  font-size: 1.6rem;
  color: var(--color-text);
    :hover{
    color: var(--color-accent-prim);
    opacity: .7;
    }
}

img {
  width: 100%;
  height: auto;
}

p{  
  line-height: 2.4rem;
}

b{
  font-weight: bold;
}

i{
  font-style: italic;
}

button{
  text-decoration: none;
  text-align: center;
  text-transform: uppercase;
  border-radius:100px;
  font-size: 2.4rem;
  border:var(--color-accent-prim) solid 1px;
  background-color: var(--color-background-sec);
  color: var(--color-accent-prim);
  -webkit-box-shadow: var(--color-accent-prim);
  box-shadow: var(--color-accent-prim);
  cursor: pointer;
  outline: none;
;}

table {
  width:100%;
  color: var(--color-light-grey);
  font-family: 'Nunito', sans-serif;
  th{
    border-bottom: 1px solid var(--color-light-grey);
    opacity: 1;
    position: relative;
    padding-bottom: 1rem;
  }
  tr {
    vertical-align: middle;
    &:last-of-type{
      td{
        border-bottom: none;
      }
    }
  }
  td {
    opacity: .8;
    padding: 1rem 1rem 1rem 0;
    vertical-align: middle;
    &:not(:first-of-type){
      border-bottom: 1px solid var(--color-dark-grey);
    }
  }
  &.table{
    td{
      border-bottom: 1px solid var(--color-dark-grey);
    }
  }
}

.label {
  color: var(--color-light-grey);
  font-family: 'Montserrat', sans-serif;
  font-size: 1.6rem;
  font-weight: 500;
}

input[type="file"] {
  cursor: pointer;
}

svg{
  cursor: pointer;
}

/*-------Headers-------*/

h1 {
  font-weight: 500;
  font-size: 3.6rem;
  text-transform: uppercase;
  font-family: 'Montserrat', sans-serif;
}

h2 {
  font-weight: 500;
  font-size: 1.8rem;
  font-family: 'Montserrat', sans-serif;
  a {    
    font-size: 1.8rem;
  }
}

h3 {
  font-weight: 500;
  font-size: 1.6rem;
  font-family: 'Montserrat', sans-serif;
  margin-bottom: 0.8rem;
}

h4 {
  font-weight: 500;
  font-size: 1.6rem;
  font-family: 'Montserrat', sans-serif;
}

h5 {
  font-weight: 500;
  font-size: 1.6rem;
  font-family: 'Montserrat', sans-serif;
}

h6 {
  font-weight: 500;
  font-size: 1.6rem;
  font-family: 'Montserrat', sans-serif;
}

/*-------Form_elements-------*/
label{

}

input, textarea{
  border:none;
    background-image:none;
    background-color: transparent;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none;
    font-family: 'Montserrat', sans-serif;
    font-size: 1.6rem;
    &:focus {
      outline: none;
    }  
}

input{
  padding: .4rem;
  color: var(--color-text);
  border-bottom: 1px solid var(--color-light-grey);
  font-size: 1.6rem;
}

input:focus {
    outline: none !important;
    border-bottom: 1px solid var(--color-accent-prim);
    box-shadow: 0 5px 10px -7px var(--color-accent-prim);
  }

  input[type='checkbox'] {
    accent-color: var(--color-accent-prim);
}

textarea{
  border-radius: .4rem;
  background-color:var(--color-text);
  padding: .8rem;
}

textarea:focus {
    outline: none !important;
    border: 1px solid var(--color-accent-prim);
    box-shadow: 0 5px 10px -7px var(--color-accent-prim);
  }

select{
  width: 100%;
  padding: .4rem
}

.required:after {
    content:" *";
    color: var(--color-accent-prim);
  }

/*-------Nav_style-------*/
nav ul{
  display:flex;
  flex-direction:row;
  justify-content: space-between;
}
nav a{ 
  font-size: 1.6rem;
  text-decoration: none;
  color: var(--color-text);
  width: 100%;
  padding-left: 1.6rem;
  margin-top: 2.4rem;
  display: flex;
  align-items: center;
  text-transform: uppercase;
  @media ${device.laptop} {
    padding-left: 4rem;
  }
  
  & svg{
    margin-right: .8rem;
    width: 2rem;
    height: 2rem;
  }
}
nav a
:hover{
  color: var(--color-accent-prim);
  opacity: .7;
  & svg{
      path{
        fill: var(--color-accent-prim);
      }
    }
}
&.active{
    color: var(--color-accent-prim);
    & svg{
      path{
        fill: var(--color-accent-prim);
      }
    }
  }
nav ul {
  flex-direction:column;
  text-align:left;
}

/*------------------------*/
.card{
  position: relative;
  border-radius: 1rem;
  background-color: white;
  color: black;
  height: 100%;
  display: flex;
  flex-direction: column;
  box-shadow: black 5px 6px 12px -5px;
  &:hover{
    opacity: .9;
  }
  a:hover{
    color: white;
    opacity: .9;
  }
    &__title{
      font-family: "Montserrat";
      font-weight: 500;
      font-size: 1.8rem;
    }
    &__subtitle {
      color: var(--color-accent-prim);
      font-family: "Montserrat";
      font-weight: 500;
      font-size: 1.2rem;
      display: flex;
      align-items: center;
      svg{
        margin-left: .2rem;
      }
      path{
        fill: var(--color-accent-prim);
      }
    }
  }
  .card_image__container {
    position: relative;
    width: 100%;
    aspect-ratio: 16 / 9;
    overflow: hidden;
    .cover {
      cursor: pointer;
      position: absolute;
      top: 0;
      left: 0;
      z-index: 0;
      width: 100%;
      height: 100%;
      img{
        border-radius: 0.8rem 0.8rem 0 0;
        width: 100%;
        height: 100%;
        object-fit: cover;
      } 
    }
    .logo{      
      position: absolute;
      top: auto;
      left: 1rem;
      bottom: 1rem;
      z-index: 1;
      img{
        width: 8rem;        
      } 
    }
  }
  .edit-button {
    position: absolute;
    top: 0.8rem;
    left: 0.8rem;
    cursor: pointer;
  }
  .card-button {
    position: absolute;
    top: 0.8rem;
    right: 0.8rem;
    cursor: pointer;
    z-index: 1;
    font-size: 2.4rem;
      & svg{
        path{
          fill: var(--color-accent-prim);
          opacity: 1;
        }
    }
  }
  .card__content {
    padding: 1.2rem 0.8rem;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    border-top: 1px solid rgba(0,0,0,0.3);
    color: var(--color-dark-grey);
    height: calc(100% - 20rem);
    &__header {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
    &__body {
      margin: 1.2rem 0;
      font-weight: 300;
    }
    &__footer {
      margin: 1.2rem 0 0 0;
      display: flex;
      align-items: center;
      justify-content: space-between;
      font-size: 1.2rem;
      font-family: "Montserrat";
    }
  }

  .switch-container{
    display: flex;
  }
  .switch-item {
    margin: 1.4rem;
    &-column{
      flex-direction: column;
    }
  }
  .modal-buttons {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    button {
      margin-right: 1rem;
    }
  }
  /*------------------------*/

  .cover-image {   
    aspect-ratio: 16 / 9;
    width: 37rem;
    position: relative;
    border-radius: .8rem;  
  }

  .page__header__sub > * {
    margin-left: 1rem;
    margin-top: 1rem;
  }

  .grid-add-btn {
    display: flex;
    align-items: center;
    margin: .8rem 0;
  }
  
  .thumbnail{
    padding: 6rem;
  }
  
  .react-selectcomponent{
    color: var(--color-black);
  }

  Form, .form{
    label {
      display: block;
      margin-bottom: 0.4rem;
    }
    p {
      margin-top: 0.4rem;
      margin-bottom: 0.4rem;
    }
    input {
      width: 100%;
    }
    textarea {
      width: 100%;
      height: 10rem;
    }
    .form_row {
      margin-bottom: 2rem;
      display: flex;
      width: 100%;
      .field {
        width: 100%;
        &:not(:first-of-type) {
          margin-left: 2.4rem;
        }
      }
    }
    .error-message {
      opacity: 0.6;
      color: red;
      padding: 1rem 0;
    }
    .buttons {
      margin-top: 1rem;
      display: flex;
      align-items: center;
      justify-content: flex-end;
      button {
        margin-right: 1rem;
      }
    }
  }

  .icon-button{
    cursor: pointer;  
    display: flex;
    align-items: center;
    color: var(--color-text);
    label{
      cursor: pointer;  
      margin-left: .4rem;
      color: white;
    }
    svg{
      path{
        fill: var(--color-accent-prim);
      }
    }
    :hover{
      & label{
        color: var(--color-accent-prim);
      }
      & svg{
        path{
          opacity: .7;
        }
      }
    }
  }

  .grid-action-button{
    justify-content: end;
  }

  .navigation-banner{
    display: grid;
    grid-template-columns: auto auto;
    align-items: baseline;
    margin-bottom: 2rem;
  }

  .back{
    :hover{
      opacity: .7;
      color: var(--color-accent-prim);
      & svg{
        path{
          fill: var(--color-accent-prim);
        }
      }
    }
  }

  .breadcrumbs{
    display: flex;
    justify-content: end;
    li{       
      display: inline-block;
      :not(:last-child):after {
        content: " >";
      }
      margin-right: .4rem;
    }
    .selected{
      opacity: .7;
      font-style: italic;      
    }
  }

  .searchBarContainer {
    margin: 2rem 0;
    overflow: auto;
    table {
      width:100%;
      //min-width: 100rem;
    }
  }

  .switch-group {
    display: flex;
    border-bottom: 1px solid rgba(255, 255, 255, 0.2);
    margin-bottom: 1.2rem;
  }
  
  .switch-item {
    p {
      padding-bottom: 0.4rem;
      font-weight: normal;
      font-size: 1.6rem;
      min-height: 3.3rem;
    }
  }

  .switch-group-full-page {
    display: flex;
    margin-bottom: 1.2rem;
  }
  
  .switch-item-full-page {
    width: 100%;
    p {
      padding-bottom: 0.4rem;
      font-weight: normal;
      font-size: 1.6rem;
      min-height: 3.3rem;
    }
  }

  .filters .row {
    margin: 1rem 1rem 2rem 0rem;

    & > div {
      margin-right: 2rem;
      display: flex;
      flex-direction: column;
    }
  }

  .section {
    margin-bottom: 2rem;
  } 
  
  .docs-info {
    display: flex;
    justify-content:flex-end;
    gap: 1rem;
    margin-bottom: .8rem;
    img {
      position: relative;
      width: 1.8rem;
      max-height: 1.4rem;
      margin-right: .4rem
    }
  }
  
  .action-button {
    cursor: pointer;
  }

  .tab__item {
    margin-bottom: 4rem;
    &.loading {
      opacity: 0.8;
    }
    .tab__header {
      display: grid;
      grid-template-columns: auto 400px;
      &__left{
        input{
          margin-left: 1rem;
        }
      }
      &__right{
        display: flex;
        justify-content: end;
        align-items: center;
        > * {
          margin-left: 1rem;
        }
        .icon-button {
          margin-left: 1rem;
          svg {
            width: 2rem;
            height: auto;
          }
        }
      }
    }    
  }

  .tab__content {
      margin-top: 1.2rem;
      display: flex;
      .tab__thumb {
        width: 30%;
        height: 100%;
        padding-bottom: 20%;
        position: relative;
        min-width: 30rem;
      }
      img {
        border-radius: .8rem;
      }
      .textarea__container{
        width: calc(70% - 3rem);
        margin-left: 3rem;
      }
      .tab__text {
        margin-left: 3rem;
      }
    }
 
  .follow-button {
    z-index: 1;
  }

  .good {
  color: var(--color-status-good);
  }
  
  .bad {
    color: var(--color-status-bad);
  }

  .button__container {
    margin: auto;
    text-align: center;
    display: flex;
    justify-content: center;
    overflow: hidden;
    flex-direction: row;
  }
  .button__container:before,
  .button__container:after {
    content: "";
    flex: 1 1;
    border-bottom: 1px solid var(--color-light-grey);
    margin: auto;
  }
  .button__container:before {
    margin-right: 10px;
  }
  .button__container:after {
    margin-left: 10px;
  }

`;
