export default function IconHeaderArrow( { transform } ) {
  const rotateDegree = transform ? "rotate(0)" : "rotate(180)";

    return (
    <svg
      width="19"
      height="9"
      viewBox="0 0 19 9"
      fill="none"
      transform={rotateDegree}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10.1393 8.4685C9.76877 8.77655 9.23123 8.77655 8.8607 8.4685L0.802409 1.76896C0.0827644 1.17065 0.505839 -1.87908e-06 1.44171 -1.79726e-06L17.5583 -3.88306e-07C18.4942 -3.0649e-07 18.9172 1.17066 18.1976 1.76896L10.1393 8.4685Z"
        fill="#C4C4C4"
      />
    </svg>
  );
}
