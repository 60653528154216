import React, { useState } from "react";
import HeaderDropDown from "../../components/UI/HeaderDropDown";
import PageHeaderSub from "../../components/PageHeaderSub";
import Page from "../../components/UI/Elements/Page";
import CategoryForm from "./Components/CategoryForm";
import PressReleaseWizard from "./Wizard/PressReleaseWizard";
import EventWizard from "./Wizard/EventWizard";

const AddEvent = () => {
  const [category, setCategory] = useState(null);

  if (!category){
    return (
      <>
        <Page>
          <div className="page__header">
            <PageHeaderSub></PageHeaderSub>
          </div>
          <div className="page__inner">
              <HeaderDropDown titleHeader="Select category">
                <CategoryForm setCategory={setCategory}/>       
              </HeaderDropDown>    
          </div>
        </Page>
      </>
    );
  }
  else if (category !== "other1"){
    return (
      <>
        <Page>
          <div className="page__header">
            <PageHeaderSub></PageHeaderSub>
          </div>
          <div className="page__inner">
            <PressReleaseWizard category={category}/>
          </div>
        </Page>
      </>
    );
  }
  else {
    return (
      <>
        <Page>
          <div className="page__header">
            <PageHeaderSub></PageHeaderSub>
          </div>
          <div className="page__inner">
            <EventWizard category={category}/> 
          </div>
        </Page>
      </>
    );
  }
};

export default AddEvent;
