import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { useParams } from "react-router-dom";
import HeaderDropDown from "../../components/UI/HeaderDropDown";
import Page from "../../components/UI/Elements/Page";
import Loader from "components/UI/Loader";
import { BackButton } from "components/UI/Buttons/BackButton";
import { UseErrorContext } from "contexts";
import PageHeaderSub from "components/PageHeaderSub";
import { getOrganization, getOrganizationProfileSection, unfollowOrganization, followOrganization, getFeaturedEvents } from "api/services/organization";
import OrganizationInfo from "./Components/OrganizationInfo";
import Button from "components/UI/Buttons/Button";
import Modal from "components/UI/Modal";
import { useHistory } from "react-router-dom";
import SectionInfo from "components/SectionInfo";
import { Link } from "react-router-dom";
import FollowedEventGrid from "pages/events/Components/FollowedEventGrid";
import { useTranslation } from 'react-i18next';
import LinksHeader from "components/LinksHeader";

const Container = styled.div``;

const ViewFollowedOrganization = () => {
  const { id } = useParams();
  const history = useHistory();
  const [organization, setOrganization] = useState(null);
  const [organizationSections, setOrganizationSections] = useState([]);
  const [featuredEvents, setFeaturedEvents] = useState([]);
  const { updateError } = UseErrorContext();
  const [showModalDelete, setShowModalDelete] = useState(false);
  const { t, i18n } = useTranslation();

  useEffect(async () => {
    if (id)
      await refresh();
  }, [id]);

  const refresh = async () => {
    try{
      const p = await getOrganization(id);
      setOrganization(p);

      const sect = await getOrganizationProfileSection(id);
      setOrganizationSections(sect);

      const ev = await getFeaturedEvents(id);
      setFeaturedEvents(ev);
    } catch (err) {
      updateError(err);
    }
  };

  const _unfollowOrganization = async () => {
    try{
      await unfollowOrganization(id);
      setShowModalDelete(false);
      refresh();
    } catch (err) {
      updateError(err);
    }
  }; 
  
  const _followOrganization = async () => {
    try{      
      await followOrganization({'organizationId': id});
      refresh();
    } catch (err) {
      updateError(err);
    }
  };

  return (
    <>    
      {showModalDelete && (
        <Modal title={`Unfollow "${organization.name}"`} onClose={() => setShowModalDelete(false)} onSubmit={() => _unfollowOrganization()} cancelLabel={"Cancel"} submitLabel={"Confirm"}>
          <div>{t('confirmunfolloworganization')}</div>
        </Modal>
      )}
      <Page>
        {!organization ? (
          <Loader />
        ) : (
          <Container>
            <div className="navigation-banner">              
              <BackButton link="/organizations"/>
              <ul className="breadcrumbs">
                <li>
                  <a href="/organizations">{t('organizations')}</a>
                </li>
                <li>
                  <span className="selected">{organization?.name}</span>
                </li>
              </ul>
            </div>     
            <div className="page__header">              
              <h1></h1>
              <PageHeaderSub show>  
                {organization.isFollowed 
                  ? <Button onClick={() => setShowModalDelete(true)} label={t('unfollow')} type="tertiary"/>
                  : <Button onClick={() => _followOrganization()} label={t('follow')}/>
                }
              </PageHeaderSub>
            </div>
            <LinksHeader>
                <Link to={`/organizations/view/${organization.id}/documents`}>
                    <Button label={t('documentsandlinks')} type="secondary"/>
                </Link>                      
                <Link to={`/organizations/view/${organization.id}/products`}>
                    <Button label={t('shares')} type="secondary"/>
                </Link>                        
                <Link to={`/organizations/view/${organization.id}/events`}>
                    <Button label={t('actions')} type="secondary"/>
                </Link>
                <Link to={`/organizations/view/${organization.id}/news`}>
                    <Button label={t('news')} type="secondary"/>
                </Link>
            </LinksHeader>
            <div className="page__inner">
              <HeaderDropDown titleHeader={t('intro')} showCollapse>              
                <OrganizationInfo organization={organization}/>
              </HeaderDropDown>
              { featuredEvents.length > 0 &&
                <HeaderDropDown titleHeader={t('featuredevents')} showCollapse>              
                  <FollowedEventGrid canEdit events={featuredEvents} link={`/organizations/view/${id}/events/`}/>
                </HeaderDropDown>
              }
              {organizationSections.map((section, idx) => (
                section.isVisible &&
                //  <HeaderDropDown titleHeader={section.title} background="section.imageUrl" showCollapse bodyStyle={{backgroundImage: "url(" + section.imageUrl + ")", backgroundSize: "cover"}}>              
                 <HeaderDropDown titleHeader={section.title} showCollapse>              
                    <SectionInfo section={section} backupImage={organization?.logoUrl}/>
                 </HeaderDropDown>
                ))}
            </div>
          </Container>
        )}
      </Page>
    </>
  );
};

export default ViewFollowedOrganization;
