export default function HeaderBullets() {
  return (
    <svg
      width="29"
      height="7"
      viewBox="0 0 29 7"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="25.5" cy="3.5" r="3.5" fill="#C4C4C4" />
      <circle cx="14.5" cy="3.5" r="3.5" fill="#C4C4C4" />
      <circle cx="3.5" cy="3.5" r="3.5" fill="#C4C4C4" />
    </svg>
  );
}
