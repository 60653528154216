import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { useHistory } from "react-router-dom";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import Loader from "components/UI/Loader";
import Button from "components/UI/Buttons/Button";
import Image from "components/UI/Images/Image";
import { getCategories, getTimeZones, removeDocument } from "api/services/event";
import { Tooltip } from "components/UI/Tooltip";
import { getOrganization } from "api/services/organization";
import { UseErrorContext, UseOrganizationContext } from "contexts";
import { pdfjs, Document, Page } from "react-pdf";
import MDEditor from "components/UI/Blocks/MDEditor";
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;
import { EventCategory } from "constants";
import { resizeImage } from "utils";
import SwitchButtonOn from "components/UI/Buttons/SwitchButtonOn";

const FormWrapper = styled.div`
  .top-field {
    display: flex;
    gap: 4rem;
    .actions {
      display: flex;
      gap: 2rem;
      button {
        align-self: baseline;
      }
    }
  }

  .react-pdf__Page__textContent,
  .react-pdf__Page__annotations {
    display: none;
  }

  .react-pdf__Page {
    min-height: 815px !important;
  }

  .pdf-buttons {
    display: flex;
    align-items: center;
    gap: 1.2rem;
    margin-top: 0.8rem;
  }

  .enable-end-date{
    display: flex;
    align-items: center;
    gap: 1.2rem;
  }
`;

const Toast = styled.div`
  position: fixed;
  bottom: 0;
  right: 10%;
  text-align: center;
  border-top-right-radius: 0.4rem;
  border-top-left-radius: 0.4rem;
  padding: 1.2rem 2.4rem;
  background-color: #1a1a1a;
  .toast_message {
    color: white;
  }
`;

const ValidationSchema = Yup.object().shape({
  title: Yup.string().required("Required").max(100),
  description: Yup.string().nullable().max(10000),
  location: Yup.string().required("Required"),
  startDate: Yup.date().required("Required"),
  endDate: Yup.date(),
  timeZone: Yup.string().required("Required"),
});

const ValidationSchemaPressRelease = Yup.object().shape({
  title: Yup.string().required("Required").max(100),
  description: Yup.string().nullable(),
  location: Yup.string().required("Required"),
  startDate: Yup.date().required("Required"),
  endDate: Yup.date(),
  timeZone: Yup.string().required("Required"),
});

export const EventForm = ({ save, event, docData, docID, setDocAsContent, children, isReadOnly, isSaving }) => {
  const history = useHistory();
  const [coverFile, setCoverFile] = useState(event?.coverUrl);
  const [cover, setCover] = useState(event?.coverUrl);
  const [uploadingCover, setUploadingCover] = useState(false);
  const [categories, setCategories] = useState([]);
  const [timeZones, setTimeZones] = useState([]);
  const [profile, setProfile] = useState();
  const [profileAddress, setProfileAddress] = useState("");
  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);
  const [toastMessage, setToastMessage] = useState();
  const [showToast, setShowToast] = useState(false);
  const [enableEndDate, setEnableEndDate] = useState(event.startDate != event.endDate);

  const { updateError } = UseErrorContext();
  const { id } = UseOrganizationContext();

  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);
  }

  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);
    setPageNumber(1);
  }

  function changePage(offset) {
    setPageNumber((prevPageNumber) => prevPageNumber + offset);
  }

  function previousPage() {
    changePage(-1);
  }

  function nextPage() {
    changePage(1);
  }

  useEffect(async () => {
    try {
      var cats = await getCategories();
      setCategories(cats);

      var tz = await getTimeZones();
      setTimeZones(tz);

      let organizationData = await getOrganization(id);
      setProfile(organizationData);
      if (!event) {
        setCover(organizationData?.logoUrl);
        setCoverFile(organizationData?.logoUrl);
        if (organizationData?.addresses.length > 0) {
          const { addressLine1, addressLine2, addressLine3, postalCode, city, countryName } = organizationData?.addresses[0]?.address;
          setProfileAddress(`${addressLine1} ${addressLine2 || ""} ${addressLine3 || ""}, ${postalCode} ${city}, ${countryName}`);
        }
      }
    } catch (err) {
      updateError(err);
    }
  }, []);

  const previewImage = async (e) => {
    setUploadingCover(true);
    let selectedFile = e.target.files[0];

    let fileSizeInMegabytes = selectedFile.size / (1024 * 1024);

    if (fileSizeInMegabytes > 10) {
      updateError({ message: t("Image should be smaller then 10mb.") });
      setUploadingCover(false);
      return;
    }

    const resizedImage = await resizeImage(selectedFile, 1250);

    setCoverFile(resizedImage);

    let reader = new FileReader();
    reader.onload = function (event) {
      setCover(event.target.result);
    };

    reader.readAsDataURL(selectedFile);
    setUploadingCover(false);
  };

  useEffect(() => {
    const timer = setTimeout(() => {
      setShowToast(false);
    }, 3000);

    // To clear or cancel a timer, you call the clearTimeout(); method,
    // passing in the timer object that you created into clearTimeout().

    return () => clearTimeout(timer);
  }, [showToast]);

  return (
    <FormWrapper className="form">
      <Formik
        enableReinitialize={true}
        initialValues={{
          title: event?.title,
          description: event?.description,
          location: event?.location || profileAddress,
          category: event?.category ?? categories[0],
          startDate: event?.startDate,
          endDate: event?.endDate,
          isPublic: event?.isPublic ?? true,
          timeZone: event?.timeZone ?? Intl.DateTimeFormat().resolvedOptions().timeZone,
          file: docData || null,
        }}
        validationSchema={() =>
          Yup.lazy((values) => {
            console.log("calidate", values);
            if (values?.category != "other1") {
              return ValidationSchemaPressRelease;
            }
            return ValidationSchema;
          })
        }
        onSubmit={async (values, { setSubmitting }) => {
          values.coverUrl = coverFile;

          //other
          if (!enableEndDate) {
            values.endDate = values.startDate;
          }

          await save(values);

          setSubmitting(false);
          setShowToast(true);
          setToastMessage("Event saved!");
        }}
      >
        {({ isSubmitting, handleSubmit, setFieldValue, values }) =>
          values.category != "other1" ? (
            <Form>
              <div className="form_row">
                <div className="field">
                  <label htmlFor="category" className="label required">
                    Category
                  </label>
                  <Field as="select" name="category">
                    {categories?.map((category, idx) => (
                      <option value={category} key={idx}>
                        {EventCategory[category]}
                      </option>
                    ))}
                  </Field>
                </div>
              </div>
              <label className="label">Cover</label>
              <div className="form_row">
                <div className="field top-field">
                  <div className="cover-image">
                    <Image url={cover} onChange={previewImage} isLoading={uploadingCover} />
                  </div>
                  <div className="actions">{children}</div>
                </div>
              </div>
              <div className="form_row">
                <div className="field">
                  <label htmlFor="title" className="required">
                    Title
                  </label>
                  <Field type="text" name="title" />
                  <ErrorMessage className="error-message" name="title" component="div" />
                </div>
              </div>
              <div className="form_row">
                <div className="field">
                  <label htmlFor="location" className="required">
                    Location
                  </label>
                  <Field type="text" name="location" />
                  <ErrorMessage className="error-message" name="location" component="div" />
                </div>
              </div>
              <div className="form_row">
                <div className="field">
                  <label htmlFor="description" className="">
                    Description
                  </label>
                  <MDEditor value={values.description} setFieldValue={(val) => setFieldValue("description", val)} />
                  <ErrorMessage className="error-message" name="description" component="div" />
                </div>
              </div>
              <div className="form_row enable-end-date">
                <label>Enable start and end date</label> <SwitchButtonOn onSwitchOff={()=>setEnableEndDate(false)} onSwitchOn={()=>setEnableEndDate(true)} forceState on={enableEndDate} />
              </div>
              <div className="form_row">
                {enableEndDate ? (
                  <>
                    <div className="field">
                      <label htmlFor="startDate" className="required">
                        Start date
                      </label>
                      <Field type="datetime-local" name="startDate" className="date" max="9999-01-01T00:00" />
                      <ErrorMessage className="error-message" name="startDate" component="div" />
                    </div>
                    <div className="field">
                      <label htmlFor="endDate" className="required">End date</label>
                      <Field type="datetime-local" name="endDate" className="date" max="9999-01-01T00:00" />
                      <ErrorMessage className="error-message" name="endDate" component="div" />
                    </div>
                  </>
                ) : (
                  <>
                  <div className="field">
                    <label htmlFor="startDate" className="required">
                      Date
                    </label>
                    <Field type="datetime-local" name="startDate" className="date" max="9999-01-01T00:00" />
                    <ErrorMessage className="error-message" name="startDate" component="div" />
                  </div>
                </>
                )}

                <div className="field">
                  <label htmlFor="timeZone" className="required">
                    Timezone
                  </label>
                  <Field as="select" name="timeZone">
                    {timeZones?.map((timeZone, idx) => (
                      <option value={timeZone} key={idx}>
                        {timeZone}
                      </option>
                    ))}
                  </Field>
                  <ErrorMessage className="error-message" name="timeZone" component="div" />
                </div>
              </div>

              <div className="form_row">
                {!(docData && values.file) && (
                  <div className="field">
                    <label htmlFor="file" className="">
                      File
                    </label>
                    <input
                      id="cover"
                      name="document"
                      type="file"
                      onChange={(e) => {
                        console.log("e.currentTarget.files[0]", e.currentTarget.files[0]);
                        setFieldValue("file", e.currentTarget.files[0]);
                      }}
                    />
                    <ErrorMessage className="error-message" name="file" component="div" />
                  </div>
                )}

                {docData && values.file && (
                  <div className="field">
                    <label htmlFor="delete-pdf" className="">
                      Remove PDF
                    </label>
                    <Button
                      onClick={() => {
                        console.log("docData", docData);
                        removeDocument(event.id, docID);
                        setFieldValue("file", null);
                        setDocAsContent(null);
                      }}
                      label="Remove"
                      disabled={isSubmitting || isReadOnly}
                    />
                  </div>
                )}
              </div>

              <div className="">
                {values.file && (
                  <>
                    <Document file={values.file} onLoadSuccess={onDocumentLoadSuccess}>
                      <Page pageNumber={pageNumber} />
                      <div className="pdf-buttons">
                        <Button label="Previous" disabled={pageNumber <= 1} onClick={previousPage} type="secondary" />
                        Page {pageNumber || (numPages ? 1 : "--")} of {numPages || "--"}
                        <Button label="Next" disabled={pageNumber >= numPages} onClick={nextPage} type="secondary" />
                      </div>
                    </Document>
                  </>
                )}
              </div>

              {!isSubmitting ? (
                <div className="buttons">
                  <Button label="cancel" onClick={() => history.push("/events")} type="secondary" />
                  <Button onClick={handleSubmit} label={isSaving ? "Is saving..." : "Save"} type="submit" disabled={isSubmitting || isReadOnly} />
                </div>
              ) : (
                <Loader />
              )}
            </Form>
          ) : (
            <Form>
              <div className="form_row">
                <div className="field">
                  <label htmlFor="category" className="label required">
                    Category
                  </label>
                  <Field as="select" name="category">
                    {categories?.map((category, idx) => (
                      <option value={category} key={idx}>
                        {EventCategory[category]}
                      </option>
                    ))}
                  </Field>
                </div>
              </div>
              <label className="label">Cover</label>
              <div className="form_row">
                <div className="field top-field">
                  <div className="cover-image">
                    <Image url={cover} onChange={previewImage} isLoading={uploadingCover} />
                  </div>
                  <div className="actions">{children}</div>
                </div>
              </div>
              <div className="form_row">
                <div className="field">
                  <label htmlFor="title" className="required">
                    Title
                  </label>
                  <Field type="text" name="title" />
                  <ErrorMessage className="error-message" name="title" component="div" />
                </div>
              </div>
              <div className="form_row">
                <div className="field">
                  <label htmlFor="location" className="required">
                    Location
                  </label>
                  <Field type="text" name="location" />
                  <ErrorMessage className="error-message" name="location" component="div" />
                </div>
              </div>
              <div className="form_row">
                <div className="field">
                  <label htmlFor="description" className="required">
                    Description
                  </label>
                  <MDEditor value={values.description} setFieldValue={(val) => setFieldValue("description", val)} />
                  <ErrorMessage className="error-message" name="description" component="div" />
                </div>
              </div>
              <div className="form_row">
                <div className="field">
                  <label htmlFor="startDate" className="required">
                    Start date
                  </label>
                  <Field type="datetime-local" name="startDate" className="date" max="9999-01-01T00:00" />
                  <ErrorMessage className="error-message" name="startDate" component="div" />
                </div>
                <div className="field">
                  <label htmlFor="endDate" className="required">
                    End date
                  </label>
                  <Field type="datetime-local" name="endDate" className="date" max="9999-01-01T00:00" />
                  <ErrorMessage className="error-message" name="endDate" component="div" />
                </div>

                <div className="field">
                  <label htmlFor="timeZone" className="required">
                    Timezone
                  </label>
                  <Field as="select" name="timeZone">
                    {timeZones?.map((timeZone, idx) => (
                      <option value={timeZone} key={idx}>
                        {timeZone}
                      </option>
                    ))}
                  </Field>
                  <ErrorMessage className="error-message" name="timeZone" component="div" />
                </div>
              </div>
              {!isSubmitting ? (
                <div className="buttons">
                  <Button label="cancel" onClick={() => history.push("/events")} type="secondary" />
                  <Button onClick={handleSubmit} label={isSaving ? "Is saving..." : "Save"} type="submit" disabled={isSubmitting || isReadOnly} />
                </div>
              ) : (
                <Loader />
              )}
            </Form>
          )
        }
      </Formik>
      {showToast && (
        <Toast>
          <div className="toast_message">{toastMessage}</div>
        </Toast>
      )}
    </FormWrapper>
  );
};
