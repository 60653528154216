import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { useHistory } from "react-router-dom";
import { Formik, Form, Field, ErrorMessage } from "formik";
import Loader from "components/UI/Loader";
import Button from "components/UI/Buttons/Button";
import * as Yup from 'yup'
import { addLink, editLink } from "api/services/event";
import { useParams } from "react-router-dom";
import { UseErrorContext } from "contexts";

const FormWrapper = styled.div`
  label {
    display: block;
    margin-bottom: 0.4rem;
  }
  input {
    width: 100%;
  }
  textarea {
    width: 100%;
    height: 10rem;
  }
  .form_row {
    margin-bottom: 2rem;
    display: flex;
    width: 100%;
    .field {
      width: 100%;
      &:not(:first-of-type) {
        margin-left: 2.4rem;
      }
    }
  }
  .error-message {
    opacity: 0.6;
    color: red;
    padding: 1rem 0;
  }
  .buttons {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    button {
      margin-right: 1rem;
    }
  }
`;

const ValidationSchema = Yup.object().shape({
  name: Yup.string().required('Required'),
  url: Yup.string().required('Required'),
}) 

export const LinkForm = ({ onClose, link }) => {
  const history = useHistory();  
  const { id } = useParams();
  const { updateError } = UseErrorContext();
  const languages = ['EN','NL','FR'];

  const callBack = async (values) => { 
    const resp = !link ? await _addLink(values) : await _editLink(values);
    return resp;
  };
 
  const _addLink = async (values) => {  
    try{
      return await addLink(id, values)
    } catch (err) {
      updateError(err);
    }
  };

  const _editLink = async (values) => {  
    try{
      return await editLink(id, link.id, values)
    } catch (err) {
      updateError(err);
    }
  };

  return (
    <FormWrapper>
      <Formik
        initialValues={{ 
          name: link?.name, 
          url: link?.url, 
          //languageCode: "",
        }}
        validationSchema={ValidationSchema}
        onSubmit={(values, { setSubmitting }) => {
          callBack(values)
            .then((value) => {
              onClose();
            })
            .catch((e) => {
              setSubmitting(false);
            });
        }}
      >
        {({ isSubmitting, handleSubmit }) => (
          <Form>
          <div className="form_row">
            <div className="field">
              <label htmlFor="name" className="required">Name</label>
              <Field type="text" name="name" />
              <ErrorMessage className="error-message" name="name" component="div" />
            </div>
          </div>  
          <div className="form_row">
            <div className="field">
              <label htmlFor="languageCode">Language</label>
              <Field as="select" name="languageCode">
                    <option value="">ALL</option>
                    {languages?.map((language, idx) => (
                      <option value={language} key={idx}>{language}</option>
                    ))}
              </Field>
              <ErrorMessage className="error-message" name="languageCode" component="div" />
            </div>
          </div> 
          <div className="form_row">
            <div className="field">
              <label htmlFor="url" className="required">URL</label>
              <Field type="text" name="url" />
              <ErrorMessage className="error-message" name="url" component="div" />
            </div>
          </div>           
            {!isSubmitting ? (
              <div className="buttons">
                <Button label="cancel" onClick={(e) => {onClose();}} type="secondary"/>
                <Button onClick={()=>{handleSubmit()}} label="Submit" disabled={isSubmitting} />
              </div>
            ) : (
              <Loader />
            )}
          </Form>
        )}
      </Formik>
    </FormWrapper>
  );
};
