import React, { useState, useEffect } from "react";
import HeaderDropDown from "components/UI/HeaderDropDown";
import PageHeaderSub from "components/PageHeaderSub";
import Page from "components/UI/Elements/Page";
import FollowedEventGrid from "pages/events/Components/FollowedEventGrid";
import SearchBar from "../../components/UI/SearchBar";
import { getFollowedEvents } from "../../api/services/event";
import { UseErrorContext } from "contexts";
import moment from "moment";
import { delay } from "api/helpers/datahelper";
import { Link } from "react-router-dom";
import Button from "components/UI/Buttons/Button";
import { useTranslation } from 'react-i18next';

const FollowedEvents = () => {
  const [search, setSearch] = useState("");
  const [pastEvents, setPastEvents] = useState(null);
  const [currentEvents, setCurrentEvents] = useState(null);
  const { updateError } = UseErrorContext();
  const { t, i18n } = useTranslation();
  const [fromDateFilter, setFromDateFilter] = useState(null);
  const [toDateFilter, setToDateFilter] = useState(null);

  useEffect(async () => {
    refresh();
  }, [search, fromDateFilter, toDateFilter]);

  const refresh = async () => {  
    try{ 
      var today = moment().startOf("day").format("YYYY-MM-DDTHH:mm");

      const pastEventsData = await getFollowedEvents({
        search: search,
        from: fromDateFilter ?? "",
        to: toDateFilter && toDateFilter < today ? toDateFilter : today,
        order: "DESC",
      });

      setPastEvents(pastEventsData);
    
      const currentEventsData = await getFollowedEvents({
        search: search,
        from: fromDateFilter && fromDateFilter > today ? fromDateFilter : today,
        to: toDateFilter ?? "",
        order: "ASC",
      });

      setCurrentEvents(currentEventsData);
    } catch (err) {
      updateError(err);
    }
  };

  // Search
  const onSearch = async (e) => {
    await delay(1000);
    const val = e.target.value.toString();
    setSearch(val);
  };

  // Filters
  const onChangeFromDateFilter = async (e) => {
    const val = e.target.value.toString();
    setFromDateFilter(val);
  };

  const onChangeToDateFilter = async(e) => {
    const val = e.target.value.toString();
    setToDateFilter(val);
  };

  return (
    <>
      <Page>
        <div className="page__header">
          <h1>{t('followedactions')}</h1>
          <PageHeaderSub show>
            <Link to="followed-events/calendar">
              <Button label={t('calendarview')}/>
            </Link>
          </PageHeaderSub>
        </div>
        <div className="page__inner">
          <div className="searchBarContainer">
            <SearchBar onChange={onSearch}/>
          </div>
          <div className="filters">
                <div className="row">
                  <div>
                    <label>{t('From')}: </label>
                    <input type="datetime-local" onChange={onChangeFromDateFilter} value={fromDateFilter}/>
                  </div>
                  <div>
                    <label>{t('To')}: </label>
                    <input type="datetime-local" onChange={onChangeToDateFilter} value={toDateFilter}/>
                  </div>
                </div>
          </div>
          <HeaderDropDown titleHeader={t('upcomingactions')}>
            <FollowedEventGrid canEdit events={currentEvents} refresh={refresh} link="/followed-events/view/"/>
          </HeaderDropDown>
          <HeaderDropDown titleHeader={t('pastactions')}>
            <FollowedEventGrid canEdit events={pastEvents} refresh={refresh} link="/followed-events/view/"/>
          </HeaderDropDown>
        </div>
      </Page>
    </>
  );
};

export default FollowedEvents;
