import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { useParams, useHistory } from "react-router-dom";
import HeaderDropDown from "../../components/UI/HeaderDropDown";
import Page from "../../components/UI/Elements/Page";
import { EventForm } from "./Components/EventForm";
import EventContent from "./Components/EventContent";
import { getEvent, publishEvent, updateEvent, uploadCover, getDocumentContent, addDocument, setPublishDate } from "api/services/event";
import Loader from "components/UI/Loader";
import Button from "components/UI/Buttons/Button";
import PageHeaderSub from "components/PageHeaderSub";
import { Link } from "react-router-dom";
import { ConvertToDateToUTC, ConvertToLocaleDate, FormatDateTime, FormatDateTimeDisplay } from "api/helpers/datahelper";
import EditCommunicationGroupsView from "./Components/EditCommunicationGroupsView";
import Modal from "components/UI/Modal";
import { BackButton } from "components/UI/Buttons/BackButton";
import { UseUserContext } from "contexts";
import { ValidateAccess } from 'api/services/authorization';
import Status from "components/UI/Status";
import { UseErrorContext } from "contexts";
import { deleteEvent } from "api/services/event";
import PublishButton from "components/PublishButton";
import LinksHeader from "components/LinksHeader";

const Container = styled.div``;

const EditEvent = () => {
  const { id } = useParams();
  const history = useHistory();
  const { permissions } = UseUserContext();
  const [event, setEvent] = useState(null);
  const [docData, setDocData] = useState(null);
  const [isSaving, setIsSaving] = useState(false);
  const [showModalEditComminicationGroups, setShowModalEditComminicationGroups] = useState(false);
  const [isReadOnly, setIsReadOnly] = useState(false);
  const [hasPublishRights, setHasPublishRights] = useState(false);
  const { updateError } = UseErrorContext();
  const [showModalDelete, setShowModalDelete] = useState(false);
  const [showModalPreview, setShowModalPreview] = useState(false);
  const [showEventPreview, setShowEventPreview] = useState(false);
  const [docAsContent, setDocAsContent] = useState();
  const [previewLanguage, setPreviewLanguage] = useState();
  const languages = ['EN', 'NL', 'FR'];

  const _updateEvent = async (eventData) => {
    try {
      setIsSaving(true);
      const newObject = { ...event, ...eventData };
      const resp = await updateEvent(newObject);
      if (eventData.coverUrl) {
        if (eventData.coverUrl != event.coverUrl) {
          await uploadCover(event.id, eventData.coverUrl);
        }
      }

      if (eventData.file && !docAsContent) {
        console.log("eventData.file", eventData.file);
        await addDocument(event.id, { file: eventData.file, name: eventData.title }, true);
      }

      setIsSaving(false);
      //history.push("/events");
    } catch (err) {
      updateError(err);
    }
  };

  const _handlePreviewLanguageChange = (event) => {
    setPreviewLanguage(event.target.value);
  }

  useEffect(async () => {
    const access = ValidateAccess(["Events.Publish"], permissions);
    setHasPublishRights(access);
    setIsReadOnly(!access && event?.publishStatus !== "Hidden");
  }, [event, permissions]);

  useEffect(async () => {
    reload();
  }, []);

  const reload = async () => {
    try {
      const eventData = await getEvent(id);
      eventData.startDate = FormatDateTime(eventData.startDate);
      eventData.endDate = FormatDateTime(eventData.endDate);
      if (eventData.publishDateTime)
        eventData.publishDateTime = ConvertToLocaleDate(eventData.publishDateTime);

        let docshowAsContent = eventData?.documents?.find((doc) => doc.showAsContent && doc.languageCode == "EN") || eventData?.documents?.find((doc) => doc.showAsContent && doc.languageCode == null) || eventData?.documents?.find((doc) => doc.showAsContent);

      if (docshowAsContent) {
        const docDataResp = await getDocumentContent(id, docshowAsContent.id, docshowAsContent.fileName, false)
        console.log('docDataResp', docDataResp)
        setDocData(docDataResp)
        setDocAsContent(docshowAsContent)
      }

      setEvent(eventData);
    } catch (err) {
      updateError(err);
    }
  };

  const _publishEvent = async (date) => {
    setIsSaving(true);
    try {
      if (date != null) {
        const dateUtc = ConvertToDateToUTC(date);
        await setPublishDate(event.id, dateUtc);
      } else {
        // Clear date
        await setPublishDate(event.id, null);
      }
      reload();
    } catch (err) {
      updateError(err);
    }
    finally {
      setIsSaving(false);
    }
  };

  const _publishNowEvent = async () => {
    setIsSaving(true);
    try {
      await publishEvent(event.id);
      reload();
    } catch (err) {
      updateError(err);
    }
    finally {
      setIsSaving(false);
    }
  };

  const _deleteEvent = async () => {
    try {
      await deleteEvent(id);
      setShowModalDelete(false);
      history.push("/events");
    } catch (err) {
      updateError(err);
    }
  };

  const openCommunicationGroupView = async () => {
    await reload();
    setShowModalEditComminicationGroups(true);
  };

  return (
    <>
      {showModalEditComminicationGroups && (
        <Modal title="Edit communication groups" hideActions>
          <EditCommunicationGroupsView event={event} onClose={() => setShowModalEditComminicationGroups(false)} isReadOnly={isReadOnly} />
        </Modal>
      )}
      {showModalDelete && (
        <Modal title={`Delete "${event.title}"`} onClose={() => setShowModalDelete(false)} onSubmit={() => _deleteEvent()} cancelLabel={"Cancel"} submitLabel={"Delete"}>
          <div>Are you sure you want to delete this action?</div>
        </Modal>
      )}
      {showModalPreview && (
        <Modal title={`Preview "${event.title}"`} onClose={() => setShowModalPreview(false)} onSubmit={() => setShowEventPreview(true)} cancelLabel={"Cancel"} submitLabel={"Preview"}>
          <p>In what language do you want to preview the event?</p>
          <select onChange={_handlePreviewLanguageChange} defaultValue="EN">
            {languages?.map((language, idx) => (
              <option value={language} key={idx}>{language}</option>
            ))}
          </select>
        </Modal>
      )}
      {showEventPreview && (
        <Modal title="Event Preview" showClose wide hideActions onClose={() => setShowEventPreview(false)}>
          <EventContent id={event.id} language={previewLanguage} />
        </Modal>
      )}
      <Page>
        {!event ? (
          <Loader />
        ) : (
          <Container>
            <div className="navigation-banner">
              <BackButton link="/events" />
              <ul className="breadcrumbs">
                <li>
                  <a href="/events">Actions</a>
                </li>
                <li>
                  <span className="selected">{event?.title}</span>
                </li>
              </ul>
            </div>
            <div className="page__header">
              <h1></h1>
              <PageHeaderSub show>
                <Button onClick={() => setShowModalDelete(true)} label="Delete" type="tertiary" />
                <Button onClick={() => setShowModalPreview(true)} label="Preview" type="tertiary" />
                {(event.publishStatus === "Hidden" && hasPublishRights)
                  ? <PublishButton publishDateTime={event.publishDateTime} publish={_publishEvent} publishNow={_publishNowEvent}/>
                  : <Status label={event.publishStatus} type={event.publishStatus} onClick={() => console.log()} />
                }
              </PageHeaderSub>
            </div>
            <LinksHeader>
              <Link to={`/events/edit/${event.id}/related`}>
                <Button label="Related events" type="secondary" />
              </Link>
              <Button label="Communication groups" onClick={() => openCommunicationGroupView()} type="secondary" />
              <Link to={`/events/edit/${event.id}/documents`}>
                <Button label="Documents and Links" type="secondary" />
              </Link>
              <Link to={`/events/edit/${event.id}/eventsections`}>
                <Button label="Sections" type="secondary" />
              </Link>
              {/* Translations are not yet supported by the full application  */}
              <Link to={`/events/edit/${event.id}/translations`}>
                <Button label="Translations" type="secondary" />
              </Link>
            </LinksHeader>
            <div className="page__inner">
              <HeaderDropDown titleHeader="Edit action">
                <EventForm save={_updateEvent} event={event} isReadOnly={isReadOnly} docData={docData} docID={docAsContent?.id} setDocAsContent={setDocAsContent} isSaving={isSaving} />
              </HeaderDropDown>
            </div>
          </Container>
        )}
      </Page>
    </>
  );
};

export default EditEvent;
