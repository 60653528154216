import React, { useState } from "react";
import styled from "styled-components";

// Styled components
const TabsWrapper = styled.div`
  display: flex;
  gap: 0;
  border-radius: 20px;
  overflow: hidden;
  background-color: #e0e0e0;
  padding: 8px;
  width: fit-content;
`;

const TabButton = styled.button`
  padding: 8px 16px;
  border: none;
  background-color: ${({ active }) => (active ? "#ff9982" : "#e0e0e0")};
  font-size: 12px;
  font-weight: bold;
  color: ${({ active }) => (active ? "#fff" : "#1f1f1f")};
  cursor: pointer;
  transition: background-color 0.3s ease;
  border-radius: 16px;
  outline: none;
  min-width: 120px;

  &:hover {
    background-color: ${({ active }) => (active ? "#ff9982" : "#ffb3a2")};
  }

  &:not(:last-child) {
    margin-right: 2px;
  }
`;

const ContentWrapper = styled.div`
  margin-top: 20px;
`;

export const TabContent = ({ activeTab, tabsData }) => {
  const activeTabData = tabsData.find((tab) => tab.id === activeTab);

  if (!activeTabData) {
    return <p>Invalid tab selected.</p>;
  }

  return <div>{activeTabData.content}</div>;
};

// Higher-Order Component (HOC)
export const withTabs = (WrappedComponent, tabsData) => {
  console.log("tabsData", tabsData);
  return function TabComponent() {
    const [activeTab, setActiveTab] = useState(tabsData[0].id); // Default to the first tab

    const handleTabClick = (tabId) => {
      setActiveTab(tabId);
    };

    return (
      <>
        {/* Tab Buttons */}
        <TabsWrapper>
          {tabsData.map((tab) => (
            <TabButton key={tab.id} active={activeTab === tab.id} onClick={() => handleTabClick(tab.id)}>
              {tab.title}
            </TabButton>
          ))}
        </TabsWrapper>

        {/* Render the WrappedComponent with props */}
        <ContentWrapper>
          <WrappedComponent activeTab={activeTab} tabsData={tabsData} />
        </ContentWrapper>
      </>
    );
  };
};

const DynamicTabs = ({ tabsData }) => {
  const TabsWithContent = withTabs(TabContent, tabsData); // Create the new component

  return <TabsWithContent />; // Render the new component
};

export default DynamicTabs;
