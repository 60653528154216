import { getAxiosClient } from "../axios";
import { getEvents, getFollowedEvents } from "./event";

export const getMailCategories = async () => {
  const axiosClient = await getAxiosClient();

  return await axiosClient
    .get(`/communication/categories`)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      console.log("error", error);
      return Promise.reject(error);
    });
};

export const getQuestions = async (categoryId, params) => {
  const axiosClient = await getAxiosClient();

  return await axiosClient
    .get(`/communication/categories/${categoryId}/questions`, { params: {...params}} )
    .then((response) => {
      return response;
    })
    .catch((error) => {
      console.log("error", error);
      return Promise.reject(error);
    });
};

export const getMyQuestions = async (params) => {
  const axiosClient = await getAxiosClient();

  return await axiosClient
    .get(`/me/communication/questions`, { params: {...params}} )
    .then((response) => {
      return response;
    })
    .catch((error) => {
      console.log("error", error);
      return Promise.reject(error);
    });
};

export const getMyQuestion = async (id) => {
  const axiosClient = await getAxiosClient();

  return await axiosClient
    .get(`/me/communication/questions/${id}`)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      console.log("error", error);
      return Promise.reject(error);
    });
};

export const sendAnswer = async (data) => {
  const axiosClient = await getAxiosClient();

  return await axiosClient
    .post(`/communication/answers`, data)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      console.log("error", error);
      return Promise.reject(error);
    });
};

export const updateAnswer = async (id, data) => {
  const axiosClient = await getAxiosClient();

  return await axiosClient
    .put(`/communication/answers/${id}`, data)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      console.log("error", error);
      return Promise.reject(error);
    });
};

export const deleteAnswer = async (id) => {
  const axiosClient = await getAxiosClient();

  return await axiosClient
    .delete(`/communication/answers/${id}`)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      console.log("error", error);
      return Promise.reject(error);
    });
};

export const deleteMail = async (eventId, mailId) => {
  const axiosClient = await getAxiosClient();

  return await axiosClient
    .delete(`/events/${eventId}/questions/${mailId}`)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      console.log("error", error);
      return Promise.reject(error);
    });
};

export const getFollowedFilters = async () => {  
  const events = await getFollowedEvents();
  const categories = await getCategories();

  return {"events": events, "categories": categories};
};

export const getFilters = async (organizationId) => {  
  const events = await getEvents(organizationId);
  const categories = await getCategories();

  return {"events": events, "categories": categories};
};

export const getCategories = async () => {
  const axiosClient = await getAxiosClient();

  return await axiosClient
    .get(`/communication/categories`)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      console.log("error", error);
      return Promise.reject(error);
    });
};

export const getTags = async () => {
  const axiosClient = await getAxiosClient();

  return await axiosClient
    .get(`/communication/keywords`)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      console.log("error", error);
      return Promise.reject(error);
    });
};

export const addPost = async (data) => {
  const axiosClient = await getAxiosClient();

  return await axiosClient
    .post(`/posts`, data )
    .then((response) => {
      return response;
    })
    .catch((error) => {
      console.log("error", error);
      return Promise.reject(error);
    });
};

export const deletePost = async (id) => {
  const axiosClient = await getAxiosClient();

  return await axiosClient
    .delete(`/posts/${id}`)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      console.log("error", error);
      return Promise.reject(error);
    });
};

export const addPostAttachments = async (id, data) => {
  const axiosClient = await getAxiosClient();

  const formData = new FormData();
  formData.append("file", data.file);
  formData.append("name", data.name);
  formData.append("type", data.type);

  return await axiosClient
    .post(`/posts/${id}/attachments`, formData, { "content-type": "multipart/form-data" })
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      console.log("error", error);
      return Promise.reject(error);
    });
};


export const deletePostAttachment = async (postId, id) => {
  const axiosClient = await getAxiosClient();

  return await axiosClient
    .delete(`/posts/${postId}/attachments/${id}`)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      console.log("error", error);
      return Promise.reject(error);
    });
};

export const editPost = async (id, data) => {
  const axiosClient = await getAxiosClient();

  return await axiosClient
    .put(`/posts/${id}`, data)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      console.log("error", error);
      return Promise.reject(error);
    });
};

export const getPosts = async (organizationId, params) => {
  const axiosClient = await getAxiosClient();

  return await axiosClient
    .get(`/organizations/${organizationId}/posts`, { params: {...params}} )
    .then((response) => {
      return response;
    })
    .catch((error) => {
      console.log("error", error);
      return Promise.reject(error);
    });
};

export const getMyPosts = async (params) => {
  const axiosClient = await getAxiosClient();

  return await axiosClient
    .get(`/me/posts`, { params: {...params}} )
    .then((response) => {
      return response;
    })
    .catch((error) => {
      console.log("error", error);
      return Promise.reject(error);
    });
};

export const approvePost = async (id) => {
  const axiosClient = await getAxiosClient();

  return await axiosClient
    .post(`/posts/${id}/isapproved`)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      console.log("error", error);
      return Promise.reject(error);
    });
};

export const publishPost = async (id) => {
  const axiosClient = await getAxiosClient();

  return await axiosClient
    .post(`/posts/${id}/ispublished`)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      console.log("error", error);
      return Promise.reject(error);
    });
};