import React, { useState } from "react";
import HeaderDropDown from "../../components/UI/HeaderDropDown";
import PageHeaderSub from "../../components/PageHeaderSub";
import Page from "../../components/UI/Elements/Page";
import Loader from "components/UI/Loader";
import { PostForm } from "./Components/PostForm";
import { useHistory } from "react-router-dom";

const AddPost = () => {
  const [isLoading, setIsLoading] = useState(false);
  const history = useHistory();

  return (
    <>
      <Page>
        <div className="page__header">
          <PageHeaderSub></PageHeaderSub>
        </div>
        <div className="page__inner">
          <HeaderDropDown titleHeader="Add a new post">
            { isLoading 
              ? <Loader/>
              : <PostForm onClose={() => history.push("/posts")}/>
            }
          </HeaderDropDown>
        </div>
      </Page>
    </>
  );
};

export default AddPost;
